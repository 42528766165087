import { Person } from '../mutations/person';
import { GrahpQL } from '../mutations/parametersMutations';


export default {
    createPerson: function* createPerson(payload) {
        try {
            const mutationCreatePerson = Person.createPerson;
            const response = yield GrahpQL.mutation(mutationCreatePerson, payload);
            return response.body;
        } catch (e) {
        }
    }
};