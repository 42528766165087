var query = {
    stars: `
    query stars($limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $stars: Int
    )
    {
        stars(limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            stars: $stars
        ){
            _id,
            stars,
        }
    }
    `
}
export default {
        query
};