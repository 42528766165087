import {
    FETCH_CREATE_PERSON_DATA_SUCCESS,
    FETCH_CREATE_PERSON_DATA_UNSUCCESS,
    LOADING_CREATE_PERSON
} from "../actionTypes";

const initialState = {
    loadingCreatePerson: null,
    createPersonStatus: null,
}

export default (state = initialState, action) => {
switch (action.type) {
    case LOADING_CREATE_PERSON: {
        return {
            ...state,
            loadingCreatePerson: action.payload,
        }
    }
    case FETCH_CREATE_PERSON_DATA_SUCCESS:
       return {
        ...state,
        loadingCreatePerson: false,
        createPersonStatus: "SUCCESS",
    }
    case FETCH_CREATE_PERSON_DATA_UNSUCCESS: {
        return {
            ...state,
            loadingCreatePerson: false,
            createPersonStatus: action.payload.data[0].message
        }   
   }
   default:
       return state;
    }
}