import React from 'react';
import ContentLoader, { Facebook } from 'react-content-loader';

export const MyLoaderAgeGenreAssessment = () => (
    <ContentLoader height={90}>
      <rect x="0" y="0" rx="4" ry="4" width="300" height="10" />
      <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
)

export const MyLoaderEngagementOfAdvertisement = () => (
  <ContentLoader height={40}>
    <rect x="0" y="0" rx="4" ry="4" width="300" height="5" />
    <rect x="0" y="20" rx="3" ry="3" width="250" height="5" />
  </ContentLoader>
)

export const MyLoaderScope = () => (
  <ContentLoader height={20}>
    <rect x="0" y="0" rx="15" ry="15" width="100%" height="15" />
  </ContentLoader>
)

export const MyFacebookLoader = () => <Facebook />