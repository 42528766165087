import { FETCH_STARS_DATA,
    FETCH_STARS_DATA_SUCCESS,
    FETCH_STARS_DATA_FAIL
} from '../actionTypes';

export const fetchStarsData = (data) => ({
    type: FETCH_STARS_DATA,
    payload: data
})

export const fetchStarsDataSuccess = (data) => ({
    type: FETCH_STARS_DATA_SUCCESS,
    payload: data
})

export const fetchStarsDataFail = () => ({
    type: FETCH_STARS_DATA_FAIL,
    success: false,
})