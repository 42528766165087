import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchCreatePreDraftSuccess, 
    fetchCreatePreDraftFail,
    fetchUpdateCampaignSuccess,
    fetchUpdateCampaignFail ,
    fetchCampaignForUpdateDataSuccess,
    fetchCampaignForUpdateDataFail,
    loadingCampaignPreDraft,
    loadingCampaignForUpdate,
} from "../actions/campaignCreation";
import { FETCH_CREATE_PREDRAFT, FETCH_UPDATE_CAMPAIGN, FETCH_CAMPAIGN_FOR_UPDATE_DATA, FETCH_CHANGE_TYPE } from '../actionTypes';
import api from '../api';

function* fetchCreatePreDraft(action) {
    try {
      yield put(loadingCampaignPreDraft(true));
      let result = yield api.campaignCreation.createCampaignPreDraft(action.payload);
      if(result.data != null & result.data.createCampaign.success) yield put(fetchCreatePreDraftSuccess({data: JSON.parse(result.data.createCampaign.data)}));
      else yield put(fetchCreatePreDraftFail({data:result.errors}));
      yield put(loadingCampaignPreDraft(false));
    } catch (e) {
      yield put(fetchCreatePreDraftFail());
      yield put(loadingCampaignPreDraft(false));
    }
}

function* fetchUpdateCampaign(action) {
    try {
      let result = yield api.campaignCreation.updateCampaign(action.payload);
      if(result.data != null & result.data.updateCampaign.success) yield put(fetchUpdateCampaignSuccess());
      else yield put(fetchUpdateCampaignFail({data:result.errors}));
    } catch (e) {
      yield put(fetchUpdateCampaignFail());
    }
}

function* fetchCampaignForUpdateData(action) {
    try {
      yield put(loadingCampaignForUpdate(true));
      let data = yield api.campaignCreation.getCampaignForUpdateData(action.payload);
      yield put(fetchCampaignForUpdateDataSuccess(data.campaigns));
      yield put(loadingCampaignForUpdate(false));
    } catch (e) {
      yield put(fetchCampaignForUpdateDataFail());
      yield put(loadingCampaignForUpdate(false));
    }
}

function* fetchChangeType(action) {
  try {
    let result = yield api.campaignCreation.updateCampaign(action.payload);
    if(result.data != null & !result.data.updateCampaign.success) yield put(fetchUpdateCampaignFail({data:result.errors}));
  } catch (e) {
    yield put(fetchUpdateCampaignFail());
  }
}

export default function* campaignCreation() {
    yield takeLatest(FETCH_CREATE_PREDRAFT, fetchCreatePreDraft);
    yield takeLatest(FETCH_UPDATE_CAMPAIGN, fetchUpdateCampaign);
    yield takeLatest(FETCH_CAMPAIGN_FOR_UPDATE_DATA, fetchCampaignForUpdateData);
    yield takeLatest(FETCH_CHANGE_TYPE, fetchChangeType);
}
