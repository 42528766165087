import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import NoSsr from '@material-ui/core/NoSsr';
import Select2 from 'react-select';
import components from '../../../componentsSearcher';
import { VectorMap } from "react-jvectormap";
import Table from "components/Table/Table.jsx";
import { MyLoaderAgeGenreAssessment } from '../../loaders';

function regionForYourCampaign({...props}) {
    const {
        classes,
        widgets,
        handleWidgets,
        handleChange,
        handlerAddLocation,
        handlerRemoveLocation,
        countries,
        country,
        states,
        state,
        cities,
        city,
        locations,
        colorAdd,
        mapData,
        display
    } = props;
    
    const registro = (classes, data, {...props}) => {
        return [
          data.country,
          data.state,
          data.city,
          <Button
            disabled={
              props.campaignCreation.enabledEdit ? false : true
            }
            color="danger"
            simple
            className={classes.actionButton}
            onClick={() => handlerRemoveLocation(data._id, data.state != null ? data.state : data.country)}
            key={data._id}
          >
            <Close className={classes.icon} />
          </Button>
        ]
    }
    
    const selectStyles = {
        input: base => ({
            ...base,
            '& input': {
            font: 'inherit',
            },
        }),
        };
    
    return (
      props.regions.countries.length === 0 ?
        <Card className={widgets === true ? null: classes.disabled2}>
          <CardHeader color="rose" icon>
          <GridContainer justify="space-between">
                      <GridItem xs={12} sm={12}>
                        <GridContainer justify="space-between">
                          <GridItem xs={12} sm={1}>
                            <CardIcon color="info">
                              <Assignment />
                            </CardIcon>
                          </GridItem>
                          <GridItem xs={12} sm={11} className={classes.alignCenter}>
                            <h4 className={classes.cardIconTitle}>
                            {props.tags.regionForYourCampaign}
                            </h4>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                  </GridContainer>
          </CardHeader>
          <CardBody height360={true}>
            {MyLoaderAgeGenreAssessment()}
          </CardBody>
        </Card>
        :
        <Card className={
                display === 'block' ?
                widgets === true ? null: classes.disabled2
                : classes.displayNone
            }>
                  <CardHeader color="rose" icon>
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={12}>
                        <GridContainer justify="space-between">
                          <GridItem xs={12} sm={2}>
                            <CardIcon color="info">
                              <Assignment />
                            </CardIcon>
                          </GridItem>
                          <GridItem xs={12} sm={7} className={classes.alignCenter}>
                            <h4 className={classes.cardIconTitle}>
                            {props.tags.regionForYourCampaign}
                            </h4>
                          </GridItem>
                          <GridItem xs={12} sm={2} className={classes.align}>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={8}
                              checked={widgets}
                              onClick={() => handleWidgets('region')}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody
                  className={
                      widgets === true ? null : classes.displayNone
                  }
                  >
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={8}>
                        <GridContainer justify="space-between">
                          <GridItem xs={12} sm={3}>
                              <div className={classes.root}>
                                  <NoSsr>
                                      <Select2
                                        disabled={
                                          props.campaignCreation.enabledEdit ? false : true
                                        }
                                        classes={classes}
                                        styles={selectStyles}
                                        options={countries}
                                        components={components}
                                        value={country}
                                        disabled={true}
                                        onChange={handleChange('country')}
                                        placeholder={props.tags.argentina}
                                      />
                                    
                                    <div className={classes.divider} />
                                </NoSsr>
                              </div>
                          </GridItem>
                          <GridItem xs={12} sm={3}>
                              <div className={classes.root}>
                                  <NoSsr>
                                    <Select2
                                        classes={classes}
                                        styles={selectStyles}
                                        options={states}
                                        components={components}
                                        value={state}
                                        onChange={handleChange('state')}
                                        placeholder={props.tags.selectState}
                                    />
                                    <div className={classes.divider} />
                                </NoSsr>
                              </div>
                          </GridItem>
                          <GridItem xs={12} sm={3}>
                            <div className={classes.root}>
                                  <NoSsr>
                                    <Select2
                                        classes={classes}
                                        styles={selectStyles}
                                        options={cities}
                                        components={components}
                                        value={city}
                                        onChange={handleChange('city')}
                                        placeholder={props.tags.selectCity}
                                    />
                                    <div className={classes.divider} />
                                </NoSsr>
                              </div>
                          </GridItem>
                          <GridItem xs={12} sm={3} className={classes.center}>
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                color={colorAdd} 
                                className={classes.marginRight}
                                onClick={() => handlerAddLocation()}
                                >
                                {props.tags.add}
                              </Button>
                          </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                      <Table 
                        tableHead={[
                          props.tags.country,
                          props.tags.state,
                          props.tags.city,
                          props.tags.action
                        ]}
                        tableData={
                          locations.map((item, index) => (
                            registro(classes, item, {...props})
                        ))
                        }
                        customCellClasses={[
                          classes.center,
                          classes.right,
                          classes.right
                        ]}
                        customClassesForCells={[0, 4, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.right,
                          classes.right
                        ]}
                        customHeadClassesForCells={[0, 4, 5]}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <VectorMap
                        map={"ar_mill"}
                        backgroundColor="transparent"
                        // ref="map"
                        zoomOnScroll={false}
                        containerStyle={{
                          width: "100%",
                          height: "280px"
                        }}
                        containerClassName="map"
                        regionStyle={{
                          initial: {
                            fill: null,
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                          }
                        }}
                        series={{
                          regions: [
                            {
                              values:  mapData,
                              scale: ["#000000", "#E1E1E1"],
                              normalizeFunction: "polynomial"
                            }
                          ]
                        }}
                      />
                    </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardBody height360={true}
                  className={
                    widgets === false ? null : classes.displayNone
                  }>
                  </CardBody>
        </Card>
    )
}

export default regionForYourCampaign;