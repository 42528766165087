//COMPANIES
export const FETCH_COMPANIES_DATA = "FETCH_COMPANIES_DATA";
export const FETCH_COMPANIES_DATA_SUCCESS = "FETCH_COMPANIES_DATA_SUCCESS";
export const FETCH_COMPANIES_DATA_FAIL = "FETCH_COMPANIES_DATA_FAIL";
export const FETCH_COMPANIES_DATA_ADD = "FETCH_COMPANIES_DATA_ADD";
export const FETCH_COMPANIES_DATA_ADD_SUCCESS = "FETCH_COMPANIES_DATA_ADD_SUCCESS";
export const FETCH_COMPANIES_DATA_ADD_FAIL = "FETCH_COMPANIES_DATA_ADD_FAIL";
export const FETCH_COMPANIES_NAME = "FETCH_COMPANIES_NAME";
export const FETCH_COMPANIES_NAME_SUCCESS = "FETCH_COMPANIES_NAME_SUCCESS";
export const FETCH_COMPANIES_NAME_FAIL = "FETCH_COMPANIES_NAME_FAIL";
//CAMPAIGNS
export const FETCH_CAMPAIGNS_DATA = "FETCH_CAMPAIGNS_DATA";
export const FETCH_CAMPAIGNS_DATA_SUCCESS = "FETCH_CAMPAIGNS_DATA_SUCCESS";
export const FETCH_CAMPAIGNS_DATA_FAIL = "FETCH_CAMPAIGNS_DATA_FAIL";
export const FETCH_CAMPAIGNS_DATA_ADD = "FETCH_CAMPAIGNS_DATA_ADD";
export const FETCH_CAMPAIGNS_DATA_ADD_SUCCESS = "FETCH_CAMPAIGNS_DATA_ADD_SUCCESS";
export const FETCH_CAMPAIGNS_DATA_ADD_FAIL = "FETCH_CAMPAIGNS_DATA_ADD_FAIL";
export const FETCH_CAMPAIGNS_NAME = "FETCH_CAMPAIGNS_NAME";
export const FETCH_CAMPAIGNS_NAME_SUCCESS = "FETCH_CAMPAIGNS_NAME_SUCCESS";
export const FETCH_CAMPAIGNS_NAME_FAIL = "FETCH_CAMPAIGNS_NAME_FAIL";
export const FETCH_COMPANY_AND_CAMPAIGNS_DATA = "FETCH_COMPANY_AND_CAMPAIGNS_DATA";
export const FETCH_COMPANY_AND_CAMPAIGNS_DATA_SUCCESS = "FETCH_COMPANY_AND_CAMPAIGNS_DATA_SUCCESS";
export const FETCH_COMPANY_AND_CAMPAIGNS_DATA_FAIL = "FETCH_COMPANY_AND_CAMPAIGNS_DATA_FAIL";
export const FETCH_CAMPAIGN_DATA = "FETCH_CAMPAIGN_DATA";
export const FETCH_CAMPAIGN_DATA_SUCCESS = "FETCH_CAMPAIGN_DATA_SUCCESS";
export const FETCH_CAMPAIGN_DATA_FAIL = "FETCH_CAMPAIGN_DATA_FAIL";
export const CHECK_SM_SUCCESS = "CHECK_SM_SUCCESS";
export const FETCH_CAMPAIGN_DATA_RESOURCES_SUCCESS = "FETCH_CAMPAIGN_DATA_RESOURCES_SUCCESS";
export const FETCH_CAMPAIGN_DATA_CARDINAL_SUCCESS = "FETCH_CAMPAIGN_DATA_CARDINAL_SUCCESS";
export const SUBSCRIPTION_CAMPAIGN_DATA = "SUBSCRIPTION_CAMPAIGN_DATA";
export const SUBSCRIPTION_CAMPAIGN_DATA_SUCCESS = "SUBSCRIPTION_CAMPAIGN_DATA_SUCCESS";
export const FETCH_CAMPAIGN_HEADER_AUDIT_DATA = "FETCH_CAMPAIGN_HEADER_AUDIT_DATA";
export const FETCH_CAMPAIGN_HEADER_AUDIT_DATA_SUCCESS = "FETCH_CAMPAIGN_HEADER_AUDIT_DATA_SUCCESS";
export const FETCH_CAMPAIGN_HEADER_AUDIT_DATA_FAIL = "FETCH_CAMPAIGN_HEADER_AUDIT_DATA_FAIL";
//CAMPAIGNS CREATION
export const LOADING_CAMPAIGN_PRE_DRAFT = "LOADING_CAMPAIGN_PRE_DRAFT";
export const LOADING_CAMPAIGN_FOR_UPDATE = "LOADING_CAMPAIGN_FOR_UPDATE";
export const FETCH_CLEAN_CAMPAIGN_CREATION = "FETCH_CLEAN_CAMPAIGN_CREATION";
export const FETCH_CREATE_PREDRAFT = "FETCH_CREATE_PREDRAFT";
export const FETCH_CREATE_PREDRAFT_SUCCESS = "FETCH_CREATE_PREDRAFT_SUCCESS";
export const FETCH_CREATE_PREDRAFT_FAIL = "FETCH_CREATE_PREDRAFT_FAIL"; 
export const FETCH_UPDATE_CAMPAIGN = "FETCH_UPDATE_CAMPAIGN";
export const FETCH_UPDATE_CAMPAIGN_SUCCESS = "FETCH_UPDATE_CAMPAIGN_SUCCESS";
export const FETCH_UPDATE_CAMPAIGN_FAIL = "FETCH_UPDATE_CAMPAIGN_FAIL";
export const FETCH_CHANGE_SPECIFICTARGET = "FETCH_CHANGE_SPECIFICTARGET";
export const FETCH_CHANGE_TYPE = "FETCH_CHANGE_TYPE";
export const FETCH_CHANGE_NAME = "FETCH_CHANGE_NAME";
export const FETCH_CHANGE_STARTDT = "FETCH_CHANGE_STARTDT";
export const FETCH_CHANGE_ENDDT = "FETCH_CHANGE_ENDDT";
export const FETCH_CHANGE_INVESTMENT = "FETCH_CHANGE_INVESTMENT";
export const FETCH_CHANGE_STOCK = "FETCH_CHANGE_STOCK";
export const FETCH_CHANGE_CUSTOM_PRICES_ENABLED = "FETCH_CHANGE_CUSTOM_PRICES_ENABLED";
export const FETCH_CHANGE_CUSTOM_AD_PRICE = "FETCH_CHANGE_CUSTOM_AD_PRICE";
export const FETCH_CHANGE_CUSTOM_INSIGHT_MULTIPLIER = "FETCH_CHANGE_CUSTOM_INSIGHT_MULTIPLIER";
export const FETCH_CHANGE_STATUS = "FETCH_CHANGE_STATUS"; 
export const FETCH_CHANGE_CATEGORIES = "FETCH_CHANGE_CATEGORIES";
export const FETCH_CHANGE_CATEGORIES_ADD = "FETCH_CHANGE_CATEGORIES_ADD";
export const FETCH_CHANGE_CATEGORIES_REMOVE = "FETCH_CHANGE_CATEGORIES_REMOVE";
export const FETCH_CHANGE_RESOURCE_VALUE = "FETCH_CHANGE_RESOURCE_VALUE";
export const FETCH_CHANGE_RESOURCE_ENABLED = "FETCH_CHANGE_RESOURCE_ENABLED";
export const FETCH_CHANGE_SEXES = "FETCH_CHANGE_SEXES";
export const FETCH_CHANGE_SEXES_ADD = "FETCH_CHANGE_SEXES_ADD";
export const FETCH_CHANGE_SEXES_REMOVE = "FETCH_CHANGE_SEXES_REMOVE";
export const FETCH_ADD_PEOPLE = "FETCH_ADD_PEOPLE";
export const FETCH_REMOVE_PEOPLE = "FETCH_REMOVE_PEOPLE";
export const FEtCH_UPDATE_PEOPLE = "FEtCH_UPDATE_PEOPLE";   
export const FETCH_IMAGE_URL_SUCCESS = "FETCH_IMAGE_URL_SUCCESS"; 
export const FETCH_VIDEO_URL_SUCCESS = "FETCH_VIDEO_URL_SUCCESS"; 
export const DELETE_SPOT = "DELETE_SPOT";
export const FETCH_CHANGE_REGION = "FETCH_CHANGE_REGION";
export const FETCH_ADD_REGION = "FETCH_ADD_REGION";
export const FETCH_REMOVE_REGION = "FETCH_REMOVE_REGION";
export const FETCH_CAMPAIGN_FOR_UPDATE_DATA = "FETCH_CAMPAIGN_FOR_UPDATE_DATA";
export const FETCH_CAMPAIGN_FOR_UPDATE_DATA_SUCCESS = "FETCH_CAMPAIGN_FOR_UPDATE_DATA_SUCCESS";
export const FETCH_CAMPAIGN_FOR_UPDATE_DATA_FAIL = "FETCH_CAMPAIGN_FOR_UPDATE_DATA_FAIL";
export const FETCH_CHANGE_STARS = "FETCH_CHANGE_STARS";
export const FETCH_CHANGE_AGES = "FETCH_CHANGE_AGES";
export const FETCH_CHANGE_SPOTEFFECTIVENESSES = "FETCH_CHANGE_SPOTEFFECTIVENESSES";
export const FETCH_CHANGE_SPORNSORSHIP_EFFECTIVENESSES = "FETCH_CHANGE_SPORNSORSHIP_EFFECTIVENESSES";
export const FETCH_CHANGE_ADVERTISING_EFFECTIVENESSES = "FETCH_CHANGE_ADVERTISING_EFFECTIVENESSES";
export const FETCH_CHANGE_BRIEF = "FETCH_CHANGE_BRIEF";
export const FETCH_CHANGE_PAYMENT_TYPE = "FETCH_CHANGE_PAYMENT_TYPE";
export const FETCH_CHANGE_INFLUENCEPEOPLEAS = "FETCH_CHANGE_INFLUENCEPEOPLEAS";
export const FETCH_CHANGE_FOLLOWERS = "FETCH_CHANGE_FOLLOWERS";
export const FETCH_CHANGE_PAYMENT_PER_EACH = "FETCH_CHANGE_PAYMENT_PER_EACH";
export const FETCH_CHANGE_PRODUCT_PAYMENT_DESCRIPTION = "FETCH_CHANGE_PRODUCT_PAYMENT_DESCRIPTION";
export const FETCH_CHANGE_ADS_PER_IR = "FETCH_CHANGE_ADS_PER_IR";
export const FETCH_CHANGE_SPOT_DESCRIPTION = "FETCH_CHANGE_SPOT_DESCRIPTION";
export const FETCH_CHANGE_MEDIA = "FETCH_CHANGE_MEDIA";
export const FETCH_LOAD_ALL_REFERENCES_DATA = "FETCH_LOAD_ALL_REFERENCES_DATA";
export const FETCH_LOAD_ALL_REFERENCES_DATA_SUCCESS = "FETCH_LOAD_ALL_REFERENCES_DATA_SUCCESS";
export const FETCH_CHANGE_VIRTUAL = "FETCH_CHANGE_VIRTUAL";
export const FETCH_CHANGE_CELLPHONE = "FETCH_CHANGE_CELLPHONE";
//CAMPAIGN SUBSCRIPTION
export const SUBSCRIPTION_SCOPE = "SUBSCRIPTION_SCOPE";
export const SUBSCRIPTION_SCOPE_SUCCESS = "SUBSCRIPTION_SCOPE_SUCCESS";
export const SUBSCRIPTION_ON = "SUBSCRIPTION_ON";
export const SUBSCRIPTION_OFF = "SUBSCRIPTION_OFF";
export const SUBSCRIPTION_RECALCULATING = "SUBSCRIPTION_RECALCULATING";
export const SUBSCRIPTION_SCOPE_CLOSE = "SUBSCRIPTION_SCOPE_CLOSE";
//AGES
export const FETCH_AGES_DATA = "FETCH_AGES_DATA";
export const FETCH_AGES_DATA_SUCCESS = "FETCH_AGES_DATA_SUCCESS";
export const FETCH_AGES_DATA_FAIL = "FETCH_AGES_DATA_FAIL";
//REGIONS
export const FETCH_REGION_SELECTION = "FETCH_REGION_SELECTION";
export const FETCH_REGION_SELECTION_FOR_DB = "FETCH_REGION_SELECTION_FOR_DB";
export const FETCH_COUNTRIES_DATA = "FETCH_COUNTRIES_DATA";
export const FETCH_COUNTRIES_DATA_SUCCESS = "FETCH_COUNTRIES_DATA_SUCCESS";
export const FETCH_COUNTRIES_DATA_FAIL = "FETCH_COUNTRIES_DATA_FAIL";
export const FETCH_STATES_DATA = "FETCH_STATES_DATA";
export const FETCH_STATES_DATA_SUCCESS = "FETCH_STATES_DATA_SUCCESS";
export const FETCH_STATES_DATA_FAIL = "FETCH_STATES_DATA_FAIL";
export const FETCH_CITIES_DATA = "FETCH_CITIES_DATA";
export const FETCH_CITIES_DATA_SUCCESS = "FETCH_CITIES_DATA_SUCCESS";
export const FETCH_CITIES_DATA_FAIL = "FETCH_CITIES_DATA_FAIL";
//CATEGORIES
export const FETCH_CATEGORIES_DATA = "FETCH_CATEGORIES_DATA";
export const FETCH_CATEGORIES_DATA_SUCCESS = "FETCH_CATEGORIES_DATA_SUCCESS";
export const FETCH_CATEGORIES_DATA_FAIL = "FETCH_CATEGORIES_DATA_FAIL";
//SEXES
export const FETCH_SEXES_DATA = "FETCH_SEXES_DATA";
export const FETCH_SEXES_DATA_SUCCESS = "FETCH_SEXES_DATA_SUCCESS";
export const FETCH_SEXES_DATA_FAIL = "FETCH_SEXES_DATA_FAIL";
//STARS
export const FETCH_STARS_DATA = "FETCH_STARS_DATA";
export const FETCH_STARS_DATA_SUCCESS = "FETCH_STARS_DATA_SUCCESS";
export const FETCH_STARS_DATA_FAIL = "FETCH_STARS_DATA_FAIL";
//SPONSORSHIPEFFECTIVENESSES
export const FETCH_SPONSORSHIPEFFECTIVENESSES_DATA = "FETCH_SPONSORSHIPEFFECTIVENESSES_DATA";
export const FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_SUCCESS = "FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_SUCCESS";
export const FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_FAIL = "FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_FAIL";
//SPOTEFFECTIVENESSES
export const FETCH_SPOTEFFECTIVENESSES_DATA = "FETCH_SPOTEFFECTIVENESSES_DATA";
export const FETCH_SPOTEFFECTIVENESSES_DATA_SUCCESS = "FETCH_SPOTEFFECTIVENESSES_DATA_SUCCESS";
export const FETCH_SPOTEFFECTIVENESSES_DATA_FAIL = "FETCH_SPOTEFFECTIVENESSES_DATA_FAIL";
//PEOPLE
export const FETCH_PEOPLE_DATA = "FETCH_PEOPLE_DATA";
export const FETCH_PEOPLE_DATA_SUCCESS = "FETCH_PEOPLE_DATA_SUCCESS";
export const FETCH_PEOPLE_DATA_FAIL = "FETCH_PEOPLE_DATA_FAIL";
//ADVERTISING_EFFECTIVENESSES
export const FETCH_ADVERTISING_EFFECTIVENESSES_DATA = "FETCH_ADVERTISING_EFFECTIVENESSES_DATA";
export const FETCH_ADVERTISING_EFFECTIVENESSES_DATA_SUCCESS = "FETCH_ADVERTISING_EFFECTIVENESSES_DATA_SUCCESS";
export const FETCH_ADVERTISING_EFFECTIVENESSES_DATA_FAIL = "FETCH_ADVERTISING_EFFECTIVENESSES_DATA_FAIL";
//LOGIN
export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FETCH_LOGIN_DATA_SUCCESS = "FETCH_LOGIN_DATA_SUCCESS";
export const FETCH_LOGIN_DATA_UNSUCCESS = "FETCH_LOGIN_DATA_UNSUCCESS";
export const FETCH_LOGIN_DATA_FAIL = "FETCH_LOGIN_DATA_FAIL ";
export const LOADING_LOGIN = "LOADING_LOGIN";
export const FETCH_VALIDATE_TOKEN = "FETCH_VALIDATE_TOKEN";
export const FETCH_LOGOUT = "FETCH_LOGOUT";

// BASICS
export const FETCH_API_REFERENCES = "FETCH_API_REFERENCES";
export const FETCH_API_REFERENCES_SUCCESS = "FETCH_API_REFERENCES_SUCCESS";
export const FETCH_API_REFERENCES_UNSUCCESS = "FETCH_API_REFERENCES_UNSUCCESS";
export const FETCH_API_REFERENCES_FAIL = "FETCH_API_REFERENCES_FAIL";

export const FETCH_CHANGE_COMPANY_LOGO = "FETCH_CHANGE_COMPANY_LOGO";
export const FETCH_CHANGE_COMPANY_DATA = "FETCH_CHANGE_COMPANY_DATA";
export const FETCH_CHANGE_COMPANY_DATA_SUCCESS = "FETCH_CHANGE_COMPANY_DATA_SUCCESS";
export const FETCH_CHANGE_COMPANY_DATA_FAIL = "FETCH_CHANGE_COMPANY_DATA_FAIL";
//CREATE PERSON
export const FETCH_CREATE_PERSON_DATA = "FETCH_CREATE_PERSON_DATA";
export const FETCH_CREATE_PERSON_DATA_SUCCESS = "FETCH_CREATE_PERSON_DATA_SUCCESS";
export const FETCH_CREATE_PERSON_DATA_UNSUCCESS = "FETCH_CREATE_PERSON_DATA_UNSUCCESS";
export const FETCH_CREATE_PERSON_DATA_FAIL = "FETCH_CREATE_PERSON_DATA_FAIL";
export const LOADING_CREATE_PERSON = "LOADING_CREATE_PERSON";

//ADVERTISEMENTS
export const FETCH_ADVERTISEMENTS_DATA = "FETCH_ADVERTISEMENTS_DATA";
export const FETCH_ADVERTISEMENTS_DATA_SUCCESS = "FETCH_ADVERTISEMENTS_DATA_SUCCESS";
export const FETCH_ADVERTISEMENTS_DATA_FAIL = "FETCH_ADVERTISEMENTS_DATA_FAIL";
export const FETCH_CHANGE_ADVERTISEMENTS_DATA = "FETCH_CHANGE_ADVERTISEMENTS_DATA";
export const FETCH_CHANGE_ADVERTISEMENTS_DATA_SUCCESS = "FETCH_CHANGE_ADVERTISEMENTS_DATA_SUCCESS";
export const FETCH_CHANGE_ADVERTISEMENTS_DATA_FAIL = "FETCH_CHANGE_ADVERTISEMENTS_DATA_FAIL";
export const FETCH_CHANGE_APPROVE = "FETCH_CHANGE_APPROVE";
export const FETCH_CHANGE_REJECTION_REASON = "FETCH_CHANGE_REJECTION_REASON";
export const FETCH_CHANGE_CHECKED_ALL = "FETCH_CHANGE_CHECKED_ALL";
export const FETCH_SAVE_AND_MORE = "FETCH_SAVE_AND_MORE";
export const FETCH_WAITING_SAVE_AND_MORE = "FETCH_WAITING_SAVE_AND_MORE";
export const FETCH_CHANGE_STAR = "FETCH_CHANGE_STAR";