import { Mutations } from "../mutations/campaign";
import * as querys from "../querys";
import parameters from "./parameters";
import { GrahpQL } from "../mutations/parametersMutations";

var opts = {
  headers: parameters.head
};

export default {
  createCampaignPreDraft: function* createCampaignPreDraft(payload) {
    try {
      var companyId = payload.companyId;
      var name = payload.name;
      var type = payload.type;
      var status = payload.status;
      var stock = payload.stock;
      var investment = payload.investment;
      var campaignPaymentType = payload.campaignPaymentType;
      

      const mutation = Mutations.createCampaign(
        companyId,
        name,
        type,
        status,
        investment,
        stock,
        campaignPaymentType
      );
      const response = yield GrahpQL.mutation(mutation);
      return response.body;
    } catch (e) {}
  },
  updateCampaign: function* updateCampaign(payload) {
    try {
      const mutation = Mutations.updateCampaign();
      const response = yield GrahpQL.mutation(mutation, payload);
      return response.body;
    } catch (e) {}
  },
  getCampaignForUpdateData: function* getCampaignForUpdateData(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.campaigns.query.campaignForUpdate,
      queryVars,
      opts
    );
    return response.data;
  }
};
