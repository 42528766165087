import { FETCH_COUNTRIES_DATA,
    FETCH_COUNTRIES_DATA_SUCCESS,
    FETCH_COUNTRIES_DATA_FAIL,
    FETCH_STATES_DATA,
    FETCH_STATES_DATA_SUCCESS,
    FETCH_STATES_DATA_FAIL,
    FETCH_CITIES_DATA,
    FETCH_CITIES_DATA_SUCCESS,
    FETCH_CITIES_DATA_FAIL,
    FETCH_REGION_SELECTION,
    FETCH_REGION_SELECTION_FOR_DB
} from '../actionTypes';

export const fetchRegionSelectionForDB = (data) => ({
    type: FETCH_REGION_SELECTION_FOR_DB,
    payload: data
})

export const fetchRegionSelection = (data) => ({
    type: FETCH_REGION_SELECTION,
    payload: data
})
export const fetchCitiesData = (data) => ({
    type: FETCH_CITIES_DATA,
    payload: data
})

export const fetchCitiesDataSuccess = (data) => ({
    type: FETCH_CITIES_DATA_SUCCESS,
    payload: data
})

export const fetchCitiesDataFail = () => ({
    type: FETCH_CITIES_DATA_FAIL,
    success: false,
})

export const fetchStatesData = (data) => ({
    type: FETCH_STATES_DATA,
    payload: data
})

export const fetchStatesDataSuccess = (data) => ({
    type: FETCH_STATES_DATA_SUCCESS,
    payload: data
})

export const fetchStatesDataFail = () => ({
    type: FETCH_STATES_DATA_FAIL,
    success: false,
})

export const fetchCountriesData = (data) => ({
    type: FETCH_COUNTRIES_DATA,
    payload: data
})

export const fetchCountriesDataSuccess = (data) => ({
    type: FETCH_COUNTRIES_DATA_SUCCESS,
    payload: data
})

export const fetchCountriesDataFail = () => ({
    type: FETCH_COUNTRIES_DATA_FAIL,
    success: false,
})