
import React from "react";
import Header from "./header.jsx";
import Body from "./body.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCampaignData, } from "../../../state/actions/campaigns";
import tags from "./tags";
import styles from "./styleIndex";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mont: true
          };
        this.refresh = this.refresh.bind(this);
    }

    refresh () {
        if (this.props.login.user !== null) {
            if (this.props.history.location.state !== undefined) {
                this.props.fetchCampaignData({_id: this.props.history.location.state.campaignId });
            } 
            else {
              const { history } = this.props;
              history.push({pathname: `/`});
            }
          }  else {
            const { history } = this.props;
            history.push({pathname: `/`});
          }
    }
    
    componentWillMount() {
        
        if (this.props.login.user !== null) {
            if (this.props.history.location.state !== undefined) {
                this.props.fetchCampaignData({_id: this.props.history.location.state.campaignId });
            } 
            else {
              const { history } = this.props;
              history.push({pathname: `/`});
            }
          }  else {
            const { history } = this.props;
            history.push({pathname: `/`});
          }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.mont) {
            this.setState({mont: false});
            return true;
        } else return false;
    }
    render() {
        const { classes } = this.props;

        return (
            <div>
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={1}>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
                <Button 
                    color="info" 
                    className={classes.alignCenter}
                    onClick={() => this.refresh()}
                    >
                    Refresh
                </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={9}>
            
                {<NavLink to={{pathname:"/panel/Campaigns",
                    state: {
                        companyId: this.props.history.location.state !== undefined ? this.props.history.location.state.companyId : this.props.login.user.company.id,
                        companyName: this.props.history.location.state !== undefined ? this.props.history.location.state.companyName : this.props.login.user.company.name,
                        campaignName: this.props.history.location.state !== undefined ? this.props.history.location.state.campaignName : '',
                        campaignId: this.props.history.location.state !== undefined ? this.props.history.location.state.campaignId: null
                     }}}>
                    {this.props.history.location.state !== undefined ? this.props.history.location.state.companyName : this.props.login.user.company.name}
                </NavLink>}
                <h4 className={classes.cardIconTitle}>{`${this.props.tags.campaigns}: 
                    ${this.props.history.location.state !== undefined ? this.props.history.location.state.campaignName : ''}`
                    }
                </h4>
                <br />
                
                
            </GridItem>
            </GridContainer>
            <Header />
            <Body />
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
        login: state.login
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
        fetchCampaignData: bindActionCreators(fetchCampaignData, dispatch),
    }
  }
export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard)));
//export default Dashboard;