import { FETCH_CAMPAIGNS_DATA, 
    FETCH_CAMPAIGNS_DATA_SUCCESS, 
    FETCH_CAMPAIGNS_DATA_FAIL,
    FETCH_CAMPAIGNS_DATA_ADD, 
    FETCH_CAMPAIGNS_DATA_ADD_SUCCESS, 
    FETCH_CAMPAIGNS_DATA_ADD_FAIL,  
    FETCH_CAMPAIGNS_NAME,
    FETCH_CAMPAIGNS_NAME_SUCCESS,
    FETCH_CAMPAIGNS_NAME_FAIL,
    FETCH_COMPANY_AND_CAMPAIGNS_DATA,
    FETCH_COMPANY_AND_CAMPAIGNS_DATA_SUCCESS,
    FETCH_COMPANY_AND_CAMPAIGNS_DATA_FAIL,
    FETCH_CAMPAIGN_DATA, 
    FETCH_CAMPAIGN_DATA_SUCCESS, 
    FETCH_CAMPAIGN_DATA_FAIL,
    CHECK_SM_SUCCESS,
    FETCH_CAMPAIGN_DATA_RESOURCES_SUCCESS,
    FETCH_CAMPAIGN_DATA_CARDINAL_SUCCESS,
    SUBSCRIPTION_CAMPAIGN_DATA,
    SUBSCRIPTION_CAMPAIGN_DATA_SUCCESS,
    FETCH_CAMPAIGN_HEADER_AUDIT_DATA,
    FETCH_CAMPAIGN_HEADER_AUDIT_DATA_SUCCESS,
    FETCH_CAMPAIGN_HEADER_AUDIT_DATA_FAIL
  } from '../actionTypes';


export const fetchCampaignHeaderAuditData = (data) => ({
  type: FETCH_CAMPAIGN_HEADER_AUDIT_DATA,
  payload: data
})

export const fetchCampaignHeaderAuditDataSuccess = (data) => ({
  type: FETCH_CAMPAIGN_HEADER_AUDIT_DATA_SUCCESS,
  payload: data
})

export const fetchCampaignHeaderAuditDataFail = (data) => ({
  type: FETCH_CAMPAIGN_HEADER_AUDIT_DATA_FAIL,
  payload: data
})

export const checkSMSuccess = (data) => ({
      type: CHECK_SM_SUCCESS,
      payload: data
})

export const fetchCompanyAndCampaignsData = (data) => ({
  type: FETCH_COMPANY_AND_CAMPAIGNS_DATA,
  payload: data
})

export const fetchCompanyAndCampaignsDataSuccess = (data) => ({
    type: FETCH_COMPANY_AND_CAMPAIGNS_DATA_SUCCESS,
    payload: data
})

export const fetchCompanyAndCampaignsDataFail = (data) => ({
  type: FETCH_COMPANY_AND_CAMPAIGNS_DATA_FAIL,
  payload: data
})

export const fetchCampaignsData = (data) => ({
    type: FETCH_CAMPAIGNS_DATA,
    payload: data
  });
  
export const fetchCampaignsDataSuccess = data => ({
    type: FETCH_CAMPAIGNS_DATA_SUCCESS,
    success: true,
    payload: data,
  });
  
export const fetchCampaignsDataFail = () => ({
    type: FETCH_CAMPAIGNS_DATA_FAIL,
    success: false,
});

export const fetchCampaignsDataAdd = (data) => ({
  type: FETCH_CAMPAIGNS_DATA_ADD,
  payload: data
});

export const fetchCampaignsDataAddSuccess = data => ({
  type: FETCH_CAMPAIGNS_DATA_ADD_SUCCESS,
  success: true,
  payload: data,
});

export const fetchCampaignsDataAddFail = () => ({
  type: FETCH_CAMPAIGNS_DATA_ADD_FAIL,
  success: false,
});

export const fetchCampaignsName = data => ({
    type: FETCH_CAMPAIGNS_NAME,
    payload: data
  });
  
export const fetchCampaignsNameSuccess = data => ({
    type: FETCH_CAMPAIGNS_NAME_SUCCESS,
    success: true,
    payload: data,
  });
  
export const fetchCampaignsNameFail = () => ({
    type: FETCH_CAMPAIGNS_NAME_FAIL,
    success: false,
  });

export const fetchCampaignData = (data) => ({
    type: FETCH_CAMPAIGN_DATA,
    payload: data
  });
  
export const fetchCampaignDataSuccess = data => ({
    type: FETCH_CAMPAIGN_DATA_SUCCESS,
    success: true,
    payload: data,
  });
export const fetchCampaignDataResourcesSuccess = data => ({
  type: FETCH_CAMPAIGN_DATA_RESOURCES_SUCCESS,
  success: true,
  payload: data,
});

export const fetchCampaignDataCardinalSuccess = data => ({
  type: FETCH_CAMPAIGN_DATA_CARDINAL_SUCCESS,
  success: true,
  payload: data,
});
  
export const fetchCampaignDataFail = () => ({
    type: FETCH_CAMPAIGN_DATA_FAIL,
    success: false,
});

export const subscriptionCampaignData = (data) => ({
  type: SUBSCRIPTION_CAMPAIGN_DATA,
  payload: data,
});

export const subscriptionCampaignDataSuccess = (data) => ({
  type: SUBSCRIPTION_CAMPAIGN_DATA_SUCCESS,
  payload: data,
});