import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CardBody from "components/Card/CardBody.jsx";

function Investment({ ...props }) {
    const {
         classes,
         data,
      } = props;

      let percentajes = {
        budgetSpent: 100 / data.investment * data.budgetSpent,
        budgetFreezed: 100 / data.investment * data.budgetFreezed,
        budgetAvailable: 100 / data.investment * data.budgetAvailable
    }

    return (
        <GridItem xs={12} sm={12} md={4}>
        <Card chart>
          <CardHeader color="rose">
          <Table
                hover
                tableData={[
                    {color: "danger",
                    data:[
                      "STATUS",
                      "VALUES",
                      "PERCENTAGES"
                    ]},
                    [
                        props.tags.spent,
                        data.budgetSpent.toFixed(0),
                        percentajes.budgetSpent.toFixed(0) + " %"
                      ],
                    [
                      props.tags.freezed,
                      data.budgetFreezed.toFixed(0),
                      percentajes.budgetFreezed.toFixed(0) + " %"
                    ],
                    [
                      props.tags.availible,
                      data.budgetAvailable.toFixed(0),
                      percentajes.budgetAvailable.toFixed(0) + " %"
                    ]
                  ]}
                />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>{`${props.tags.campaignInvestment}: ${data.investment} $`}</h4>
            <p className={classes.cardCategory}>  {props.tags.lineChart} </p>
          </CardBody>
        </Card>
      </GridItem>
      )
}

export default Investment;