import Pages from "../layouts/Pages.jsx";
import Dashboard from "layouts/Dashboard.jsx";


var indexRoutes = [
   { path: "/panel", name: "Home", component: Dashboard },
   { path: "/", name: "Pages", component: Pages, exact: true },
];

export default indexRoutes;
