import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchAdvertisingEffectivenessesDataSuccess,
    fetchAdvertisingEffectivenessesDataFail
 } from "../actions/advertisingEffectivenesses";
import {
    FETCH_ADVERTISING_EFFECTIVENESSES_DATA
} from '../actionTypes';
import api from '../api';

function* fetchAdvertisingEffectivenessesData(action) {
    try {
          let data = yield api.advertisingEffectivenesses.getAdvertisingEffectivenessesData(action.payload);
          yield put(fetchAdvertisingEffectivenessesDataSuccess({data:data.advertisingEffectiveness}));
    } catch (e) {
          yield put(fetchAdvertisingEffectivenessesDataFail());
    }
}

export default function* advertisingEffectivenessesSaga() {
    yield takeLatest(FETCH_ADVERTISING_EFFECTIVENESSES_DATA, fetchAdvertisingEffectivenessesData);
}