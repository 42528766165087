import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

export default theme => ({
    ...chartsStyle,
    ...extendedFormsStyle,
    colorFacebook: {
        color: "#4267b2"
    },
    colorInstagram: {
        color: "#8a3ab9"
    },
    colorTwitter: {
        color: "#429cd6"
    },
    width50: {
        width: "48%",
        float: "left",
        "margin-right": "5px"
    },
    colorWhite: {
        color: "white"
    }
})