const cardBodyStyle = {
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
  minWidth200: {
    "min-width": "200px",
    height: "270px"
  },
  height130: {
    'min-height': "130px"
  },
  height141: {
    'min-height': "141px"
  },
  height90: {
    'min-height': "90px"
  },
  height101: {
    'min-height': "101px"
  },
  height600: {
    'min-height': "600px"
  },
  height180: {
    'min-height': "180px"
  },
  height360: {
    'min-height': "360px"
  },
  minWidth230: {
    "min-width": "230px",
    height: "270px"
  },
  cardBodyBackground: {
    position: "relative",
    zIndex: "2",
    minHeight: "280px",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "440px",
    margin: "0 auto"
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyFormHorizontal: {
    paddingLeft: "15px",
    paddingRight: "15px",
    "& form": {
      margin: "0"
    }
  },
  cardPricing: {
    padding: "15px!important",
    margin: "0px!important"
  },
  cardSignup: {
    padding: "0px 30px 0px 30px"
  },
  cardBodyColor: {
    borderRadius: "6px",
    "&": {
      "h1, h2, h3": {
        "& small": {
          color: "rgba(255, 255, 255, 0.8)"
        }
      }
    }
  },
  cardBodyProfile: {
    marginTop: "15px"
  },
  cardBodyCalendar: {
    padding: "0px !important"
  }
};

export default cardBodyStyle;
