import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchLoginDataSuccess
} from "../actions/login";
import { 
    fetchCreatePersonDataSuccess,
    fetchCreatePersonDataUnsuccess,
    fetchCreatePersonDataFail,
    loadingCreatePerson
} from "../actions/person";
import { FETCH_CREATE_PERSON_DATA } from '../actionTypes';
import api from '../api';

function* fetchCreatePersonData(action) {
    try {
      yield yield put(loadingCreatePerson(true));
      let result = yield api.person.createPerson(action.payload);
      if(result.data != null & result.data.createPerson.success) {
        yield put(fetchLoginDataSuccess({data: JSON.parse(result.data.createPerson.data)}));
        yield put(fetchCreatePersonDataSuccess());
        localStorage.setItem('@token', JSON.parse(result.data.createPerson.data).token);
      }
      else yield put(fetchCreatePersonDataUnsuccess({data:result.errors}));

      yield yield put(loadingCreatePerson(false));
    } catch (e) {
      yield put(fetchCreatePersonDataFail());
      yield yield put(loadingCreatePerson(false));
    }
}

export default function* campaignCreation() {
    yield takeLatest(FETCH_CREATE_PERSON_DATA, fetchCreatePersonData);
}
