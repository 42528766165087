import * as querys from "../querys";
import parameters from "./parameters";
import { GrahpQL } from "../mutations/parametersMutations";

var opts = {
  headers: parameters.head
};

export default {
  getCampaignsData: function* getCampaignsData(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.campaigns.query.allCampaigns,
      queryVars,
      opts
    );
    return response.data;
  },
  getCampaignsName: function* getCampaignsName(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.campaigns.query.allCampaignsName,
      queryVars,
      opts
    );
    return response.data;
  },
  getCampaignData: function* getCampaignData(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.campaigns.query.campaign,
      queryVars,
      opts
    );
    return response.data;
  },
  getCampaignHeaderAuditData: function* getCampaignHeaderAuditData(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.campaigns.query.campaignHeaderAudit,
      queryVars,
      opts
    );
    return response.data;
  }
};
