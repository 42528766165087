import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({
    listOfCompanies,
    loading,
    youHaveSeenItAll,
    campaigns,
    engagement,
    investment,
    stock,
    velocity,
    active,
    engagementVelocity,
    draft,
    waitingForPayment,
    finished,
    onGoing,
    stoped,
    tag,
    like,
    shared,
    link,
    print,
    notSponsored,
    campaignTotal,
    lastThreeActiveCampaings,
    realVelocity,
    velocityExpented,
    real,
    expented,
    totalInvestment,
    spent,
    freezed,
    availible,
    withoutCampaigns,
    withoutCampaignsCreated,
    investmentMin,
    waitingForApproval,
    searchCampaign,
    withoutLogo,
    campaign,
    summaryOfYourCampaigns,
    editMyCompany,
    address,
    editCampaign,
    viewDashboard,
    pendingAuditByPlatform,
    pendingAuditByCompany,
    newButton,
    influencer,
    influencedValue,
    womens,
    mens,
    insightsByReactions,
    insightsBySocialMedia,
    evenWithoutLoading,
    withoutActiveCampaigns,
    comment,
    mentionValue,
    hashtag,
    unknown,
    type,
    name,
    status,

}) => ({tags:{
        status,
        name,
        type,
        listOfCompanies,
        loading,
        youHaveSeenItAll,
        campaigns,
        engagement,
        investment,
        stock,
        velocity,
        active,
        engagementVelocity,
        draft,
        waitingForPayment,
        finished,
        onGoing,
        stoped,
        tag,
        like,
        shared,
        link,
        print,
        notSponsored,
        campaignTotal,
        lastThreeActiveCampaings,
        realVelocity,
        velocityExpented,
        real,
        expented,
        totalInvestment,
        spent,
        freezed,
        availible,
        withoutCampaigns,
        withoutCampaignsCreated,
        investmentMin,
        waitingForApproval,
        searchCampaign,
        withoutLogo,
        campaign,
        summaryOfYourCampaigns,
        editMyCompany,
        address,
        editCampaign,
        viewDashboard,
        pendingAuditByPlatform,
        pendingAuditByCompany,
        newButton,
        influencer,
        influencedValue,
        womens,
        mens,
        insightsByReactions,
        insightsBySocialMedia,
        evenWithoutLoading,
        withoutActiveCampaigns,
        comment,
        mentionValue,
        hashtag,
        unknown
    }});

export default translatable(mapTranslationsToProps);