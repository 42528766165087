import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCampaignsName, fetchCampaignsData, fetchCampaignsDataSuccess } from "../../../state/actions/campaigns";
import components from '../componentsSearcher';
import tags from "./tags";
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

class Searcher extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            single: null,
          };
      }

    componentWillMount(){
        if(this.props.companyId !== null)
            this.props.fetchCampaignsName({companyId: this.props.companyId, active:true, page: 0, limit: 1000});
    }
    handleToggle(active) {
        this.setState({
            single: null,
          });
        if(!active) {
            this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.companyId, active: true});
            this.props.fetchCampaignsName({companyId: this.props.companyId, active:true});
        }
        else {
            this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.companyId});
            this.props.fetchCampaignsName({companyId: this.props.companyId});
        }
    }
    
    handleChange = name => value => {
        this.setState({
          [name]: value,
        });
        let campaign = this.props.campaigns.find(c => c.name === value.label);
        if(campaign) this.props.fetchCampaignsDataSuccess({data:[campaign], hasMore: false, active: this.props.active});
        else if (value && value.label){
           if(this.props.active) this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.companyId, name: value.label, active: this.props.active});
           else this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.companyId, name: value.label});
        }
        else {
            if(this.props.active) this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.companyId, active: this.props.active});
            else this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.companyId});
        }
    };

    render (){
        const { classes, campaignsName } = this.props;
        let suggestions = campaignsName.map(suggestion => ({
            value: suggestion.label,
            label: suggestion.name,
          }));
        const selectStyles = {
        input: base => ({
            ...base,
            '& input': {
            font: 'inherit',
            },
        }),
        };
        return (
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={1}>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
                <Button 
                    color="info" 
                    className={classes.alignCenter}
                    onClick={() => this.props.refresh()}
                    >
                    Refresh
                </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={9}>
            <AppBar position="static" className={classes.menu} >
                <Toolbar>
                        <div className={classes.grow} />
                            <div className={classes.rootSearcher}>
                                <NoSsr>
                                    <Select
                                        classes={classes}
                                        styles={selectStyles}
                                        options={suggestions}
                                        components={components}
                                        value={this.state.single}
                                        onChange={this.handleChange('single')}
                                        placeholder={this.props.tags.searchCampaign}
                                    />
                                    <div className={classes.divider} />
                                </NoSsr>
                            </div>
                </Toolbar>
            </AppBar>
            </GridItem>
        </GridContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        campaignsName: state.campaigns.campaignsName,
        campaigns: state.campaigns.campaigns,
        companyId: state.campaigns.company !== null ?state.campaigns.company._id: null,
        active: state.campaigns.active
    }
}

function mapDispatchToProps(dispatch){
    return {
     fetchCampaignsName: bindActionCreators(fetchCampaignsName, dispatch),
     fetchCampaignsData: bindActionCreators(fetchCampaignsData, dispatch),
     fetchCampaignsDataSuccess: bindActionCreators(fetchCampaignsDataSuccess, dispatch)
    }
}


export default tags(connect(mapStateToProps, mapDispatchToProps)(Searcher));