import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Place from "@material-ui/icons/Place";
import iconInfluencers from "../../../assets/icons/parking_lot_maps.png"
import iconInfo from "../../../assets/icons/info-i_maps.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";


import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const CustomSkinMap = withScriptjs(
          withGoogleMap(props => (
            <GoogleMap
              defaultZoom={13}
              defaultCenter={{ lat: -34.6083, lng: -58.3712 }}
              defaultOptions={{
                scrollwheel: false,
                disableDefaultUI: true,
                zoomControl: true
              }}
            >
            {props.influencersCardinal.map((item, index) => (
                <Marker
                icon={iconInfluencers} 
                title={'Ad of an influencer'}
                position={ {lat: item['coordinates'][0], lng:  item['coordinates'][1]} } 
                />
                ))}
            {props.InfluencedsCardinal.map((item, index) => (
                <Marker
                icon={iconInfo} 
                title={'Insight of an influenced'}
                position={ {lat: item['coordinates'][0], lng:  item['coordinates'][1]} } 
                />
                ))}
              
            </GoogleMap>
          ))
  );


function Map({ ...props }) {

    const {
         classes,
         data,
         handleChange,
         checkedA,
         checkedB
      } = props;
    return (
        <GridItem xs={12} sm={12} md={8}>
                <Card>
                    <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <Place />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                        {` ${props.tags.activityOfCampaign} `}
                        <FormControlLabel
                        control={
                          <Switch
                            checked={checkedA}
                            onChange={handleChange("checkedA")}
                            value="checkedA"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label= {`${props.tags.influencers}: ${data.influencersCardinal.length}`}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedB}
                            onChange={handleChange("checkedB")}
                            value="checkedB"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label= {`${props.tags.engagement}: ${data.InfluencedsCardinal.length}`}
                      />
                    </h4>
                    </CardHeader>
                    <CardBody>
                    <CustomSkinMap
                      influencersCardinal={
                        checkedA ? data.influencersCardinal : []
                      }
                      InfluencedsCardinal={
                        checkedB ? data.InfluencedsCardinal : []
                      }
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBF2Mx7MPt8O0w0tY2Js2NvZDPZKtcF374"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={
                        <div
                            style={{
                            height: `385px`,
                            borderRadius: "6px",
                            overflow: "hidden"
                            }}
                        />
                        }
                        mapElement={<div style={{ 
                          height: `100%` 
                        }} />}
                    />
                    </CardBody>
                </Card>
        </GridItem>
    )
}

export default Map;