import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import CircularProgressbar from 'react-circular-progressbar';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";



class InvestmentRequired extends React.Component {

    render() {
        const { classes } = this.props;

        let value = this.props.paymentType == "Money" ? 
            this.props.campaignCreationSubscription.influencersScopeCount == 0 ? 100 
            :
            (this.props.investment / (this.props.campaignCreationSubscription.investmentRequired * this.props.campaignCreationSubscription.influencersScopeCount) * 100).toFixed(2)
            :
            this.props.paymentType == "Product" ? 
            this.props.campaignCreationSubscription.influencersScopeCount == 0 ? 100 
            :
            ((this.props.paymentPerEach / this.props.campaignCreationSubscription.influencersScopeCount) * 100).toFixed(2)
            :
            null;
        return (
            <CircularProgressbar
                    percentage={value}
                    text={`${value}%`}
                  />
              
        );
    }
}

InvestmentRequired.propTypes = {
  classes: PropTypes.object.isRequired
};


function mapStateToProps(state){
  return {
    campaignCreationSubscription: state.campaignCreationSubscription,
    campaignId: state.campaignCreation._id,
    paymentType: state.campaignCreation.paymentType,
    investment: state.campaignCreation.investment,
    paymentPerEach: state.campaignCreation.paymentPerEach

  }
}

function mapDispatchToProps(dispatch){
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InvestmentRequired));
