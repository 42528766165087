import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardBodyStyle from "assets/jss/material-dashboard-pro-react/components/cardBodyStyle.jsx";

function CardBody({ ...props }) {
  const {
    classes,
    className,
    height130,
    height141,
    height90,
    height101,
    height600,
    height180,
    height360,
    minWidth200,
    minWidth230,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    ...rest
  } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignup]: signup,
    [classes.cardBodyColor]: color,
    [classes.cardBodyProfile]: profile,
    [classes.cardBodyCalendar]: calendar,
    [className]: className !== undefined,
    [classes.minWidth200]: minWidth200 !== undefined ? minWidth200 : false,
    [classes.minWidth230]: minWidth230 !== undefined ? minWidth230 : false,
    [classes.height90]: height90 !== undefined ? height90 : false,
    [classes.height101]: height101 !== undefined ? height101 : false,
    [classes.height130]: height130 !== undefined ? height130 : false,
    [classes.height141]: height141 !== undefined ? height141 : false,
    [classes.height180]: height180 !== undefined ? height180 : false,
    [classes.height360]: height360 !== undefined ? height360 : false,
    [classes.height600]: height600 !== undefined ? height600 : false,
    
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  background: PropTypes.bool,
  plain: PropTypes.bool,
  formHorizontal: PropTypes.bool,
  pricing: PropTypes.bool,
  signup: PropTypes.bool,
  color: PropTypes.bool,
  profile: PropTypes.bool,
  calendar: PropTypes.bool
};

export default withStyles(cardBodyStyle)(CardBody);
