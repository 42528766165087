import { FETCH_CATEGORIES_DATA_SUCCESS,
} from "../actionTypes";


const initialState = {
    categories: []
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_CATEGORIES_DATA_SUCCESS:
       return {
       categories: action.payload.data,
   }
   default:
       return state;
    }
}