var query = {
    advertisements: `

    

    query advertisements($limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $status: advertisementStatus
        $campaignId: ID
        $campaignType: CampaignType
        $companyId: ID
        $personId:  ID
        $active: Boolean
        $personGenre: genreType
    )
    {
        advertisements(limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            status: $status
            campaignId: $campaignId
            campaignType: $campaignType
            companyId: $companyId
            personId: $personId
            active: $active
            personGenre: $personGenre
        )
        {
            _id,
            personId,
            campaignId,
            multimediaUri,
            caption,
            status,
            linkIncluded,
            bannerIncluded,
            hashtagIncluded,
            watermarkIncluded,
            productUsageIncluded,
            mentionToOtherIncluded,
            mentionToCompanyIncluded,
            productUsageOficialIncluded,
            platformScore,
            custumerScore,
            rejectionReason,
            socialMediaTarget,
            resources {
              frame {
                enabled
              },
              hashtag {
                enabled
                value
              },
              webSite {
                enabled
                value
              }
              tagMentionMe {
                enabled,
                facebook {
                  enabled
                  value
                }
                instagram{
                  enabled
                  value
                }
                twitter {
                  enabled
                  value
                }
              }
              seal {
                enabled
                value
              },
              tagMentionPeople {
                enabled
              },
              productUsage {
                enabled
              },
              productUsageOficial {
                enabled
                value
              },
              spot {
                imageEnabled
                image
                description
                videoEnabled
                video
              }
            }
            _person {
              _id,
              platformStars,
              customerStars,
              username,
              firstName,
              lastName,
              sponsorshipEffectiveness,
              sponsorshipFreezedBudgetReference,
              sponsorshipEffectivenessReference {
                AdPrice,
                framePrice,
                hashtagPrice,
                webSitePrice,
                tagMentionMePrice,
                tagMentionPeoplePrice,
                sealPrice,
                productUsagePrice,
                freezedBudget,
                totalPrice
              },
              advertisingEffectiveness,
              advertisingFreezedBudgetReference,
              advertisingEffectivenessReference {
                AdPrice,
                framePrice,
                hashtagPrice,
                webSitePrice,
                tagMentionMePrice,
                tagMentionPeoplePrice,
                sealPrice,
                productUsagePrice,
                freezedBudget,
                totalPrice
              },
              spotEffectiveness,
              spotFreezedBudgetReference,
              spotEffectivenessReference {
                AdPrice,
                freezedBudget,
                totalPrice
              },
            }
          }
    }
    `
}
export default {
        query
};