import { FETCH_SEXES_DATA_SUCCESS,
} from "../actionTypes";

const initialState = {
    sexes: [],
    itemFemale: null,
    itemMale: null
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_SEXES_DATA_SUCCESS:
       return {
        ...state,
        sexes: action.payload.data,
        itemFemale: action.payload.data.reduce((newItem, item) => {
            if (item.name === "Female") {
                newItem = item;
            }
            return newItem;
        }, {}),
        itemMale: action.payload.data.reduce((newItem, item) => {
            if (item.name === "Male") {
                newItem = item;
            }
            return newItem;
        }, {})

   }
   default:
       return state;
    }
}