var query = {
    sexes: `
    query sex($limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $name: String
    )
    {
        sex(limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            name: $name
        ){
            _id,
            name,
        }
    }
    `
}
export default {
        query
};