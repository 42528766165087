import {
  FETCH_LOGIN_DATA,
  FETCH_LOGIN_DATA_SUCCESS,
  FETCH_LOGIN_DATA_UNSUCCESS,
  FETCH_LOGIN_DATA_FAIL,
  LOADING_LOGIN,
  FETCH_VALIDATE_TOKEN,
  FETCH_LOGOUT,
  FETCH_API_REFERENCES,
  FETCH_CHANGE_COMPANY_LOGO,
  FETCH_CHANGE_COMPANY_DATA,
  FETCH_CHANGE_COMPANY_DATA_SUCCESS,
  FETCH_CHANGE_COMPANY_DATA_FAIL,
  FETCH_API_REFERENCES_SUCCESS,
  FETCH_API_REFERENCES_UNSUCCESS,
  FETCH_API_REFERENCES_FAIL
} from "../actionTypes";

export const fetchChangeCompanyLogo = data => ({
  type: FETCH_CHANGE_COMPANY_LOGO,
  payload: data
});

export const fetcheChangeCompanyData = data => ({
  type: FETCH_CHANGE_COMPANY_DATA,
  payload: data
});

export const fetcheChangeCompanyDataSuccess = data => ({
  type: FETCH_CHANGE_COMPANY_DATA_SUCCESS,
  payload: data
});

export const fetcheChangeCompanyDataFail = data => ({
  type: FETCH_CHANGE_COMPANY_DATA_FAIL,
  payload: data
});

export const fetchLogOut = data => ({
  type: FETCH_LOGOUT,
  payload: data
});

export const fetchApiReferences = data => ({
  type: FETCH_API_REFERENCES,
  payload: data
});
export const fetchApiReferencesSuccess = data => ({
  type: FETCH_API_REFERENCES_SUCCESS,
  payload: data
});

export const fetchApiReferencesUnsuccess = data => ({
  type: FETCH_API_REFERENCES_UNSUCCESS,
  payload: data
});

export const fetchApiReferencesFail = data => ({
  type: FETCH_API_REFERENCES_FAIL,
  payload: data
});

export const fetchvalidateToken = data => ({
  type: FETCH_VALIDATE_TOKEN,
  payload: data
});
export const loadingLogin = data => ({
  type: LOADING_LOGIN,
  payload: data
});

export const fetchLoginData = data => ({
  type: FETCH_LOGIN_DATA,
  payload: data
});

export const fetchLoginDataSuccess = data => ({
  type: FETCH_LOGIN_DATA_SUCCESS,
  payload: data
});

export const fetchLoginDataUnsuccess = data => ({
  type: FETCH_LOGIN_DATA_UNSUCCESS,
  payload: data
});

export const fetchLoginDataFail = () => ({
  type: FETCH_LOGIN_DATA_FAIL,
  success: false
});
