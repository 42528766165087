import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "components/Table/Table.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import EngagementOfAdvertisementSponsorship from "./engagementOfAdvertisementSponsorship";
import EngagementOfAdvertisementAdvertising from "./engagementOfAdvertisementAdvertising";
import { MyLoaderEngagementOfAdvertisement } from '../../loaders';


const registroSpecificStarget = (classes, data, tags, handlerRemovePerson, type) => {
  switch (type) {
    case "Advertising":
    return [
      `${data.firstName} - ${data.lastName}`,
      data.advertisingEffectivenessReference.AdPrice,
      data.advertisingEffectivenessReference.hashtagPrice,
      data.advertisingEffectivenessReference.webSitePrice,
      data.advertisingEffectivenessReference.tagMentionMePrice,
      data.advertisingEffectivenessReference.tagMentionPeoplePrice,
      data.advertisingEffectivenessReference.framePrice,
      data.advertisingEffectivenessReference.sealPrice,
      data.advertisingEffectivenessReference.productUsagePrice,
      "-",
      data.advertisingEffectivenessReference.freezedBudget.toFixed(2),
      data.advertisingEffectivenessReference.totalPrice.toFixed(2),
      <Button
                color="danger"
                simple
                className={classes.actionButton}
                onClick={() => handlerRemovePerson(data._id)}
                key={data._id}
              >
                <Close className={classes.icon} />
              </Button>
    ]
      break;
    case "Sponsorship":
    return [
      `${data.firstName} - ${data.lastName}`,
      data.sponsorshipEffectivenessReference.AdPrice,
      data.sponsorshipEffectivenessReference.hashtagPrice,
      data.sponsorshipEffectivenessReference.webSitePrice,
      data.sponsorshipEffectivenessReference.tagMentionMePrice,
      data.sponsorshipEffectivenessReference.tagMentionPeoplePrice,
      data.sponsorshipEffectivenessReference.framePrice,
      data.sponsorshipEffectivenessReference.sealPrice,
      data.sponsorshipEffectivenessReference.productUsagePrice,
      "-",
      data.sponsorshipEffectivenessReference.freezedBudget.toFixed(2),
      data.sponsorshipEffectivenessReference.totalPrice.toFixed(2),
      <Button
                color="danger"
                simple
                className={classes.actionButton}
                onClick={() => handlerRemovePerson(data._id)}
                key={data._id}
              >
                <Close className={classes.icon} />
              </Button>
    ]
      break;
    default:
      break;
  }
  
}
const registro = (classes, data, tags, goToLink) => {
    return [
      `Desde ${data.from} - Hasta ${data.to}`,
      data.AdPrice,
      data.hashtagPrice,
      data.webSitePrice,
      data.tagMentionMePrice,
      data.tagMentionPeoplePrice,
      data.framePrice,
      data.sealPrice,
      data.productUsagePrice,
      "-",
      data.freezedBudget? data.freezedBudget.toFixed(2) : 0,
      data.totalPrice.toFixed(2)
    ]
}
function resourcesOfAdvertisement({...props}) {
    const {
        type,
        specificTarget,
        followers,
        classes,
        widgets,
        handleResources,
        inputAlert,
        resourcesTag,
        resources,
        items,
        handlerRemovePerson
      } = props;
    return (
        <Card className={widgets === true ? null: classes.disabled2}>
                <CardHeader color="rose" icon>
                  <GridContainer justify="space-between">
                    <GridItem xs={12} sm={12}>
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={2}>
                        <CardIcon color="info">
                          <Assignment />
                        </CardIcon>
                      </GridItem>
                      <GridItem xs={12} sm={10} className={classes.alignCenter}>
                        <h4 className={classes.cardIconTitle}>
                          {props.tags.resourcesOfAdvertisement}
                        </h4>
                      </GridItem>
                    </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody height600={true}>
                {
                  specificTarget && !followers ?
                  null
                  :
                    props.campaignCreation.type === "Sponsorship" ?
                    props.sponsorshipeffectivenesses.sponsorshipeffectivenesses.length === 0 ?
                      MyLoaderEngagementOfAdvertisement()
                      :
                      <EngagementOfAdvertisementSponsorship
                                    type="Sponsorship"
                                    classes={classes}
                                    widgets={props.campaignCreation.sponsorshipEffectivenessCriteriasChecked}
                                    handleEffectivenesses={props.handleEffectivenesses} 
                                    objectFrom={
                                      props.campaignCreation.sponsorshipEffectivenessCriteriasObjectFrom === null &&
                                      props.campaignCreation.sponsorshipEffectivenessCriterias.length !== 0 ?
                                      props.sponsorshipeffectivenesses.sponsorshipeffectivenesses.find(o => o.from === props.campaignCreation.sponsorshipEffectivenessCriterias[0].from)
                                      :
                                      props.campaignCreation.sponsorshipEffectivenessCriteriasObjectFrom
                                    }
                                    objectTo={
                                      props.campaignCreation.sponsorshipEffectivenessCriteriasObjectTo === null &&
                                      props.campaignCreation.sponsorshipEffectivenessCriterias.length ?
                                      props.sponsorshipeffectivenesses.sponsorshipeffectivenesses.find(o => o.from === props.campaignCreation.sponsorshipEffectivenessCriterias[props.campaignCreation.sponsorshipEffectivenessCriterias.length - 1].from)
                                      :
                                      props.campaignCreation.sponsorshipEffectivenessCriteriasObjectTo
                                    }
                                    starIdSlide={
                                      props.campaignCreation.sponsorshipEffectivenessCriterias.length !== 0 && 
                                      props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.length !== 0 ?
                                      props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.find(o => o.from === props.campaignCreation.sponsorshipEffectivenessCriterias[0].from).idSlide
                                      : 0}
                                    endIdSlide={
                                      props.campaignCreation.sponsorshipEffectivenessCriterias.length !== 0 && 
                                      props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.length !== 0 ?
                                      props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.find(o => o.from === props.campaignCreation.sponsorshipEffectivenessCriterias[props.campaignCreation.sponsorshipEffectivenessCriterias.length - 1].from).idSlide
                                      : 0}
                                      {...props}
                      /> 
                    :
                    props.campaignCreation.type === "Advertising" ?
                    props.advertisingEffectivenesses.advertisingEffectivenesses.length === 0 ?
                      MyLoaderEngagementOfAdvertisement()
                      :
                      <EngagementOfAdvertisementAdvertising
                                    type="Advertising"
                                    classes={classes}
                                    widgets={props.campaignCreation.advertisingEffectivenessCriteriasChecked}
                                    handleEffectivenesses={props.handleEffectivenesses}
                                    objectFrom={
                                      props.campaignCreation.advertisingEffectivenessCriteriasObjectFrom === null &&
                                      props.campaignCreation.advertisingEffectivenessCriterias.length !== 0 ?
                                      props.advertisingEffectivenesses.advertisingEffectivenesses.find(o => o.from === props.campaignCreation.advertisingEffectivenessCriterias[0].from)
                                      :
                                      props.campaignCreation.advertisingEffectivenessCriteriasObjectFrom
                                    }
                                    objectTo={
                                      props.campaignCreation.advertisingEffectivenessCriteriasObjectTo === null &&
                                      props.campaignCreation.advertisingEffectivenessCriterias.length ?
                                      props.advertisingEffectivenesses.advertisingEffectivenesses.find(o => o.from === props.campaignCreation.advertisingEffectivenessCriterias[props.campaignCreation.advertisingEffectivenessCriterias.length - 1].from)
                                      :
                                      props.campaignCreation.advertisingEffectivenessCriteriasObjectTo
                                    }
                                    starIdSlide={
                                      props.campaignCreation.advertisingEffectivenessCriterias.length !== 0 && 
                                      props.advertisingEffectivenesses.advertisingEffectivenessesRead.length !== 0 ?
                                      props.advertisingEffectivenesses.advertisingEffectivenessesRead.find(o => o.from === props.campaignCreation.advertisingEffectivenessCriterias[0].from).idSlide
                                      : 0}
                                    endIdSlide={
                                      props.campaignCreation.advertisingEffectivenessCriterias.length !== 0 && 
                                      props.advertisingEffectivenesses.advertisingEffectivenessesRead.length !== 0 ?
                                      props.advertisingEffectivenesses.advertisingEffectivenessesRead.find(o => o.from === props.campaignCreation.advertisingEffectivenessCriterias[props.campaignCreation.advertisingEffectivenessCriterias.length - 1].from).idSlide
                                      : 0}
                                    {...props}
                      />
                    :
                      null 
                }
                {
                    widgets === true ?
                    specificTarget  && 
                    followers === false ?
                      <Table
                        striped
                        tableHead={[
                          "I-R",
                          <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    tabIndex={1}
                                    checked={true}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  disabled: classes.disabledCheckboxAndRadio
                                }}
                                label= {props.tags.ads}
                          />,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={1}
                              checked={resources.hashtag.enabled}
                              onClick={() => handleResources(resourcesTag.hashtag)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title= {props.tags.hashtag}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.hashtag)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title={
                                ! resources.hashtag.value || resources.hashtag.value.length === 0 ?
                                props.tags.hashtag
                                :
                                `${props.tags.hashtag}: ${resources.hashtag.value}`
                                
                              }  
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fas fa-hashtag"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={2}
                                checked={resources.webSite.enabled}
                                onClick={() => handleResources(resourcesTag.webSite)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                              <Tooltip
                                id="tooltip-top"
                                title= {props.tags.changeWeb}
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  disabled={
                                    props.campaignCreation.enabledEdit ? false : true
                                  }
                                  onClick={() => inputAlert(resourcesTag.webSite)}
                                  color="transparent" 
                                  simple justIcon>
                                  <Edit className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>             
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  !resources.webSite.value || resources.webSite.value.length === 0 ?
                                  props.tags.webSite
                                  :
                                  `${props.tags.webSite}: ${resources.webSite.value}`
                                  
                                }
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-globe"></i>
                                </Button>
                              </Tooltip>
                          </div>,
                          <div>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={3}
                                checked={resources.tagMentionMe.enabled}
                                onClick={() => handleResources(resourcesTag.tagMentionMe)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />                   
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  props.tags.changeMention
                                }
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  disabled={
                                    props.campaignCreation.enabledEdit ? false : true
                                  }
                                  onClick={() => inputAlert(resourcesTag.tagMentionMe)}
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-at"></i>
                                  <Edit className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  !resources.tagMentionMe.value || resources.tagMentionMe.value.length === 0 ?
                                  props.tags.tagMentionMe
                                  :
                                  `${props.tags.tagMentionMe.value}: ${resources.tagMentionMe.value}`
                                  
                                }
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-globe"></i>
                                </Button>
                              </Tooltip>
                          </div>,
                          <div>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={4}
                                checked={resources.tagMentionPeople.enabled}
                                onClick={() => handleResources(resourcesTag.tagMentionPeople)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  resources.tagMentionPeople.enabled ?
                                  `${props.tags.tagMentionPeople}: ${props.tags.enabled}`
                                  :
                                  `${props.tags.tagMentionPeople}: ${props.tags.disabled}`
                                }
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-users"></i>
                                </Button>
                              </Tooltip>,
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={5}
                              checked={resources.frame.enabled}
                              onClick={() => handleResources(resourcesTag.frame)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.frame.enabled ?
                                `${props.tags.frame}: ${props.tags.enabled}`
                                :
                                `${props.tags.frame}: ${props.tags.disabled}`
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="far fa-image"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={6}
                              checked={resources.seal.enabled}
                              onClick={() => handleResources(resourcesTag.seal)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title={props.tags.changeDate}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.seal)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>            
                            <Tooltip
                              id="tooltip-top"
                              title={
                                !resources.seal.value || resources.seal.value.length === 0 ?
                                props.tags.seal
                                :
                                `${props.tags.seal}: ${resources.seal.value}`
                                
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fas fa-paint-brush"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={7}
                              checked={resources.productUsage.enabled}
                              onClick={() => handleResources(resourcesTag.productUsage)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />     
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.productUsage.enabled ?
                                `${props.tags.productUsage}: ${props.tags.required}`
                                :
                                `${props.tags.productUsage}: No ${props.tags.required}`
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fab fa-product-hunt"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={8}
                              checked={resources.productUsageOficial.enabled}
                              onClick={() => handleResources(resourcesTag.productUsageOficial)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title={props.tags.changeDate}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.productUsageOficial)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.productUsageOficial.enabled &&
                                resources.productUsageOficial.value.length !== 0 ?
                                `${props.tags.productUsageOficial}: ${props.tags.required}.
                                ${props.tags.description}: ${resources.productUsageOficial.value}`
                                :
                                resources.productUsageOficial.enabled ?
                                `${resourcesTag.productUsageOficial}: ${props.tags.required}.
                                ${props.tags.description}: ${props.tags.completeInstructions}`
                                :
                                `${resourcesTag.productUsageOficial}: No ${props.tags.required}.`
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fas fa-info-circle"></i>
                              </Button>
                            </Tooltip>,
                          </div>,
                          <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    tabIndex={9}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  disabled: classes.disabledCheckboxAndRadio
                                }}
                                label={props.tags.freezed}
                          />,
                          "Total",
                          ""
                        ]}
                        tableData={
                          items.map((item, index) => (
                            registroSpecificStarget(classes, item, null, handlerRemovePerson, type)
                          ))
                        }
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customClassesForCells={[1, 2, 3, 4, 5, 6]}
                      />
                      :
                      <Table
                        striped
                        tableHead={[
                          "EPA",
                          <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    tabIndex={1}
                                    checked={true}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  disabled: classes.disabledCheckboxAndRadio
                                }}
                                label={props.tags.ads}
                          />,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={1}
                              checked={resources.hashtag.enabled}
                              onClick={() => handleResources(resourcesTag.hashtag)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title="Change Hashtag"
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.hashtag)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.hashtag.value.length === 0 ?
                                resourcesTag.hashtag
                                :
                                `${resourcesTag.hashtag}: ${resources.hashtag.value}`
                                
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fas fa-hashtag"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={2}
                                checked={resources.webSite.enabled}
                                onClick={() => handleResources(resourcesTag.webSite)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                              <Tooltip
                                id="tooltip-top"
                                title={props.tags.changeWeb}
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  disabled={
                                    props.campaignCreation.enabledEdit ? false : true
                                  }
                                  onClick={() => inputAlert(resourcesTag.webSite)}
                                  color="transparent" 
                                  simple justIcon>
                                  <Edit className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>             
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  resources.webSite.value.length === 0 ?
                                  resourcesTag.webSite
                                  :
                                  `${resourcesTag.webSite}: ${resources.webSite.value}`
                                  
                                }
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-globe"></i>
                                </Button>
                              </Tooltip>
                          </div>,
                          <div>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={3}
                                checked={resources.tagMentionMe.enabled}
                                onClick={() => handleResources(resourcesTag.tagMentionMe)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                              <Tooltip
                              id="tooltip-top"
                              title={props.tags.changeMention}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.tagMentionMe)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>                   
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  `${resourcesTag.tagMentionMe}: 
                                  Facebook= ${resources.tagMentionMe.facebook.value? resources.tagMentionMe.facebook.value : "" } /
                                  Instagram= ${resources.tagMentionMe.instagram.value? resources.tagMentionMe.instagram.value : "" }
                                  `
                                }
                                /* Twitter= ${resources.tagMentionMe.twitter.value? resources.tagMentionMe.twitter.value : "" } */
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                onClick={() => inputAlert(resourcesTag.tagMentionMe)}
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-at"></i>
                                  <Edit className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>
                          </div>,
                          <div>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={4}
                                checked={resources.tagMentionPeople.enabled}
                                onClick={() => handleResources(resourcesTag.tagMentionPeople)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  resources.tagMentionPeople.enabled ?
                                  `${resourcesTag.tagMentionPeople}: ${props.tags.enabled}`
                                  :
                                  `${resourcesTag.tagMentionPeople}: ${props.tags.disabled}`
                                }
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent" 
                                  simple justIcon>
                                  <i className="fas fa-users"></i>
                                </Button>
                              </Tooltip>,
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={5}
                              checked={resources.frame.enabled}
                              onClick={() => handleResources(resourcesTag.frame)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.frame.enabled ?
                                `${resourcesTag.frame}: ${props.tags.enabled}`
                                :
                                `${resourcesTag.frame}: ${props.tags.disabled}`
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="far fa-image"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={6}
                              checked={resources.seal.enabled}
                              onClick={() => handleResources(resourcesTag.seal)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title={props.tags.changeDate}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.seal)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>            
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.seal.value.length === 0 ?
                                resourcesTag.seal
                                :
                                `${resourcesTag.seal}: ${resources.seal.value}`
                                
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fas fa-paint-brush"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={7}
                              checked={resources.productUsage.enabled}
                              onClick={() => handleResources(resourcesTag.productUsage)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />     
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.productUsage.enabled ?
                                `${resourcesTag.productUsage}: ${props.tags.required}`
                                :
                                `${resourcesTag.productUsage}: No ${props.tags.required}`
                                
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fab fa-product-hunt"></i>
                              </Button>
                            </Tooltip>
                          </div>,
                          <div>
                            <Checkbox
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              tabIndex={8}
                              checked={resources.productUsageOficial.enabled}
                              onClick={() => handleResources(resourcesTag.productUsageOficial)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                            <Tooltip
                              id="tooltip-top"
                              title={props.tags.changeDate}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                onClick={() => inputAlert(resourcesTag.productUsageOficial)}
                                color="transparent" 
                                simple justIcon>
                                <Edit className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title={
                                resources.productUsageOficial.enabled &&
                                resources.productUsageOficial.value.length !== 0 ?
                                `${resourcesTag.productUsageOficial}: ${props.tags.required}.
                                ${props.tags.description}: ${resources.productUsageOficial.value}`
                                :
                                resources.productUsageOficial.enabled ?
                                `${resourcesTag.productUsageOficial}: ${props.tags.required}.
                                ${props.tags.description}: ${props.tags.completeInstructions}`
                                :
                                `${resourcesTag.productUsageOficial}: No ${props.tags.required}.`
                              }
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="transparent" 
                                simple justIcon>
                                <i className="fas fa-info-circle"></i>
                              </Button>
                            </Tooltip>,
                          </div>,
                          <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    tabIndex={9}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  disabled: classes.disabledCheckboxAndRadio
                                }}
                                label={props.tags.freezed}
                          />,
                          "Total"
                        ]}
                        tableData={
                          items.map((item, index) => (
                            registro(classes, item, null, null)
                        ))
                        }
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customClassesForCells={[1, 2, 3, 4, 5, 6]}
                      />
                    : null
                }
                </CardBody>
        </Card>
    )
}
        
export default resourcesOfAdvertisement;