import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Nouislider from "nouislider-react";

function engagementOfAdvertisementSpot({...props}) {
    const {
        classes,
        widgets,
        handleEffectivenesses,
        starIdSlide,
        endIdSlide,
        objectFrom,
        objectTo,
      } = props;
    return (
      <Card 
        className={widgets === true ? null: classes.disabled2}
      >
        <CardBody height90={true}>
          {widgets === true ? 
                  <GridContainer justify="space-between">
                    <GridItem xs={12} sm={6}>
                      <div>
                      <br/>
                        <Nouislider
                            disabled={
                              props.campaignCreation.enabledEdit ? false : true
                            }
                            className={"slider-primary"}
                            key={'spoteffectivenesses'}
                            id={0}
                            start={[starIdSlide, endIdSlide]}
                            step={1}
                            connect={[false, true, false]}
                            range={{
                              min: 0,
                              max: props.spoteffectivenesses.spotEffectiveness.length - 1
                              //items.length-1
                            }}
                            onChange={handleEffectivenesses()}
                        />
                      <br/>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                            <div>
                              {objectFrom !== null && objectFrom !== undefined &&
                                objectTo !== null && objectTo !== undefined  ? `${props.tags.engagementOfAdvertisement}.  ${props.tags.from}: ${objectFrom.from.toFixed(2)}. ${props.tags.to}: ${objectTo.to.toFixed(2)} ` : null}
                            </div>
                          <br />
                            <div>
                              {objectFrom !== null && objectFrom !== undefined && 
                               objectTo !== null && objectTo !== undefined ?`${props.tags.costPerAd}. ${props.tags.from}: $${objectFrom.totalPrice.toFixed(2)}. ${props.tags.to}: $${objectTo.totalPrice.toFixed(2)} `: null}
                            </div>
                    </GridItem>
                  </GridContainer>
                  : null
          }
        </CardBody>
      </Card>
    )
}

export default engagementOfAdvertisementSpot;