import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "./tags";
import styles from "./styleIndex";
import { 
  fetchCreatePreDraft, 
  fetchUpdateCampaign, 
  fetchCampaignForUpdateData, 
  fetchChangeInvestment,
  fetchChangeCustomPricesEnabled, 
  fetchChangeCustomAdPrice,
  fetchChangeCustomInsightMultiplier,
  fetchChangeStatus,
  fetchCleanCampaignCreation,
  fetchChangeName,
  fetchChangePaymentType,
  fetchChangePaymentPerEach,
  fetchChangeStock,
  fetchChangeProductPaymentDescription,
  fetchChangeSpoteffectivenesses,
  fetchChangeSponsorshipeffectivenesses,
  fetchChangeAdvertisingEffectivenesses,
  fetchChangeType,
  loadingCampaignForUpdate,
  loadingCampaignPreDraft,
  fetchLoadAllReferencesData
} from "../../../state/actions/campaignCreation";
import { fetchStarsData } from "../../../state/actions/stars";
import { fetchSexesData } from "../../../state/actions/sexes";
import { fetchAgesData } from "../../../state/actions/ages";
import { fetchCategoriesData } from "../../../state/actions/categories";
import {  fetchCountriesData } from "../../../state/actions/regions";
import { subscriptionScope, fetchSubscriptionRecalculating, subscriptionScopeClose } from  "../../../state/actions/campaignCreationSubscription";
import { fetchSponsorshipeffectivenessesData } from "../../../state/actions/sponsorshipeffectivenesses";
import { fetchSpoteffectivenessesData } from "../../../state/actions/spoteffectivenesses";
import { fetchAdvertisingEffectivenessesData } from "../../../state/actions/advertisingEffectivenesses";
// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Step1 from "./WizardSteps/Step1.jsx";
import Step2 from "./WizardSteps/Step2.jsx";
import Step3 from "./WizardSteps/Step3.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.jsx";
import { MyFacebookLoader } from './loaders';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { GraphQLNonNull } from "graphql";

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

class WizardView extends React.Component {
  constructor(props) {
    super(props);
    this.title = React.createRef()
    this.state = {
      campaignName: '',
      ready: false,
      validateStatus: null,
      setType: "pending",
      steps: props.campaignCreation.specificTarget == true ?
      [
        { stepName: "Campaign", stepComponent: Step1, stepId: "campaign" },
        { stepName: "Cost Reference", stepComponent: Step2, stepId: "target" }
      ]
      :
      [
        { stepName: "Campaign", stepComponent: Step1, stepId: "campaign" },
        { stepName: "Cost Reference", stepComponent: Step2, stepId: "target" },
        { stepName: "Target", stepComponent: Step3, stepId: "costReference" },
      ],
      default: props.campaignCreation.specificTarget,
      //adPriceCheckBox: false

    }

    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangePaymentType = this.handleChangePaymentType.bind(this);
    this.handleChangeInvestment = this.handleChangeInvestment.bind(this);
    this.handleChangeAdPrice = this.handleChangeAdPrice.bind(this);
    this.handleChangeAdMultiplier = this.handleChangeAdMultiplier.bind(this);
    this.handleChangePaymentPerEach = this.handleChangePaymentPerEach.bind(this);
    this.handleChangeStock = this.handleChangeStock.bind(this);
  }

 componentWillUnmount(){
  this.props.subscriptionScopeClose();
  this.props.fetchCleanCampaignCreation();
 }

 componentWillMount(){
   //this.props.subscriptionScopeClose();
    if (this.props.login.user !== null) {
      //this.props.fetchCleanCampaignCreation();
      this.props.fetchLoadAllReferencesData();
      // this.props.fetchSponsorshipeffectivenessesData();
      // this.props.fetchSpoteffectivenessesData();
      // this.props.fetchAdvertisingEffectivenessesData();
      this.props.fetchCountriesData({country: "Argentina",state: null,city: null});
      // this.props.fetchSexesData(null);
      // this.props.fetchStarsData(null);
      // this.props.fetchAgesData(null);
      // this.props.fetchCategoriesData(null);

      if (this.props.history.location.state != undefined) {
        this.props.fetchCreatePreDraft({
          companyId: this.props.history.location.state.companyId, 
          type: this.props.history.location.state.type,
          name: '',
          status: 'PreDraft',
          active: false,
          investment: "0",
          stock:'100',
          campaignPaymentType: 'Product'
      });
        if (this.props.history.location.state.campaignId != null) 
          this.props.fetchCampaignForUpdateData({_id: this.props.history.location.state.campaignId});
        else 
          this.props.loadingCampaignForUpdate(false);
          
      } 
      else {
        const { history } = this.props;
        history.push({pathname: `/`});
      }
    }  else {
      const { history } = this.props;
      history.push({pathname: `/`});
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.name !== this.state.name){
      return false;
    }
    if(this.state.ready === false && this.props.campaignCreation.loadingCampaignPreDraft === false && this.props.campaignCreation.loadingCampaignForUpdate === false) {
      this.setState({
        ready: true,
        campaignName: this.props.campaignCreation.name
      });
      this.props.loadingCampaignForUpdate(true);
      this.props.loadingCampaignPreDraft(true);
    }
    if(this.props.campaignCreation.productPaymentDescription != nextProps.campaignCreation.productPaymentDescription) {
      return false;
    }
    if (nextProps.campaignCreation.specificTarget != nextState.default) {
      this.setState({
        steps: nextProps.campaignCreation.specificTarget == true ?
        [
          { stepName: "Campaign", stepComponent: Step1, stepId: "campaign" },
          { stepName: "Cost Reference", stepComponent: Step2, stepId: "target" }
        ]
        :
        [
          { stepName: "Campaign", stepComponent: Step1, stepId: "campaign" },
          { stepName: "Cost Reference", stepComponent: Step2, stepId: "target" },
          { stepName: "Target", stepComponent: Step3, stepId: "costReference" },
        ],
        default: nextProps.campaignCreation.specificTarget
      })
    }
    if(this.state.setType === "pending" && 
      this.props.spoteffectivenesses.spotEffectivenessRead.length !== 0 &&
      this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.length !== 0 &&
      this.props.advertisingEffectivenesses.advertisingEffectivenessesRead.length !== 0) {
        this.setState({setType: "loaded"});
      }
    
    if (this.state.setType === "loaded" && this.props.campaignCreation._id != null) {
      this.setState({setType: "ready"});
      if (this.props.campaignCreation._id != null){
        this.props.subscriptionScope({
            _id: this.props.campaignCreation._id, 
            observedFields: ['influencersScopeCount', 'influencedScopeCount', 'influencedExtraScopeCount', 'investmentRequired']
        });
      }
      
    }
    if (this.state.setType === "ready" && this.props.campaignCreation._id != null) {
      
      
     
      switch (this.props.history.location.state.type) { 
        case "Spot":
            this.addSpot();
        break;
        case "Sponsorship":
          this.addSponsorship();
        break;
        case "Advertising":
          this.addAdvertising();
        break;
        default:
          break;
      }

      this.setState({setType: "subscriptionOK"});
    }

    // if (this.state.setType === "subscriptionOK" && this.props.campaignCreation._id != null) {
    //   if (!this.props.campaignCreation.enabledEdit) {
    //     this.props.fetchChangeStatus(this.props.campaignCreation.status);
    //   }

    //   this.setState({setType: "OK"});
    // }

    return true;
  }

  addSpot () {
    //if (this.props.campaignCreation.enabledEdit) {
      this.props.fetchChangeSpoteffectivenesses(
        {from: this.props.spoteffectivenesses.spotEffectivenessRead[0].idSlide, 
        to: this.props.spoteffectivenesses.spotEffectivenessRead[this.props.spoteffectivenesses.spotEffectivenessRead.length-1].idSlide,
        items: this.props.spoteffectivenesses.spotEffectivenessRead, 
        checked: true, 
        changeSlide: true}
        );
      this.props.fetchChangeSponsorshipeffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
      this.props.fetchChangeAdvertisingEffectivenesses({from: 0, to: 0, items:[], checked: false, changeSlide: true});
  
      this.props.fetchSubscriptionRecalculating();
    //}
  }
  
  addSponsorship() {
    //if (this.props.campaignCreation.enabledEdit) {
      this.props.fetchChangeSponsorshipeffectivenesses(
        {from: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead[0].idSlide, 
          to: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead[this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.length-1].idSlide,
          items: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead, 
          checked: true, 
          changeSlide: true}
        );
      this.props.fetchChangeSpoteffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
      this.props.fetchChangeAdvertisingEffectivenesses({from: 0, to: 0, items:[], checked: false, changeSlide: true});
      
      this.props.fetchSubscriptionRecalculating();
    //}
  }
  
  addAdvertising() {
    //if (this.props.campaignCreation.enabledEdit) {
      this.props.fetchChangeAdvertisingEffectivenesses(
      {from: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead[0].idSlide, 
        to: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead[this.props.advertisingEffectivenesses.advertisingEffectivenessesRead.length-1].idSlide,
        items: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead, 
        checked: true, 
        changeSlide: true}
      );
      this.props.fetchChangeSpoteffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
      this.props.fetchChangeSponsorshipeffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
  
      this.props.fetchSubscriptionRecalculating();
    //}
  }

  registroPaymentType = (classes, data, key) => {
    return (
      <MenuItem
        key={key}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value={data}
      >
        {data}
      </MenuItem>
    )
  }

  handleChangeName (event) {
    if (this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      this.setState({campaignName: event.target.value});
      //this.props.fetchChangeName(event.target.value);
    }
  }

  handleChangeInvestment (event) {
    if (this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      if(event.target.value > 0 ){
        this.props.fetchChangeInvestment(parseFloat(event.target.value));
      }
    }
  }

  handleChangePaymentPerEach (event) {
    if (this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      this.props.fetchChangePaymentPerEach(parseFloat(event.target.value));
    }
  }

  handleChangeStock (event) {
    if (this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      this.props.fetchChangeStock(parseFloat(event.target.value));
    }
  }

  registro = (classes, item, tags, goToLink) => {
      return (item)
  }

  handleChangeStatus = event => {
      this.props.fetchChangeStatus(event.target.value);
  };

  handleChangePaymentType = event => {
    if (this.props.campaignCreation.enabledEdit) {
      this.props.fetchChangePaymentType(event.target.value);
      
    }

    if (event.target.value === "Product" && this.props.campaignCreation.customPricesEnabled){
      this.props.fetchChangeCustomPricesEnabled(false);
    }
  }

  handleChangeProductPaymentDescription = event => {
    if (this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      this.props.fetchChangeProductPaymentDescription(event.target.value);
    }
  }

  finishButtonClick(){

    let customAdPrice = this.props.campaignCreation.customAdPrice;
    let customInsightMultiplier = this.props.campaignCreation.customInsightMultiplier;

    // if(this.props.campaignCreation.name.length === 0) return false;
    
    if(this.state.campaignName.length === 0) {
      this.setState({validateStatus: "Required Name !! "});
      return false;
    }
    if(this.props.campaignCreation.virtual && !this.props.campaignCreation.whatsappNumber) {
      this.setState({validateStatus: "Required whatsappNumber!! "});
      return false;
    }
    if (this.props.campaignCreation.adsPerIR < 1 || isNaN(this.props.campaignCreation.adsPerIR)) {
      this.setState({validateStatus: "Required Cantidad de Ads por I-R"});
      return false;
    } 
    if (this.props.campaignCreation.paymentType === "Money") {
      if(this.props.campaignCreation.investment < 1  || isNaN(this.props.campaignCreation.investment)) {
        this.setState({validateStatus: "Required investment !!"});
        return false;
      } 
      if (this.props.campaignCreation.customPricesEnabled && !( this.props.campaignCreation.customAdPrice > 0 && this.props.campaignCreation.customInsightMultiplier >= 0 ) ){
        console.log("Required customize AD info");
        this.setState({validateStatus: "Required customize AD info !!"});
        return false;
      } 
      if (!this.props.campaignCreation.customPricesEnabled ) {
        customAdPrice = 0;
        customInsightMultiplier = 1;
      }
    } else if (this.props.campaignCreation.paymentType === "Product") {
      if(this.props.campaignCreation.paymentPerEach < 1 || isNaN(this.props.campaignCreation.paymentPerEach)) {
        this.setState({validateStatus: "Required paymentPerEach !!"});
        return false;
      }

      if(this.props.campaignCreation.stock < 1 || isNaN(this.props.campaignCreation.stock)) {
        this.setState({validateStatus: "Required Stock !!"});
        return false;
      }
      
      if(!this.props.campaignCreation.productPaymentDescription || this.props.campaignCreation.productPaymentDescription.length === 0){
        this.setState({validateStatus: "Required Product Payment Description !!"});
        return false;
      }
    }
    if(!this.props.campaignCreation.specificTarget && this.props.campaignCreation.regionChecked) {
      
      if(this.props.campaignCreation.regionCriterias.length === 0) {
        this.setState({validateStatus: "Required region !!"});
        return false;
      }
    }
    if(this.props.campaignCreation.type === "Spot"){
      if (this.props.campaignCreation.resources.spot.imageEnabled && this.props.campaignCreation.resources.spot.image === null) {
        this.setState({validateStatus: "Required image !!"});
        return false;
      }
      if (this.props.campaignCreation.resources.spot.videoEnabled && this.props.campaignCreation.resources.spot.video === null) {
        this.setState({validateStatus: "Required video !!"});
        return false;
      }
      if (!this.props.campaignCreation.resources.spot.imageEnabled && !this.props.campaignCreation.resources.spot.videoEnabled) {
        this.setState({validateStatus: "Required media !!"});
        return false;
      }
    }

    let _id = this.props.campaignCreation._id;
    if (this.props.campaignCreation.existingCampaign != null) _id = this.props.campaignCreation.existingCampaign._id;

      let regionCriterias = this.props.campaignCreation.regionCriterias;
      let sexCriterias = this.props.campaignCreation.sexCriterias;
      let platformStarCriterias = this.props.campaignCreation.platformStarCriterias;
      let customerStarCriterias = this.props.campaignCreation.customerStarCriterias;
      let sponsorshipEffectivenessCriterias = this.props.campaignCreation.type == "Sponsorship" ? this.props.campaignCreation.sponsorshipEffectivenessCriterias : [];
      let spotEffectivenessCriterias = this.props.campaignCreation.type == "Spot" ? this.props.campaignCreation.spotEffectivenessCriterias : [];
      let advertisingEffectivenessCriterias = this.props.campaignCreation.type == "Advertising" ? this.props.campaignCreation.advertisingEffectivenessCriterias : [];
      let categoryCriterias = this.props.campaignCreation.categoryCriterias;
      let ageCriterias = this.props.campaignCreation.ageCriterias;
      let specificTargetScope = this.props.campaignCreation.specificTargetScope;
      if (this.props.campaignCreation.specificTarget) {
        regionCriterias = [];
        sexCriterias = [];
        platformStarCriterias = [];
        customerStarCriterias = [];
        // this.props.campaignCreation.followers ? sponsorshipEffectivenessCriterias : [];
        // this.props.campaignCreation.followers ? spotEffectivenessCriterias : [];
        // this.props.campaignCreation.followers ? advertisingEffectivenessCriterias : [];
        categoryCriterias = [];
      } else { specificTargetScope = [] }

      this.props.fetchUpdateCampaign({
        _id,
        active: true,
        name: this.state.campaignName, //this.props.campaignCreation.name,
        status: this.props.campaignCreation.status === "PreDraft" ? "Draft" : this.props.campaignCreation.status,
        followers: this.props.campaignCreation.followers,
        type: this.props.campaignCreation.type,
        paymentType: this.props.campaignCreation.paymentType,
        influencePeopleAs: this.props.campaignCreation.influencePeopleAs,
        adsPerIR: this.props.campaignCreation.adsPerIR,
        productPaymentDescription: this.props.campaignCreation.productPaymentDescription,
        paymentPerEach: this.props.campaignCreation.paymentPerEach,
        brief: this.props.campaignCreation.brief,
        investment: this.props.campaignCreation.paymentType === "Money"? this.props.campaignCreation.investment: null,
        stock: this.props.campaignCreation.paymentType === "Product" ? this.props.campaignCreation.stock : null,
        customPricesEnabled: this.props.campaignCreation.customPricesEnabled,
        customAdPrice: customAdPrice,
        customInsightMultiplier: customInsightMultiplier,
        startDt: this.props.campaignCreation.startDt,
        endDt: this.props.campaignCreation.endDt,
        resources: this.props.campaignCreation.resources,
        specificTarget: this.props.campaignCreation.specificTarget,
        //aca vienen los criterios a actualizarse
        regionCriterias,
        sexCriterias,
        platformStarCriterias,
        customerStarCriterias,
        sponsorshipEffectivenessCriterias,
        spotEffectivenessCriterias,
        advertisingEffectivenessCriterias,
        categoryCriterias,
        ageCriterias,
        specificTargetScope,
        virtual: this.props.campaignCreation.virtual,
        whatsappNumber: this.props.campaignCreation.whatsappNumber,
      });

    this.props.history.push({
      pathname: `Start`});
  }

  handleCustomAdCheckBox (){
    /* this.setState({
      adPriceCheckBox: !this.state.adPriceCheckBox
    }); */
    this.props.fetchChangeCustomPricesEnabled(!this.props.campaignCreation.customPricesEnabled);
    /* if (this.state.adPriceCheckBox){
      this.props.fetchChangeCustomAdPrice(0)
      this.props.fetchChangeCustomInsightMultiplier(1);
    } */
  };

  handleChangeAdPrice (event){
    if (this.props.campaignCreation.customPricesEnabled && this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      if(event.target.value > 0 ){
        this.props.fetchChangeCustomAdPrice(parseFloat(event.target.value));
      } else {
        this.props.fetchChangeCustomAdPrice('');
      }
    }
  }

  handleChangeAdMultiplier (event){
    if (this.props.campaignCreation.customPricesEnabled && this.props.campaignCreation.enabledEdit) {
      event.preventDefault();
      if(event.target.value >= 0 ){
        this.props.fetchChangeCustomInsightMultiplier(parseFloat(event.target.value));
      } else {
        this.props.fetchChangeCustomInsightMultiplier('');
      }
    }
  }
  render() {
    const { classes } = this.props;
    const { steps } = this.state;

    return (
      this.state.ready === false ? 
      MyFacebookLoader() : 
      <GridContainer justify="center">
        <GridItem xs={12} sm={10}>
          <Wizard
            tags={this.props.tags}
            type={
                this.props.campaignCreation.enabledEdit ?
                <FormControl fullWidth className={`${classes.selectFormControl}` }>
                  <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                  >
                    Payment Type
                  </InputLabel>
                  <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.props.campaignCreation.paymentType}
                          onChange={this.handleChangePaymentType}
                          inputProps={{
                            name: "type",
                            id: "simple-select"
                          }}
                  >
                    <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                    >
                      Campaign Type
                    </MenuItem>
                    {
                      this.props.campaignCreation.paymentTypeList.map((item, index) => (
                        this.registroPaymentType(classes, item, index)
                    ))
                    }
                  </Select>
                </FormControl>
                :
                <TextField
                  disabled={true}
                  id="outlined-number13"
                  label={"Campaign Type"}
                  defaultValue={this.props.campaignCreation.paymentType}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
            }
            handleChangeStatus={this.handleChangeStatus}
            statusCampaign={this.props.campaignCreation.status}
            nextStatuses={this.props.campaignCreation.nextStatuses}
            finishButtonClick={this.finishButtonClick}
            validate={true}
            steps={this.state.steps.map((item, index) => (
              this.registro(null, item, null, null)
              ))
            }
            title={
              <Input
                disabled={
                  this.props.campaignCreation.enabledEdit ? false : true
                }
                defaultValue={this.props.campaignCreation.name}
                placeholder={`nombre de campaña nueva para ${this.props.history.location.state.companyName !== undefined ? this.props.history.location.state.companyName : this.props.login.user.company.name}`}
                fullWidth={true}
                inputProps={{
                  'aria-label': 'Description',
                  className:classes.fontzise40,
                  onChange: event => this.handleChangeName(event)
                }}
              />
            }
            subtitle2={
                    <TextField
                      disabled={
                        this.props.campaignCreation.enabledEdit ? false : true
                      }
                      id="outlined-number"
                      label={
                        this.props.campaignCreation.paymentType == "Money" ?
                        "Monto ( $ARS ) para la campaña"
                        :
                        "Cantidad de productos / Stock"
                      }
                      value={
                        this.props.campaignCreation.paymentType == "Money" ?
                        this.props.campaignCreation.investment
                        :
                        this.props.campaignCreation.stock
                      }
                      onChange={
                        this.props.campaignCreation.paymentType == "Money" ?
                        this.handleChangeInvestment
                        :
                        //this.handleChangePaymentPerEach
                        this.handleChangeStock
                      }
                      type="number"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  }
            productPaymentDescription={
              this.props.campaignCreation.paymentType == "Product" ?
              <Input
                disabled={
                  this.props.campaignCreation.enabledEdit ? false : true
                }              
                ref={this.title}
                defaultValue={this.props.campaignCreation.productPaymentDescription}
                placeholder={"Descripcion del producto/servicio ofrecido."}
                fullWidth={true}
                inputProps={{
                  'aria-label': 'Description',
                  className:classes.fontzise40,
                  onChange: event => this.handleChangeProductPaymentDescription(event)
                }}
              />
              :
              null
             }
             customAdPriceCheck={
              this.props.campaignCreation.paymentType == "Money" ?
               <Checkbox
                    disabled={
                      !this.props.campaignCreation.enabledEdit
                    }
                    control
                    tabIndex={8}
                    /* checked={widgets} */
                    /* onClick={() => handleWidgets('region')} */
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    onClick={()=> this.handleCustomAdCheckBox()}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                    checked={this.props.campaignCreation.customPricesEnabled}
                  />
                  :
                null  
             }
             customAdPriceTag = {
              this.props.campaignCreation.paymentType == "Money" ?
               this.props.tags.customAdPrice
               :
               ""
             }
             customAdPrice = {
              this.props.campaignCreation.customPricesEnabled &&
                <TextField label={"New Ad Price"}  type="number" onChange={this.handleChangeAdPrice} margin="normal"
                variant="outlined" className={classes.textField}  value = { this.props.campaignCreation.customAdPrice} disabled= {!this.props.campaignCreation.enabledEdit}></TextField>
             }
             customInsightMultiplier = {
              this.props.campaignCreation.customPricesEnabled &&
                <TextField label={"Insight Multiplier"}  type="number" onChange={this.handleChangeAdMultiplier} margin="normal"
                variant="outlined" className={classes.textField} value = { this.props.campaignCreation.customInsightMultiplier} disabled= {!this.props.campaignCreation.enabledEdit}></TextField>
            }

            validateStatus = {
                this.state.validateStatus != null ?
                <div className={classes.formCategory}>
                  <small>* * * </small> {this.state.validateStatus}
                </div> 
                :
                null
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

WizardView.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    campaignCreation: state.campaignCreation,
    login: state.login,
    spoteffectivenesses: state.spoteffectivenesses,
    sponsorshipeffectivenesses: state.sponsorshipeffectivenesses,
    advertisingEffectivenesses: state.advertisingEffectivenesses
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchSexesData: bindActionCreators(fetchSexesData, dispatch),
    fetchStarsData: bindActionCreators(fetchStarsData, dispatch),
    fetchAgesData: bindActionCreators(fetchAgesData, dispatch),
    fetchCategoriesData: bindActionCreators(fetchCategoriesData, dispatch),
    fetchCountriesData: bindActionCreators(fetchCountriesData, dispatch),
    fetchCleanCampaignCreation: bindActionCreators(fetchCleanCampaignCreation, dispatch),
    fetchCreatePreDraft: bindActionCreators(fetchCreatePreDraft, dispatch),
    fetchUpdateCampaign: bindActionCreators(fetchUpdateCampaign, dispatch),
    fetchChangeInvestment: bindActionCreators(fetchChangeInvestment, dispatch),
    fetchChangeCustomPricesEnabled: bindActionCreators(fetchChangeCustomPricesEnabled, dispatch),
    fetchChangeCustomAdPrice: bindActionCreators(fetchChangeCustomAdPrice, dispatch),
    fetchChangeCustomInsightMultiplier:bindActionCreators(fetchChangeCustomInsightMultiplier, dispatch),
    fetchCampaignForUpdateData: bindActionCreators(fetchCampaignForUpdateData, dispatch),
    fetchChangeStatus: bindActionCreators(fetchChangeStatus, dispatch),
    subscriptionScope: bindActionCreators(subscriptionScope, dispatch),
    fetchChangeName: bindActionCreators(fetchChangeName, dispatch),
    fetchChangePaymentType: bindActionCreators(fetchChangePaymentType, dispatch),
    fetchChangePaymentPerEach: bindActionCreators(fetchChangePaymentPerEach, dispatch),
    fetchChangeStock: bindActionCreators(fetchChangeStock, dispatch),
    fetchChangeProductPaymentDescription: bindActionCreators(fetchChangeProductPaymentDescription, dispatch),
    fetchChangeSpoteffectivenesses: bindActionCreators(fetchChangeSpoteffectivenesses, dispatch),
    fetchChangeSponsorshipeffectivenesses: bindActionCreators(fetchChangeSponsorshipeffectivenesses, dispatch),
    fetchChangeAdvertisingEffectivenesses: bindActionCreators(fetchChangeAdvertisingEffectivenesses, dispatch),
    fetchSubscriptionRecalculating: bindActionCreators(fetchSubscriptionRecalculating, dispatch),
    fetchSponsorshipeffectivenessesData: bindActionCreators(fetchSponsorshipeffectivenessesData, dispatch),
    fetchSpoteffectivenessesData: bindActionCreators(fetchSpoteffectivenessesData, dispatch),
    fetchAdvertisingEffectivenessesData: bindActionCreators(fetchAdvertisingEffectivenessesData, dispatch),
    fetchChangeType: bindActionCreators(fetchChangeType, dispatch),
    loadingCampaignForUpdate: bindActionCreators(loadingCampaignForUpdate, dispatch),
    loadingCampaignPreDraft: bindActionCreators(loadingCampaignPreDraft, dispatch),
    subscriptionScopeClose: bindActionCreators(subscriptionScopeClose, dispatch),
    fetchLoadAllReferencesData: bindActionCreators(fetchLoadAllReferencesData, dispatch)
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WizardView)));