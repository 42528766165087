import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { subscriptionScope, subscriptionScopeClose } from  "../../state/actions/campaignCreationSubscription";
import { MyLoaderScope } from './loaders';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";



class InfluencersScopeValue extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
         if(this.props.campaignCreationSubscription.influencersScopeCount != nextProps.campaignCreationSubscription.influencersScopeCount
            || this.props.campaignCreationSubscription.recalculating != nextProps.campaignCreationSubscription.recalculating) return true;
         else return false;
    }
    render() {
        const { classes } = this.props;
        return (
        <div>
            {   this.props.campaignCreationSubscription.recalculating ?
                <h3 className={classes.subtitle}>{MyLoaderScope()}</h3>
                :
                <h3 className={classes.subtitle}>{this.props.campaignCreationSubscription.influencersScopeCount}</h3>
            }
        </div>
        );
    }
}

InfluencersScopeValue.propTypes = {
  classes: PropTypes.object.isRequired
};


function mapStateToProps(state){
  return {
    campaignCreationSubscription: state.campaignCreationSubscription,
    campaignId: state.campaignCreation._id
  }
}

function mapDispatchToProps(dispatch){
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InfluencersScopeValue));
