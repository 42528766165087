import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Facebook } from 'react-content-loader';
import styles from "./styleIndex";
import row from "./row";
import Searcher from "./searcher";
import tags from "./tags";
import { fetchCompaniesData, fetchCompaniesDataAdd, fetchCompaniesDataSuccess } from "../../../state/actions/companies";
import { fetchCompanyAndCampaignsDataSuccess } from "../../../state/actions/campaigns";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const MyFacebookLoader = () => <Facebook />

class CompaniesLevel extends React.Component {
    constructor(props) {
        super(props);
        this.handlerRefresh = this.handlerRefresh.bind(this);
    }

    handlerRefresh(){
        this.props.fetchCompaniesData({page:0, limit:9});
    }

    componentWillMount() {
        this.props.fetchCompaniesData({page:0, limit:9});
    }
    
    handleToggle(value) {
    }

    registro = (classes, data, tags) => {
        return (row(classes, data, tags))
    }
        
    fetchMoreData = () => {
        if (!this.props.hasMore) {
            return;
        }
        this.props.fetchCompaniesDataAdd({page: this.props.page, limit:3});
    };
     
    render() {
      const { classes, companies, hasMore 
    } = this.props;
      return (
        <div className={classes.root}>
            <br/>
            {
                companies.length === 0 ? MyFacebookLoader() : 
                <GridContainer justify="center">
                <InfiniteScroll
                    dataLength={companies.length}
                    next={this.fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>{this.props.tags.loading}</h4>}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>{this.props.tags.youHaveSeenItAll}</b>
                    </p>
                    }
                >
                <GridItem xs={12} sm={12} md={12}>
                    <Card>        
                        <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{this.props.tags.listOfCompanies}</h4>
                        </CardHeader>
                        
                        <CardBody>
                        <GridContainer justify="center">
                        <Searcher refresh={this.handlerRefresh} classes={classes} />
                        <Table className={classes.table}
                            tableHead={[
                            "",
                            this.props.tags.campaigns,
                            this.props.tags.engagement,
                            this.props.tags.investment,
                            this.props.tags.engagementVelocity
                            ]}
                            tableData={
                                companies.map((item, index) => (
                                    this.registro(classes, item, this.props.tags)
                                ))
                            }
                            tableShopping
                            customHeadCellClasses={[
                            classes.center,
                            classes.center,
                            classes.center,
                            classes.center,
                            classes.center,
                            classes.center
                            ]}
                            customCellClasses={[
                            classes.center,
                            classes.center,
                            classes.center,
                            classes.center,
                            classes.center,
                            classes.center
                            ]}
                            customClassesForCells={[1, 2, 3, 4, 5, 6]}
                        />
                        </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                </InfiniteScroll>
            </GridContainer>
            }
        </div>
    )
    }
}

    function mapStateToProps(state){
        return {
            companies: state.companies.companies,
            hasMore: state.companies.hasMore,
            page: state.companies.page
        }
    }
  
    function mapDispatchToProps(dispatch){
        return {
        fetchCompaniesData: bindActionCreators(fetchCompaniesData, dispatch),
        fetchCompaniesDataAdd: bindActionCreators(fetchCompaniesDataAdd, dispatch),
        fetchCompaniesDataSuccess: bindActionCreators(fetchCompaniesDataSuccess, dispatch),
        fetchCompanyAndCampaignsDataSuccess: bindActionCreators(fetchCompanyAndCampaignsDataSuccess, dispatch),
        }
    }
    

export default tags(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompaniesLevel))));
