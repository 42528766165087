import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchSexesDataSuccess,
    fetchSexesDataFail
 } from "../actions/sexes";
import {
    FETCH_SEXES_DATA
} from '../actionTypes';
import api from '../api';

function* fetchSexesData(action) {
    try {
          let data = yield api.sexes.getSexesData(action.payload);
          yield put(fetchSexesDataSuccess({data:data.sex}));
    } catch (e) {
          yield put(fetchSexesDataFail());
    }
}

export default function* sexSaga() {
    yield takeLatest(FETCH_SEXES_DATA, fetchSexesData);
}