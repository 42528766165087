import React from "react";
import components from '../componentsSearcher';
import { fetchCompaniesName, fetchCompaniesData, fetchCompaniesDataSuccess } from "../../../state/actions/companies";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import tags from "./tags";
// @material-ui/core components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// material-ui icons
import WorkIcon from '@material-ui/icons/Work';

import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";


class Searcher extends React.Component {
    componentWillMount(){
        this.props.fetchCompaniesName();
    }

    state = {
        single: null
      };
    
    handleChange = name => value => {
        this.setState({
          [name]: value,
        });
        let company = this.props.companies.find(c => c.name === value.label);
        if(company) this.props.fetchCompaniesDataSuccess({data:[company], hasMore: false});
        else if (value && value.label) this.props.fetchCompaniesData({page:0, limit:5, name: value.label});
        else this.props.fetchCompaniesData({page:0, limit:5});
      };

    render(){

        const { classes, companiesNames } = this.props;
        let suggestions = companiesNames.map(suggestion => ({
            value: suggestion.label,
            label: suggestion.name,
          }));
        const selectStyles = {
        input: base => ({
            ...base,
            '& input': {
            font: 'inherit',
            },
        }),
        };

        return (
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={1}>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
                <Button 
                    color="info" 
                    className={classes.alignCenter}
                    onClick={() => this.props.refresh()}
                    >
                    Refresh
                </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={9}>
            <AppBar position="static" className={classes.menu} >
                
                    <Toolbar>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
                        <WorkIcon />
                    </IconButton>
                    
                    <div className={classes.grow} />
                        <div className={classes.rootSearcher}>
                            <NoSsr>
                            <Select
                                classes={classes}
                                styles={selectStyles}
                                options={suggestions}
                                components={components}
                                value={this.state.single}
                                onChange={this.handleChange('single')}
                                placeholder={this.props.tags.searchCompany}
                            />
                            <div className={classes.divider} />
                            </NoSsr>
                        </div>
                </Toolbar>
            </AppBar>
            </GridItem>
        </GridContainer>
        )
    }
    
}

function mapStateToProps(state){
    return {
        companiesNames: state.companies.companiesName,
        companies: state.companies.companies
    }
}

function mapDispatchToProps(dispatch){
    return {
    fetchCompaniesName: bindActionCreators(fetchCompaniesName, dispatch),
    fetchCompaniesData: bindActionCreators(fetchCompaniesData, dispatch),
    fetchCompaniesDataSuccess: bindActionCreators(fetchCompaniesDataSuccess, dispatch)
    }
}

export default tags(withRouter(connect(mapStateToProps, mapDispatchToProps)(Searcher)));