import { 
    SUBSCRIPTION_SCOPE,
    SUBSCRIPTION_ON,
    SUBSCRIPTION_OFF,
    SUBSCRIPTION_SCOPE_SUCCESS,
    SUBSCRIPTION_RECALCULATING,
    SUBSCRIPTION_SCOPE_CLOSE
} from '../actionTypes'; 

export const subscriptionScopeClose = (data) => ({
    type: SUBSCRIPTION_SCOPE_CLOSE,
    payload: data,
});

export const fetchSubscriptionRecalculating = (data) => ({
    type: SUBSCRIPTION_RECALCULATING,
    payload: data,
});

export const subscriptionOn = (data) => ({
    type: SUBSCRIPTION_ON,
    payload: data,
});

export const subscriptionOff = (data) => ({
    type: SUBSCRIPTION_OFF,
    payload: data,
});

export const subscriptionScope = (data) => ({
    type: SUBSCRIPTION_SCOPE,
    payload: data,
});

export const subscriptionScopeSucess = (data) => ({
    type: SUBSCRIPTION_SCOPE_SUCCESS,
    payload: data,
});