import { FETCH_CREATE_PREDRAFT,
    FETCH_CREATE_PREDRAFT_SUCCESS,
    FETCH_CREATE_PREDRAFT_FAIL,
    FETCH_CHANGE_SPECIFICTARGET,
    FETCH_CHANGE_TYPE,
    FETCH_CHANGE_RESOURCE_VALUE,
    FETCH_CHANGE_RESOURCE_ENABLED,
    FETCH_IMAGE_URL_SUCCESS,
    FETCH_VIDEO_URL_SUCCESS,
    FETCH_CHANGE_NAME,
    FETCH_CHANGE_STARTDT,
    FETCH_CHANGE_ENDDT,
    FETCH_CHANGE_INVESTMENT,
    FETCH_CHANGE_STOCK,
    FETCH_CHANGE_CUSTOM_PRICES_ENABLED,
    FETCH_CHANGE_CUSTOM_AD_PRICE,
    FETCH_CHANGE_CUSTOM_INSIGHT_MULTIPLIER,
    FETCH_CHANGE_STATUS,
    DELETE_SPOT,
    FETCH_CHANGE_REGION,
    FETCH_ADD_REGION,
    FETCH_REMOVE_REGION,
    FETCH_UPDATE_CAMPAIGN,
    FETCH_UPDATE_CAMPAIGN_SUCCESS,
    FETCH_UPDATE_CAMPAIGN_FAIL,
    FETCH_CAMPAIGN_FOR_UPDATE_DATA,
    FETCH_CAMPAIGN_FOR_UPDATE_DATA_SUCCESS,
    FETCH_CAMPAIGN_FOR_UPDATE_DATA_FAIL,
    FETCH_CHANGE_SEXES,
    FETCH_CHANGE_SEXES_ADD,
    FETCH_CHANGE_SEXES_REMOVE,
    FETCH_CHANGE_STARS,
    FETCH_CHANGE_AGES,
    FETCH_CLEAN_CAMPAIGN_CREATION,
    FETCH_CHANGE_CATEGORIES,
    FETCH_CHANGE_CATEGORIES_ADD,
    FETCH_CHANGE_CATEGORIES_REMOVE,
    FETCH_CHANGE_SPOTEFFECTIVENESSES,
    FETCH_CHANGE_SPORNSORSHIP_EFFECTIVENESSES,
    FETCH_CHANGE_ADVERTISING_EFFECTIVENESSES,
    FETCH_ADD_PEOPLE,
    FETCH_REMOVE_PEOPLE,
    FEtCH_UPDATE_PEOPLE,
    FETCH_CHANGE_BRIEF,
    FETCH_CHANGE_PAYMENT_TYPE,
    FETCH_CHANGE_INFLUENCEPEOPLEAS,
    FETCH_CHANGE_FOLLOWERS,
    FETCH_CHANGE_PAYMENT_PER_EACH,
    FETCH_CHANGE_PRODUCT_PAYMENT_DESCRIPTION,
    FETCH_CHANGE_ADS_PER_IR,
    FETCH_CHANGE_SPOT_DESCRIPTION,
    FETCH_CHANGE_MEDIA,
    LOADING_CAMPAIGN_PRE_DRAFT,
    LOADING_CAMPAIGN_FOR_UPDATE,
    FETCH_LOAD_ALL_REFERENCES_DATA,
    FETCH_LOAD_ALL_REFERENCES_DATA_SUCCESS,
    FETCH_CHANGE_VIRTUAL,
    FETCH_CHANGE_CELLPHONE
} from '../actionTypes';

export const fetchLoadAllReferencesData = (data) => ({
    type: FETCH_LOAD_ALL_REFERENCES_DATA,
    payload: data
})

export const fetchLoadAllReferencesDataSuccess = (data) => ({
    type: FETCH_LOAD_ALL_REFERENCES_DATA_SUCCESS,
    payload: data
})

export const fetchChangeMedia = (data) => ({
    type: FETCH_CHANGE_MEDIA,
    payload: data
})

export const fetchChangeSpotDescription = (data) => ({
    type: FETCH_CHANGE_SPOT_DESCRIPTION,
    payload: data
})

export const fetchChangeAdsPerIR = (data) => ({
    type: FETCH_CHANGE_ADS_PER_IR,
    payload: data
})

export const fetchChangeProductPaymentDescription = (data) => ({
    type: FETCH_CHANGE_PRODUCT_PAYMENT_DESCRIPTION,
    payload: data
})

export const fetchChangePaymentPerEach = (data) => ({
    type: FETCH_CHANGE_PAYMENT_PER_EACH,
    payload: data
})

export const fetchChangeStock = (data) => ({
    type: FETCH_CHANGE_STOCK,
    payload: data
})

export const fetchChangeFollowers = (data) => ({
    type: FETCH_CHANGE_FOLLOWERS,
    payload: data
})

export const fetchChangeInfluencePeopleAs = (data) => ({
    type: FETCH_CHANGE_INFLUENCEPEOPLEAS,
    payload: data
})

export const fetchChangePaymentType = (data) => ({
    type: FETCH_CHANGE_PAYMENT_TYPE,
    payload: data
})

export const fetchChangeBrief = (data) => ({
    type: FETCH_CHANGE_BRIEF,
    payload: data
})

export const fetcUpdatePeople = (data) => ({
    type: FEtCH_UPDATE_PEOPLE,
    payload: data
})

export const fetchAddPeople = (data) => ({
    type: FETCH_ADD_PEOPLE,
    payload: data
})

export const fetchRemovePeople = (data) => ({
    type: FETCH_REMOVE_PEOPLE,
    payload: data
})

export const fetchChangeAdvertisingEffectivenesses = (data) => ({
    type: FETCH_CHANGE_ADVERTISING_EFFECTIVENESSES,
    payload: data
})

export const fetchChangeSponsorshipeffectivenesses = (data) => ({
    type: FETCH_CHANGE_SPORNSORSHIP_EFFECTIVENESSES,
    payload: data
})
export const fetchChangeSpoteffectivenesses = (data) => ({
    type: FETCH_CHANGE_SPOTEFFECTIVENESSES,
    payload: data
})

export const loadingCampaignPreDraft = (data) => ({
    type: LOADING_CAMPAIGN_PRE_DRAFT,
    payload: data
})
export const loadingCampaignForUpdate = (data) => ({
    type: LOADING_CAMPAIGN_FOR_UPDATE,
    payload: data
})
export const fetchChangeCategoriesAdd = (data) => ({
    type: FETCH_CHANGE_CATEGORIES_ADD,
    payload: data
})

export const fetchChangeCategoriesRemove = (data) => ({
    type: FETCH_CHANGE_CATEGORIES_REMOVE,
    payload: data
})

export const fetchChangeCategories = (data) => ({
    type: FETCH_CHANGE_CATEGORIES,
    payload: data
})

export const fetchCleanCampaignCreation = (data) => ({
    type: FETCH_CLEAN_CAMPAIGN_CREATION,
    payload: data
})

export const fetchChangeAges = (data) => ({
    type: FETCH_CHANGE_AGES,
    payload: data
})

export const fetchChangeStars = (data) => ({
    type: FETCH_CHANGE_STARS,
    payload: data
})

export const fetchChangeSexesAdd = (data) => ({
        type: FETCH_CHANGE_SEXES_ADD,
        payload: data
})

export const fetchChangeSexesRemove = (data) => ({
        type: FETCH_CHANGE_SEXES_REMOVE,
        payload: data
})

export const fetchChangeSexes = (data) => ({
    type: FETCH_CHANGE_SEXES,
    payload: data
})

export const fetchChangeStatus = (data) => ({
    type: FETCH_CHANGE_STATUS,
    payload: data
})
export const fetchChangeStartDt = (data) => ({
    type: FETCH_CHANGE_STARTDT,
    payload: data
})

export const fetchChangeEndDt = (data) => ({
    type: FETCH_CHANGE_ENDDT,
    payload: data
}) 

export const fetchChangeInvestment = (data) => ({
    type: FETCH_CHANGE_INVESTMENT,
    payload: data
})

export const fetchChangeCustomPricesEnabled = (data) => ({
    type: FETCH_CHANGE_CUSTOM_PRICES_ENABLED,
    payload: data
})

export const fetchChangeCustomAdPrice = (data) => ({
    type: FETCH_CHANGE_CUSTOM_AD_PRICE,
    payload: data
})

export const fetchChangeCustomInsightMultiplier = (data) => ({
    type: FETCH_CHANGE_CUSTOM_INSIGHT_MULTIPLIER,
    payload: data
})

export const fetchCampaignForUpdateData = (data) => ({
        type: FETCH_CAMPAIGN_FOR_UPDATE_DATA,
        payload: data
})

export const fetchCampaignForUpdateDataSuccess = (data) => ({
    type: FETCH_CAMPAIGN_FOR_UPDATE_DATA_SUCCESS,
    payload: data
})

export const fetchCampaignForUpdateDataFail = (data) => ({
    type: FETCH_CAMPAIGN_FOR_UPDATE_DATA_FAIL,
    payload: data
})

export const fetchUpdateCampaign = (data) => ({
    type: FETCH_UPDATE_CAMPAIGN,
    payload: data
})

export const fetchUpdateCampaignSuccess = (data) => ({
    type: FETCH_UPDATE_CAMPAIGN_SUCCESS,
    payload: data
})

export const fetchUpdateCampaignFail = (data) => ({
    type: FETCH_UPDATE_CAMPAIGN_FAIL,
    payload: data
})

export const fetchRemoveRegion = (data) => ({
   type: FETCH_REMOVE_REGION,
   payload: data
})

export const fetchChangeRegion = (data) => ({
    type: FETCH_CHANGE_REGION,
    payload: data
})

export const fetchAddRegion = (data) => ({
    type: FETCH_ADD_REGION,
    payload: data
})

export const deleteSpot = () => ({
    type: DELETE_SPOT
})
export const fetchImageUrlSuccess = (data) => ({
    type: FETCH_IMAGE_URL_SUCCESS,
    payload: data
})
export const fetchVideoUrlSuccess = (data) => ({
    type: FETCH_VIDEO_URL_SUCCESS,
    payload: data
})
export const fetchChangeResourceEnabled = (data) => ({
    type: FETCH_CHANGE_RESOURCE_ENABLED,
    payload: data
})       
export const fetchChangeResourceValue = (data) => ({
    type: FETCH_CHANGE_RESOURCE_VALUE,
    payload: data
})
export const fetchCreatePreDraft = (data) => ({
    type: FETCH_CREATE_PREDRAFT,
    payload: data
})
export const fetchCreatePreDraftSuccess = (data) => ({
    type: FETCH_CREATE_PREDRAFT_SUCCESS,
    payload: data
})
export const fetchCreatePreDraftFail = (data) => ({
    type: FETCH_CREATE_PREDRAFT_FAIL,
    payload: data
})
export const fetchChangeSpecificTarget = (data) => ({
    type: FETCH_CHANGE_SPECIFICTARGET,
    payload: data
})
export const fetchChangeType = (data) => ({
    type: FETCH_CHANGE_TYPE,
    payload: data
})
export const fetchChangeName = (data) => ({
    type: FETCH_CHANGE_NAME,
    payload: data
})

export const fetchChangeVirtual = (data) => ({
    type: FETCH_CHANGE_VIRTUAL,
    payload: data
})

export const fetchChangeCellPhone = (data) => ({
    type: FETCH_CHANGE_CELLPHONE,
    payload: data
})  