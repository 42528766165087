import * as request from "request-promise";
import fetchLib from "graphql-fetch";
import { select } from "redux-saga/effects";
// const api_host = state => state.basics.api.host;
// const api_port = state => state.basics.api.port;
const api_domain = state => state.basics.api.api_domain;

export class GrahpQL {
  static *mutation(mutation, vars) {
    // const host = yield select(api_host);
    // const port = yield select(api_port);
    const domain = yield select(api_domain);
    return yield request({
      baseUrl: `http://${domain}`,
      method: "POST",
      uri: "/graphql",
      body: {
        query: mutation,
        variables: JSON.stringify(vars)
      },
      resolveWithFullResponse: true,
      json: true
    });
  }

  static *query(query, vars, opts) {
    // const host = yield select(api_host);
    // const port = yield select(api_port);
    const domain = yield select(api_domain);
    let fetch = fetchLib(`http://${domain}/graphql`);
    return yield fetch(query, vars, opts);
  }
}
