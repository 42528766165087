import React from "react";
import EngagmentSocialMedia from '../graphicsWithHeader/engagmentSocialMedia';
import EngagmentInsights from '../graphicsWithHeader/engagmentInsights';
import EngagmentDemographic from '../graphicsWithHeader/engagmentDemographic';
import InvestmentStatus from '../graphicsWithHeader/investmentStatus';
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardText from "components/Card/CardText.jsx";
// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import ArtTrack from "@material-ui/icons/ArtTrack";
import Edit from "@material-ui/icons/Edit";
import Typography from '@material-ui/core/Typography';
import { NavLink } from "react-router-dom";

const rate_review = require("assets/icons/rate_review.svg");

function Row({ ...props }) {
    const {
        classes,
        data,
        tags,
        handlerName,
      } = props;
      var editPlatform = false;
      var editCompany = false;

      props.login.user.permissions.forEach(element => {
        if (element === "viewWaitingForPlatformAudit") editPlatform = true;
        if (element === "viewWaitingForCustomerAudit") editCompany = true;
      })

      return (
        <ExpansionPanel key={props.key}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <GridContainer justify="space-between">
                    <GridItem xs={12} sm={12}>
                        <GridContainer justify="space-between">
                            <GridItem xs={12} sm={8}>
                                <CardText color="info" className={classes.width700}>
                                    <Typography className={classes.secondaryHeading}>
                                        {handlerName(data)}
                                    </Typography>
                                </CardText>
                            </GridItem>
                                <GridItem xs={12} sm={1}>
                                    {/* <div className={classes.width100}> */}
                                            <Tooltip
                                                id="tooltip-top"
                                                title={props.tags.editCampaign}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                                >
                                                <div className={classes.width100}>
                                                <NavLink to={{pathname:"/panel/WizardAction",
                                                    state: {
                                                        companyId: props.history.location.state !== undefined ? props.history.location.state.companyId : props.login.user.company.id,
                                                        companyName: props.history.location.state !== undefined ? props.history.location.state.companyName : props.login.user.company.name,
                                                        campaignName: data.name,
                                                        type: data.type,
                                                    campaignId: data._id}}}>
                                                    
                                                    <Edit className={classes.underChartIcons} />
                                                </NavLink>
                                                </div>
                                            </Tooltip>
                                        {/* </div> */}
                                </GridItem>
                                <GridItem xs={12} sm={1}>
                                    <GridContainer>
                                     <GridItem xs={2} sm={1}>
                                        {/* <div className={classes.width100}> */}
                                            <Tooltip
                                                id="tooltip-top"
                                                title={props.tags.viewDashboard}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                                >
                                                <div className={classes.width100}>
                                                <NavLink to={{pathname:"/panel/Dashboard",
                                                    state: { companyId: props.history.location.state !== undefined ? props.history.location.state.companyId : props.login.user.company.id,
                                                        companyName: props.history.location.state !== undefined ? props.history.location.state.companyName : props.login.user.company.name,
                                                        campaignName: data.name,
                                                    campaignId: data._id}}}>
                                                   <ArtTrack className={classes.underChartIcons} />
                                                </NavLink>
                                                </div>
                                            </Tooltip>
                                        {/* </div> */}
                                    </GridItem>
                                    </GridContainer>
                                </GridItem>
                                {
                                data._AdvertisementsWaitingForPlatformAudit !== null
                                && editPlatform ?
                                <GridItem xs={2} sm={1}>
                                            {/* <div className={classes.width100}> */}
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title={props.tags.pendingAuditByPlatform}
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                    >
                                                    <div className={classes.width100}>
                                                    <NavLink to={{pathname:"/panel/Audit",
                                                        state: {
                                                            companyId: props.history.location.state !== undefined ? props.history.location.state.companyId : props.login.user.company.id,
                                                            companyName: props.history.location.state !== undefined ? props.history.location.state.companyName : props.login.user.company.name,
                                                            campaignName: data.name,
                                                            status: "WaitingForPlatformAudit",
                                                        campaignId: data._id}}}>
                                                        <img src={rate_review} className={classes.underChartIcons}/>
                                                    </NavLink>
                                                    </div>
                                                </Tooltip>
                                            {/* </div> */}
                                        </GridItem>
                                :
                                null
                                }
                                {
                                    data._AdvertisementsWaitingForCustomerAudit !== null
                                    && editCompany ?
                                    <GridItem xs={2} sm={1}>
                                                {/* <div className={classes.width100}> */}
                                                    <Tooltip
                                                        id="tooltip-top"
                                                        title={props.tags.pendingAuditByCompany}
                                                        placement="bottom"
                                                        classes={{ tooltip: classes.tooltip }}
                                                        >
                                                        <div className={classes.width100}>
                                                        <NavLink to={{pathname:"/panel/Audit",
                                                            state: {
                                                                companyId: props.history.location.state !== undefined ? props.history.location.state.companyId : props.login.user.company.id,
                                                                companyName: props.history.location.state !== undefined ? props.history.location.state.companyName : props.login.user.company.name,
                                                                campaignName: data.name,
                                                                status: "WaitingForCustomerAudit",
                                                            campaignId: data._id}}}>
                                                            <img src={rate_review} className={classes.underChartIcons}/>
                                                        </NavLink>
                                                        </div>
                                                    </Tooltip>
                                                {/* </div> */}
                                            </GridItem>
                                    :
                                    null
                                }
                                    <GridItem xs={2} sm={1}></GridItem>
                                <GridItem xs={12} sm={1}>
                                </GridItem>
                                
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
          <GridContainer>
            <GridItem xs={12}>
              <Card noneMarginTop={true}>
                <CardBody>
                  <GridContainer justify="space-between">
                    <EngagmentInsights classes={classes} data={data} tags={tags}/>
                    <EngagmentSocialMedia classes={classes} data={data} tags={tags}/>
                    <EngagmentDemographic classes={classes} data={data} tags={tags} type={"Influencer"}/>
                    <EngagmentDemographic classes={classes} data={data} tags={tags} type={"Influenced"}/>
                    <InvestmentStatus classes={classes} data={data} tags={tags}/>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
}

export default Row;