import * as datos from "../querys";
import parameters from "./parameters";
import { GrahpQL } from "../mutations/parametersMutations";

var opts = {
  headers: parameters.head
};

export default {
  getSponsorshipeffectivenessesData: function* getSponsorshipeffectivenessesData(
    payload
  ) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      datos.default.sponsorshipeffectivenesses.query.sponsorshipEffectiveness,
      queryVars,
      opts
    );
    return response.data;
  }
};
