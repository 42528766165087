import { FETCH_SPONSORSHIPEFFECTIVENESSES_DATA,
    FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_SUCCESS,
    FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_FAIL
} from '../actionTypes';

export const fetchSponsorshipeffectivenessesData = (data) => ({
    type: FETCH_SPONSORSHIPEFFECTIVENESSES_DATA,
    payload: data
})

export const fetchSponsorshipeffectivenessesDataSuccess = (data) => ({
    type: FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_SUCCESS,
    payload: data
})

export const fetchSponsorshipeffectivenessesDataFail = () => ({
    type: FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_FAIL,
    success: false,
})