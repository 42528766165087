import { FETCH_COMPANIES_DATA, 
         FETCH_COMPANIES_DATA_SUCCESS, 
         FETCH_COMPANIES_DATA_FAIL,
         FETCH_COMPANIES_DATA_ADD, 
         FETCH_COMPANIES_DATA_ADD_SUCCESS, 
         FETCH_COMPANIES_DATA_ADD_FAIL,  
         FETCH_COMPANIES_NAME,
         FETCH_COMPANIES_NAME_SUCCESS,
         FETCH_COMPANIES_NAME_FAIL } from '../actionTypes';

export const fetchCompaniesData = (data) => ({
    type: FETCH_COMPANIES_DATA,
    payload: data
  });
  
export const fetchCompaniesDataSuccess = data => ({
    type: FETCH_COMPANIES_DATA_SUCCESS,
    success: true,
    payload: data,
  });
  
export const fetchCompaniesDataFail = () => ({
    type: FETCH_COMPANIES_DATA_FAIL,
    success: false,
});

export const fetchCompaniesDataAdd = (data) => ({
  type: FETCH_COMPANIES_DATA_ADD,
  payload: data
});

export const fetchCompaniesDataAddSuccess = data => ({
  type: FETCH_COMPANIES_DATA_ADD_SUCCESS,
  success: true,
  payload: data,
});

export const fetchCompaniesDataAddFail = () => ({
  type: FETCH_COMPANIES_DATA_ADD_FAIL,
  success: false,
});

export const fetchCompaniesName = () => ({
    type: FETCH_COMPANIES_NAME,
  });
  
export const fetchCompaniesNameSuccess = data => ({
    type: FETCH_COMPANIES_NAME_SUCCESS,
    success: true,
    payload: data,
  });
  
export const fetchCompaniesNameFail = () => ({
    type: FETCH_COMPANIES_NAME_FAIL,
    success: false,
  });