var query = {
    allCollections: `
    query
    {
        advertisingEffectiveness{
            _id,
            from,
            to,
            AdPrice,
            framePrice,
            hashtagPrice,
            webSitePrice,
            tagMentionMePrice,
            sealPrice,
            tagMentionPeoplePrice,
            productUsagePrice,
            insightAveragePrice,
            freezedBudget,
            insightPriceFactor,
            totalPrice,
        }

        ages{
            _id,
            age,
        }

        categories{
            _id,
            name,
        }

        sex{
            _id,
            name,
        }

        sponsorshipEffectiveness{
            _id,
            from,
            to,
            AdPrice,
            framePrice,
            hashtagPrice,
            webSitePrice,
            tagMentionMePrice,
            sealPrice,
            tagMentionPeoplePrice,
            productUsagePrice,
            insightAveragePrice,
            freezedBudget,
            insightPriceFactor,
            totalPrice,
        }

        spotEffectiveness{
            _id,
            from,
            to,
            AdPrice,
            freezedBudget,
            insightPriceFactor,
            totalPrice,
        }
        
        stars{
            _id,
            stars,
        }
    }
    
    `
}
export default {
        query
};