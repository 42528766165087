import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { MyLoaderAgeGenreAssessment } from '../../loaders';

function genre({...props}) {
    const {
        classes,
        widgets,
        handleWidgets,
        handleChange,
        checkedFemale,
        valueFemale,
        checkedMale,
        valueMale
      } = props;
      return (
        props.ages.ages.length === 0 ?
          <Card className={widgets === true ? null: classes.disabled2}>
            <CardHeader color="rose" icon>
            <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={10} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                              Genre
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                    </GridContainer>
            </CardHeader>
            <CardBody height141={true}>
              {MyLoaderAgeGenreAssessment()}
            </CardBody>
          </Card>
          :
        <Card className={widgets === true ? null: classes.disabled2}>
                  <CardHeader color="rose" icon>
                    <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={7} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                                {props.tags.genre}
                              </h4>
                            </GridItem>
                            <GridItem xs={12} sm={3} className={classes.align}>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={8}
                                checked={widgets}
                                onClick={() => handleWidgets('genre')}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody height130={true}>
                  {widgets === true ? 
                    <div>
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                              checked={
                                  widgets === false ? true : checkedFemale
                              }
                              onChange={widgets === true ? handleChange(valueFemale) : null}
                              value={valueFemale}
                              classes={{
                                //switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                icon: classes.switchIcon,
                                iconChecked: classes.switchIconChecked,
                                bar: classes.switchBar
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={props.tags.female}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                props.campaignCreation.enabledEdit ? false : true
                              }
                                checked={
                                    widgets === false ? true : checkedMale
                                }
                              onChange={widgets === true ? handleChange(valueMale) : null}
                              value={valueMale}
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                icon: classes.switchIcon,
                                iconChecked: classes.switchIconChecked,
                                bar: classes.switchBar
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={props.tags.male}
                        />
                      </div>
                    </div>
                    : null
                  }
                  </CardBody>
                </Card>
      )
}

export default genre;