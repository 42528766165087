import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCompaniesData } from "../../state/actions/companies";
import tags from "./tags";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import pricingPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx";
import webVideo from "assets/img/general/Adme Web-Es.mp4";
import webImg from "assets/img/general/AdmeWeb2.png";
import mobileVideo from "assets/img/general/Adme Mobile-Es.mp4";
import mobileImg from "assets/img/general/AdmeMobile1.png";
import admeLogo from "assets/img/general/admeWhite2.png";
import YouTube from 'react-youtube';


class KnowUs extends React.Component {
  state = {
    value: 0,
    playWeb: false,
    playMobile: false,
    playDemo: false
  };


  componentDidMount() {
    //
  }

  playWeb() {
    this.setState({
      playWeb: !this.state.playWeb
    });
  }

  playMobile() {
    this.setState({
      playMobile: !this.state.playMobile
    });
  }

  playDemo() {
    this.setState({
        playDemo: !this.state.playDemo
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    const opts = {
        height: '600',
        width: '800',
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 1
        }
      };
    return (
      <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
        <div style={{ 'text-align': 'center' }}>
          <img  src={admeLogo} alt="..." className={classes.img} />
        </div>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>
          {this.props.tags.admeSlogan}
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card pricing raised>
            <CardBody pricing pricing>
              <h6 className={classes.cardCategory}>{this.props.tags.HowDoesItWork}</h6>
              {
                this.state.playWeb ? 
                <div style={{ 'min-height': '280px' }}> 
                <video src={webVideo} className={classes.video} autoPlay={true} controls></video>
                </div>
                :
                <div style={{ 'min-height': '280px' }}> 
                  <Button color="transparent" onClick={() => this.playWeb()}>
                    <img className={`${classes.thumbnail}`} src={webImg} alt="..." />
                  </Button>
                </div>
              }
              
              <h3
                className={`${classes.cardTitle} ${classes.marginTop30}`}
              >
                {this.props.tags.brands}
              </h3>
              <p className={classes.cardDescription}>
              {this.props.tags.admeWebDescription}
              </p>
              <Button round color="success" onClick={() => this.playWeb()}>
              {this.state.playWeb ? this.props.tags.stop : this.props.tags.play}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>{this.props.tags.HowDoesItWork}</h6>
              {
                this.state.playMobile ?
                <div style={{ 'min-height': `${this.props.locale == "es"? "280px" : "300px"}`}}> 
                  <video src={mobileVideo} className={classes.video} autoPlay={true} controls></video>
                </div>
                :
                <div style={{ 'min-height': `${this.props.locale == "es"? "280px" : "300px"}`}}> 
                  <Button color="transparent" onClick={() => this.playMobile()}>
                    <img className={`${classes.thumbnail}`} src={mobileImg} alt="..." />
                  </Button>
                </div>
              }
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
              {this.props.tags.targetPeople}
              </h3>
              <p className={classes.cardDescription}>
                {this.props.tags.admeMobileDescription}
              </p>
              <Button round color="success" onClick={() => this.playMobile()}>
              {this.state.playMobile ? this.props.tags.stop : this.props.tags.play}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
            <Card pricing raised>
                <CardBody pricing>
                <h6 className={classes.cardCategory}>{"ADME"}</h6>
                {
                    this.state.playDemo ?
                    // <div style={{ 'min-height': `${this.props.locale == "es"? "280px" : "300px"}`}}> 
                        <YouTube
                            videoId={"p45QaqmkaoI"}
                            opts={opts}
                            onReady={this._onReady}
                        />
                    // </div>
                    :
                    <div style={{ 'min-height': `${this.props.locale == "es"? "280px" : "300px"}`}}> 
                    <Button color="transparent" onClick={() => this.playDemo()}>
                        <img className={`${classes.thumbnail}`} src={mobileImg} alt="..." />
                    </Button>
                    </div>
                }
                <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                {this.props.tags.fullDemo}
                </h3>
                <p className={classes.cardDescription}>
                    {/* {this.props.tags.admeMobileDescription} */}
                </p>
                <Button round color="success" onClick={() => this.playDemo()}>
                {this.state.playDemo ? this.props.tags.stop : this.props.tags.play}
                </Button>
                </CardBody>
            </Card>
        </GridItem>
      </GridContainer>
    </div>
    );
  }
}

// KnowUs.propTypes = {
//   classes: PropTypes.object.isRequired
// };

//export default withStyles(dashboardStyle)(Dashboard);


function mapStateToProps(state){
  return {
    companies: state.companies,
    locale: state.locale
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchCompaniesData: bindActionCreators(fetchCompaniesData, dispatch)
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(pricingPageStyle)(KnowUs)));
