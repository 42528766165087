import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Timeline from "@material-ui/icons/Timeline";
import { Facebook } from 'react-content-loader';

const MyFacebookLoader = () => <Facebook />

function Demographic({ ...props }) {
    const {
         classes,
         data,
         type
      } = props;
      return (
        data == null ? MyFacebookLoader() : 
        <GridItem xs={12} sm={12} md={6}>
            <Card>
                <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                    <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                    {type === "influencer" ? `${props.tags.influencer}: ` : `${props.tags.influencedValue}: ` }
                    {
                        type === "influenced" ? 
                        data.male + data.female + data.anonymous
                        : 
                        data.male + data.female
                    }
                    {
                        type === "influenced" ? 
                        <h6 className={classes.cardIconTitle}>
                            {`(${data.anonymous} ${props.tags.unknown} `}
                            {data.anonymousPercentages} <small>%</small>
                            {` )`}
                        </h6>
                        : 
                        <h6 className={classes.cardIconTitle}>
                            {`(${0} ${props.tags.unknown} `}
                            {0} <small>%</small>
                            {` )`}
                        </h6>
                    }
                    </h4>
                    
                </CardHeader>
                <CardBody>
                    
                    <Card className={classes.width50}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="info">
                            <i className="fas fa-female"></i>
                            </CardIcon>
                            <p className={classes.cardCategory}></p>
                            <h3 className={classes.cardTitle}>
                            {data.femalePercentages} <small>%</small>
                            </h3>
                            <h3 className={classes.cardTitle}>
                            {`${data.female} ${props.tags.womens}`} 
                            </h3>
                        </CardHeader>
                    </Card>
                    <Card className={classes.width50}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="info">
                            <i className="fas fa-female"></i>
                            </CardIcon>
                            <p className={classes.cardCategory}></p>
                            <h3 className={classes.cardTitle}>
                            {data.malePercentages} <small>%</small>
                            </h3>
                            <h3 className={classes.cardTitle}>
                            {`${data.male} ${props.tags.mens}`} 
                            </h3>
                        </CardHeader>
                    </Card>
                </CardBody>
            </Card>
        </GridItem>
      )
}

export default Demographic;