import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchPeopleData } from "../../../../../state/actions/people";
import {
    fetchAddPeople,
    fetchRemovePeople
} from "../../../../../state/actions/campaignCreation";
import { fetchSubscriptionRecalculating } from "../../../../../state/actions/campaignCreationSubscription";
import components from '../../../componentsSearcher';
import tags from "../../tags";
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../../styleIndex";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Assignment from "@material-ui/icons/Assignment";
import TableOfInfluencers from "./tables/tableOfInfluencers";


class PeopleSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            single: null,
            companyId: this.props.companyId
          };
        this.handlerRemovePerson = this.handlerRemovePerson.bind(this);
        this.handleAddPerson = this.handleAddPerson.bind(this);
    }

    componentWillMount(){
        this.props.fetchPeopleData({limit: 10000, socialMediaAccountsReadyToUse: true});
    }
    
    handleAddPerson = name => value => {
        if (this.props.campaignCreation.enabledEdit) {
            let item = this.props.campaignCreation.specificTargetScope.find(c => c._id === value.value);
            if(item) return false;
            
            let person = this.props.people.people.find(c => c._id === value.value);
            this.props.fetchAddPeople(person);
            this.props.fetchSubscriptionRecalculating();
        }
    };

    handlerRemovePerson (id) {
        if (this.props.campaignCreation.enabledEdit) {
            this.props.fetchRemovePeople({_id: id});
            this.props.fetchSubscriptionRecalculating();
        }
    }

    render (){
        const { classes } = this.props;
        let suggestions = this.props.people.people.map(suggestion => (
            {
                value: suggestion._id,
                label: `${suggestion.firstName? suggestion.firstName : ''} ${suggestion.lastName? suggestion.lastName : ''}`,
            }
          ));
          
        const selectStyles = {
        input: base => ({
            ...base,
            '& input': {
            font: 'inherit',
            },
        }),
        };
        return (
            <Card 
            >
                <CardHeader color="rose" icon className={classes.alignCenter}>
                  <CardIcon color="info">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>{this.props.tags.searchPeople}</h4>
                </CardHeader>
                <CardBody height101={true}>
                    {/* {widgets === true ?  */}
                    <AppBar position="static" className={classes.menu} >
                        <Toolbar>
                            <div className={classes.rootSearcher}>
                                <NoSsr>
                                    <Select
                                        classes={classes}
                                        styles={selectStyles}
                                        options={suggestions}
                                        components={components}
                                        value={this.state.single}
                                        onChange={this.handleAddPerson('single')}
                                        placeholder={this.props.tags.searchPerson}
                                    />
                                    <div className={classes.divider} />
                                </NoSsr>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {
                        this.props.campaignCreation.followers &&
                        this.props.campaignCreation.specificTargetScope.length !== 0 ?
                        <TableOfInfluencers 
                            items={this.props.campaignCreation.specificTargetScope} 
                            handlerRemovePerson={this.handlerRemovePerson}
                            classes={classes}
                        />
                      :
                      null
                    }
                    
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state){
    return {
        people: state.people,
        campaignCreation: state.campaignCreation
    }
}

function mapDispatchToProps(dispatch){
    return {
        fetchPeopleData: bindActionCreators(fetchPeopleData, dispatch),
        fetchAddPeople: bindActionCreators(fetchAddPeople, dispatch),
        fetchRemovePeople: bindActionCreators(fetchRemovePeople, dispatch),
        fetchSubscriptionRecalculating: bindActionCreators(fetchSubscriptionRecalculating, dispatch)
    }
}


export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeopleSearch)));