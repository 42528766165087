import {
  FETCH_LOGIN_DATA_SUCCESS,
  FETCH_LOGIN_DATA_UNSUCCESS,
  LOADING_LOGIN,
  FETCH_LOGOUT,
  FETCH_CHANGE_COMPANY_LOGO,
  FETCH_CHANGE_COMPANY_DATA_SUCCESS
} from "../actionTypes";

const initialState = {
  user: null,
  token: null,
  loadingLogin: null,
  loginStatus: null,
  changeLogo: null,
  media: {
    file: null,
    type: null,
    imagePreviewUrl: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANGE_COMPANY_DATA_SUCCESS: {
      return {
        ...state,
        changeLogo: true
      };
    }
    case FETCH_CHANGE_COMPANY_LOGO: {
      return {
        ...state,
        media: action.payload
      };
    }
    case FETCH_LOGOUT: {
      localStorage.removeItem("@token");
      return {
        user: null,
        token: null,
        loadingLogin: null,
        loginStatus: null,
        media: {
          file: null,
          type: null,
          imagePreviewUrl: null
        }
      };
    }
    case LOADING_LOGIN: {
      return {
        ...state,
        loadingLogin: action.payload
      };
    }
    case FETCH_LOGIN_DATA_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        token: action.payload.data.token,
        loginStatus: null,
        media: {
          ...state.media,
          imagePreviewUrl: action.payload.data.user.company.logo
            ? action.payload.data.user.company.logo
            : null
        }
      };
    case FETCH_LOGIN_DATA_UNSUCCESS: {
      return {
        ...state,
        user: null,
        token: null,
        loginStatus: action.payload.data[0].message,
        media: {
          file: null,
          type: null,
          imagePreviewUrl: null
        }
      };
    }
    default:
      return state;
  }
};
