import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchPeopleDataSuccess,
    fetchPeopleDataFail
 } from "../actions/people";
import {
    FETCH_PEOPLE_DATA
} from '../actionTypes';
import api from '../api';

function* fetchPeopleData(action) {
    try {
          let data = yield api.people.getPeopleData(action.payload);
          yield put(fetchPeopleDataSuccess({data:data.persons}));
    } catch (e) {
          yield put(fetchPeopleDataFail());
    }
}

export default function* regions() {
    yield takeLatest(FETCH_PEOPLE_DATA, fetchPeopleData);
}