import { advertisements } from "../mutations/advertisements";
import * as querys from "../querys";
import parameters from "./parameters";
import { GrahpQL } from "../mutations/parametersMutations";

var opts = {
  headers: parameters.head
};

export default {
  getAdvertisementsData: function* getAdvertisementsData(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.advertisements.query.advertisements,
      queryVars,
      opts
    );
    return response.data;
  },
  updateAdvertisementsData: function* updateAdvertisementsData(payload) {
    try {
      const mutation = advertisements.updateAdvertisement;
      const response = yield GrahpQL.mutation(mutation, payload);
      return response.body;
    } catch (e) {}
  }
};
