const updateAdvertisement = `
mutation updateAdvertisement(
    $_id: ID!
    $active: Boolean
    $status: advertisementStatus!
    $platformScore: Float
    $custumerScore: Float
    $rejectionReason: rejectionReason
){
    updateAdvertisement(
        _id: $_id
        active: $active
        status: $status
        platformScore: $platformScore
        custumerScore: $custumerScore
        rejectionReason: $rejectionReason
        
  ) {
    data,
    success,
    error {
        path
        message
      }
  }
}
`;


export const advertisements = {
    updateAdvertisement
}