import { FETCH_CREATE_PERSON_DATA,
    FETCH_CREATE_PERSON_DATA_SUCCESS,
    FETCH_CREATE_PERSON_DATA_UNSUCCESS,
    FETCH_CREATE_PERSON_DATA_FAIL,
    LOADING_CREATE_PERSON
} from '../actionTypes';

export const loadingCreatePerson = (data) => ({
    type: LOADING_CREATE_PERSON,
    payload: data
})

export const fetchCreatePersonData = (data) => ({
    type: FETCH_CREATE_PERSON_DATA,
    payload: data
})

export const fetchCreatePersonDataSuccess = (data) => ({
    type: FETCH_CREATE_PERSON_DATA_SUCCESS,
    payload: data
})

export const fetchCreatePersonDataUnsuccess = (data) => ({
    type: FETCH_CREATE_PERSON_DATA_UNSUCCESS,
    payload: data
})

export const fetchCreatePersonDataFail = () => ({
    type: FETCH_CREATE_PERSON_DATA_FAIL,
    success: false,
})