import { FETCH_PEOPLE_DATA_SUCCESS,
} from "../actionTypes";


const initialState = {
    people: []
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_PEOPLE_DATA_SUCCESS: {
    return {
        people: action.payload.data.reduce((collection, item) => {
            if(item.roleIds.length != 0) {
                collection.push(item);
            }
            return collection;
            }, []),
    }   
   }
   default:
       return state;
    }
}