import { FETCH_COMPANIES_DATA_SUCCESS, 
         FETCH_COMPANIES_DATA_ADD_SUCCESS, 
         FETCH_COMPANIES_NAME_SUCCESS,
} from "../actionTypes";

const initialState = {
      companies: [],
      companiesName: [],
      hasMore: true,
      page: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_DATA_SUCCESS:
      return {
        ...state,
        companies: action.payload.data,
        hasMore: action.payload.hasMore,
        page: 3
    }
    case FETCH_COMPANIES_DATA_ADD_SUCCESS:
      return {
        ...state,
        companies: [...state.companies,...action.payload.data],
        hasMore: action.payload.hasMore,
        page: state.page + 1
    }
    case FETCH_COMPANIES_NAME_SUCCESS:
      return {
        ...state,
        companiesName: action.payload
    }
    default:
      return state;
  }
};
