import { FETCH_ADVERTISING_EFFECTIVENESSES_DATA,
    FETCH_ADVERTISING_EFFECTIVENESSES_DATA_SUCCESS,
    FETCH_ADVERTISING_EFFECTIVENESSES_DATA_FAIL
} from '../actionTypes';

export const fetchAdvertisingEffectivenessesData = (data) => ({
    type: FETCH_ADVERTISING_EFFECTIVENESSES_DATA,
    payload: data
})

export const fetchAdvertisingEffectivenessesDataSuccess = (data) => ({
    type: FETCH_ADVERTISING_EFFECTIVENESSES_DATA_SUCCESS,
    payload: data
})

export const fetchAdvertisingEffectivenessesDataFail = () => ({
    type: FETCH_ADVERTISING_EFFECTIVENESSES_DATA_FAIL,
    success: false,
})