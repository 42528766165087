import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchCategoriesDataSuccess,
    fetchCategoriesDataFail
 } from "../actions/categories";
import {
    FETCH_CATEGORIES_DATA
} from '../actionTypes';
import api from '../api';

function* fetchCategoriesData(action) {
    try {
          let data = yield api.categories.getCategoriesData(action.payload);
          yield put(fetchCategoriesDataSuccess({data:data.categories}));
    } catch (e) {
          yield put(fetchCategoriesDataFail());
    }
}

export default function* categoriesSaga() {
    yield takeLatest(FETCH_CATEGORIES_DATA, fetchCategoriesData);
}