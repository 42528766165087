import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Nouislider from "nouislider-react";
import { MyLoaderAgeGenreAssessment } from '../../loaders';

function age({...props}) {
    const {
        classes,
        widgets,
        handleWidgets,
        handleAge,
        ageLabelFrom,
        ageLabelTo,
        ageSlideFrom,
        ageSlideTo
      } = props;
      
      return (
          props.ages.ages.length === 0 ?
          <Card className={widgets === true ? null: classes.disabled2}>
            <CardHeader color="rose" icon>
            <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={10} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                              {props.tags.age}
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                    </GridContainer>
            </CardHeader>
            <CardBody height141={true}>
              {MyLoaderAgeGenreAssessment()}
            </CardBody>
          </Card>
          :
          <Card className={widgets === true ? null: classes.disabled2}>
                  <CardHeader color="rose" icon>
                    <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={7} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                              {props.tags.age}
                              </h4>
                            </GridItem>
                            <GridItem xs={12} sm={3} className={classes.align}>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={8}
                                checked={widgets}
                                onClick={() =>handleWidgets('age')}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody height130={true}>
                  {widgets === true ? 
                    <div>
                      <Nouislider className="slider-primary"
                          disabled={
                            props.campaignCreation.enabledEdit ? false : true
                          }
                          key={'1'}
                          id={0}
                          start={[ageSlideFrom, ageSlideTo]}
                          value={[ageLabelFrom, ageLabelTo]}
                          step={1}
                          connect={[false, true, false]}
                          range={{
                            min: 0,
                            max: 99
                          }}
                          onChange={handleAge()}
                      />
                      <br />
                      <div>
                          {`${props.tags.from}: ${ageLabelFrom} ${props.tags.years} `}
                      </div>
                      <br />
                      <div>
                          {`${props.tags.to}: ${ageLabelTo} ${props.tags.years} `}
                      </div>
                    </div>
                    : null
                  }
          </CardBody>
        </Card>
        
      )
}

export default age;