import { FETCH_PEOPLE_DATA,
    FETCH_PEOPLE_DATA_SUCCESS,
    FETCH_PEOPLE_DATA_FAIL,
} from '../actionTypes'; 

export const fetchPeopleData = (data) => ({
    type: FETCH_PEOPLE_DATA,
    payload: data
})

export const fetchPeopleDataSuccess = (data) => ({
    type: FETCH_PEOPLE_DATA_SUCCESS,
    payload: data
})

export const fetchPeopleDataFail = () => ({
    type: FETCH_PEOPLE_DATA_FAIL,
    success: false,
})