import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({
    loading,
    knowUs,
    dashboard,
    english,
    spanish,
    login,
    register,
    adme,
}) => ({tags:{
        loading,
        knowUs,
        dashboard,
        english,
        spanish,
        login,
    register,
    adme,
    }});

export default translatable(mapTranslationsToProps);