import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({
    loading,
    email,
    password,
    withoutAccount,
    letsGo,
    createAccount,
    logOut,
    company,
    remove,
    change,
    getStarted,
    companyName,
    firstName,
    lastName,
    register,
    selectImage,
    addPhoto,
    admeMobileDescription,
    admeWebDescription,
    targetPeople,
    brands,
    play,
    stop,
    admeSlogan,
    HowDoesItWork,
    fullDemo
}) => ({tags:{
        loading,
        email,
        password,
        withoutAccount,
        letsGo,
        createAccount,
        logOut,
        company,
        remove,
        change,
        getStarted,
        companyName,
        firstName,
        lastName,
        register,
        selectImage,
        addPhoto,
        admeWebDescription,
        admeMobileDescription,
        brands,
        targetPeople,
        play,
        stop,
        admeSlogan,
        HowDoesItWork,
        fullDemo
    }});

export default translatable(mapTranslationsToProps);