import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "../tags";
import styles from "../styleIndex";
import PeopleSearch from "../ComponentsSteps/step2/peopleSearch";
import {  
  fetchChangeResourceValue,
  fetchChangeResourceEnabled,
  fetchChangeSpoteffectivenesses,
  fetchChangeSponsorshipeffectivenesses,
  fetchChangeAdvertisingEffectivenesses,
  fetchUpdateCampaign,
  fetchRemovePeople,
  fetchChangeSpotDescription
} from "../../../../state/actions/campaignCreation";
import { fetchSubscriptionRecalculating } from "../../../../state/actions/campaignCreationSubscription";
import ResourcesOfAdvertisement from "../ComponentsSteps/step2/resourcesOfAdvertisement";
import ResourcesOfAdvertisementSpot from "../ComponentsSteps/step2/resourcesOfAdvertisementSpot";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ImageVideoUpload from "../ComponentsSteps/step2/imageVideoUpload";
import TextField from '@material-ui/core/TextField';




const resourcesTag = {
  frame: 'frame',
  hashtag: 'hashtag',
  webSite: 'webSite',
  tagMentionMe: 'tagMentionMe',
  seal: 'seal',
  tagMentionPeople: 'tagMentionPeople',
  productUsage: 'productUsage',
  productUsageOficial: 'productUsageOficial',
  spot: 'spot'
 }  

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      alert: null,
      show: false,
      
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.inputConfirmAlert = this.inputConfirmAlert.bind(this);
    this.inputConfirmAlertNext = this.inputConfirmAlertNext.bind(this);
    this.handleResources = this.handleResources.bind(this);
    this.inputAlert = this.inputAlert.bind(this);
    this.handleSpoteffectivenesses = this.handleSpoteffectivenesses.bind(this);
    this.handleEffectivenesses = this.handleEffectivenesses.bind(this);
    this.handlerRemovePerson = this.handlerRemovePerson.bind(this);
    this.handleChangeSpotDescription = this.handleChangeSpotDescription.bind(this);

    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
    this.swiper = null
}

shouldComponentUpdate(nextProps, nextState) {
  //#region specificTargetScope
  if(this.props.campaignCreation.specificTargetScope.length !== nextProps.campaignCreation.specificTargetScope.length) {
    this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
      specificTargetScope: nextProps.campaignCreation.specificTargetScope,
      sponsorshipEffectivenessCriterias: nextProps.campaignCreation.sponsorshipEffectivenessCriterias,
      spotEffectivenessCriterias: nextProps.campaignCreation.spotEffectivenessCriterias,
      advertisingEffectivenessCriterias: nextProps.campaignCreation.advertisingEffectivenessCriterias,
      type: nextProps.campaignCreation.type,
      followers: nextProps.campaignCreation.followers,
      specificTarget: nextProps.campaignCreation.specificTarget
    });
  }
  //endregion

  return true;
}

isValidated() {
  return true;
}

handleChangeSpotDescription (event) {
  event.preventDefault();
  this.props.fetchChangeSpotDescription(event.target.value);
}

handleSpoteffectivenesses = () => (render, handle, value, un, percent) => {
  if (this.props.campaignCreation.enabledEdit) {
    if(this.props.spoteffectivenesses !== undefined && this.props.spoteffectivenesses.spotEffectiveness.length !== 0) {
      this.props.fetchChangeSpoteffectivenesses(
        {from: value[0], 
        to: value[1], 
        checked: true, 
        items: this.props.spoteffectivenesses.spotEffectivenessRead, 
        changeSlide: false}
      );

      this.props.fetchSubscriptionRecalculating();
    }
  }
};

handleEffectivenesses = (type) => (render, handle, value, un, percent) => {
  if (this.props.campaignCreation.enabledEdit) {
    switch (type) {
      case "Sponsorship":
        if(this.props.sponsorshipeffectivenesses !== undefined && this.props.sponsorshipeffectivenesses.sponsorshipeffectivenesses.length !== 0) {
          this.props.fetchChangeSponsorshipeffectivenesses(
            {from: value[0], 
            to: value[1], 
            checked: true, 
            items: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead, 
            changeSlide: false}
          );

          this.props.fetchSubscriptionRecalculating();
        }
        break;
      case "Advertising":
        if(this.props.advertisingEffectivenesses !== undefined && this.props.advertisingEffectivenesses.advertisingEffectivenesses.length !== 0) {
          this.props.fetchChangeAdvertisingEffectivenesses(
            {from: value[0], 
            to: value[1], 
            checked: true, 
            items: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead, 
            changeSlide: false}
          );

          this.props.fetchSubscriptionRecalculating();
        }
        break;
      default:
        break;
    }
  }
};

handleResources(value) {
  if (this.props.campaignCreation.enabledEdit) {
    var currentIndex = this.props.campaignCreation.resources[value];
    if (currentIndex.enabled === true) {
      currentIndex.enabled = false;
    } else {
      currentIndex.enabled = true;
    }
    this.props.fetchChangeResourceEnabled({key:value, enabled: currentIndex.enabled});
  }
}

inputAlert(resource) {
  if (this.props.campaignCreation.enabledEdit) {
    this.setState({
      alert: (
        <SweetAlert
          customClass={this.props.classes.cardIconTitle}
          input
          showCancel
          style={{ 'font-size': '10px', 'text-align': 'center', display: "block", marginTop: "-100px" }}
          title={this.props.campaignCreation.resources[resource].value }
          defaultValue={this.props.campaignCreation.resources[resource].value }
          onConfirm={e => this.inputConfirmAlert(e, resource)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
        </SweetAlert>
      )
    });
  }
}

inputConfirmAlert(e, resource) {
  this.props.fetchChangeResourceValue({key:resource, value:e})
  this.setState({ 
    alert: e,
  });
  setTimeout(this.inputConfirmAlertNext, 20);
}

hideAlert() {
  this.setState({
    alert: null
  });
}

handlerRemovePerson (id) {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchRemovePeople({_id: id});

    this.props.fetchSubscriptionRecalculating();
  }
}

inputConfirmAlertNext() {
  const inputValue = this.state.alert;
  this.setState({
    alert: (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.info
        }
        title={
          <p>
            {this.props.tags.youEntered} <b>{inputValue}</b>
          </p>
        }
      />
    )
  });
}

goNext() {
  if (this.swiper) this.swiper.slideNext()
}

goPrev() {
  if (this.swiper) this.swiper.slidePrev()
}

render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer justify="center">
          { 
            this.props.campaignCreation.specificTarget ?
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12}>
                    <PeopleSearch />
                  </GridItem>
                </GridContainer>
                :
                null
            }
            {this.props.campaignCreation.type === "Spot" ?
                <GridItem xs={12} sm={6}>
                  <ResourcesOfAdvertisementSpot
                    type="Spot"
                    handleSpoteffectivenesses={this.handleSpoteffectivenesses}
                    specificTarget={this.props.campaignCreation.specificTarget}
                    followers={this.props.campaignCreation.followers}
                    handlerRemovePerson={this.handlerRemovePerson}
                    classes={classes}
                    widgets={
                      this.props.campaignCreation.specificTarget &&
                      this.props.campaignCreation.followers === false ?
                      true 
                      :
                      this.props.campaignCreation.spotEffectivenessCriteriasChecked}
                    items={
                      this.props.campaignCreation.specificTarget &&
                      this.props.campaignCreation.followers === false ?
                      this.props.campaignCreation.specificTargetScope
                      :
                      this.props.campaignCreation.spotEffectivenessCriterias
                    }
                    {...this.props}
                  />
                </GridItem>
              : this.props.campaignCreation.type === "Sponsorship" ?
                <GridItem xs={12} sm={12}>
                  <ResourcesOfAdvertisement
                    type="Sponsorship"
                    handlerRemovePerson={this.handlerRemovePerson}
                    handleEffectivenesses={this.handleEffectivenesses}
                    specificTarget={this.props.campaignCreation.specificTarget}
                    followers={this.props.campaignCreation.followers}
                    classes={classes}
                    widgets={
                      this.props.campaignCreation.specificTarget &&
                      this.props.campaignCreation.followers === false ?
                      true
                      :  
                      this.props.campaignCreation.sponsorshipEffectivenessCriteriasChecked}
                    handleResources={this.handleResources}
                    inputAlert={this.inputAlert}
                    resourcesTag={resourcesTag}
                    resources={this.props.campaignCreation.resources}
                    items={
                      this.props.campaignCreation.specificTarget &&
                      this.props.campaignCreation.followers === false ?
                      this.props.campaignCreation.specificTargetScope
                      :
                      this.props.campaignCreation.sponsorshipEffectivenessCriterias
                    }
                    {...this.props}
                  />
                </GridItem> 
                :
                this.props.campaignCreation.type === "Advertising" ?
                <GridItem xs={12} sm={12}>
                  <ResourcesOfAdvertisement
                    type="Advertising"
                    handlerRemovePerson={this.handlerRemovePerson}
                    handleEffectivenesses={this.handleEffectivenesses}
                    specificTarget={this.props.campaignCreation.specificTarget}
                    followers={this.props.campaignCreation.followers}
                    classes={classes}
                    widgets={
                      this.props.campaignCreation.specificTarget &&
                      this.props.campaignCreation.followers === false ?
                      true
                      :  
                      this.props.campaignCreation.advertisingEffectivenessCriteriasChecked}
                    handleResources={this.handleResources}
                    inputAlert={this.inputAlert}
                    resourcesTag={resourcesTag}
                    resources={this.props.campaignCreation.resources}
                    items={
                      this.props.campaignCreation.specificTarget &&
                      this.props.campaignCreation.followers === false ?
                      this.props.campaignCreation.specificTargetScope
                      :
                      this.props.campaignCreation.advertisingEffectivenessCriterias
                    }
                    {...this.props}
                  />
                </GridItem>
                :
                null
            }
          {this.props.campaignCreation.type === "Spot" ? 
            <GridItem xs={12} sm={6}>
                <Card>
                  <CardHeader color="rose" icon>
                          <CardIcon color="info">
                            <Assignment />
                          </CardIcon>
                          <GridItem xs={12} sm={10} className={classes.alignCenter}>
                            <h4 className={classes.cardIconTitle}>
                              {this.props.tags.loadYourSpot}
                            </h4>
                          </GridItem>
                  </CardHeader>
                  <CardBody height130={true}>
                      <TextField
                            disabled={
                              this.props.campaignCreation.enabledEdit ? false : true
                            }
                            id="standard-multiline-flexible"
                            label={this.props.tags.defineSpot}
                            multiline
                            rowsMax="6"
                            fullWidth={true}
                            defaultValue={this.props.campaignCreation.resources.spot.description}
                            onChange={this.handleChangeSpotDescription}
                            className={classes.textField}
                            margin="normal"
                          />
                          
                    <ImageVideoUpload
                      classes={classes}
                        spot={this.props.campaignCreation.resources.spot}
                        addButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true
                        }}
                      />
                  </CardBody>
                </Card>
            </GridItem>
              : null
          }
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    campaignCreation: state.campaignCreation,
    spoteffectivenesses: state.spoteffectivenesses,
    sponsorshipeffectivenesses: state.sponsorshipeffectivenesses,
    advertisingEffectivenesses: state.advertisingEffectivenesses
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchChangeResourceValue: bindActionCreators(fetchChangeResourceValue, dispatch),
    fetchChangeResourceEnabled: bindActionCreators(fetchChangeResourceEnabled, dispatch),
    fetchChangeSpoteffectivenesses: bindActionCreators(fetchChangeSpoteffectivenesses, dispatch),
    fetchChangeSponsorshipeffectivenesses: bindActionCreators(fetchChangeSponsorshipeffectivenesses, dispatch),
    fetchChangeAdvertisingEffectivenesses: bindActionCreators(fetchChangeAdvertisingEffectivenesses, dispatch),
    fetchUpdateCampaign: bindActionCreators(fetchUpdateCampaign, dispatch),
    fetchRemovePeople: bindActionCreators(fetchRemovePeople, dispatch),
    fetchSubscriptionRecalculating: bindActionCreators(fetchSubscriptionRecalculating, dispatch),
    fetchChangeSpotDescription: bindActionCreators(fetchChangeSpotDescription, dispatch)
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step2)));
