import { FETCH_STARS_DATA_SUCCESS,
} from "../actionTypes";

const initialState = {
    stars: [],
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_STARS_DATA_SUCCESS:
       return {
        stars: action.payload.data,
   }
   default:
       return state;
    }
}