import * as querys from "../querys";
import parameters from "./parameters";
import { GrahpQL } from "../mutations/parametersMutations";

var opts = {
  headers: parameters.head
};

export default {
  getCompaniesData: function* getCompaniesData(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.companies.query.allCompanies,
      queryVars,
      opts
    );
    return response.data;
  },
  getCompaniesName: function* getCompaniesName(payload) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.companies.query.allCompaniesName,
      queryVars,
      opts
    );
    return response.data;
  }
};
