import { FETCH_ADVERTISEMENTS_DATA_SUCCESS,
    FETCH_CHANGE_APPROVE,
    FETCH_CHANGE_REJECTION_REASON,
    FETCH_CHANGE_CHECKED_ALL,
    FETCH_CHANGE_STAR,
    FETCH_WAITING_SAVE_AND_MORE
} from "../actionTypes";

const initialState = {
    advertisements: [],
    checkedAll: true,
    waitingSaveAndMore: false
}

export default (state = initialState, action) => {
switch (action.type) {
    case FETCH_WAITING_SAVE_AND_MORE: {
        return {
            ...state,
            waitingSaveAndMore: action.payload
        }
    }
    case FETCH_CHANGE_STAR: {
        return {
            ...state,
            advertisements: state.advertisements.reduce((collection, item) => {
                if(item._id === action.payload._id && item.status === "WaitingForPlatformAudit") item.platformScore = action.payload.score;
                else if(item._id === action.payload._id && item.status === "WaitingForCustomerAudit") item.custumerScore = action.payload.score;
                collection.push(item);
                return collection;
                }, []),
        }
    }
    case FETCH_CHANGE_CHECKED_ALL: {
        return {
            checkedAll: action.payload.checked,
            advertisements: state.advertisements.reduce((collection, item) => {
                if (action.payload.checked) {
                    item.rejectionReason = "None";
                    item.approve = true;
                    if (item.status === "WaitingForPlatformAudit") item.platformScore = 2.5;
                    else if (item.status === "WaitingForCustomerAudit") item.custumerScore = 2.5;
                } else {
                    item.approve = false;
                    if (item.status === "WaitingForPlatformAudit") item.platformScore = 1;
                    else if (item.status === "WaitingForCustomerAudit") item.custumerScore = 1;
                }

                collection.push(item);
                return collection;
                }, []),
        }
    }
    case FETCH_CHANGE_REJECTION_REASON: {
        return {
            ...state,
            advertisements: state.advertisements.reduce((collection, item) => {
                if(item._id === action.payload._id) item.rejectionReason = action.payload.rejectionReason;
                collection.push(item);
                return collection;
                }, []),
        }
    }
    case FETCH_CHANGE_APPROVE: {
        return {
            ...state,
            advertisements: state.advertisements.reduce((collection, item) => {
            if(item._id === action.payload._id) item.approve = !item.approve;
            if (item.approve) item.rejectionReason = "None";
            collection.push(item);
            return collection;
            }, []),
        }
    }
    case FETCH_ADVERTISEMENTS_DATA_SUCCESS: {
        return {
            ...state,
            advertisements: action.payload.data.reduce((collection, item) => {
                let amountPayableSponsorship = item._person.sponsorshipEffectivenessReference.AdPrice + item._person.sponsorshipEffectivenessReference.freezedBudget;
                let amountPayableAdvertising = item._person.advertisingEffectivenessReference.AdPrice + item._person.advertisingEffectivenessReference.freezedBudget;

                let amountPayableSpot = item._person.spotEffectivenessReference.AdPrice + item._person.spotEffectivenessReference.freezedBudget;;

                if (item.linkIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.webSitePrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.webSitePrice : 0;
                }

                if (item.bannerIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.framePrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.framePrice : 0;
                }

                if (item.hashtagIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.hashtagPrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.hashtagPrice : 0;
                }

                if (item.watermarkIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.sealPrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.sealPrice : 0;
                }

                if (item.productUsageIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.productUsagePrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.productUsagePrice : 0;
                }

                if (item.mentionToCompanyIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.tagMentionMePrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.tagMentionMePrice : 0;
                }

                if (item.mentionToOtherIncluded) {
                    amountPayableSponsorship += item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.tagMentionPeoplePrice : 0;
                    amountPayableAdvertising += item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.tagMentionPeoplePrice : 0;
                }

                amountPayableSponsorship = amountPayableSponsorship.toFixed(2);
                amountPayableAdvertising = amountPayableAdvertising.toFixed(2);

                let rejectionReasonList = [
                    "None",
                    "MultimediaContentNoApply",
                    "MissingResources",
                    "CaptionNotApply"
                ];

                item = {...item, approve: true, amountPayableSponsorship, amountPayableAdvertising, amountPayableSpot, rejectionReasonList};
                collection.push(item);
                return collection;
            }, []),
    }
   }
       
   default:
       return state;
    }
}