export const initMapData = {
    'AR-Z': 10040,	//Santa Cruz
    'AR-X': 10040,	//Córdoba
    'AR-Y': 10040,	//Jujuy
    'AR-V': 10040,	//Tierra del Fuego
    'AR-W': 10040,	//Corrientes
    'AR-T': 10040,	//Tucumán
    'AR-U': 10040,	//Chubut
    'AR-R': 10040,	//Río Negro
    'AR-S': 10040,	//Santa Fe
    'AR-P': 10040,	//Formosa
    'AR-Q': 10040,	//Neuquén
    'AR-N': 10040,	//Misiones
    'AR-L': 10040,	//La Pampa
    'AR-M': 10040,	//Mendoza
    'AR-J': 10040,	//San Juan
    'AR-K': 10040,	//Catamarca
    'AR-H': 10040,	//Chaco
    'AR-F': 10040,	//La Rioja
    'AR-G': 10040,	//Santiago del Estero
    'AR-D': 10040,	//San Luis
    'AR-E': 10040,	//Entre Ríos
    'AR-B': 10040,	//Buenos Aires
    'AR-C': 10040,	//Ciudad de Buenos Aires
    'AR-A': 10040,	//Salta
}

export const argMapData = {
    'AR-Z': 300,	//Santa Cruz
    'AR-X': 300,	//Córdoba
    'AR-Y': 300,	//Jujuy
    'AR-V': 300,	//Tierra del Fuego
    'AR-W': 300,	//Corrientes
    'AR-T': 300,	//Tucumán
    'AR-U': 300,	//Chubut
    'AR-R': 300,	//Río Negro
    'AR-S': 300,	//Santa Fe
    'AR-P': 300,	//Formosa
    'AR-Q': 300,	//Neuquén
    'AR-N': 300,	//Misiones
    'AR-L': 300,	//La Pampa
    'AR-M': 300,	//Mendoza
    'AR-J': 300,	//San Juan
    'AR-K': 300,	//Catamarca
    'AR-H': 300,	//Chaco
    'AR-F': 300,	//La Rioja
    'AR-G': 300,	//Santiago del Estero
    'AR-D': 300,	//San Luis
    'AR-E': 300,	//Entre Ríos
    'AR-B': 300,	//Buenos Aires
    'AR-C': 300,	//Ciudad de Buenos Aires
    'AR-A': 300,	//Salta
}

export const mapDataDB = {
    'Santa Cruz': 'AR-Z',	//Santa Cruz
    'Cordoba': 'AR-X',	//Córdoba
    'Jujuy': 'AR-Y',	//Jujuy
    'Tierra del Fuego, Antartida e Islas del Atlantico Sur': 'AR-V',	//Tierra del Fuego
    'Corrientes': 'AR-W',	//Corrientes
    'Tucuman': 'AR-T',	//Tucumán
    'Chubut': 'AR-U',	//Chubut
    'Rio Negro': 'AR-R',	//Río Negro
    'Santa Fe': 'AR-S',	//Santa Fe
    'Formosa': 'AR-P',	//Formosa
    'Neuquen': 'AR-Q',	//Neuquén
    'Misiones':'AR-N',	//Misiones
    'La Pampa': 'AR-L',	//La Pampa
    'Mendoza':'AR-M',	//Mendoza
    'San Juan': 'AR-J',	//San Juan
    'Catamarca': 'AR-K',	//Catamarca
    'Chaco': 'AR-H',	//Chaco
    'La Rioja':'AR-F',	//La Rioja
    'Santiago del Estero': 'AR-G',	//Santiago del Estero
    'San Luis': 'AR-D',	//San Luis
    'Entre Rios': 'AR-E',	//Entre Ríos
    'Buenos Aires': 'AR-B',	//Buenos Aires
    'Ciudad Autonoma de Buenos Aires': 'AR-C',	//Ciudad de Buenos Aires
    'Salta':'AR-A',	//Salta
}