import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import KnowUs from "views/Pages/KnowUs.jsx";

// @material-ui/icons
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Home from "@material-ui/icons/Home";

const pagesRoutes = [
  {
    view: true,
    permission: "public",
    path: "/pages/AdMe",
    name: "adme",
    short: "adme",
    mini: "K.U",
    icon: Home,
    component: KnowUs
  },
  {
    view: true,
    permission: "public",
    path: "/pages/register-page",
    name: "Register Page",
    short: "register",
    mini: "RP",
    icon: AddCircleOutline,
    component: RegisterPage
  },
  {
    view: true,
    permission: "public",
    path: "/pages/login-page",
    name: "Login Page",
    short: "login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    view: true,
    permission: "public",
    redirect: true,
    path: "/",
    pathTo: "/pages/AdMe",
    name: "adme"
  }
];

export default pagesRoutes;
