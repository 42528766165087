import { combineReducers } from "redux";
import { localeReducer } from "react-multilingual";

import companies from "./companies";
import campaigns from "./campaigns";
import campaignCreation from "./campaignCreation";
import campaignCreationSubscription from "./campaignCreationSubscription";
import ages from "./ages";
import categories from "./categories";
import regions from "./regions";
import sexes from "./sexes";
import sponsorshipeffectivenesses from "./sponsorshipeffectivenesses";
import spoteffectivenesses from "./spoteffectivenesses";
import stars from "./stars";
import people from "./people";
import advertisingEffectivenesses from "./advertisingEffectivenesses";
import login from "./login";
import person from "./person";
import advertisements from "./advertisements";
import basics from "./basics";

export default combineReducers({
  basics,
  companies,
  campaigns,
  campaignCreation,
  campaignCreationSubscription,
  locale: localeReducer("en", require("../languageResources/index").default),
  ages,
  categories,
  regions,
  sexes,
  sponsorshipeffectivenesses,
  spoteffectivenesses,
  stars,
  people,
  advertisingEffectivenesses,
  login,
  person,
  advertisements
});
