import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "../../tags";
import styles from "../../styleIndex";
import withStyles from "@material-ui/core/styles/withStyles";
import { fetchImageUrlSuccess, fetchVideoUrlSuccess, deleteSpot, fetchChangeMedia } from "../../../../../state/actions/campaignCreation";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import LinearProgress from '@material-ui/core/LinearProgress';
import firebase from 'firebase';
import moment from 'moment';

var config = {
  apiKey: "AIzaSyBjZr3h6sKeFLTnmPyq-JmZzVq7YFAE4co",
  authDomain: "influencers-14b0c.firebaseapp.com",
  databaseURL: "https://influencers-14b0c.firebaseio.com",
  projectId: "influencers-14b0c",
  storageBucket: "influencers-14b0c.appspot.com",
  messagingSenderId: "978158793560"
};
firebase.initializeApp(config);
 
const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

class imageVideoUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //file: null,
      //imagePreviewUrl: props.spot.imageEnabled === true ? props.spot.image : defaultImage,
      //videoPreviewUrl: props.spot.videoEnabled === true ? props.spot.video : defaultImage,
      progress: 0,
      urlFireBase: '',
      //type: null
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSnapshot = this.handleSnapshot.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.getDownload = this.getDownload.bind(this);
  }
  async handleSnapshot(snapshot){
        var progressSnap = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
            progress: progressSnap
        });
        if (progressSnap === 100) {
          await sleep(5000);
          this.getDownload(snapshot);
          //this.getUrl(result);
        }
  }
  async getDownload(snapshot){
    try {
      var result = await snapshot.ref.getDownloadURL();
      this.getUrl(result);
    } catch (error) {
    }
  }
  getUrl(url) {
    this.setState({
        urlFireBaserl: url
    });
    if (this.props.campaignCreation.media.type === "image") this.props.fetchImageUrlSuccess(url);
    else if (this.props.campaignCreation.media.type === "video") this.props.fetchVideoUrlSuccess(url);

  }
  handleImageChange(e) {
    if (this.props.campaignCreation.enabledEdit) {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
          if (file.type === "image/png" || file.type === "image/jpeg") {
             this.props.fetchChangeMedia(
              {
                file: file,
                type: "image",
                imagePreviewUrl: reader.result,
                videoPreviewUrl: null
              });
          } else if (file.type === "video/mp4") {
              this.props.fetchChangeMedia(
              {
                  file: file,
                  type: "video",
                  imagePreviewUrl: null,
                  videoPreviewUrl: reader.result
              });
          }
      };

      let dateTime = moment(Date.now());

      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "video/mp4"){
          let folder = null;
          if (file.type === "image/png" || file.type === "image/jpeg"){
              folder = "/spots/images/";
          } else if (file.type === "video/mp4") {
              folder = "/spots/video/";
          }
          this.props.deleteSpot();

          const storageRef = firebase.storage().ref(`${folder}${dateTime}_${file.name}`);

          var uploadTask = storageRef.put(file);

          uploadTask.on('state_changed', this.handleSnapshot, function(error) {
                }, function(){})
      }

      reader.readAsDataURL(file);
    }
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  handleClick() {
    if (this.props.campaignCreation.enabledEdit) {
      this.refs.fileInput.click();
    }
  }
  handleRemove() {
    if (this.props.campaignCreation.enabledEdit) {
      this.props.fetchChangeMedia(
        {
            file: null,
            type: null,
            imagePreviewUrl: null,
            videoPreviewUrl: null
        });

      this.refs.fileInput.value = null;

      this.props.deleteSpot();
    }
  }

  render() {
    var {
    classes,
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      spot
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        {
            this.props.campaignCreation.media.file !== null ? 
              <div className={`thumbnail ${classes.width70percentage}`}>
                  {
                      this.state.progress !== 100 ?
                      <LinearProgress variant="determinate" value={this.state.progress} />
                      :
                      <LinearProgress className={classes.backgroundColor} variant="determinate" value={0} />
                  }
                  {
                      this.props.campaignCreation.media.type === "image" ? 
                      <img src={this.props.campaignCreation.media.imagePreviewUrl} alt="..." />
                      :
                      this.props.campaignCreation.media.type === "video" ? 
                      <video src={this.props.campaignCreation.media.videoPreviewUrl } className={classes.video} controls></video>
                      :
                      <img src={defaultImage} alt="..." />
                  }   
              </div>
              :
              <div className={`thumbnail ${classes.width70percentage}`}>
                  {
                    this.props.spot.imageEnabled === true ? 
                    <img src={this.props.spot.image} alt="..." />
                    :
                      this.props.spot.videoEnabled === true ? 
                      <video src={this.props.spot.video } className={classes.video} controls></video>
                      :
                      <img src={defaultImage} alt="..." />
                  }
              </div>
        }
        <div>
          {this.props.campaignCreation.media.file === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar ? "Add Photo" : "Select image or Video"}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                Change
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

imageVideoUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  spot: PropTypes.object
};

function mapStateToProps(state){
    return {
      spot: state.campaignCreation.resources.spot,
      campaignCreation: state.campaignCreation
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
        fetchImageUrlSuccess: bindActionCreators(fetchImageUrlSuccess, dispatch),
        fetchVideoUrlSuccess: bindActionCreators(fetchVideoUrlSuccess, dispatch),
        deleteSpot: bindActionCreators(deleteSpot, dispatch),
        fetchChangeMedia: bindActionCreators(fetchChangeMedia, dispatch)
    }
  }


export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(imageVideoUpload)));
