import React from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Timeline from "@material-ui/icons/Timeline";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";

function EngagmentDemographic({ ...props }) {
    const {
        classes,
        data,
        type
    } = props;

    var engagementTotal = 0;
    var values = {
        male: 0,
        female: 0
    }
    var percentajes = {
        male: 0,
        female: 0
    }

    switch (type) {
        case "Influencer":
            engagementTotal = data.engagementMaleInfluencer + data.engagementFemaleInfluencer;
            values = {
                male: data.engagementMaleInfluencer,
                female: data.engagementFemaleInfluencer
            }
            percentajes = {
                    male: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementMaleInfluencer,
                    female: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementFemaleInfluencer
            }
            break;
        case "Influenced":
            engagementTotal = data.engagementMaleInfluenced + data.engagementFemaleInfluenced + data.engagementAnonymousInfluenced;

            values = {
                male: data.engagementMaleInfluenced,
                female: data.engagementFemaleInfluenced,
                anonymous: data.engagementAnonymousInfluenced
            }

            percentajes = {
                male: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementMaleInfluenced,
                female: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementFemaleInfluenced,
                anonymous: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementAnonymousInfluenced
            }
            break;
    
        default:
            break;
    }
    return (
        <GridItem xs={12} sm={10} md={3} className={classes.center}>
            <Card>
                <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                    <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                    {type === "Influencer" ? props.tags.influencer : props.tags.influencedValue }
                    : {engagementTotal}
                    </h4>
                </CardHeader>
                <CardBody minWidth230={true}>
                    {
                        type === "Influenced" ? 
                        <div>
                            <h5 className={classes.cardTitle}>
                            {`(${values.anonymous} ${props.tags.unknown} `}
                            {percentajes.anonymous.toFixed(0)} <small>%</small>
                            {` )`}
                            </h5>
                        </div>
                        : null
                    }
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="info">
                            <i className="fas fa-female"></i>
                            </CardIcon>
                            <p className={classes.cardCategory}></p>
                            <h3 className={classes.cardTitle}>
                            {percentajes.female.toFixed(0)} <small>%</small>
                            </h3>
                            <h3 className={classes.cardTitle}>
                            {`${values.female} ${props.tags.womens}`} 
                            </h3>
                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="info">
                            <i className="fas fa-male"></i>
                            </CardIcon>
                            <p className={classes.cardCategory}></p>
                            <h3 className={classes.cardTitle}>
                            {percentajes.male.toFixed(0)} <small>%</small>
                            </h3>
                            <h3 className={classes.cardTitle}>
                            {`${values.male} ${props.tags.mens}`}
                            </h3>
                        </CardHeader>
                    </Card>
                    
                    
                </CardBody>
            </Card>
        </GridItem>
    )
}

export default EngagmentDemographic;