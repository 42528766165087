import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchSponsorshipeffectivenessesDataSuccess,
    fetchSponsorshipeffectivenessesDataFail
 } from "../actions/sponsorshipeffectivenesses";
import {
    FETCH_SPONSORSHIPEFFECTIVENESSES_DATA
} from '../actionTypes';
import api from '../api';

function* fetchSponsorshipeffectivenessesData(action) {
    try {
          let data = yield api.sponsorshipeffectivenesses.getSponsorshipeffectivenessesData(action.payload);
          yield put(fetchSponsorshipeffectivenessesDataSuccess({data:data.sponsorshipEffectiveness}));
    } catch (e) {
          yield put(fetchSponsorshipeffectivenessesDataFail());
    }
}

export default function* sponsorshipeffectivenessesSaga() {
    yield takeLatest(FETCH_SPONSORSHIPEFFECTIVENESSES_DATA, fetchSponsorshipeffectivenessesData);
}