import { put, takeLatest } from "redux-saga/effects";
import { fetchCompaniesDataSuccess, 
         fetchCompaniesDataFail,
         fetchCompaniesDataAddSuccess, 
         fetchCompaniesDataAddFail,
         fetchCompaniesNameSuccess,
         fetchCompaniesNameFail
        } from "../actions/companies";

import { FETCH_COMPANIES_DATA, 
         FETCH_COMPANIES_DATA_ADD, 
         FETCH_COMPANIES_NAME  } from '../actionTypes';
import api from '../api';

function* fetchCompaniesData(action) {
    try {
      let data = yield api.companies.getCompaniesData(action.payload);
      let hasMore = true;
      if (action.payload.limit > data.companies.length) hasMore = false;
      yield put(fetchCompaniesDataSuccess({data:data.companies, hasMore}));
    } catch (e) {
      yield put(fetchCompaniesDataFail());
    }
}

function* fetchCompaniesDataAdd(action) {
  try {
    const data = yield api.companies.getCompaniesData(action.payload);
    let hasMore = true;
    if (action.payload.limit > data.companies.length) hasMore = false;
    yield put(fetchCompaniesDataAddSuccess({data:data.companies, hasMore}));
  } catch (e) {
    yield put(fetchCompaniesDataAddFail());
  }
}

function* fetchCompaniesName(action) {
  try {
    const data = yield api.companies.getCompaniesName(action.payload);
    yield put(fetchCompaniesNameSuccess(data.companies));
  } catch (e) {
    yield put(fetchCompaniesNameFail());
  }
}

export default function* companiesSaga() {
    yield takeLatest(FETCH_COMPANIES_DATA, fetchCompaniesData);
    yield takeLatest(FETCH_COMPANIES_DATA_ADD, fetchCompaniesDataAdd);
    yield takeLatest(FETCH_COMPANIES_NAME, fetchCompaniesName);
    
}