import { FETCH_CATEGORIES_DATA,
    FETCH_CATEGORIES_DATA_SUCCESS,
    FETCH_CATEGORIES_DATA_FAIL
} from '../actionTypes';

export const fetchCategoriesData = (data) => ({
    type: FETCH_CATEGORIES_DATA,
    payload: data
})

export const fetchCategoriesDataSuccess = (data) => ({
    type: FETCH_CATEGORIES_DATA_SUCCESS,
    payload: data
})

export const fetchCategoriesDataFail = () => ({
    type: FETCH_CATEGORIES_DATA_FAIL,
    success: false,
})