const createPerson = `
mutation createPerson(
    $firstName: String!
    $lastName: String!
    $companyName: String
    $email: String!
    $password: String!
){
    createPerson(
        app: Web
        firstName: $firstName
        lastName: $lastName
        companyName: $companyName
        email: $email
        password: $password
        
  ) {
    data,
    success,
    error {
        path
        message
      }
  }
}
`;

export const Person = {
    createPerson,
}