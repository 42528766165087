import { FETCH_COUNTRIES_DATA_SUCCESS,
         FETCH_STATES_DATA_SUCCESS,
         FETCH_CITIES_DATA_SUCCESS,
         FETCH_REGION_SELECTION,
         FETCH_REGION_SELECTION_FOR_DB
    } from "../actionTypes";

const initialState = {
    countries:[],
    countriesRead:[],
    country: null,
    states: [],
    statesRead: [],
    state: null,
    cities: [],
    citiesRead: [],
    city: null,
    regionSelection: null,
    regionSelectionForDB: null

}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGION_SELECTION_FOR_DB:
            return{
            ...state,
            regionSelectionForDB: action.payload[0],
        }
        case FETCH_REGION_SELECTION:
            return{
                ...state,
                [action.payload.name]: action.payload.delete === true ? null : action.payload.item,
                regionSelection: action.payload.item,
            }
        case FETCH_COUNTRIES_DATA_SUCCESS:
            return {
            ...state,
            countries: action.payload.data,
            countriesRead: action.payload.data.reduce((collection, item) => {
                var newItem = {value: item._id, label: item.country};
                collection.push(newItem);
                return collection;
                },[]),
            states: [],
            statesRead: [],
            state: null,
            cities: [],
            citiesRead: [],
            city: null,
        }
        case FETCH_STATES_DATA_SUCCESS:
            return {
            ...state,
            states: action.payload.data,
            statesRead: action.payload.data.reduce((collection, item) => {
                if(item.state != null){
                    var newItem = {value: item._id, label: item.state};
                    collection.push(newItem);
                }
                return collection;
                }, []),
            state: null,
            cities: [],
            citiesRead: [],
            city: null
        }
        case FETCH_CITIES_DATA_SUCCESS:
            return {
            ...state,
            city: null,
            cities: action.payload.data,
            citiesRead: action.payload.data.reduce((collection, item) => {
                if(item.city != null){
                    var newItem = {value: item._id, label: item.city};
                    collection.push(newItem);
                }
                return collection;
            }, [])
        }
        default:
            return state;
    }
}