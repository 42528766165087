import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MyLoaderEngagementOfAdvertisement } from '../../loaders';

const registro = (classes, item, tags, widgets, checked, index, handleCategory, {...props}) => {
  return (
    <div key={item._id} className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
      <FormControlLabel
        disabled={
          props.campaignCreation.enabledEdit ? false : true
        }
        key={item._id}
        control={
          <Checkbox
            key={item._id + 1}
            tabIndex={index}
            onClick={widgets === true ? () => handleCategory(item) : null}
            checked={checked.find(i => i._id === item._id) !== undefined ? true:false}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{checked: classes.checked}}
          />
        }
        classes={{label: classes.label}}
        label={item.name}
      />
    </div>
  )
}
function categoryOfInfluencers({...props}) {
    const {
        classes,
        widgets,
        checked,
        handleWidgets,
        handleCategory,
        //categories,
  } = props;
    return (
        props.categories.categories.length === 0 ?
        <Card className={widgets === true ? null: classes.disabled2}>
          <CardHeader color="rose" icon>
            <GridContainer justify="space-between">
                <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={10} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                              {props.tags.categoryOfInfluencers}
                              </h4>
                            </GridItem>
                          </GridContainer>
                </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody height180={true}>
            {MyLoaderEngagementOfAdvertisement()}
          </CardBody>
        </Card>
        :
        <Card className={widgets === true ? null: classes.disabled2}>
                  <CardHeader color="rose" icon>
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={12}>
                      <GridContainer justify="space-between">
                        <GridItem xs={12} sm={1}>
                          <CardIcon color="info">
                            <Assignment />
                          </CardIcon>
                        </GridItem>
                        <GridItem xs={12} sm={10} className={classes.alignCenter}>
                          <h4 className={classes.cardIconTitle}>
                            {props.tags.categoryOfInfluencers}
                          </h4>
                        </GridItem>
                        <GridItem xs={12} sm={1} className={classes.align}>
                          <Checkbox
                            disabled={
                              props.campaignCreation.enabledEdit ? false : true
                            }
                            tabIndex={8}
                            checked={widgets}
                            onClick={() => handleWidgets('categoryOfInfluencers')}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody height180={true}>
                  {widgets === true ? 
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={4}>
                        <div>
                          {
                            props.categories.categories.map((item, index) => (
                                index <= 3 ? registro(classes, item, null, widgets, checked, index, handleCategory, {...props}) : null
                              ))
                          }
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <div>
                          {
                            props.categories.categories.map((item, index) => (
                                index <= 7 && index >= 4 ? registro(classes, item, null, widgets, checked, index + 4, handleCategory, {...props}) : null
                              ))
                          }
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <div>
                          {
                            props.categories.categories.map((item, index) => (
                                index <= 11 && index >= 8 ? registro(classes, item, null, widgets, checked, index + 8, handleCategory, {...props}) : null
                              ))
                          }
                        </div>
                      </GridItem>
                    </GridContainer>
                   : null
                  }
                  </CardBody>
        </Card>
    )
}

export default categoryOfInfluencers;