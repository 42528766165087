var query = {
    regions: `
    query regions($limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $country: String
        $state: String
        $city: String
    )
    {
        regions(limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            country: $country
            state: $state
            city: $city
        ){
            _id,
            country,
            state,
            city,
            iso2,
            iso3,
            thumbnail,
        }
    }
    `
}
export default {
        query
};