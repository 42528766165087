import fetchLib from "graphql-fetch";
// import variables from '../../env';
// const api_host = state => state.basics.api.host;
// const api_port = state => state.basics.api.port;
const api_domain = state => state.basics.api.api_domain;

const url = `http://${api_domain}/graphql`;
let fetch = fetchLib(url);

let head = new Headers();
if (localStorage.getItem("@token") !== null)
  head.append("x-token", localStorage.getItem("@token"));
//head.append("x-token", "El_Sr.Luis_en_tanga");
head.append("mode", "no-cors");

export default {
  fetch,
  head
};
