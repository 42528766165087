import * as querys from "../querys";
import parameters from "./parameters";
import { GrahpQL } from "../mutations/parametersMutations";

var opts = {
  headers: parameters.head
};

export default {
  getAllCollectionsToCampaignData: function* getAllCollectionsToCampaignData(
    payload
  ) {
    var queryVars = {};
    if (payload != null) queryVars = payload;
    const response = yield GrahpQL.query(
      querys.default.allCollectionsToCampaign.query.allCollections,
      queryVars,
      opts
    );
    return response.data;
  }
};
