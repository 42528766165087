import {put, takeLatest } from "redux-saga/effects";
import { fetchAgesDataSuccess } from "../actions/ages";
import { fetchAdvertisingEffectivenessesDataSuccess } from "../actions/advertisingEffectivenesses";
import { fetchCategoriesDataSuccess } from "../actions/categories";
import { fetchSexesDataSuccess } from "../actions/sexes";
import { fetchSponsorshipeffectivenessesDataSuccess } from "../actions/sponsorshipeffectivenesses";
import { fetchSpoteffectivenessesDataSuccess } from "../actions/spoteffectivenesses";
import { fetchStarsDataSuccess } from "../actions/stars";
import { fetchCountriesDataSuccess } from "../actions/regions";
import { FETCH_LOAD_ALL_REFERENCES_DATA } from '../actionTypes';
import api from '../api';

function* fetchLoadAllReferencesData(action) {
    try {
          let data = yield api.allCollectionsToCampaign.getAllCollectionsToCampaignData(action.payload);
          yield put(fetchAdvertisingEffectivenessesDataSuccess({data:data.advertisingEffectiveness}));
          yield put(fetchAgesDataSuccess({data:data.ages}));
          yield put(fetchCategoriesDataSuccess({data:data.categories}));
          //yield put(fetchCountriesDataSuccess({data:data.regions}));
          yield put(fetchSexesDataSuccess({data:data.sex}));
          yield put(fetchSponsorshipeffectivenessesDataSuccess({data:data.sponsorshipEffectiveness}));
          yield put(fetchSpoteffectivenessesDataSuccess({data:data.spotEffectiveness}));
          yield put(fetchStarsDataSuccess({data:data.stars}));
    } catch (e) {
          
    }
}

export default function* allCollectionsToCampaignSaga() {
    yield takeLatest(FETCH_LOAD_ALL_REFERENCES_DATA, fetchLoadAllReferencesData);
}