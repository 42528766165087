import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "./tags";
import { fetchCreatePersonData } from "../../state/actions/person";
import { fetchApiReferences } from "../../state/actions/login";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import ReactLoading from "react-loading";
import styled from "tachyons-components";

const Section = styled('div')`
flex flex-wrap content-center justify-center w-100 h-100`;

const Article = styled('div')`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;


class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      cardAnimaton: "cardHidden",
      firstName: "",
      firstNameState: "",
      lastName: "",
      lastNameState: "",
      companyName: "",
      companyNameState: "",
      email: "",
      emailState: "",
      password: "",
      passwordState: "",
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.createPersonClick = this.createPersonClick.bind(this);
  }
  componentDidMount() {
    // this.props.fetchApiReferences(null);
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      50
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(!this.props.person.loadingCreatePerson && this.props.login.user !== null)
    {
      const { history } = this.props;
      history.push({pathname: `AdMe`});
    }

    return true;
  }

    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      return false;
    }
  
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
      if (value.length >= length) {
        return true;
      }
      return false;
    }
  
    change(event, stateName) {
      switch (stateName) {
        case "email":
          if (this.verifyEmail(event.target.value)) {
            this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value});
          } else {
            this.setState({ [stateName + "State"]: "error" , [stateName]: event.target.value });
          }
          break;
        case "password":
          if (this.verifyLength(event.target.value, 8)) {
            this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
          } else {
            this.setState({ [stateName + "State"]: "error", [stateName]: event.target.value });
          }
          break;
        case "firstName":
          if (this.verifyLength(event.target.value, 1)) {
            this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
          } else {
            this.setState({ [stateName + "State"]: "error", [stateName]: event.target.value });
          }
          break;
        case "lastName":
          if (this.verifyLength(event.target.value, 1)) {
            this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
          } else {
            this.setState({ [stateName + "State"]: "error", [stateName]: event.target.value });
          }
          break;
        case "companyName":
          if (this.verifyLength(event.target.value, 1)) {
            this.setState({ [stateName + "State"]: "success", [stateName]: event.target.value });
          } else {
            this.setState({ [stateName + "State"]: "error", [stateName]: event.target.value });
          }
          break;
        default:
          break;
      }
    }

    createPersonClick() {
      if (this.state.firstNameState === "") {
        this.setState({ firstNameState: "error" });
      }
      if (this.state.lastNameState === "") {
        this.setState({ lastNameState: "error" });
      }
      if (this.state.companyNameState === "") {
        this.setState({ companyNameState: "error" });
      }
      if (this.state.emailState === "") {
        this.setState({ emailState: "error" });
      }
      if (this.state.passwordState === "") {
        this.setState({ passwordState: "error" });
      }
      if (this.state.firstNameState === "success" && 
          this.state.lastNameState === "success" &&
          this.state.companyNameState === "success" &&
          this.state.emailState === "success" &&
          this.state.passwordState === "success"
        )
      {
        this.props.fetchCreatePersonData({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          companyName: this.state.companyName,
          email: this.state.email, 
          password: this.state.password});
      }
    }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{this.props.tags.register}</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title="Marketing"
                      description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                      icon={Timeline}
                      iconColor="rose"
                    />
                    <InfoArea
                      title="Built Audience"
                      description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  {
                    this.props.person.loadingCreatePerson ?
                    <GridItem xs={12} sm={8} md={5}>
                    <Section>
                      <Article key={"spinningBubbles"}>
                        <ReactLoading type={"spinningBubbles"} color="#00acc1" />
                      </Article>
                    </Section>
                    </GridItem>
                    :
                    <GridItem xs={12} sm={8} md={5}>
                      <form className={classes.form}>
                      <CustomInput
                          success={this.state.firstNameState === "success"}
                          error={this.state.firstNameState === "error"}
                          defaultValue={this.state.firstName}
                          labelText={this.props.tags.firstName}
                          id="firstName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            onChange: event =>
                              this.change(event, "firstName"),
                            type: "text"
                          }}
                        />
                        <CustomInput
                          success={this.state.lastNameState === "success"}
                          error={this.state.lastNameState === "error"}
                          defaultValue={this.state.lastName}
                          labelText={this.props.tags.lastName}
                          id="lastName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            onChange: event =>
                              this.change(event, "lastName"),
                            type: "text"
                          }}
                        />
                        <CustomInput
                          success={this.state.companyNameState === "success"}
                          error={this.state.companyNameState === "error"}
                          defaultValue={this.state.companyName}
                          labelText={this.props.tags.companyName}
                          id="companyName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "companyName"),
                            type: "text",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <i className={ `fas fa-building + ${classes.inputAdornmentIcon}`}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          success={this.state.emailState === "success"}
                          error={this.state.emailState === "error"}
                          defaultValue={this.state.email}
                          labelText={this.props.tags.email}
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "email"),
                            type: "email",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <form onSubmit={this.createPersonClick}>
                        <CustomInput
                          success={this.state.passwordState === "success"}
                          error={this.state.passwordState === "error"}
                          defaultValue={this.state.password}
                          labelText={this.props.tags.password}
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "password"),
                            type: "password",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        </form>
                        {
                          this.props.person.loadingCreatePerson != null ?
                          <div className={classes.formCategory}>
                            <small>*</small> {this.props.person.createPersonStatus}
                          </div>
                          :
                          null
                        }
                        
                        <br/>
                        
                        <div className={classes.center}>
                          <Button round color="primary" onClick={this.createPersonClick}>
                            {this.props.tags.getStarted}
                          </Button>
                        </div>
                      </form>
                    </GridItem>
                  }
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    login: state.login,
    person: state.person,
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchCreatePersonData: bindActionCreators(fetchCreatePersonData, dispatch),
    fetchApiReferences: bindActionCreators(fetchApiReferences, dispatch)
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(registerPageStyle)(RegisterPage)));
