const createCampaign = (companyId, name, type, status, investment, stock, campaignPaymentType) => `
mutation createCampaign{
    createCampaign(
        companyId: "${companyId}",
        name: "${name}",
        type: ${type},
        status: ${status},
        investment: ${investment},
        stock: ${stock}
        paymentType: ${campaignPaymentType}
  ) {
    success,
    data,
    error {
        path
        message
      }
  }
}
`;

const updateCampaign = () => `
mutation updateCampaign(
    $_id: ID
    $active: Boolean
    $name: String
    $startDt: DateTime
    $endDt: DateTime
    $status: CampaignStatus
    $followers: Boolean
    $type: CampaignType
    $influencePeopleAs: CampaignInfluencePeopleAs
    $adsPerIR: Int
    $brief: String
    $paymentType: campaignPaymentType
    $productPaymentDescription: String
    $paymentPerEach: Int
    $investment: Float,
    $stock: Int
    $customPricesEnabled: Boolean
    $customAdPrice: Int
    $customInsightMultiplier: Float
    $specificTarget: Boolean
    $virtual: Boolean
    $whatsappNumber: String
    $regionCriterias: [RegionParameter]
    $sexCriterias: [SexParameter]
    $platformStarCriterias: [StarParameter]
    $customerStarCriterias: [StarParameter]
    $sponsorshipEffectivenessCriterias: [SponsorshipEffectivenessParameter]
    $advertisingEffectivenessCriterias: [AdvertisingEffectivenessParameter]
    $spotEffectivenessCriterias: [SpotEffectivenessParameter]
    $categoryCriterias: [CategoryParameter]
    $ageCriterias: [AgeParameter]
    $specificTargetScope: [SpecificTargetParameter]
    $resources: inputResources

){
    updateCampaign(
        _id: $_id
        active: $active
        name: $name
        startDt: $startDt
        endDt: $endDt
        status: $status
        followers: $followers
        type: $type
        influencePeopleAs: $influencePeopleAs
        adsPerIR: $adsPerIR
        brief: $brief
        paymentType: $paymentType
        productPaymentDescription: $productPaymentDescription
        paymentPerEach: $paymentPerEach
        investment: $investment
        stock: $stock
        customPricesEnabled: $customPricesEnabled
        customAdPrice: $customAdPrice
        customInsightMultiplier: $customInsightMultiplier
        resources: $resources
        specificTarget: $specificTarget
        virtual: $virtual
        whatsappNumber: $whatsappNumber
        regionCriterias: $regionCriterias
        sexCriterias: $sexCriterias
        platformStarCriterias: $platformStarCriterias
        customerStarCriterias: $customerStarCriterias
        sponsorshipEffectivenessCriterias: $sponsorshipEffectivenessCriterias
        advertisingEffectivenessCriterias: $advertisingEffectivenessCriterias
        spotEffectivenessCriterias: $spotEffectivenessCriterias
        categoryCriterias: $categoryCriterias
        ageCriterias: $ageCriterias
        specificTargetScope: $specificTargetScope
        
  ) {
    success,
    error {
        path
        message
      }
  }
}
`;

export const Mutations = {
    createCampaign,
    updateCampaign
}