import { FETCH_AGES_DATA,
    FETCH_AGES_DATA_SUCCESS,
    FETCH_AGES_DATA_FAIL
} from '../actionTypes';

export const fetchAgesData = (data) => ({
    type: FETCH_AGES_DATA,
    payload: data
})

export const fetchAgesDataSuccess = (data) => ({
    type: FETCH_AGES_DATA_SUCCESS,
    payload: data
})

export const fetchAgesDataFail = () => ({
    type: FETCH_AGES_DATA_FAIL,
    success: false,
})