import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import MuiVirtualizedTable from "./tables/tableOfAdvertisementSpot";
import EngagementOfAdvertisementSpot from "./engagementOfAdvertisementSpot";
import {  MyLoaderEngagementOfAdvertisement } from '../../loaders';

function resourcesOfAdvertisementSpot({...props}) {
    const {
        specificTarget,
        followers,
        classes,
        widgets,
        items,
        handlerRemovePerson,
      } = props;
    return (
        <Card className={widgets === true ? null: classes.disabled2}>
                <CardHeader color="rose" icon>
                  <GridContainer justify="space-between">
                    <GridItem xs={12} sm={12}>
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={2}>
                        <CardIcon color="info">
                          <Assignment />
                        </CardIcon>
                      </GridItem>
                      <GridItem xs={12} sm={10} className={classes.alignCenter}>
                        <h4 className={classes.cardIconTitle}>
                          {props.tags.resourcesOfAdvertisement}
                        </h4>
                      </GridItem>
                    </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody height600={true}>
                  {
                    widgets === true ?
                          specificTarget && !followers ?
                          null
                          :
                            props.campaignCreation.type === "Spot" ?
                              props.spoteffectivenesses.spotEffectiveness.length === 0 ?
                                MyLoaderEngagementOfAdvertisement()
                                  :
                                    props.campaignCreation.spotEffectivenessCriterias.length !== 0 ?
                                    <EngagementOfAdvertisementSpot
                                      type="Spot"
                                      classes={classes}
                                      widgets={props.campaignCreation.spotEffectivenessCriteriasChecked}
                                      handleEffectivenesses={props.handleSpoteffectivenesses}
                                      objectFrom={
                                        props.campaignCreation.spotEffectivenessCriteriasObjectFrom === null &&
                                        props.campaignCreation.spotEffectivenessCriterias.length !== 0 ?
                                        props.spoteffectivenesses.spotEffectiveness.find(o => o.from === props.campaignCreation.spotEffectivenessCriterias[props.campaignCreation.spotEffectivenessCriterias.length - 1].from) 
                                        : 
                                        props.campaignCreation.spotEffectivenessCriteriasObjectFrom
                                      }
                                      objectTo={
                                        props.campaignCreation.spotEffectivenessCriteriasObjectTo === null &&
                                        props.campaignCreation.spotEffectivenessCriterias.length !== 0 ?
                                        props.spoteffectivenesses.spotEffectiveness.find(o => o.from === props.campaignCreation.spotEffectivenessCriterias[props.campaignCreation.spotEffectivenessCriterias.length - 1].from)
                                        :
                                        props.campaignCreation.spotEffectivenessCriteriasObjectTo
                                      }
                                      starIdSlide={
                                        props.campaignCreation.spotEffectivenessCriterias.length !== 0 && 
                                        props.spoteffectivenesses.spotEffectivenessRead.length !== 0 ?
                                        props.spoteffectivenesses.spotEffectivenessRead.find(o => o.from === props.campaignCreation.spotEffectivenessCriterias[0].from).idSlide
                                        : 0}
                                      endIdSlide={
                                        props.campaignCreation.spotEffectivenessCriterias.length !== 0 && 
                                        props.spoteffectivenesses.spotEffectivenessRead.length !== 0 ?
                                        props.spoteffectivenesses.spotEffectivenessRead.find(o => o.from === props.campaignCreation.spotEffectivenessCriterias[props.campaignCreation.spotEffectivenessCriterias.length - 1].from).idSlide
                                        : 0}
                                      {...props}
                                    />
                                    :
                                    null
                              : 
                              null
                            :
                          null
                  }
                  {
                    <MuiVirtualizedTable 
                        items={items} 
                        specificTarget={specificTarget}
                        followers={followers}
                        handlerRemovePerson={handlerRemovePerson}
                        classes={classes}
                        tags={props.tags}/>
                  }
                </CardBody>
        </Card>
    )
}
        
export default resourcesOfAdvertisementSpot;