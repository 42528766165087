import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./styleIndex";
import Row from "./row";
import Searcher from "./searcher";
import tags from "./tags";
import CampaignsStatus from '../graphics/campaignsStatus';
//import EngagmentStatusMoney from '../graphics/engagmentStatusMoney';
import EngagmentStatus from '../graphics/engagmentStatus';
import InvestmentStatus from '../graphics/investmentStatus';
import EngagmentVelocityStatus from '../graphics/EngagementVelocityStatus';
import { fetchCampaignsName, 
  fetchCampaignsData, 
  fetchCampaignsDataAdd, 
  fetchCompanyAndCampaignsData,
  fetchCompanyAndCampaignsDataSuccess } from "../../../state/actions/campaigns";

import { Facebook } from 'react-content-loader';
import InfiniteScroll from "react-infinite-scroll-component";
// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { NavLink } from "react-router-dom";

const MyFacebookLoader = () => <Facebook />

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.handlerName = this.handlerName.bind(this);
    this.handlerRefresh = this.handlerRefresh.bind(this);
}

handlerRefresh(){
  if (this.props.login.user !== null) {
    if (this.props.history.location.state !== undefined) {
      this.props.fetchCompanyAndCampaignsDataSuccess({data: null});
      this.props.fetchCompanyAndCampaignsData({_id: this.props.history.location.state.companyId});
      this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.history.location.state.companyId, active: true});
    } 
    else {
      this.props.fetchCompanyAndCampaignsDataSuccess({data: null});
      this.props.fetchCompanyAndCampaignsData({_id: this.props.login.user.company.id});
      this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.login.user.company.id, active: true});
    }
   } else {
    const { history } = this.props;
    history.push({pathname: `/`});
  }
}

  componentWillMount() {
    if (this.props.login.user !== null) {
      
      if (this.props.history.location.state !== undefined) {
        this.props.fetchCompanyAndCampaignsDataSuccess({data: null});
        this.props.fetchCompanyAndCampaignsData({_id: this.props.history.location.state.companyId});
        this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.history.location.state.companyId, active: true});
      } 
      else {
        
        this.props.fetchCompanyAndCampaignsDataSuccess({data: null});
        this.props.fetchCompanyAndCampaignsData({_id: this.props.login.user.company.id});
        this.props.fetchCampaignsData({page:0, limit:8, companyId: this.props.login.user.company.id, active: true});
      }
     } else {
      
      const { history } = this.props;
      history.push({pathname: `/`});
    }
  }

  handlerName = (data)  => {
    if (data.name.length >= 60) {
      return `${this.props.tags.type}: ${data.type} -
      ${this.props.tags.status}: ${data.status} - ${this.props.tags.name}: ${data.name.substring(0,55)} ...`;
    } else 
      return `${this.props.tags.type}: ${data.type} -
      ${this.props.tags.status}: ${data.status} - ${this.props.tags.name}: ${data.name}`;
  }

  fetchMoreData = () => {
    if (!this.props.hasMore) {
        return;
    }
    if(this.props.active) this.props.fetchCampaignsDataAdd({page: this.props.page, limit:2, companyId: this.props.company._id, active: true});
    else this.props.fetchCampaignsDataAdd({page: this.props.page, limit:2, companyId: this.props.company._id});
  };

  render() {
    const { classes } = this.props;
    return (
      this.props.company === null ? MyFacebookLoader() : 
      <div>
        <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <GridContainer justify="space-between">
            <GridItem xs={12} sm={12}>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={1}>
                  <CardIcon color="info">
                    <i className="fas fa-share-square"></i>
                  </CardIcon>
                </GridItem>
                <GridItem xs={12} sm={10} className={classes.alignCenter}>
                  <Typography className={classes.secondaryHeading}>{this.props.tags.summaryOfYourCampaigns}</Typography>
                </GridItem>
                <GridItem xs={12} sm={1} className={classes.alignCenter}>
                  <NavLink to={{pathname:"/panel/Wizard",state:{companyId: this.props.company._id, companyName: this.props.company.name}}}>
                    <Button color="info" className={classes.alignCenter}>
                    <i className="fas fa-plus"></i> {this.props.tags.newButton}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
        {
          this.props.company.campaignsOnGoingCount +
          this.props.company.campaignsStoppedCount +
          this.props.company.campaignsFinishedCount +
          this.props.company.campaignsWaitingForPaymentCount +
          this.props.company.campaignsWaitingForApprovalCount +
          this.props.company.campaignsDraftCount === 0 ?
            <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                    <Card product className={classes.cardHover}>
                      <CardHeader image className={classes.cardHeaderHover}>
                        {
                          this.props.company.logo.length !== 0 ?
                              <img  src={this.props.company.logo} alt="..." className={classes.img} />
                            :
                              `${this.props.company.name} ${this.props.tags.withoutLogo}` 
                        }
                      </CardHeader>
                      <CardBody>
                        <div className={classes.cardHoverUnder}>
                          <Tooltip
                            id="tooltip-top"
                            title={this.props.tags.editMyCompany}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="danger" simple justIcon>
                              <Edit className={classes.underChartIcons} />
                            </Button>
                          </Tooltip>
                        </div>
                        <h3 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            {this.props.company.name}
                          </a>
                        </h3>
                      </CardBody>
                      <CardFooter product>
                        <div className={classes.price}>
                          <h4>{this.props.tags.address} </h4>
                        </div>
                        <div className={`${classes.stats} ${classes.productStats}`}>
                          <Place /> {this.props.tags.EvenWithoutLoading}
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  {this.props.tags.withoutActiveCampaigns}
            </GridContainer>
            :
            <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                    <Card product className={classes.cardHover}>
                      <CardHeader image className={classes.cardHeaderHover}>
                        {
                          this.props.company.logo.length !== 0 ?
                              <img  src={this.props.company.logo} alt="..." className={classes.img} />
                            :
                              `${this.props.company.name} sin logo` 
                        }
                      </CardHeader>
                      <CardBody>
                        <div className={classes.cardHoverUnder}>
                          <Tooltip
                            id="tooltip-top"
                            title="Edit My Company"
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="danger" simple justIcon>
                              <Edit className={classes.underChartIcons} />
                            </Button>
                          </Tooltip>
                        </div>
                        <h3 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            {this.props.company.name}
                          </a>
                        </h3>
                      </CardBody>
                      <CardFooter product>
                        <div className={classes.price}>
                          <h4>{this.props.tags.address}</h4>
                        </div>
                        <div className={`${classes.stats} ${classes.productStats}`}>
                          <Place /> {this.props.tags.EvenWithoutLoading}
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                       <GridItem xs={12} sm={12} md={2} className={classes.center}>
                        <CampaignsStatus classes={classes} data={this.props.company} tags={this.props.tags}/>
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={2} className={classes.center}>
                          <EngagmentStatusMoney classes={classes} data={this.props.company} tags={this.props.tags}/>
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={3} className={classes.center}>
                          <EngagmentStatus classes={classes} data={this.props.company} tags={this.props.tags}/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className={classes.center}>
                          <InvestmentStatus classes={classes} data={this.props.company} tags={this.props.tags}/>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} className={classes.center}>
                          <EngagmentVelocityStatus classes={classes} data={this.props.company} tags={this.props.tags}/>
                        </GridItem>
            </GridContainer>
        }  
        </ExpansionPanelDetails>
          
        </ExpansionPanel>
        <br/>
        { this.props.campaigns.length === 0 ? null : 
          <Searcher classes={classes} refresh={this.handlerRefresh} />
        }
        <br/>
        {this.props.campaigns.length === 0 ? null : 
        <InfiniteScroll
                    dataLength={this.props.campaigns.length}
                    next={this.fetchMoreData}
                    hasMore={this.props.hasMore}
                    loader={<h4>{this.props.tags.loading}</h4>}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>{this.props.tags.youHaveSeenItAll}</b>
                    </p>
                    }
        >
          {this.props.campaigns.map((item, index) => (
              <Row classes={classes}
              key={index} 
              data={item} 
              tags={this.props.tags} 
              handlerName={this.handlerName} 
              {...this.props}/>
            ))
          }
        </InfiniteScroll>}
      </div>
    );
  }
}

Campaigns.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    login: state.login,
    company: state.campaigns.company,
    campaigns: state.campaigns.campaigns,
    active: state.campaigns.active,
    hasMore: state.campaigns.hasMore,
    page: state.campaigns.page
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchCampaignsName: bindActionCreators(fetchCampaignsName, dispatch),
    fetchCampaignsData: bindActionCreators(fetchCampaignsData, dispatch),
    fetchCampaignsDataAdd: bindActionCreators(fetchCampaignsDataAdd, dispatch),
    fetchCompanyAndCampaignsData: bindActionCreators(fetchCompanyAndCampaignsData, dispatch),
    fetchCompanyAndCampaignsDataSuccess : bindActionCreators(fetchCompanyAndCampaignsDataSuccess, dispatch),
  }
}
export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Campaigns)));

