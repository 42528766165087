var query = {
    persons: `
    query persons(
        $limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $key: String
        $socialMediaAccountsReadyToUse: Boolean
    )
    {
        persons(
            limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            key: $key
            socialMediaAccountsReadyToUse: $socialMediaAccountsReadyToUse
        ){
            _id
            firstName
            lastName
            key
            roleIds
            sponsorshipEffectivenessReference {
                _id
                framePrice
                hashtagPrice
                webSitePrice
                tagMentionMePrice
                sealPrice
                tagMentionPeoplePrice
                productUsagePrice
                insightAveragePrice
                freezedBudget
                insightPriceFactor
                totalPrice
                from
                to
                AdPrice
            }
            spotEffectivenessReference {
              _id
              freezedBudget
              insightPriceFactor
              totalPrice
              AdPrice
              from
              to
            }
            advertisingEffectivenessReference {
                _id
                from
                to
                AdPrice
                framePrice
                hashtagPrice
                webSitePrice
                tagMentionMePrice
                sealPrice
                tagMentionPeoplePrice
                productUsagePrice
                insightAveragePrice
                freezedBudget
                insightPriceFactor
                totalPrice
            }
        }
    }
    `
}
export default {
        query
};