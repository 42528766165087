import {
  FETCH_API_REFERENCES,
  FETCH_API_REFERENCES_SUCCESS,
  FETCH_API_REFERENCES_UNSUCCESS,
  FETCH_API_REFERENCES_FAIL
} from "../actionTypes";

const initialState = {
  api: {
    api_domain: null,
    fetchingStatus: "none"
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_API_REFERENCES: {
      return {
        ...state,
        api: {
          ...state.api,
          fetchingStatus: "fetching"
        }
      };
    }
    case FETCH_API_REFERENCES_SUCCESS: {
      return {
        ...state,
        api: {
          api_domain: action.payload.data.api_domain,
          fetchingStatus: "fetched"
        }
      };
    }
    case FETCH_API_REFERENCES_UNSUCCESS:
    case FETCH_API_REFERENCES_FAIL: {
      return {
        ...state,
        api: {
          ...state.api,
          fetchingStatus: "failed"
        }
      };
    }
    default:
      return state;
  }
};
