import moment from 'moment';

import { FETCH_CAMPAIGNS_DATA_SUCCESS, 
    FETCH_CAMPAIGNS_DATA_ADD_SUCCESS, 
    FETCH_CAMPAIGNS_NAME_SUCCESS,
    FETCH_COMPANY_AND_CAMPAIGNS_DATA_SUCCESS,
    FETCH_CAMPAIGN_DATA_SUCCESS, 
    CHECK_SM_SUCCESS,
    FETCH_CAMPAIGN_DATA_RESOURCES_SUCCESS,
    FETCH_CAMPAIGN_DATA_CARDINAL_SUCCESS,
    SUBSCRIPTION_CAMPAIGN_DATA_SUCCESS,
    FETCH_CAMPAIGN_HEADER_AUDIT_DATA_SUCCESS,
    } from "../actionTypes";

const initialState = {
    company: null,
    campaigns: [],
    campaign: {
      campaign: null,
      influencerHistory: {
        scopeValueExpected: 0,
        scopeValueReal: 0,
        scopeReal: [],
        scopePromise: []
      },
      engagementHistory: {
        scopeValueExpected: 0,
        scopeValueReal: 0,
        scopeReal: [],
        scopePromise: []
      },
      investment: {
        investment: 0,
        budgetSpent: 0,
        budgetFreezed: 0,
        budgetAvailable: 0
      },
      stock: 0,
      facebookCheck: 1,
      instagramCheck: 1,
      twitterCheck:1,
      influencer: {
        read: {
          male:0,
          female: 0,
          malePercentages:0,
          femalePercentages: 0
        },
        total: {
          male:0,
          female: 0,
          malePercentages:0,
          femalePercentages: 0
        },
        facebookInfluencer: {
          male:0,
          female: 0,
          malePercentages:0,
          femalePercentages: 0
        },
        instagramInfluencer: {
          male:0,
          female: 0,
          malePercentages:0,
          femalePercentages: 0
        },
        tweeterInfluencer: {
          male:0,
          female: 0,
          malePercentages:0,
          femalePercentages: 0
        },
      },
      influenced: {
        read: {
          male:0,
          female: 0,
          anonymous: 0,
          malePercentages:0,
          femalePercentages: 0,
          anonymousPercentages: 0,
        },
        total: {
          Male:0,
          Female: 0,
          Anonymous:0,
          MalePercentages:0,
          FemalePercentages: 0,
          AnonymousPercentages: 0,
        },
        facebookInfluencer: {
          Male:0,
          Female: 0,
          Anonymous:0,
          MalePercentages:0,
          FemalePercentages: 0,
          AnonymousPercentages: 0,
        },
        instagramInfluencer: {
          Male:0,
          Female: 0,
          Anonymous:0,
          MalePercentages:0,
          FemalePercentages: 0,
          AnonymousPercentages: 0,
        },
        tweeterInfluencer: {
          Male:0,
          Female: 0,
          Anonymous:0,
          MalePercentages:0,
          FemalePercentages: 0,
          AnonymousPercentages: 0,
        },
      },
      resources: {
        read: {
          likeCount: 0,
          likePercentage: 0,
          linkCount: 0,
          linkPercentage: 0,
          tagCount: 0,
          tagPercentage: 0,
          sharedCount: 0,
          sharedPercentage: 0,
          mentionCount: 0,
          mentionPercentage: 0,
          hashtagCount: 0,
          hashtagPercentage: 0,
          printCount: 0,
          printPercentage: 0,
          commentCount: 0,
          commentPercentage: 0,
          totalCount: 0

        },
        facebookLikeCount: 0,
        facebookLinkCount: 0,
        facebookTagCount: 0,
        facebookSharedCount: 0,
        facebookMentionCount: 0,
        facebookHashtagCount: 0,
        facebookPrintCount: 0,
        facebookCommentCount: 0,
        twitterLikeCount: 0,
        twitterLinkCount: 0,
        twitterTagCount: 0,
        twitterSharedCount: 0,
        twitterMentionCount: 0,
        twitterHashtagCount: 0,
        twitterPrintCount: 0,
        twitterCommentCount: 0,
        instagramLikeCount: 0,
        instagramLinkCount: 0,
        instagramTagCount: 0,
        instagramSharedCount: 0,
        instagramMentionCount: 0,
        instagramHashtagCount: 0,
        instagramPrintCount: 0,
        instagramCommentCount: 0
      },
      cardinal: {
        read: {
          influencersCardinal:[],
          InfluencedsCardinal:[]
        },
        facebookInfluencersCardinal: [],
        twitterInfluencersCardinal: [],
        instagramInfluencersCardinal: [],
        facebookInfluencedsCardinal: [],
        twitterInfluencedsCardinal: [],
        instagramInfluencedsCardinal: [],
      }
    },
    campaignsName: [],
    campaignHeaderAudit: null,
    hasMore: true,
    active: true,
    page: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CAMPAIGN_HEADER_AUDIT_DATA_SUCCESS: {
        return {
          ...state,
          campaignHeaderAudit: action.payload.data
        }
      }
      case FETCH_COMPANY_AND_CAMPAIGNS_DATA_SUCCESS: {
        return {
          ...state,
          company: action.payload.data
        }
      }
      case FETCH_CAMPAIGNS_DATA_SUCCESS:
        return {
          ...state,
          campaigns: action.payload.data,
          active: action.payload.active,
          hasMore: action.payload.hasMore,
          page: 4
      }
      case FETCH_CAMPAIGNS_DATA_ADD_SUCCESS:
        return {
          ...state,
          campaigns: [...state.campaigns,...action.payload.data],
          active: action.payload.active,
          hasMore: action.payload.hasMore,
          page: state.page + 1
      }
      case FETCH_CAMPAIGNS_NAME_SUCCESS:
        return {
          ...state,
          campaignsName: action.payload
      }
      case FETCH_CAMPAIGN_DATA_SUCCESS:
        var data = action.payload.data[0];
        var fragmentDuration = calculateDateDiff(data.startDt, data.endDt);
        // influencerHistory
        var scopePromiseInfluencers = calculateInfluencerScopePromise(data.influencersExpected / 10);
        var scopeRealInfluencers = calculateInfluencerScopeReal(data.influencersTotalHistoric, fragmentDuration, action.payload.data[0].startDt, action.payload.data[0].endDt);
        // engagementHistory
        var scopePromiseEngagement = calculateInfluencerScopePromise(data.engagementExpected / 10);
        var scopeRealEngagement = calculateInfluencerScopeReal(data.engagementRealHistoric, fragmentDuration, action.payload.data[0].startDt, action.payload.data[0].endDt);
        // engagment influencer
        var engagementTotalInfluencer = data.engagementMaleInfluencer + data.engagementFemaleInfluencer;
        var facebookEngagementTotalInfluencer = data.facebookMaleInfluencerCount + data.facebookFemaleInfluencerCount;
        var instagramEngagementTotalInfluencer = data.instagramMaleInfluencerCount + data.instagramFemaleInfluencerCount;
        var twitterEngagementTotalInfluencer = data.twitterMaleInfluencerCount + data.twitterFemaleInfluencerCount;
        // engagment influenced
        var engagementTotalInfluenced = data.engagementMaleInfluenced + data.engagementFemaleInfluenced + data.engagementAnonymousInfluenced;
        var facebookEngagementTotalInfluenced = data.facebookMaleInfluencedCount + data.facebookFemaleInfluencedCount + data.facebookAnonymousInfluencedCount;
        var instagramEngagementTotalInfluenced = data.instagramMaleInfluencedCount + data.instagramFemaleInfluencedCount + data.instagramAnonymousInfluencedCount;
        var twitterEngagementTotalInfluenced = data.twitterMaleInfluencedCount + data.twitterFemaleInfluencedCount + data.twitterAnonymousInfluencedCount;
        
        return {
          ...state,
          campaign: {
            ...state.campaign,
            campaign: data,
            influencerHistory: {
              scopeValueExpected: data.influencersExpected,
              scopeValueReal: data.influencersTotal,
              scopePromise: scopePromiseInfluencers,
              scopeReal: scopeRealInfluencers
            },
            engagementHistory: {
              scopeValueExpected: data.engagementExpected,
              scopeValueReal: data.engagementReal,
              scopePromise: scopePromiseEngagement,
              scopeReal: scopeRealEngagement
            },
            investment: {
              investment: data.investment,
              budgetSpent: data.budgetSpent,
              budgetFreezed: data.budgetFreezed,
              budgetAvailable: data.budgetAvailable
            },
            stock: 0,
            influencer: {
              read: {
                male: data.engagementMaleInfluencer,
                female: data.engagementFemaleInfluencer,
                malePercentages: engagementTotalInfluencer !== 0 ? (100 / engagementTotalInfluencer * data.engagementMaleInfluencer).toFixed(0) : 0,
                femalePercentages: engagementTotalInfluencer !== 0 ? (100 / engagementTotalInfluencer * data.engagementFemaleInfluencer).toFixed(0) : 0,
              },
              total:{
                male: data.engagementMaleInfluencer,
                female: data.engagementFemaleInfluencer,
                malePercentages: engagementTotalInfluencer !== 0 ? (100 / engagementTotalInfluencer * data.engagementMaleInfluencer).toFixed(0) : 0,
                femalePercentages: engagementTotalInfluencer !== 0 ? (100 / engagementTotalInfluencer * data.engagementFemaleInfluencer).toFixed(0) : 0
              },
              facebookInfluencer: {
                male: data.facebookMaleInfluencerCount,
                female: data.facebookFemaleInfluencerCount,
                malePercentages: facebookEngagementTotalInfluencer !== 0 ? (100 / facebookEngagementTotalInfluencer * data.facebookMaleInfluencerCount).toFixed(0) : 0,
                femalePercentages: facebookEngagementTotalInfluencer !== 0 ? (100 / facebookEngagementTotalInfluencer * data.facebookFemaleInfluencerCount).toFixed(0) : 0
              },
              instagramInfluencer: {
                male: data.instagramMaleInfluencerCount,
                female: data.instagramFemaleInfluencerCount,
                malePercentages: instagramEngagementTotalInfluencer !== 0 ? (100 / instagramEngagementTotalInfluencer * data.instagramMaleInfluencerCount).toFixed(0) : 0,
                femalePercentages: instagramEngagementTotalInfluencer !== 0 ? (100 / instagramEngagementTotalInfluencer * data.instagramFemaleInfluencerCount).toFixed(0) : 0
              },
              tweeterInfluencer: {
                male: data.twitterMaleInfluencerCount,
                female: data.twitterFemaleInfluencerCount,
                malePercentages: twitterEngagementTotalInfluencer !== 0 ? (100 / twitterEngagementTotalInfluencer * data.twitterMaleInfluencerCount).toFixed(0) : 0,
                femalePercentages: twitterEngagementTotalInfluencer !== 0 ? (100 / twitterEngagementTotalInfluencer * data.twitterFemaleInfluencerCount).toFixed(0): 0
              },
            },
            influenced: {
              read: {
                male: data.engagementMaleInfluenced,
                female: data.engagementFemaleInfluenced,
                anonymous: data.engagementAnonymousInfluenced,
                malePercentages: engagementTotalInfluenced !== 0 ? (100 / engagementTotalInfluenced * data.engagementMaleInfluenced).toFixed(0) : 0,
                femalePercentages: engagementTotalInfluenced !== 0 ? (100 / engagementTotalInfluenced * data.engagementFemaleInfluenced).toFixed(0) : 0,
                anonymousPercentages: engagementTotalInfluenced !== 0 ? (100 / engagementTotalInfluenced * data.engagementAnonymousInfluenced).toFixed(0) : 0,
              },
              total:{
                male: data.engagementMaleInfluenced,
                female: data.engagementFemaleInfluenced,
                anonymous: data.engagementAnonymousInfluenced,
                malePercentages: engagementTotalInfluenced !== 0 ? (100 / engagementTotalInfluenced * data.engagementMaleInfluenced).toFixed(0) : 0,
                femalePercentages: engagementTotalInfluenced !== 0 ? (100 / engagementTotalInfluenced * data.engagementFemaleInfluenced).toFixed(0) : 0,
                anonymousPercentages: engagementTotalInfluenced !== 0 ? (100 / engagementTotalInfluenced * data.engagementAnonymousInfluenced).toFixed(0) : 0,
              },
              facebookInfluenced: {
                male: data.facebookMaleInfluencedCount,
                female: data.facebookFemaleInfluencedCount,
                anonymous: data.facebookAnonymousInfluencedCount,
                malePercentages: facebookEngagementTotalInfluenced !== 0 ? (100 / facebookEngagementTotalInfluenced * data.facebookMaleInfluencedCount).toFixed(0) : 0,
                femalePercentages: facebookEngagementTotalInfluenced !== 0 ? (100 / facebookEngagementTotalInfluenced * data.facebookFemaleInfluencedCount).toFixed(0): 0,
                anonymousPercentages: facebookEngagementTotalInfluenced !== 0 ? (100 / facebookEngagementTotalInfluenced * data.facebookAnonymousInfluencedCount).toFixed(0) : 0,
              },
              instagramInfluenced: {
                male: data.instagramMaleInfluencedCount,
                female: data.instagramFemaleInfluencedCount,
                anonymous: data.instagramAnonymousInfluencedCount,
                malePercentages: instagramEngagementTotalInfluenced !== 0 ? (100 / instagramEngagementTotalInfluenced * data.instagramMaleInfluencedCount).toFixed(0) : 0,
                femalePercentages: instagramEngagementTotalInfluenced !== 0 ? (100 / instagramEngagementTotalInfluenced * data.instagramFemaleInfluencedCount).toFixed(0) : 0,
                anonymousPercentages: instagramEngagementTotalInfluenced !== 0 ? (100 / instagramEngagementTotalInfluenced * data.instagramAnonymousInfluencedCount).toFixed(0) : 0,
              },
              tweeterInfluenced: {
                male: data.twitterMaleInfluencedCount,
                female: data.twitterFemaleInfluencedCount,
                anonymous: data.twitterAnonymousInfluencedCount,
                malePercentages: twitterEngagementTotalInfluenced !== 0 ? (100 / twitterEngagementTotalInfluenced * data.twitterMaleInfluencedCount).toFixed(0) : 0,
                femalePercentages: twitterEngagementTotalInfluenced !== 0 ? (100 / twitterEngagementTotalInfluenced * data.twitterFemaleInfluencedCount).toFixed(0) : 0,
                anonymousPercentages: twitterEngagementTotalInfluenced !== 0 ? (100 / twitterEngagementTotalInfluenced * data.twitterAnonymousInfluencedCount).toFixed(0) : 0,
              },
            }
          }
      }
      case CHECK_SM_SUCCESS: {
        let facebookCheck;
        let instagramCheck;
        let twitterCheck;

        var maleInfluencer = 0;
        var femaleInfluencer = 0;
        var malePercentagesInfluencer = 0;
        var femalePercentagesInfluencer = 0;

        var maleInfluenced = 0;
        var femaleInfluenced = 0;
        var anonymousInfluenced = 0;
        var malePercentagesInfluenced = 0;
        var femalePercentagesInfluenced = 0;
        var anonymousPercentagesInfluenced = 0;
        switch (action.payload.sm) {
          case "facebook":
            facebookCheck = action.payload.status;
            instagramCheck = state.campaign.instagramCheck;
            twitterCheck = state.campaign.twitterCheck;
            break;
          case "instagram":
            facebookCheck = state.campaign.facebookCheck;
            instagramCheck = action.payload.status;
            twitterCheck = state.campaign.twitterCheck;
            break;
          case "twitter":
            facebookCheck = state.campaign.facebookCheck;
            instagramCheck = state.campaign.instagramCheck;
            twitterCheck = action.payload.status;
            break;
          default:
            break;
        }
        //Influencer
        maleInfluencer = state.campaign.influencer.facebookInfluencer.male * facebookCheck +
                  state.campaign.influencer.instagramInfluencer.male * instagramCheck +
                  state.campaign.influencer.tweeterInfluencer.male * twitterCheck;
        femaleInfluencer = state.campaign.influencer.facebookInfluencer.female * facebookCheck +
                  state.campaign.influencer.instagramInfluencer.female * instagramCheck +
                  state.campaign.influencer.tweeterInfluencer.female * twitterCheck;
        var sumMaleFemaleInfluencer = maleInfluencer + femaleInfluencer;
        if( sumMaleFemaleInfluencer > 0) {
          var malePercentagesInfluencer = (100 / sumMaleFemaleInfluencer * maleInfluencer).toFixed(0);
          var femalePercentagesInfluencer = (100 / sumMaleFemaleInfluencer * femaleInfluencer).toFixed(0);
        }
        //Influenced
        maleInfluenced = state.campaign.influenced.facebookInfluenced.male * facebookCheck +
                  state.campaign.influenced.instagramInfluenced.male * instagramCheck +
                  state.campaign.influenced.tweeterInfluenced.male * twitterCheck;
        femaleInfluenced = state.campaign.influenced.facebookInfluenced.female * facebookCheck +
                  state.campaign.influenced.instagramInfluenced.female * instagramCheck +
                  state.campaign.influenced.tweeterInfluenced.female * twitterCheck;
        anonymousInfluenced = state.campaign.influenced.facebookInfluenced.anonymous * facebookCheck +
                  state.campaign.influenced.instagramInfluenced.anonymous * instagramCheck +
                  state.campaign.influenced.tweeterInfluenced.anonymous * twitterCheck;
        var sumMaleFemaleInfluenced = maleInfluenced + femaleInfluenced + anonymousInfluenced;
        if( sumMaleFemaleInfluenced > 0) {
          malePercentagesInfluenced = (100 / sumMaleFemaleInfluenced * maleInfluenced).toFixed(0);
          femalePercentagesInfluenced = (100 / sumMaleFemaleInfluenced * femaleInfluenced).toFixed(0);
          anonymousPercentagesInfluenced = (100 / sumMaleFemaleInfluenced * anonymousInfluenced).toFixed(0);
        }
        //Insights
        var read = calculateTotalInsigths(state.campaign.resources, facebookCheck, twitterCheck, instagramCheck);
        //Cardinal
        var readCardinal = calculateCardinal(state.campaign.cardinal, facebookCheck, twitterCheck, instagramCheck);
        return {
          ...state,
          campaign: {
            ...state.campaign,
            facebookCheck: facebookCheck,
            instagramCheck: instagramCheck,
            twitterCheck: twitterCheck,
            influencer: {
              ...state.campaign.influencer, 
              read: {
                male: maleInfluencer,
                female: femaleInfluencer,
                malePercentages: malePercentagesInfluencer,
                femalePercentages: femalePercentagesInfluencer
              }
            },
            influenced: {
              ...state.campaign.influenced, 
              read: {
                male: maleInfluenced,
                female: femaleInfluenced,
                anonymous: anonymousInfluenced,
                malePercentages: malePercentagesInfluenced,
                femalePercentages: femalePercentagesInfluenced,
                anonymousPercentages: anonymousPercentagesInfluenced
              }
            },
            resources: {
              ...state.campaign.resources,
              read,
            },
            cardinal: {
              ...state.campaign.cardinal,
              read: readCardinal
            }
          }
        }
      }
      case FETCH_CAMPAIGN_DATA_RESOURCES_SUCCESS : {
        var data = action.payload.data[0];

        var read = calculateTotalInsigths(data, state.campaign.facebookCheck, state.campaign.twitterCheck, state.campaign.instagramCheck);

        return {
          ...state,
          campaign: {
            ...state.campaign,
            resources: {
              read,
              facebookLikeCount: data.facebookLikeCount,
              facebookLinkCount: data.facebookLinkCount,
              facebookTagCount: data.facebookTagCount,
              facebookSharedCount: data.facebookSharedCount,
              facebookMentionCount: data.facebookMentionCount,
              facebookHashtagCount: data.facebookHashtagCount,
              facebookPrintCount: data.facebookPrintCount,
              facebookCommentCount: data.facebookCommentCount,
              twitterLikeCount: data.twitterLikeCount,
              twitterLinkCount: data.twitterLinkCount,
              twitterTagCount: data.twitterTagCount,
              twitterSharedCount: data.twitterSharedCount,
              twitterMentionCount: data.twitterMentionCount,
              twitterHashtagCount: data.twitterHashtagCount,
              twitterPrintCount: data.twitterPrintCount,
              twitterCommentCount: data.twitterCommentCount,
              instagramLikeCount: data.instagramLikeCount,
              instagramLinkCount: data.instagramLinkCount,
              instagramTagCount: data.instagramTagCount,
              instagramSharedCount: data.instagramSharedCount,
              instagramMentionCount: data.instagramMentionCount,
              instagramHashtagCount: data.instagramHashtagCount,
              instagramPrintCount: data.instagramPrintCount,
              instagramCommentCount: data.instagramCommentCount
            }
          }
        }
      }
      case FETCH_CAMPAIGN_DATA_CARDINAL_SUCCESS: {
        var data = action.payload.data[0];

        var read = calculateCardinal(data, state.campaign.facebookCheck, state.campaign.twitterCheck, state.campaign.instagramCheck);

        return {
          ...state,
          campaign: {
            ...state.campaign,
            cardinal: {
              read,
              facebookInfluencersCardinal: data.facebookInfluencersCardinal,
              facebookInfluencedsCardinal: data.facebookInfluencedsCardinal,
              twitterInfluencersCardinal: data.twitterInfluencersCardinal,
              twitterInfluencedsCardinal: data.twitterInfluencedsCardinal,
              instagramInfluencersCardinal: data.instagramInfluencersCardinal,
              instagramInfluencedsCardinal: data.instagramInfluencedsCardinal
            }
          }
        }
      }
      case SUBSCRIPTION_CAMPAIGN_DATA_SUCCESS: {
        var influencersScope = action.payload.res2.data;
        var fragmentDuration = calculateDateDiff(state.campaign.campaign.startDt, state.campaign.campaign.endDt);
        // influencerHistory
        var scopePromiseInfluencers = calculateInfluencerScopePromise(influencersScope / 10);
        var scopeRealInfluencers = calculateInfluencerScopeReal(state.campaign.campaign.influencersTotalHistoric, fragmentDuration, state.campaign.campaign.startDt, state.campaign.campaign.endDt);
        return {
          ...state,
          campaign: {
            ...state.campaign,
            campaign : {
              ...state.campaign.campaign,
              influencersScope: influencersScope
            },
            influencerHistory: {
              scope: influencersScope,
              scopePromise: scopePromiseInfluencers,
              scopeReal: scopeRealInfluencers
            },
          }
        }
      } 
      default:
        return state;
      
    }
  };

const calculateCardinal = (data, facebookCheck, twitterCheck, instagramCheck) => {
  var read = {
    influencersCardinal:[],
    InfluencedsCardinal:[]
  }

  if (facebookCheck) {
    read.influencersCardinal = [...data.facebookInfluencersCardinal];
    read.InfluencedsCardinal = [...data.facebookInfluencedsCardinal];
  }
  if (twitterCheck) {
    read.influencersCardinal = [...read.influencersCardinal,...data.twitterInfluencersCardinal];
    read.InfluencedsCardinal = [...read.InfluencedsCardinal,...data.twitterInfluencedsCardinal];
  }
  if (instagramCheck) {
    read.influencersCardinal = [...read.influencersCardinal,...data.instagramInfluencersCardinal];
    read.InfluencedsCardinal = [...read.InfluencedsCardinal,...data.instagramInfluencedsCardinal];
  }

  return read;
}

const calculateDateDiff = (startDt, endDt) => {
  //va la logica para cambiar de dia a semana o mes
  var diff = moment(endDt).diff(moment(startDt), 'minutes') / 10;
  return diff;
}

const calculateInfluencerScopePromise = (scope) => {
  var promise = [0];
      for (let index = 1; index < 11; index++) {
        if (index === 1) promise.push(scope);
        else promise.push(scope + promise[index-1]);
      }
  return promise;
}

const calculateInfluencerScopeReal = (totalHistory, fragmentDuration, startDt, endDt) => {
  var dateToCompare = moment(startDt).add(fragmentDuration, 'minutes');

  var resultArray = [0];

  for (let index = 0; index < totalHistory.length; index++) {
    const element = totalHistory[index];
    var diff = moment(element.timespan).diff(dateToCompare, 'minutes');

    if(moment(element.timespan).diff(dateToCompare, 'minutes') > 0){
      resultArray.push(totalHistory[index-1].value);
      if ( (diff / fragmentDuration).toFixed(0) >= 1) {
        var countDiff = (diff / fragmentDuration).toFixed(0);

        for (let i = 1; i < countDiff; i++) {
          resultArray.push(totalHistory[index-1].value);
        }

        dateToCompare = dateToCompare.add(fragmentDuration * countDiff, 'minutes');
      } else {
        dateToCompare = dateToCompare.add(fragmentDuration, 'minutes');
      }

    } else if (moment(element.timespan).diff(dateToCompare, 'minutes') === 0){
      resultArray.push(element.value);
      if ( (diff / fragmentDuration).toFixed(0) >= 1) {
        var countDiff = (diff / fragmentDuration).toFixed(0);
        
        for (let i = 1; i < countDiff; i++) {
          resultArray.push(totalHistory[index-1].value);
        }

        dateToCompare = dateToCompare.add(fragmentDuration * countDiff, 'minutes');
      } else {
        dateToCompare = dateToCompare.add(fragmentDuration, 'minutes');
      }
    }
    
    if (index === totalHistory.length - 1) {
      resultArray.push(element.value);
    }
  } 
  return resultArray;
}

const calculateTotalInsigths = (data, facebookCheck, twitterCheck, instagramCheck) => {
  var read = {
    likeCount: 0,
    likePercentage: 0,
    linkCount: 0,
    linkPercentage: 0,
    tagCount: 0,
    tagPercentage: 0,
    sharedCount: 0,
    sharedPercentage: 0,
    mentionCount: 0,
    mentionPercentage: 0,
    hashtagCount: 0,
    hashtagPercentage: 0,
    printCount: 0,
    printPercentage: 0,
    commentCount: 0,
    commentPercentage: 0,
    totalCount: 0
  }
  read.likeCount = data.facebookLikeCount * facebookCheck +
                        data.twitterLikeCount * twitterCheck +
                        data.instagramLikeCount * instagramCheck;
        
  read.linkCount = data.facebookLinkCount * facebookCheck +
                        data.twitterLinkCount * twitterCheck +
                        data.instagramLinkCount * instagramCheck;
        
  read.tagCount = data.facebookTagCount * facebookCheck +
                        data.twitterTagCount * twitterCheck +
                        data.instagramTagCount * instagramCheck;
        
  read.sharedCount = data.facebookSharedCount * facebookCheck +
                        data.twitterSharedCount * twitterCheck +
                        data.instagramSharedCount * instagramCheck;
        
  read.mentionCount = data.facebookMentionCount * facebookCheck +
                        data.twitterMentionCount * twitterCheck +
                        data.instagramMentionCount * instagramCheck;
        
  read.hashtagCount = data.facebookHashtagCount * facebookCheck +
                        data.twitterHashtagCount * twitterCheck +
                        data.instagramHashtagCount * instagramCheck;
        
  read.printCount = data.facebookPrintCount * facebookCheck +
                        data.twitterPrintCount * twitterCheck +
                        data.instagramPrintCount * instagramCheck;
        
  read.commentCount = data.facebookCommentCount * facebookCheck +
                        data.twitterCommentCount * twitterCheck +
                        data.instagramCommentCount * instagramCheck;
  
  read.totalCount = read.likeCount + read.linkCount  + read.tagCount  + read.sharedCount
               + read.mentionCount + read.hashtagCount + read.printCount + read.commentCount;
  if (read.totalCount > 0) {
    read.likePercentage = (100 / read.totalCount * read.likeCount).toFixed(0);
    read.linkPercentage = (100 / read.totalCount * read.linkCount).toFixed(0);
    read.tagPercentage = (100 / read.totalCount * read.tagCount).toFixed(0);
    read.sharedPercentage = (100 / read.totalCount * read.sharedCount).toFixed(0);
    read.mentionPercentage = (100 / read.totalCount * read.mentionCount).toFixed(0);
    read.hashtagPercentage = (100 / read.totalCount * read.hashtagCount).toFixed(0);
    read.printPercentage = (100 / read.totalCount * read.printCount).toFixed(0);
    read.commentPercentage = (100 / read.totalCount * read.commentCount).toFixed(0);
  }

  return read;
}