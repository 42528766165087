import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ChartistGraph from "react-chartist";
import CardHeader from "components/Card/CardHeader.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import {
    pieChart
} from "variables/charts.jsx";

function InvestmentStatus({ ...props }) {
    const {
        classes,
        data,
        tags,
    } = props;

    let percentajes = {
        budgetSpent: 100 / data.investment * data.budgetSpent,
        budgetFreezed: 100 / data.investment * data.budgetFreezed,
        budgetAvailable: 100 / data.investment * data.budgetAvailable
    }

    const investmentStatus = {
        data: {
            labels: [ 
                    `${percentajes.budgetSpent.toFixed(0)}%`,
                    `${percentajes.budgetFreezed.toFixed(0)}%`, 
                    `${percentajes.budgetAvailable.toFixed(0)}%`,
                    ],
            series: [
                    percentajes.budgetSpent.toFixed(0),
                    percentajes.budgetFreezed.toFixed(0),
                    percentajes.budgetAvailable.toFixed(0),
                    ]
        }
    }

    return (
        <Card>
                <CardHeader color="danger" icon>
                <h6 className={classes.cardIconTitle}>{tags.totalInvestment}: $ {data.investment} </h6>
                </CardHeader>
                <CardBody  minWidth200={true}>
                <ChartistGraph
                    data={investmentStatus.data}
                    type="Pie"
                    options={pieChart.optionsMin}
                    />
                                            {` `}
                        <Tooltip
                            id="tooltip-top"
                            title={`${tags.spent}: ${data.budgetSpent.toFixed(2)} $`}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <i className={"fas fa-circle " + classes.colorOnGoing}>{` `}</i>
                        </Tooltip>
                        {` `}
                        <Tooltip
                            id="tooltip-top"
                            title={`${tags.freezed}: ${data.budgetFreezed.toFixed(2)} $`}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <i className={"fas fa-circle " + classes.danger}>{` `}</i>
                        </Tooltip>
                        {` `}
                        <Tooltip
                            id="tooltip-top"
                            title={`${tags.availible}: ${data.budgetAvailable.toFixed(2)} $`}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <i className={"fas fa-circle " + classes.colorFinished}>{` `}</i>
                        </Tooltip>
                </CardBody>
            </Card>
    )
}

export default InvestmentStatus;