import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';


import reducer from "../reducers";
import mySaga from "../sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const enhancer = composeWithDevTools(
    applyMiddleware(sagaMiddleware, createLogger())
  );
// mount it on the Store
export default createStore(reducer, enhancer);

// then run the saga
sagaMiddleware.run(mySaga);
