import { FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_SUCCESS,
} from "../actionTypes";

const initialState = {
    sponsorshipeffectivenesses: [],
    sponsorshipeffectivenessesRead: []
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_SPONSORSHIPEFFECTIVENESSES_DATA_SUCCESS: {
    let sponsorshipeffectivenessesRead = [];
    
    for (let index = 0; index < action.payload.data.length; index++) {
        const element = action.payload.data[index];
        let newItem = {...element, idSlide: index};
        sponsorshipeffectivenessesRead.push(newItem);
    }
    return {
        ...state,
        sponsorshipeffectivenesses: action.payload.data,
        sponsorshipeffectivenessesRead
        }
   }
       
   default:
       return state;
    }
}