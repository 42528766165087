import companies from './companies';
import campaigns from './campaigns';
import ages from './ages';
import categories from './categories';
import regions from './regions';
import sexes from './sexes';
import sponsorshipeffectivenesses from './sponsorshipeffectivenesses';
import spoteffectivenesses from './spoteffectivenesses';
import stars from './stars';
import people from './people';
import advertisingEffectivenesses from './advertisingEffectivenesses';
import advertisements from './advertisements';
import allCollectionsToCampaign from './allCollectionsToCampaign';

export default {
    companies,
    campaigns,
    ages,
    categories,
    regions,
    sexes,
    sponsorshipeffectivenesses,
    spoteffectivenesses,
    stars,
    people,
    advertisingEffectivenesses,
    advertisements,
    allCollectionsToCampaign
};