import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkSMSuccess } from "../../../state/actions/campaigns";
import tags from "./tags";
import styles from "./styleIndex";
import Map from "../dashboardGraphics/map";
import Insights from "../dashboardGraphics/insights";
import Demographic from "../dashboardGraphics/demographic";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import IconButton from "@material-ui/core/IconButton";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Facebook } from 'react-content-loader';
const MyFacebookLoader = () => <Facebook />

class Body extends React.Component {
    state = {
        enabledFacebook: true,
        classFacebook: "facebook",
        whiteFacebook: this.props.classes.colorWhite,
        enabledInstagram: true,
        classInstagram: "instagram",//this.props.classes.colorInstagram,
        whiteInstagram: this.props.classes.colorWhite,
        enabledTwitter: true,
        classTwitter: "twitter",
        whiteTwitter: this.props.classes.colorWhite,
        checkedA: true,
        checkedB: true,
    };

    handleChange = name => event => {
        this.setState({ 
            [name]: event.target.checked 
        });

        if (name === "checkedA") this.setState({ checkedB: true })
        else if (name === "checkedB") this.setState({ checkedA: true })
      };
    
    handlerEnableSocialMedia = (item) => {
        switch (item.sm) {
            case "facebook":
                if (this.state.enabledFacebook) this.setState({ enabledFacebook: !this.state.enabledFacebook, all: this.state.all -1, classFacebook: '', whiteFacebook: '' });
                else this.setState({ enabledFacebook: !this.state.enabledFacebook, classFacebook:"facebook", whiteFacebook: this.props.classes.colorWhite });

                this.props.checkSMSuccess({sm: "facebook", status: this.state.enabledFacebook ? 0 : 1 });
                break;
            case "instagram":
                if (this.state.enabledInstagram) this.setState({ enabledInstagram: !this.state.enabledInstagram, all: this.state.all -1, classInstagram: '', whiteInstagram: '' });
                else this.setState({ enabledInstagram: !this.state.enabledInstagram, classInstagram: "instagram", whiteInstagram: this.props.classes.colorWhite });
                
                this.props.checkSMSuccess({sm: "instagram", status: this.state.enabledInstagram ? 0 : 1 });
                break;
            case "twitter":
                if (this.state.enabledTwitter) this.setState({ enabledTwitter: !this.state.enabledTwitter, all: this.state.all -1, classTwitter: '', whiteTwitter: '' });
                else this.setState({ enabledTwitter: !this.state.enabledTwitter, classTwitter: "twitter", whiteTwitter: this.props.classes.colorWhite });
                
                this.props.checkSMSuccess({sm: "twitter", status: this.state.enabledTwitter ? 0 : 1 });
                break;
            default:
                break;
        }
    }
  render() {
    const { classes } = this.props;
    return (
        this.props.campaign.campaign === null ? MyFacebookLoader() : 
        <div>
            <GridContainer>
                
                <GridContainer ustify="center">
                            <GridItem xs={12} sm={12} md={4}>
                                <Button onClick={() => this.handlerEnableSocialMedia({sm:"facebook"})}
                                    fullWidth
                                    color={this.state.classFacebook}
                                    // color="primary"
                                >
                                    <IconButton className={classes.menuButton}>
                                    <i className={"fab fa-facebook " + this.state.whiteFacebook}></i>
                                    </IconButton>
                                    Facebook
                                </Button>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Button onClick={() => this.handlerEnableSocialMedia({sm:"instagram"})}
                                    fullWidth
                                    color={this.state.classInstagram}
                                >
                                    <IconButton className={classes.menuButton}>
                                        <i className={"fab fa-instagram " + this.state.whiteInstagram}></i>
                                    </IconButton>
                                    Instagram
                                </Button>
                                
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Button onClick={() => this.handlerEnableSocialMedia({sm:"twitter"})}
                                    fullWidth
                                    color={this.state.classTwitter}
                                >
                                    <IconButton  className={classes.menuButton}>
                                        <i className={"fab fa-twitter " + this.state.whiteTwitter}></i>
                                    </IconButton>
                                    Twitter
                                </Button>
                                
                            </GridItem>
                </GridContainer>
            </GridContainer>
            <GridContainer>
                <Insights classes={classes} data={this.props.campaign.resources.read} tags={this.props.tags}/>
                <Map 
                    classes={classes} 
                    data={this.props.campaign.cardinal.read} 
                    tags={this.props.tags}
                    handleChange={this.handleChange}
                    checkedA={this.state.checkedA}
                    checkedB={this.state.checkedB}
                />
            </GridContainer>
            <GridContainer>
                <Demographic 
                    classes={classes} 
                    data={this.props.campaign.influencer.read} 
                    tags={this.props.tags} 
                    type={"influencer"}
                    />
                <Demographic 
                    classes={classes} 
                    data={this.props.campaign.influenced.read} 
                    tags={this.props.tags}
                    type={"influenced"}/>
            </GridContainer>
        </div>
    );
  }
}

function mapStateToProps(state){
    return {
      campaign: state.campaigns.campaign
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
        checkSMSuccess: bindActionCreators(checkSMSuccess, dispatch)
    }
  }

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Body)));
