import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import {
    colouredLinesChart
} from "variables/charts.jsx";

var Chartist = require("chartist");

function History({ ...props }) {
    const {
         classes,
         data,
         type
      } = props;
      var thisColor = type === "influencers" ? "success" : "warning";

      var real = data.scopeReal;
      for (let index = real.length; index < 10; index++) {
        real.push(null);
      }

      var realToUI = real * 100;
      var values = {
        labels: [
          "'0",
          "'1",
          "'2",
          "'3",
          "'4",
          "'5",
          "'6",
          "'7",
          "'8",
          "'9",
          "'10"
        ],
        series: [
          data.scopePromise,
          real
        ]
      }

      var options = {
        showArea: true,
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 10
        }),
        axisY: {
          showGrid: true,
          offset: 40
        },
        axisX: {
          showGrid: false
        },
        low: 0,
        high: data.scopeValueExpected > data.scopeValueReal ? data.scopeValueExpected * 1.4 : data.scopeValueReal * 1.4,
        showPoint: true,
        height: "190px"
      }

      return (
        <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color={thisColor} >
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={values}
                  type="Line"
                  options={options}
                  listener={colouredLinesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>{type === "influencers" ? `${props.tags.influencer}: ` : `${props.tags.engagement}: `} {`Real: ${data.scopeValueReal} - Promise: ${data.scopeValueExpected}`} </h4>
                <p className={classes.cardCategory}>{type === "influencers" ? `${props.tags.post}` : props.tags.engagement } {props.tags.realVsPromise} </p>
              </CardBody>
            </Card>
        </GridItem>
      )
}

export default History;