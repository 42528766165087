import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchSpoteffectivenessesDataSuccess,
    fetchSpoteffectivenessesDataFail
 } from "../actions/spoteffectivenesses";
import {
    FETCH_SPOTEFFECTIVENESSES_DATA
} from '../actionTypes';
import api from '../api';

function* fetchSpoteffectivenessesData(action) {
    try {
          let data = yield api.spoteffectivenesses.getSpoteffectivenessesData(action.payload);
          yield put(fetchSpoteffectivenessesDataSuccess({data:data.spotEffectiveness}));
    } catch (e) {
          yield put(fetchSpoteffectivenessesDataFail());
    }
}

export default function* spoteffectivenessesSaga() {
    yield takeLatest(FETCH_SPOTEFFECTIVENESSES_DATA, fetchSpoteffectivenessesData);
}