import { FETCH_ADVERTISEMENTS_DATA,
    FETCH_ADVERTISEMENTS_DATA_SUCCESS,
    FETCH_ADVERTISEMENTS_DATA_FAIL,
    FETCH_CHANGE_ADVERTISEMENTS_DATA,
    FETCH_CHANGE_ADVERTISEMENTS_DATA_SUCCESS,
    FETCH_CHANGE_ADVERTISEMENTS_DATA_FAIL,
    FETCH_CHANGE_APPROVE,
    FETCH_CHANGE_REJECTION_REASON,
    FETCH_CHANGE_CHECKED_ALL,
    FETCH_SAVE_AND_MORE,
    FETCH_WAITING_SAVE_AND_MORE,
    FETCH_CHANGE_STAR
} from '../actionTypes';

export const fetchChangeStar = (data) => ({
    type: FETCH_CHANGE_STAR,
    payload: data
}) 

export const fetchWaitingSaveAndMore = (data) => ({
    type: FETCH_WAITING_SAVE_AND_MORE,
    payload: data
})

export const fetchSaveAndMore = (data)  => ({
    type: FETCH_SAVE_AND_MORE,
    payload: data
})

export const fetchChangeCheckedAll = (data) => ({
    type: FETCH_CHANGE_CHECKED_ALL,
    payload: data
})

export const fetchChangeRejectionReason = (data) => ({
    type: FETCH_CHANGE_REJECTION_REASON,
    payload: data
})

export const fetchChangeApprove = (data) => ({
    type: FETCH_CHANGE_APPROVE,
    payload: data
})
export const fetchChangeAdvertisementsData = (data) => ({
    type: FETCH_CHANGE_ADVERTISEMENTS_DATA,
    payload: data
})

export const fetchChangeAdvertisementsDataSuccess = (data) => ({
    type: FETCH_CHANGE_ADVERTISEMENTS_DATA_SUCCESS,
    payload: data
})

export const fetchChangeAdvertisementsDataFail = () => ({
    type: FETCH_CHANGE_ADVERTISEMENTS_DATA_FAIL,
    success: false,
})

export const fetchAdvertisementsData = (data) => ({
    type: FETCH_ADVERTISEMENTS_DATA,
    payload: data
})

export const fetchAdvertisementsDataSuccess = (data) => ({
    type: FETCH_ADVERTISEMENTS_DATA_SUCCESS,
    payload: data
})

export const fetchAdvertisementsDataFail = () => ({
    type: FETCH_ADVERTISEMENTS_DATA_FAIL,
    success: false,
})