import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ChartistGraph from "react-chartist";
import CardHeader from "components/Card/CardHeader.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import {
    pieChart
  } from "variables/charts.jsx";

function CampaignsStatus({ ...props }) {
    const {
        classes,
        data,
        tags
      } = props;
    let totalCampaign = 
    data.campaignsOnGoingCount +
    data.campaignsStoppedCount +
    data.campaignsFinishedCount +
    data.campaignsWaitingForPaymentCount +
    data.campaignsWaitingForApprovalCount +
    data.campaignsDraftCount;

    let percentajes = {
        campaignsOnGoingCount: 100 / totalCampaign * data.campaignsOnGoingCount,
        campaignsStoppedCount: 100 / totalCampaign * data.campaignsStoppedCount,
        campaignsFinishedCount: 100 / totalCampaign * data.campaignsFinishedCount,
        campaignsWaitingForPaymentCount: 100 / totalCampaign * data.campaignsWaitingForPaymentCount,
        campaignsWaitingForApprovalCount: 100 / totalCampaign * data.campaignsWaitingForApprovalCount,
        campaignsDraftCount: 100 / totalCampaign * data.campaignsDraftCount
    }
    const campaignStatus = {
        data: {
            labels: [ 
                    `${percentajes.campaignsOnGoingCount.toFixed(0)}%`,
                    `${percentajes.campaignsStoppedCount.toFixed(0)}%`, 
                    `${percentajes.campaignsFinishedCount.toFixed(0)}%`,
                    `${percentajes.campaignsWaitingForApprovalCount.toFixed(0)}%`,
                    `${percentajes.campaignsWaitingForPaymentCount.toFixed(0)}%`,
                    `${percentajes.campaignsDraftCount.toFixed(0)}%`
                    ],
            series: [
                    percentajes.campaignsOnGoingCount.toFixed(0),
                    percentajes.campaignsStoppedCount.toFixed(0),
                    percentajes.campaignsFinishedCount.toFixed(0),
                    percentajes.campaignsWaitingForApprovalCount.toFixed(0),
                    percentajes.campaignsWaitingForPaymentCount.toFixed(0),
                    percentajes.campaignsDraftCount.toFixed(0)
                    ]
        }
    }

    return (
        <Card>
                <CardHeader color="danger" icon>
                <h6 className={classes.cardIconTitle}>{tags.campaignTotal}: {totalCampaign} </h6>
                </CardHeader>
                <CardBody minWidth200={true}>
                {
                    totalCampaign === 0 ? null :
                    <ChartistGraph
                        data={ campaignStatus.data }
                        type="Pie"
                        options={pieChart.optionsMin}
                    />
                }               
                {` `}
                <Tooltip
                        id="tooltip-top"
                        title={`${tags.onGoing}: ${data.campaignsOnGoingCount}`} 
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorOnGoing}>{` `}</i>
                </Tooltip>
                {` `}
                <Tooltip
                        id="tooltip-top"
                        title={`${tags.stoped}: ${data.campaignsStoppedCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.danger}>{` `}</i>
                </Tooltip>
                {` `}
                <Tooltip
                        id="tooltip-top"
                        title={`${tags.finished}: ${data.campaignsFinishedCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorFinished}>{` `}</i>
                </Tooltip>
                {` `}
                <Tooltip
                        id="tooltip-top"
                        title={`${tags.waitingForApproval}: ${data.campaignsWaitingForApprovalCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorWaitingForApproval}>{` `}</i>
                </Tooltip>
                {` `}
                <Tooltip
                        id="tooltip-top"
                        title={`${tags.waitingForPayment}: ${data.campaignsWaitingForPaymentCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorWaitingForPayment}>{` `}</i>
                </Tooltip>
                {` `}
                <Tooltip
                        id="tooltip-top"
                        title={`${tags.draft}: ${data.campaignsDraftCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorIsDraft}>{` `}</i>
                </Tooltip>
                </CardBody>
            </Card>
    )
}

export default CampaignsStatus;