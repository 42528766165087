var query = {
    allCompanies: `
        query companies($limit: Float
            $page: Float
            $_id: ID
            $name: String) {
            companies(limit: $limit
                page: $page
                _id: $_id
                name: $name) {
                _id,
                name,
                logo,
                campaignsOnGoingCount,
                campaignsStoppedCount,
                campaignsFinishedCount,
                campaignsWaitingForPaymentCount,
                campaignsWaitingForApprovalCount,
                campaignsDraftCount,
                investment,
                budgetSpent,
                budgetFreezed,
                budgetAvailable,
                _campaignsActives (limit:3){
                  name,
                  moneyPerEngagementReal,
                  moneyPerEngagementExpected,
                  engagementReal,
                  engagementExpected,
                  engagementVelocityReal,
                  engagementVelocityExpected,
                  forecastDays,
                  investment,
                  budgetSpent,
                  budgetFreezed,
                  budgetAvailable,
                }
            }
        }
        `,
    allCompaniesName: `
        query companies($name: String) {
            companies(name: $name) {
                name
                }
            }
        `
}

var mutation = {

}

export default {
    query,
    mutation
};