import React from "react";
import CampaignsStatus from '../graphics/campaignsStatus';
import EngagmentStatusMoney from '../graphics/engagmentStatusMoney';
import EngagmentStatus from '../graphics/engagmentStatus';
import InvestmentStatus from '../graphics/investmentStatus';
import EngagmentVelocityStatus from '../graphics/EngagementVelocityStatus';
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { NavLink } from "react-router-dom";

function Row(classes, data, tags) {
    return (
            data.campaignsOnGoingCount === 0 &&
            data.campaignsStoppedCount === 0 &&
            data.campaignsFinishedCount === 0 &&
            data.campaignsWaitingForPaymentCount === 0 &&
            data.campaignsWaitingForApprovalCount === 0 &&
            data.campaignsDraftCount === 0 ? 
            [   
                <CardBody minWidth200={true}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={`thumbnail ${classes.width70percentage}`}>
                        {
                            data.logo.length !== 0 ?
                            <NavLink to={{pathname:"/panel/Campaigns",state:{companyId: data._id, companyName: data.name}}}>
                                <img  src={data.logo} alt="..." className={classes.img} />
                            </NavLink>
                            :
                            <NavLink to={{pathname:"/panel/Campaigns",state:{companyId: data._id, companyName: data.name}}}>
                                {data.name} sin logo 
                            </NavLink>
                        }
                            
                        </div>
                        <NavLink to={{pathname:"/panel/Campaigns",state:{companyId: data._id, companyName: data.name}}}>
                            {data.name} 
                        </NavLink>
                    </GridItem>
                    </GridContainer>
                 </CardBody>
                ,
                <CardBody minWidth200={true}></CardBody>,
                // <div className={classes.Without}>,
                //     <small className={classes.cardTitle}>
                //         {tags.withoutCampaignsCreated}
                //     </small>
                // </div>,
                <CardBody minWidth200={true}></CardBody>,
                <CardBody minWidth200={true}></CardBody>,
                <CardBody minWidth200={true}></CardBody>
            ]
            : 
            [
                <CardBody minWidth200={true}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={`thumbnail ${classes.width70percentage}`}>
                        {
                            data.logo.length !== 0 ?
                            <NavLink to={{pathname:"/panel/Campaigns",state:{companyId: data._id, companyName: data.name}}}>
                                <img  src={data.logo} alt="..." className={classes.img} />
                            </NavLink>
                            :
                            <NavLink to={{pathname:"/panel/Campaigns",state:{companyId: data._id, companyName: data.name}}}>
                                {data.name} sin logo 
                            </NavLink>
                        }
                            
                        </div>
                        <NavLink to={{pathname:"/panel/Campaigns",state:{companyId: data._id, companyName: data.name}}}>
                            {data.name} 
                        </NavLink>
                    </GridItem>
                    </GridContainer>
                 </CardBody>
                ,
                <CampaignsStatus classes={classes} data={data} tags={tags}/>,
                // <EngagmentStatusMoney classes={classes} data={data} tags={tags}/>,
                <EngagmentStatus classes={classes} data={data} tags={tags}/>,
                <InvestmentStatus classes={classes} data={data} tags={tags}/>,
                <EngagmentVelocityStatus classes={classes} data={data} tags={tags}/>
            ]
      )
}

export default Row;