import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({
    sponsorshipCampaign,
    sponsorshipCampaignDescription1,
    sponsorshipCampaignDescription2,
    sponsorshipCampaignCounter,
    spotCampaign,
    spotDescription1,
    spotDescription2,
    spotCounter,
    advertisingCampaign,
    advertisingDescription1,
    advertisingDescription2,
    advertisingCounter,
    viewVideo,
    chooseCampaignType,
    campaignType,
    sponsorship,
    spot,
    advertising,
    adsIR,
    dateFrom,
    dateTo,
    defineShortDescription,
    whoAndHow,
    how,
    howDoYouGetThem,
    addressed,
    byCriteria,
    toPeople,
    toHisFollowers,
    makingThemIR,
    makingThemID,
    youEntered,
    loadYourSpot,
    defineSpot,
    basicInformation,
    required,
    whatAreYouDoing,
    code,
    develop,
    chooseCity,
    country,
    satelliteMap,
    regularMap,
    customAndSettings,
    regionForYourCampaign,
    region,
    city,
    action,
    selectCity,
    selectRegion,
    argentina,
    add,
    state, 
    selectState,
    frame,
    tagMentionMe,
    tagMentionPeople,
    tagsproductUsage,
    productUsageOficial,
    seal,
    hashtag,
    webSite,
    resourcesOfAdvertisement,
    ads,
    enabled,
    disabled,
    freezed,
    description,
    completeInstructions,
    from,
    to,
    ir,
    adPrice,
    freezedBudget,
    totalPrice,
    epa,
    total,
    age,
    years,
    engagementOfAdvertisement,
    costPerAd,
    costReference,
    target,
    campaigns,
    nameOfNewCampaign,
    productServiceDescrption,
    categoryOfInfluencers,
    genre,
    male,
    female,
    assessment,
    values,
    influencersScope,
    engagmentScope,
    amountUsd,
    properties,
    searchPeople,
    searchPerson,
    save,
    next,
    previous,
    requiredName,
    requiredAdsIR,
    requiredInvestment,
    requiredPaymentPerEach,
    equiredProductPaymentDescription,
    requiredRegion,
    changeDate,
    changeWeb,
    changeMention,
    customAdPrice,
    newAdPrice,
    multiplierAd





}) => ({tags:{
    sponsorshipCampaign,
    sponsorshipCampaignDescription1,
    sponsorshipCampaignDescription2,
    sponsorshipCampaignCounter,
    spotCampaign,
    spotDescription1,
    spotDescription2,
    spotCounter,
    advertisingCampaign,
    advertisingDescription1,
    advertisingDescription2,
    advertisingCounter,
    viewVideo,
    chooseCampaignType,
    campaignType,
    sponsorship,
    spot,
    advertising,
    adsIR,
    dateFrom,
    dateTo,
    defineShortDescription,
    whoAndHow,
    how,
    howDoYouGetThem,
    addressed,
    byCriteria,
    toPeople,
    toHisFollowers,
    makingThemIR,
    makingThemID,
    youEntered,
    loadYourSpot,
    defineSpot,
    basicInformation,
    required,
    whatAreYouDoing,
    code,
    develop,
    chooseCity,
    country,
    satelliteMap,
    regularMap,
    customAndSettings,
    regionForYourCampaign,
    region,
    city,
    action,
    selectCity,
    selectRegion,
    argentina,
    add,
    state, 
    selectState,
    frame,
    tagMentionMe,
    tagMentionPeople,
    tagsproductUsage,
    productUsageOficial,
    seal,
    hashtag,
    webSite,
    resourcesOfAdvertisement,
    ads,
    enabled,
    disabled,
    freezed,
    description,
    completeInstructions,
    from,
    to,
    ir,
    adPrice,
    freezedBudget,
    totalPrice,
    epa,
    total,
    age,
    years,
    engagementOfAdvertisement,
    costPerAd,
    costReference,
    target,
    campaigns,
    nameOfNewCampaign,
    productServiceDescrption,
    categoryOfInfluencers,
    genre,
    male,
    female,
    assessment,
    values,
    influencersScope,
    engagmentScope,
    amountUsd,
    searchPeople,
    searchPerson,
    save,
    next,
    previous,
    properties,
    requiredName,
    requiredAdsIR,
    requiredInvestment,
    requiredPaymentPerEach,
    equiredProductPaymentDescription,
    requiredRegion,
    changeDate,
    changeWeb,
    changeMention,
    customAdPrice,
    newAdPrice,
    multiplierAd
    }});   

export default translatable(mapTranslationsToProps);