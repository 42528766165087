import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "../tags";
import styles from "../styleIndex";
import { 
  fetchChangeBrief, 
  fetchAddRegion, 
  fetchRemoveRegion, 
  fetchUpdateCampaign, 
  fetchChangeStartDt, 
  fetchChangeEndDt,
  fetchChangeRegion,
  fetchChangeSpecificTarget,
  fetchChangeType,
  fetchChangeInfluencePeopleAs,
  fetchChangeFollowers,
  fetchChangeAdsPerIR,
  fetchChangeSpoteffectivenesses,
  fetchChangeSponsorshipeffectivenesses,
  fetchChangeAdvertisingEffectivenesses,
  fetcUpdatePeople, 
  fetchChangeVirtual,
  fetchChangeCellPhone
} from "../../../../state/actions/campaignCreation";
import { fetchSubscriptionRecalculating } from "../../../../state/actions/campaignCreationSubscription";
import { fetchCitiesData, fetchStatesData, fetchCountriesData, fetchRegionSelection, fetchRegionSelectionForDB } from "../../../../state/actions/regions";
import { initMapData, mapDataDB, argMapData } from "../mapCoordinates";
import RegionForYourCampaign from "../ComponentsSteps/step1/regionForYourCampaign";
import { fetchSponsorshipeffectivenessesData } from "../../../../state/actions/sponsorshipeffectivenesses";
import { fetchSpoteffectivenessesData } from "../../../../state/actions/spoteffectivenesses";
import { fetchAdvertisingEffectivenessesData } from "../../../../state/actions/advertisingEffectivenesses";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Assignment from "@material-ui/icons/Assignment";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import moment from 'moment';
import Datetime from "react-datetime";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from "@material-ui/core/FormControlLabel";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    let varInitMapData = initMapData;
    var varMapDataDB = mapDataDB;
    
    this.props.campaignCreation.regionCriterias.forEach(element => {
      if(element.state === null) varInitMapData = argMapData;
      else varInitMapData = {...varInitMapData,[varMapDataDB[element.state]]: 300}
    })

    this.state = {
      regionChecked: true,
      colorAdd: "",
      locations: this.props.campaignCreation.regionCriterias,
      mapData: varInitMapData,
    };
    this.handleWidgets= this.handleWidgets.bind(this);
    this.handleChange= this.handleChange.bind(this);
    this.handlerAddLocation= this.handlerAddLocation.bind(this);
    this.handlerRemoveLocation = this.handlerRemoveLocation.bind(this);
    this.handleChangeStartDt = this.handleChangeStartDt.bind(this);
    this.handleChangeBrief = this.handleChangeBrief.bind(this);
    this.handleType = this.handleType.bind(this);
    this.handleChangeAdsPerIR = this.handleChangeAdsPerIR.bind(this);
    this.addSpot = this.addSpot.bind(this);
    this.addSponsorship = this.addSponsorship.bind(this);
    this.addAdvertising = this.addAdvertising.bind(this);
    this.handleFollowers = this.handleFollowers.bind(this);
    this.removeAll = this.removeAll.bind(this);
    this.validStartDt = this.validStartDt.bind(this);
    this.validEndDt = this.validEndDt.bind(this);
}

shouldComponentUpdate(nextProps, nextState) {
  //#region  Brief
  if(this.props.campaignCreation.brief !== nextProps.campaignCreation.brief) {
    return false;
  }
  //#endregion

  //#region Region
  if(this.props.campaignCreation.regionChecked !== this.state.regionChecked || nextState.regionChecked !== this.state.regionChecked) {
    this.setState({
      regionChecked: this.props.campaignCreation.regionChecked
    });
  }

  if(this.props.campaignCreation.regionCriterias.length !== nextProps.campaignCreation.regionCriterias.length) {
      this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
        regionCriterias: nextProps.campaignCreation.regionCriterias
      });
  }

  if(this.props.campaignCreation.regionCriterias.length !== nextProps.campaignCreation.regionCriterias.length) {
    this.setState({
      locations: nextProps.campaignCreation.regionCriterias,
      regionChecked: this.props.campaignCreation.regionChecked
    });
    let varInitMapData = initMapData;
    var varMapDataDB = mapDataDB;
    nextProps.campaignCreation.regionCriterias.forEach(element => {
      if(element.state === null) varInitMapData = argMapData;
      else varInitMapData = {...varInitMapData,[varMapDataDB[element.state]]: 300}
    })
    this.setState({
      mapData: varInitMapData
    });
  }
  //#endregion

  //a revisar
  //#region SpecificTarget
  if(this.props.campaignCreation.specificTarget !== nextProps.campaignCreation.specificTarget &&
    nextProps.campaignCreation.specificTarget === true) {
    this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id,
      sponsorshipEffectivenessCriterias: [],
      spotEffectivenessCriterias: [],
      advertisingEffectivenessCriterias: [],
      categoryCriterias: [],
      regionCriterias: [],
      sexCriterias: [],
      platformStarCriterias: [],
      customerStarCriterias: [],
      ageCriterias: [],
      type: nextProps.campaignCreation.type,
      followers: nextProps.campaignCreation.followers,
      specificTarget: nextProps.campaignCreation.specificTarget
    });
  }
  //#endregion

  //#region influencePeopleAs
  if(this.props.campaignCreation.influencePeopleAs !== nextProps.campaignCreation.influencePeopleAs) {
    this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id,
      influencePeopleAs: nextProps.campaignCreation.influencePeopleAs,
      type: nextProps.campaignCreation.type,
      followers: nextProps.campaignCreation.followers,
      specificTarget: nextProps.campaignCreation.specificTarget
    });
  }
  //#endregion
  
  //#region spotEffectivenessCriterias
  if(this.props.campaignCreation.spotEffectivenessCriterias.length !== nextProps.campaignCreation.spotEffectivenessCriterias.length) {
    this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
      spotEffectivenessCriterias: nextProps.campaignCreation.spotEffectivenessCriterias,
      sponsorshipEffectivenessCriterias: [],
      advertisingEffectivenessCriterias: [],
      type: nextProps.campaignCreation.type,
      followers: nextProps.campaignCreation.followers,
      specificTarget: nextProps.campaignCreation.specificTarget
    });
  }
  //endregion
  //#region sponsorshipEffectivenessCriterias
  else if(this.props.campaignCreation.sponsorshipEffectivenessCriterias.length !== nextProps.campaignCreation.sponsorshipEffectivenessCriterias.length) {
    this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
      sponsorshipEffectivenessCriterias: nextProps.campaignCreation.sponsorshipEffectivenessCriterias,
      spotEffectivenessCriterias: [],
      advertisingEffectivenessCriterias: [],
      type: nextProps.campaignCreation.type,
      followers: nextProps.campaignCreation.followers,
      specificTarget: nextProps.campaignCreation.specificTarget
    });
  }
  //#endregion

  //#region advertisingEffectivenessCriterias
  else if(this.props.campaignCreation.advertisingEffectivenessCriterias.length !== nextProps.campaignCreation.advertisingEffectivenessCriterias.length) {
    this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
      advertisingEffectivenessCriterias: nextProps.campaignCreation.advertisingEffectivenessCriterias,
      sponsorshipEffectivenessCriterias: [],
      spotEffectivenessCriterias: [],
      type: nextProps.campaignCreation.type,
      followers: nextProps.campaignCreation.followers,
      specificTarget: nextProps.campaignCreation.specificTarget
    });
  }
  //#endregion

  return true;
}

validStartDt (current) {
  var yesterday = Datetime.moment().subtract( 1, 'day' );
  return current.isAfter( yesterday );
}

validEndDt (current) {
  return current.isAfter(this.props.campaignCreation.startDt);
}

handleWidgets(value) {
  if (this.props.campaignCreation.enabledEdit){
    switch (value) {
      case "region":
        if ( this.props.campaignCreation.regionChecked === true) {
          this.props.fetchChangeRegion({items:[], checked: false});
          this.setState({
            regionChecked: false
          });
        } else {
          this.props.fetchChangeRegion({items:this.props.campaignCreation.regionCriteriasReadonly, checked: true});
          this.setState({
            regionChecked: true
          });
        }
      break;
      default:
        break;
    }
  }
}
  
sendState() {
    return this.state;
}

isValidated() {
  return true;
}

handleChangeBrief (event) {
  if (this.props.campaignCreation.enabledEdit) {
    event.preventDefault();
    this.props.fetchChangeBrief(event.target.value);
  }
}

handleChangeStartDt (event) {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeStartDt(event);
  }
}

handleChangeEndDt (event) {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeEndDt(event);
  }
}

handleChange = name => value => {
  if (this.props.campaignCreation.enabledEdit) {
    if (value.length === 0 && name === "country") {
      this.setState({ colorAdd: "" });
      this.props.fetchRegionSelectionForDB(null);
      this.props.fetchRegionSelection({name:name, item:null, delete: true});
    } 
    else if (value.length === 0 && name === "state") {
      this.setState({ colorAdd: "rose" });
      let itemDB = this.props.regions.countries.filter(item => item._id === this.props.regions.country.value);
      this.props.fetchRegionSelectionForDB(itemDB);
      this.props.fetchRegionSelection({name:name, item: this.props.regions.country, delete: true});
    } 
    else if (value.length === 0 && name === "city") {
      this.setState({ colorAdd: "rose" });
      let itemDB = this.props.regions.states.filter(item => item._id === this.props.regions.state.value);
      this.props.fetchRegionSelectionForDB(itemDB);
    } 
    else {
      let itemDB = null;
      switch (name) {
        case "country":
          itemDB = this.props.regions.countries.filter(item => item._id === value.value);
          this.props.fetchRegionSelectionForDB(itemDB);
          break;
        case "state":
          itemDB = this.props.regions.states.filter(item => item._id === value.value);
          this.props.fetchRegionSelectionForDB(itemDB);
          break;
        case "city":
          itemDB = this.props.regions.cities.filter(item => item._id === value.value);
          this.props.fetchRegionSelectionForDB(itemDB);
          break;
        default:
          break;
      }

      this.props.fetchRegionSelection({name:name, item : value, delete: false});
      this.setState({ colorAdd: "rose" });
    }

    if (name === "country") this.props.fetchStatesData({ limit: 1000, orderBy:'city', asc: true, country: value.label,city: null});
    else if (name === "state") this.props.fetchCitiesData({ limit: 1000, orderBy:'state', asc: true, state: value.label});
  }
};

handlerAddLocation() {
  if (this.props.campaignCreation.enabledEdit) {
    let item = this.state.locations.find(c => c._id === this.props.regions.regionSelectionForDB._id);
    if(item) return false;

    this.props.fetchCountriesData({country: "Argentina",state: null,city: null});
    this.setState({ colorAdd: "" });
    this.props.fetchRegionSelection({name:'country', item:null, delete: true});
    let stateSelected = null;
    if (this.props.regions.city !== null) stateSelected = this.props.regions.state.label;
    else if (this.props.regions.state !== null) stateSelected = this.props.regions.state.label;
    else stateSelected = "all";

    if (stateSelected === "all") {
      this.setState({
        locations: [...this.state.locations, this.props.regions.regionSelectionForDB],
        mapData: argMapData,
        colorAdd: ""
      });
    } else {
      this.setState({
        locations: [...this.state.locations, this.props.regions.regionSelectionForDB],
        mapData: {[mapDataDB[stateSelected]]: 300},
        colorAdd: ""
      });
    }

    this.props.fetchAddRegion(this.props.regions.regionSelectionForDB);
    
    this.props.fetchSubscriptionRecalculating();
  }
}

handlerRemoveLocation = (key, regionValue) => {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchRemoveRegion(key);
    if (regionValue === "Argentina") {
      var varInitMapData = initMapData;
      var varMapDataDB = mapDataDB;
  
      this.setState({
        locations: this.state.locations.reduce((location, item) => {
          if (item._id !== key)
            location.push(item);
          return location;
          }, []),
        mapData: varInitMapData
      });

      this.state.locations.forEach(element => {
        varInitMapData = {...varInitMapData,[varMapDataDB[element.state]]: 300}
      })
    }
    else
    {
      this.setState({
        locations: this.state.locations.reduce((location, item) => {
          if (item._id !== key)
            location.push(item);
          return location;
          }, []),
        mapData: {[mapDataDB[regionValue]]: 10040}
      });
    }

    this.props.fetchSubscriptionRecalculating();
  }
}

handleType = event => {
  if (this.props.campaignCreation.enabledEdit) {
    if([event.target.name] == "specificTarget") {
      this.props.fetchChangeSpecificTarget(event.target.value);
      if (event.target.value) {
        this.removeAll();
      }
      else {
        this.props.fetchChangeFollowers(false);

        switch (this.props.campaignCreation.type) { 
          case "Spot":
              this.addSpot();
              this.props.fetcUpdatePeople([]);
          break;
          case "Sponsorship":
            this.addSponsorship();
            this.props.fetcUpdatePeople([]);
          break;
          case "Advertising":
            this.addAdvertising();
            this.props.fetcUpdatePeople([]);
          break;
          default:
            break;
        }

      } 
    } 
    else if([event.target.name] == "type") {
      let scopeAll;
      switch (event.target.value) {
        case "Spot": 
            scopeAll = "spotEffectivenessScopeAll";
            if (this.props.campaignCreation.specificTarget 
              && this.props.campaignCreation.followers) this.addSpot();
            else if (!this.props.campaignCreation.specificTarget) this.addSpot();
        break;
        case "Sponsorship":
          scopeAll = "sponsorshipEffectivenessScopeAll";
          if (this.props.campaignCreation.specificTarget 
            && this.props.campaignCreation.followers) this.addSponsorship();
          else if (!this.props.campaignCreation.specificTarget) this.addSponsorship();
        break;
        case "Advertising":
          scopeAll = "advertisingEffectivenessScopeAll";
          if (this.props.campaignCreation.specificTarget 
            && this.props.campaignCreation.followers) this.addAdvertising();
          else if (!this.props.campaignCreation.specificTarget) this.addAdvertising();
        break;
        default:
          break;
      }

      this.props.fetchChangeType({_id: this.props.campaignCreation._id, 
        type: event.target.value,
        "spotEffectivenessScopeAll": true,
        "sponsorshipEffectivenessScopeAll": true,
        "advertisingEffectivenessScopeAll": true});
    } 
    else if([event.target.name] == "influencePeopleAs") this.props.fetchChangeInfluencePeopleAs(event.target.value);
  
    this.props.fetchSubscriptionRecalculating();
  }
}

handleFollowers () {
  if (this.props.campaignCreation.enabledEdit) {
    if(this.props.campaignCreation.followers) {
      this.props.fetchChangeFollowers(false);

      if(this.props.campaignCreation.specificTarget) this.removeAll();
    } 
    else {
      this.props.fetchChangeFollowers(true);
      switch (this.props.campaignCreation.type) {
        case "Spot":
          this.addSpot();
          break;
        case "Sponsorship":
          this.addSponsorship();
          break;
        case "Advertising":
          this.addAdvertising();
        break;
        default:
          break;
      }
    }

    this.props.fetchSubscriptionRecalculating();
  }
}

removeAll() {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeSpoteffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
    this.props.fetchChangeSponsorshipeffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
    this.props.fetchChangeAdvertisingEffectivenesses({from: 0, to: 0, items:[], checked: false, changeSlide: true});
    
    this.props.fetchSubscriptionRecalculating();
  }
}

addSpot () {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeSpoteffectivenesses(
      {from: this.props.spoteffectivenesses.spotEffectivenessRead[0].idSlide, 
      to: this.props.spoteffectivenesses.spotEffectivenessRead[this.props.spoteffectivenesses.spotEffectivenessRead.length-1].idSlide,
      items: this.props.spoteffectivenesses.spotEffectivenessRead, 
      checked: true, 
      changeSlide: true}
      );
    this.props.fetchChangeSponsorshipeffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
    this.props.fetchChangeAdvertisingEffectivenesses({from: 0, to: 0, items:[], checked: false, changeSlide: true});

    this.props.fetchSubscriptionRecalculating();
  }
}

addSponsorship() {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeSponsorshipeffectivenesses(
      {from: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead[0].idSlide, 
        to: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead[this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead.length-1].idSlide,
        items: this.props.sponsorshipeffectivenesses.sponsorshipeffectivenessesRead, 
        checked: true, 
        changeSlide: true}
      );
    this.props.fetchChangeSpoteffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
    this.props.fetchChangeAdvertisingEffectivenesses({from: 0, to: 0, items:[], checked: false, changeSlide: true});
    
    this.props.fetchSubscriptionRecalculating();
  }
}

addAdvertising() {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeAdvertisingEffectivenesses(
    {from: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead[0].idSlide, 
      to: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead[this.props.advertisingEffectivenesses.advertisingEffectivenessesRead.length-1].idSlide,
      items: this.props.advertisingEffectivenesses.advertisingEffectivenessesRead, 
      checked: true, 
      changeSlide: true}
    );
    this.props.fetchChangeSpoteffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});
    this.props.fetchChangeSponsorshipeffectivenesses({from: 0, to: 0,items:[], checked: false, changeSlide: true});

    this.props.fetchSubscriptionRecalculating();
  }
}

handleChangeAdsPerIR (event) {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeAdsPerIR(parseFloat(event.target.value));
  }
}

handleVirtual (value) {
   if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeVirtual(!value);
    value && this.props.fetchChangeCellPhone(null);
  }
}

handleChangeCellPhone (value) {
  if (this.props.campaignCreation.enabledEdit) {
    this.props.fetchChangeCellPhone(value);
  }
}

render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Card>
            <CardHeader color="rose" icon>
              <GridContainer justify="space-between">
                              <GridItem xs={12} sm={12}>
                                <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={2}>
                                    <CardIcon color="info">
                                      <Assignment />
                                    </CardIcon>
                                  </GridItem>
                                  <GridItem xs={12} sm={10} className={classes.alignCenter}>
                                    <h4 className={classes.cardIconTitle}>
                                      {this.props.tags.chooseCampaignType}
                                    </h4>
                                  </GridItem>
                                </GridContainer>
                                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                  <GridItem xs={12} sm={3}>
                    {
                      this.props.campaignCreation.enabledEdit ?
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                        {this.props.tags.campaignType}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.props.campaignCreation.type}
                          onChange={this.handleType}
                          inputProps={{
                            name: "type",
                            id: "type"
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                          {this.props.tags.campaignType}
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Sponsorship"
                          >
                          {this.props.tags.sponsorship}
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Spot"
                          >
                          {this.props.tags.spot}
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="Advertising"
                          >
                          {this.props.tags.advertising}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    :
                      <TextField
                        disabled={true}
                        id="outlined-number2"
                        label={"campaign Type"}
                        defaultValue={this.props.campaignCreation.type}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />      
                    }
                    
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                      <TextField
                        disabled={
                          this.props.campaignCreation.enabledEdit ? false : true
                        }
                        id="outlined-number"
                        label={this.props.tags.adsIR}
                        defaultValue={this.props.campaignCreation.adsPerIR}
                        onChange={this.handleChangeAdsPerIR}
                        type="number"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                      
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <InputLabel className={classes.label}>
                              {this.props.tags.dateFrom}
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect={true}
                        input={true}
                        isValidDate={ this.validStartDt }
                        viewDate={moment(this.props.campaignCreation.startDt)}
                        defaultValue={moment(this.props.campaignCreation.startDt)}
                        onChange= {event => this.handleChangeStartDt(event)}
                        inputProps={{
                          disabled: this.props.campaignCreation.enabledEdit ? false : true
                        }}
                      />  
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={3}>                                      
                    <InputLabel className={classes.label}>
                              {this.props.tags.dateTo}
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              input={true}
                              isValidDate={ this.validEndDt }
                              viewDate={moment(this.props.campaignCreation.endDt)}
                              defaultValue={moment(this.props.campaignCreation.endDt)}
                              inputProps={{ placeholder: "Seleccione fecha y hora." }}
                              onChange= {event => this.handleChangeEndDt(event)}
                              //inputProps={{ disabled: this.props.campaignCreation.enabledEdit ? false : true }}
                              disabled= {this.props.campaignCreation.enabledEdit ? false : true }
                            />
                    </FormControl>                                
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                      <FormControlLabel
                                disabled={this.props.campaignCreation.enabledEdit ? false :  true }
                                control={
                                  <Checkbox
                                    disabled= {this.props.campaignCreation.enabledEdit ? false : true }
                                    tabIndex={1}
                                    checked={this.props.campaignCreation.virtual}
                                    onClick={() => this.handleVirtual(this.props.campaignCreation.virtual)}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                label= {"campaña virtual"}
                          />    
                    
                  </GridItem> 
                  <GridItem xs={12} sm={3}>
                    {
                      this.props.campaignCreation.virtual && 
                      <TextField
                            disabled={this.props.campaignCreation.enabledEdit ? false : true }
                            id="standard-multiline-flexible"
                            label={"Celular"}
                            fullWidth={true}
                            defaultValue={this.props.campaignCreation.whatsappNumber}
                            onChange={(e) => this.handleChangeCellPhone(e.target.value)}
                            className={classes.textField}
                            margin="normal"
                          />
                    }
                    
                  </GridItem> 
                  <GridItem xs={12} sm={3}>
                    </GridItem>     
                    <GridItem xs={12} sm={3}>
                    </GridItem>                                  
                  {/* {
                      this.props.campaignCreation.type === "Advertising" ? */}
                      <GridItem xs={12} sm={12}>
                          <TextField
                            disabled={
                              this.props.campaignCreation.enabledEdit ? false : true
                            }
                            id="standard-multiline-flexible"
                            label={this.props.tags.defineShortDescription}
                            multiline
                            rowsMax="6"
                            fullWidth={true}
                            defaultValue={this.props.campaignCreation.brief}
                            onChange={this.handleChangeBrief}
                            className={classes.textField}
                            margin="normal"
                          />
                        </GridItem>
                  {/* //       :
                  //       null
                  // } */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} lg={4}>
                <Card>
                  <CardHeader color="rose" icon>
                    <GridContainer justify="space-between">
                      <GridItem xs={12} sm={12}>
                      <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12} className={classes.alignCenter}>
                          <CardIcon color="info">
                              <Assignment />
                            </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            {this.props.tags.whoAndHow}
                          </h4>
                        </GridItem>
                      </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody>
                    {
                      this.props.campaignCreation.specificTarget === true ?
                        <GridContainer justify="center"> 
                            <GridItem xs={12} sm={6}>
                            {
                              this.props.campaignCreation.enabledEdit ?
                                <FormControl fullWidth className={classes.selectFormControl}>
                                                            <InputLabel
                                                              htmlFor="simple-select"
                                                              className={classes.selectLabel}
                                                            >
                                                              {this.props.tags.addressed}
                                                            </InputLabel>
                                                            <Select
                                                              MenuProps={{
                                                                className: classes.selectMenu
                                                              }}
                                                              classes={{
                                                                select: classes.select
                                                              }}
                                                              value={this.props.campaignCreation.specificTarget}
                                                              onChange={this.handleType}
                                                              inputProps={{
                                                                name: "specificTarget",
                                                                id: "specificTarget"
                                                              }}
                                                            >
                                                              <MenuItem
                                                                disabled
                                                                classes={{
                                                                  root: classes.selectMenuItem
                                                                }}
                                                              >
                                                              {this.props.tags.addressed}
                                                              </MenuItem>
                                                              <MenuItem
                                                                classes={{
                                                                  root: classes.selectMenuItem,
                                                                  selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={false}
                                                              >
                                                                {this.props.tags.byCriteria}
                                                              </MenuItem>
                                                              <MenuItem
                                                                classes={{
                                                                  root: classes.selectMenuItem,
                                                                  selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={true}
                                                              >
                                                                {this.props.tags.toPeople}
                                                              </MenuItem>
                                                            </Select>
                                </FormControl>
                              :
                              <TextField
                                disabled={true}
                                id="outlined-number3"
                                label={this.props.tags.addressed}
                                defaultValue={this.props.campaignCreation.specificTarget}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin="normal"
                              />
                            }
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                              <FormControlLabel
                                key={"SeguidoresDe"}
                                control={
                                  <Checkbox
                                    disabled={
                                      this.props.campaignCreation.enabledEdit ? false : true
                                    }
                                    tabIndex={"SeguidoresDe"}
                                    onClick={this.props.campaignCreation.specificTarget === true ? () => this.handleFollowers() : null}
                                    checked={this.props.campaignCreation.followers}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked}}
                                  />
                                }
                                classes={{label: classes.label}}
                                label={this.props.tags.toHisFollowers}
                              />
                            </GridItem>
                        </GridContainer>
                        :
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12}>
                          {
                              this.props.campaignCreation.enabledEdit ?
                            <FormControl fullWidth className={classes.selectFormControl}>
                                <InputLabel
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  {this.props.tags.addressed}
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={this.props.campaignCreation.specificTarget}
                                  onChange={this.handleType}
                                  inputProps={{
                                    name: "specificTarget",
                                    id: "specificTarget"
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                  {this.props.tags.addressed}
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={false}
                                  >
                                    {this.props.tags.byCriteria}
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={true}
                                  >
                                    {this.props.tags.toPeople}
                                  </MenuItem>
                                </Select>
                            </FormControl>
                            :
                            <TextField fullWidth
                              disabled={true}
                              id="outlined-number4"
                              label={this.props.tags.addressed}
                              defaultValue={this.props.campaignCreation.specificTarget}
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                            />
                          }
                          </GridItem>
                        </GridContainer>
                    }
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12}>
                      {
                        this.props.campaignCreation.enabledEdit ?
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                            {this.props.tags.how}?
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.props.campaignCreation.influencePeopleAs}
                              onChange={this.handleType}
                              inputProps={{
                                name: "influencePeopleAs",
                                id: "influencePeopleAs"
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                              {this.props.tags.howDoYouGetThem}?
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={"IR"}
                              >
                                {this.props.tags.makingThemIR} 
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={"ID"}
                              >
                                {this.props.tags.makingThemID} 
                              </MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <TextField fullWidth
                          disabled={true}
                          id="outlined-number5"
                          label={`${this.props.how}?`}
                          defaultValue={this.props.campaignCreation.influencePeopleAs}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                        />
                      }
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
        </GridItem>
        <GridItem xs={12} lg={12}>
          <RegionForYourCampaign
            classes={classes}
            widgets={
              this.state.regionChecked
            }
            handleWidgets={this.handleWidgets}
            handleChange={this.handleChange}
            handlerAddLocation={this.handlerAddLocation}
            handlerRemoveLocation={this.handlerRemoveLocation}
            countries={this.props.campaignCreation.regionChecked ? this.props.regions.countriesRead : []}
            country={this.props.campaignCreation.regionChecked ? this.props.regions.country : null}
            states={this.props.campaignCreation.regionChecked ? this.props.regions.statesRead: []}
            state={this.props.campaignCreation.regionChecked ? this.props.regions.state: null}
            cities={this.props.campaignCreation.regionChecked ? this.props.regions.citiesRead : []}
            city={this.props.campaignCreation.regionChecked ? this.props.regions.city : null}
            locations={this.props.campaignCreation.regionCriteriasReadonly}
            colorAdd={this.state.colorAdd}
            mapData={this.state.mapData}
            display={this.props.campaignCreation.specificTarget === false ? 'block': 'none'}
            {...this.props}
          />
          
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    campaignCreation: state.campaignCreation,
    regions: state.regions,
    spoteffectivenesses: state.spoteffectivenesses,
    sponsorshipeffectivenesses: state.sponsorshipeffectivenesses,
    advertisingEffectivenesses: state.advertisingEffectivenesses
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchChangeBrief: bindActionCreators(fetchChangeBrief, dispatch),
    fetchChangeStartDt: bindActionCreators(fetchChangeStartDt, dispatch),
    fetchChangeEndDt: bindActionCreators(fetchChangeEndDt, dispatch),
    fetchChangeRegion: bindActionCreators(fetchChangeRegion, dispatch),
    fetchAddRegion: bindActionCreators(fetchAddRegion, dispatch),
    fetchUpdateCampaign: bindActionCreators(fetchUpdateCampaign, dispatch),
    fetchRemoveRegion: bindActionCreators(fetchRemoveRegion, dispatch),
    fetchRegionSelection: bindActionCreators(fetchRegionSelection, dispatch),
    fetchRegionSelectionForDB: bindActionCreators(fetchRegionSelectionForDB, dispatch),
    fetchCountriesData: bindActionCreators(fetchCountriesData, dispatch),
    fetchStatesData: bindActionCreators(fetchStatesData, dispatch), 
    fetchCitiesData: bindActionCreators(fetchCitiesData, dispatch),
    fetchChangeSpecificTarget: bindActionCreators(fetchChangeSpecificTarget, dispatch),
    fetchChangeType: bindActionCreators(fetchChangeType, dispatch),
    fetchChangeInfluencePeopleAs: bindActionCreators(fetchChangeInfluencePeopleAs, dispatch),
    fetchChangeFollowers: bindActionCreators(fetchChangeFollowers, dispatch),
    fetchChangeAdsPerIR: bindActionCreators(fetchChangeAdsPerIR, dispatch),
    fetchSponsorshipeffectivenessesData: bindActionCreators(fetchSponsorshipeffectivenessesData, dispatch),
    fetchSpoteffectivenessesData: bindActionCreators(fetchSpoteffectivenessesData, dispatch),
    fetchAdvertisingEffectivenessesData: bindActionCreators(fetchAdvertisingEffectivenessesData, dispatch),
    fetchChangeSpoteffectivenesses: bindActionCreators(fetchChangeSpoteffectivenesses, dispatch),
    fetchChangeSponsorshipeffectivenesses: bindActionCreators(fetchChangeSponsorshipeffectivenesses, dispatch),
    fetchChangeAdvertisingEffectivenesses: bindActionCreators(fetchChangeAdvertisingEffectivenesses, dispatch),
    fetcUpdatePeople: bindActionCreators(fetcUpdatePeople, dispatch),
    fetchSubscriptionRecalculating: bindActionCreators(fetchSubscriptionRecalculating, dispatch),
    fetchChangeVirtual: bindActionCreators(fetchChangeVirtual, dispatch),
    fetchChangeCellPhone: bindActionCreators(fetchChangeCellPhone, dispatch),
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step1)));
