import React from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Store from "@material-ui/icons/Store";
import { NavLink } from "react-router-dom";
import { Facebook } from 'react-content-loader';

const MyFacebookLoader = () => <Facebook />
const createTagMentionMe = (tagMentionMe) => {

    let tagMe = tagMentionMe.facebook.enabled ?
    `Tag Me Facebook: ${tagMentionMe.facebook.value}`
    : `Tag Me Facebook: Disabled`;
    
    tagMe += tagMentionMe.instagram.enabled ?
    ` - Tag Me Instagram: ${tagMentionMe.instagram.value}`
    : `- Tag Me Instagram: Disabled`;
    
    tagMe += tagMentionMe.twitter.enabled ?
    ` - Tag Me Twitter: ${tagMentionMe.twitter.value}`
    : `- Tag Me Twitter: Disabled`;

    return tagMe;
}

export default function Header({ ...props }) {
    const { classes } = props;
    return (
        props.campaignHeaderAudit === null ?
        <Card>
            <CardHeader color="warning" icon>
                {MyFacebookLoader()}
            </CardHeader> 
        </Card>
        : 
        <Card>
            <CardHeader color="warning" icon>
                <CardIcon color="success">
                    <Store />
                  </CardIcon>
              <GridItem xs={12} sm={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={6} className={classes.alignCenter}>
                      <p className={classes.cardCategory}> {props.tags.companyName} </p>
                      <NavLink to={{pathname:"/panel/Campaigns",
                            state: {
                            companyId: props.history.location.state !== undefined ? props.history.location.state.companyId : props.login.user.company.id,
                            companyName: props.history.location.state !== undefined ? props.history.location.state.companyName : props.login.user.company.name
                            }}}>
                        <Tooltip
                            id="tooltip-top"
                            title={props.tags.returnToCampaignList}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                            >
                            <h3 className={classes.cardTitle}>
                                {props.history.location.state.companyName}
                            </h3>
                        </Tooltip>
                      </NavLink>
                      <br/>
                      <p className={classes.cardCategory}>{props.tags.campaignName}</p>
                  <Tooltip
                      id="tooltip-top"
                      title={props.campaignHeaderAudit.name}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                      >
                      <h3 className={classes.cardTitle}>
                          {props.campaignHeaderAudit.name}
                      </h3>
                  </Tooltip>
                        
                          <br/>
                  </GridItem>
                  <GridItem xs={12} sm={6} className={classes.alignCenter}>
                  <p className={classes.cardCategory}>{props.tags.resources}</p>
                            <h3 className={classes.cardTitle}>
                            {
                                props.campaignHeaderAudit.resources.hashtag.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={`${props.tags.hashtag}: ${props.campaignHeaderAudit.resources.hashtag.value}`}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                    <i className="fas fa-hashtag"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.webSite.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={`${props.tags.webSite}: ${props.campaignHeaderAudit.resources.webSite.value}`}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="fas fa-globe"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.tagMentionMe.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={createTagMentionMe(props.campaignHeaderAudit.resources.tagMentionMe)}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="fas fa-at"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.tagMentionPeople.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={props.tags.tagMentionPeopleEnable}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="fas fa-users"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.frame.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={props.tags.frameEnable}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="far fa-image"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.seal.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={`${props.tags.seal}: ${props.campaignHeaderAudit.resources.seal.value}`}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="fas fa-paint-brush"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.productUsage.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={props.tags.productUsageCampaign}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="fab fa-product-hunt"></i>
                                </Tooltip>
                                :
                                null
                            }
                            {
                                props.campaignHeaderAudit.resources.productUsageOficial.enabled ?
                                <Tooltip
                                    id="tooltip-top"
                                    title={props.tags.productUsageCampaign}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                    >
                                     <i className="fas fa-info-circle"></i>
                                </Tooltip>
                                :
                                null
                            }
                          
                          </h3>
                          <br/>
                          <p className={classes.cardCategory}>{`${props.tags.type}: ${props.campaignHeaderAudit.type}`}</p>
                          {
                              props.campaignHeaderAudit.type === "Advertising" ?
                            <p className={classes.cardCategory}>Brief</p>
                            :
                            null
                            }
                          {
                              props.campaignHeaderAudit.type === "Advertising" ?
                              
                              <Tooltip
                                  id="tooltip-top"
                                  title={props.campaignHeaderAudit.brief}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                  >
                                  <h3 className={classes.cardTitle}>
                                      {props.campaignHeaderAudit.brief}
                                  </h3>
                              </Tooltip>
                              :
                              null
                          }
                          
                  </GridItem>
                </GridContainer>
              </GridItem>
            </CardHeader>
          </Card>
    )
}