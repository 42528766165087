import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({
    listOfCompanies,
    campaigns,
    like,
    link,
    tag,
    shared,
    mention,
    hashtag,
    print,
    comment,
    mentionValue,
    insightsByReactions,
    insightsBySocialMedia,
    influencer,
    influencedValue,
    engagement,
    post,
    realVsPromise,
    campaignInvestment,
    lineChart,
    influencers,
    activityOfCampaign,
    womens,
    mens,
    unknown,
    spent,
    freezed,
    availible
}) => ({tags:{
    listOfCompanies,
    campaigns,
    like,
    link,
    tag,
    shared,
    mention,
    hashtag,
    print,
    comment,
    mentionValue,
    insightsByReactions,
    insightsBySocialMedia,
    influencer,
    influencedValue,
    engagement,
    post,
    realVsPromise,
    campaignInvestment,
    lineChart,
    influencers,
    activityOfCampaign,
    womens,
    mens,
    unknown,
    spent,
    freezed,
    availible
    }});

export default translatable(mapTranslationsToProps);