let myJson;
export default {
  getApiReferences: function*() {
    try {
      if (myJson){
        return {
            data: {
              ...myJson
            },
            success: true,
            errors: null
          };
      }
      if (!process.env.REACT_APP_ENVIRONMENT) {
        return {
          data: null,
          success: false,
          errors: new Error("ENVIRONMENT variable not set")
        };
      }

      
      if (process.env.REACT_APP_ENVIRONMENT === "localmachine") {
        return {
          data: {
            api_domain: `${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}`
            // host: process.env.REACT_APP_API_IP,
            // port: process.env.REACT_APP_API_PORT
          },
          success: true,
          errors: null
        };
      } 
    //   else {
    //     return {
    //         data: {
    //             api_domain: `api-${process.env.REACT_APP_ENVIRONMENT.toLocaleLowerCase()}.adme.com.ar`
    //         },
    //         success: true,
    //         errors: null
    //       };

    //   }
      const configDbUrl = `https://adme-277711.firebaseio.com/environments/k8s/adme-${
        process.env.REACT_APP_ENVIRONMENT
      }/adme-api.json`;

      const response = yield fetch(configDbUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });
      myJson = yield response.json();
      return {
        data: {
            api_domain: `${myJson.host}:${myJson.port}`
        },
        success: true,
        errors: null
      };
    } catch (ex) {
      return {
        data: null,
        success: false,
        errors: ex
      };
    }
  }
};
