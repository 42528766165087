const login = `
mutation login(
    $email: String!
    $password: String!
){
    login(
        app: Web
        email: $email
        password: $password
        
  ) {
    data,
    success,
    error {
        path
        message
      }
  }
}
`;

const validateToken = `
mutation validateToken(
    $app:  AppType!
    $token: String!
){
    validateToken(
        app: $app
        token: $token
        
  ) {
    data,
    success,
    error {
        path
        message
      }
  }
}
`;

const changeCompanyData = `
mutation updateCompany(
    $_id: ID!
    $logo: String!
){
    updateCompany(
        _id: $_id
        logo: $logo
        
  ) {
    success,
    error {
        path
        message
      }
  }
}
`;

export const Login = {
    login,
    validateToken,
    changeCompanyData
}