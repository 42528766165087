import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Timeline from "@material-ui/icons/Timeline";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import IconButton from "@material-ui/core/IconButton";


function EngagmentSocialMedia({ ...props }) {
    const {
        classes,
        data,
      } = props;
    let engagementTotal = data.engagementFacebook +
                            data.engagementInstagram +
                            data.engagementTwitter;
    
    let percentajes = {
        engagementFacebook: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementFacebook,
        engagementInstagram: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementInstagram,
        engagementTwitter: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementTwitter
    }
    
    return (
        <GridItem xs={12} sm={12} md={2} className={classes.center}>
            <Card>
                <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                            <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                        {`${props.tags.insightsBySocialMedia}: ${engagementTotal}`}
                    </h4>
                </CardHeader>
                <CardBody minWidth230={true}>
                    <Table
                        tableData={[
                            [
                                <IconButton className={classes.menuButton}>
                                    <i className={"fab fa-facebook " + classes.colorFacebook}></i>
                                </IconButton>,
                                data.engagementFacebook.toFixed(0),
                                `${percentajes.engagementFacebook.toFixed(0)} %`
                            ],
                            [
                                <IconButton className={classes.menuButton}>
                                    <i className={"fab fa-instagram " + classes.colorInstagram}></i>
                                </IconButton>,
                                data.engagementInstagram.toFixed(0),
                                `${percentajes.engagementInstagram.toFixed(0)} %`
                            ],
                            [
                                <IconButton className={classes.menuButton}>
                                    <i className={"fab fa-twitter " + classes.colorTwitter}></i>
                                </IconButton>,
                                data.engagementTwitter.toFixed(0),
                                `${percentajes.engagementTwitter.toFixed(0)} %`
                            ]
                        ]}
                    />
                </CardBody>
            </Card>
        </GridItem>
      )
}

export default EngagmentSocialMedia;
