import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCampaignData, subscriptionCampaignData } from "../../../state/actions/campaigns";
import tags from "./tags";
import styles from "./styleIndex";
import Investment from "../dashboardGraphics/investment";
import History from "../dashboardGraphics/history";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import { Facebook } from 'react-content-loader';
const MyFacebookLoader = () => <Facebook />

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mont: true
          };
    }

  render() {
    const { classes } = this.props;
    return (
        this.props.investment.investment === 0 ? MyFacebookLoader() : 
        <div>
            <GridContainer>
            <Investment classes={classes} data={this.props.investment} tags={this.props.tags}/>
            <History classes={classes} data={this.props.influencerHistory} tags={this.props.tags} type={"influencers"}/>
            <History classes={classes} data={this.props.engagementHistory} tags={this.props.tags} type={"engagement"}/>
            </GridContainer>
        </div>
    );
  }
}

function mapStateToProps(state){
    return {
        influencerHistory: state.campaigns.campaign.influencerHistory,
        engagementHistory: state.campaigns.campaign.engagementHistory,
        investment: state.campaigns.campaign.investment,
        login: state.login
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
        fetchCampaignData: bindActionCreators(fetchCampaignData, dispatch),
        subscriptionCampaignData: bindActionCreators(subscriptionCampaignData, dispatch)
    }
  }

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)));
