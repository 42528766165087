import { put, takeLatest } from "redux-saga/effects";
import {
  fetchLoginDataSuccess,
  fetchLoginDataUnsuccess,
  fetchLoginDataFail,
  loadingLogin,
  fetcheChangeCompanyDataSuccess,
  fetcheChangeCompanyDataFail,
  fetchApiReferencesSuccess,
  fetchApiReferencesUnsuccess,
  fetchApiReferencesFail
} from "../actions/login";
import {
  FETCH_LOGIN_DATA,
  FETCH_VALIDATE_TOKEN,
  FETCH_CHANGE_COMPANY_DATA,
  FETCH_API_REFERENCES
} from "../actionTypes";
import api from "../api";

function* fetchLoginData(action) {
  try {
    yield yield put(loadingLogin(true));
    let result = yield api.login.login(action.payload);
    if ((result.data != null) & result.data.login.success) {
      yield put(
        fetchLoginDataSuccess({ data: JSON.parse(result.data.login.data) })
      );
      localStorage.setItem("@token", JSON.parse(result.data.login.data).token);
    } else yield put(fetchLoginDataUnsuccess({ data: result.errors }));
    yield yield put(loadingLogin(false));
  } catch (e) {
    yield put(fetchLoginDataFail());
    yield yield put(loadingLogin(false));
  }
}

function* fetchvalidateToken(action) {
  try {
    yield yield put(loadingLogin(true));
    yield fetchApiReferences();
    let result = yield api.login.validateToken(action.payload);
    if ((result.data != null) & result.data.validateToken.success) {
      yield put(
        fetchLoginDataSuccess({
          data: JSON.parse(result.data.validateToken.data)
        })
      );
      localStorage.setItem("@token", JSON.parse(result.data.login.data).token);
    } else yield put(fetchLoginDataUnsuccess({ data: result.errors }));
    yield yield put(loadingLogin(false));
  } catch (e) {
    yield put(fetchLoginDataFail());
    yield yield put(loadingLogin(false));
  }
}

function* fetcheChangeCompanyData(action) {
  try {
    let result = yield api.login.ChangeCompanyData(action.payload);
    if ((result.data != null) & result.data.updateCompany.success) {
      yield put(fetcheChangeCompanyDataSuccess());
    } else yield put(fetcheChangeCompanyDataFail());
  } catch (e) {
    yield put(fetcheChangeCompanyDataFail());
  }
}

// eslint-disable-next-line no-unused-vars
function* fetchApiReferences(action) {
  try {
    let result = yield api.basics.getApiReferences();
    if (result.success) {
      // eslint-disable-next-line no-undef
      yield put(fetchApiReferencesSuccess({ data: result.data }));
    } else {
      yield put(fetchApiReferencesUnsuccess({ data: result.errors }));
    }
  } catch (ex) {
    yield put(fetchApiReferencesFail({ data: ex }));
  }
}

export default function* campaignCreation() {
  yield takeLatest(FETCH_LOGIN_DATA, fetchLoginData);
  yield takeLatest(FETCH_VALIDATE_TOKEN, fetchvalidateToken);
  yield takeLatest(FETCH_CHANGE_COMPANY_DATA, fetcheChangeCompanyData);
  yield takeLatest(FETCH_API_REFERENCES, fetchApiReferences);
}
