import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchvalidateToken } from "../state/actions/login";

// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";


const switchRoutes = (user) => {
    if (user === null) return (<Switch></Switch>);
    return (
    <Switch>
      {
        dashboardRoutes.map((prop, key) => {
          let itemOK = false;
          if (prop.permission === "public") itemOK = true;
          else {
            user.permissions.forEach(element => {
              if (element === prop.permission) itemOK = true;
            })
          }
          if(itemOK === false) return null;
          else { 
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            if (prop.collapse)
              return prop.views.map((prop, key) => {
                return (
                  <Route path={prop.path} component={prop.component} key={key} />
                );
              });
            return <Route path={prop.path} component={prop.component} key={key} />;
          }        
      })}
      
    </Switch>
    )
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentWillMount() {
    if(this.props.login.user === null && localStorage.getItem('@token') !== null)
      this.props.fetchvalidateToken({app: "Web", token: localStorage.getItem('@token')});
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText={"AdMe-Enterprise"}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          userState={this.props.login.user !== null ? this.props.login.user : null}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
        {
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            user={this.props.login.user !== null ? this.props.login.user : null}
            {...rest}
          />
        }
          
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes(this.props.login.user !== null ? this.props.login.user : null)}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes(this.props.login.user !== null ? this.props.login.user : null)}</div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    login: state.login,
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchvalidateToken: bindActionCreators(fetchvalidateToken, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appStyle)(Dashboard));

