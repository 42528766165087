import { fork } from "redux-saga/effects";
import companies from './companies';
import campaigns from './campaigns';
import campaignSubscription from './campaignSubscription';
import campaignCreationSubscription from './campaignCreationSubscription';
import campaignCreation from './campaignCreation';
import ages from './ages';
import categories from './categories';
import regions from './regions';
import sexes from './sexes';
import sponsorshipeffectivenesses from './sponsorshipeffectivenesses';
import spoteffectivenesses from './spoteffectivenesses';
import stars from './stars';
import people from './people';
import advertisingEffectivenesses from './advertisingEffectivenesses';
import login from './login';
import person from './person';
import advertisements from "./advertisements";
import allCollectionsToCampaign from "./allCollectionsToCampaign";

export default function* mySaga() {
  yield [
    fork(companies),
    fork(campaigns),
    // fork(campaignSubscription),
    fork(campaignCreation),
    // fork(campaignCreationSubscription),
    fork(ages),
    fork(categories),
    fork(regions),
    fork(sexes),
    fork(sponsorshipeffectivenesses),
    fork(spoteffectivenesses),
    fork(stars),
    fork(people),
    fork(advertisingEffectivenesses),
    fork(login),
    fork(person),
    fork(advertisements),
    fork(allCollectionsToCampaign)
  ]
}