import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({saveAndMore,
    all,
    amountToFreezed,
    approve,
    reason,
    selectReason,
    amountPayable,
    postedIn,
    noAdvertisementToAudit,
    companyName,
    campaignName,
    resources,
    type,
    returnToCampaignList,
    hashtag,
    webSite,
    seal,
    tagMentionPeopleEnable,
    frameEnabled,
    productUsageCampaign,
    user,
    starPlatform,
    starEnterprise,
    selectAll,
    selectDisapproval,
    item,
    price,
    tagMentionPeople,
    advertisement,
    productUsage,
    tagMentionCompany,
    frame


}) => ({tags:{saveAndMore,
    all,
    amountToFreezed,
    approve,
    reason,
    selectReason,
    amountPayable,
    postedIn,
    noAdvertisementToAudit,
    companyName,
    campaignName,
    resources,
    type,
    returnToCampaignList,
    hashtag,
    webSite,
    seal,
    tagMentionPeopleEnable,
    frameEnabled,
    productUsageCampaign,
    user,
    starPlatform,
    starEnterprise,
    selectAll,
    selectDisapproval,
    item,
    price,
    tagMentionPeople,
    advertisement,
    productUsage,
    tagMentionCompany,
    frame
    }});

export default translatable(mapTranslationsToProps);
