import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ChartistGraph from "react-chartist";

import {
    multipleBarsChart,
  } from "variables/charts.jsx";

function EngagmentVelocityStatus({ ...props }) {
    const {
        classes,
        data,
        tags,
      } = props;

    const engagementVelocityStatus = {
        data: {}
    }

    switch (data._campaignsActives.length) {
        case 0:           
            break;
        case 1:
            engagementVelocityStatus.data = {
                labels: [data._campaignsActives[0].name],
                series: [
                    [data._campaignsActives[0].engagementVelocityReal],
                    [data._campaignsActives[0].engagementVelocityExpected]
                ]
            }
            break;
        case 2:
            engagementVelocityStatus.data = {
                labels: [data._campaignsActives[0].name, data._campaignsActives[1].name],
                series: [
                    [data._campaignsActives[0].engagementVelocityReal, data._campaignsActives[1].engagementVelocityReal],
                    [data._campaignsActives[0].engagementVelocityExpected, data._campaignsActives[1].engagementVelocityExpected]
                ]
            }
            break;
        case 3:
            engagementVelocityStatus.data = {
                labels: [data._campaignsActives[0].name, data._campaignsActives[1].name, data._campaignsActives[2].name],
                series: [
                    [data._campaignsActives[0].engagementVelocityReal, data._campaignsActives[1].engagementVelocityReal, data._campaignsActives[2].engagementVelocityReal],
                    [data._campaignsActives[0].engagementVelocityExpected, data._campaignsActives[1].engagementVelocityExpected, data._campaignsActives[2].engagementVelocityExpected]
                ]
            }
            break;
        default:
            engagementVelocityStatus.data = {
                labels: [data._campaignsActives[0].name, data._campaignsActives[1].name, data._campaignsActives[2].name],
                series: [
                    [data._campaignsActives[0].engagementVelocityReal, data._campaignsActives[1].engagementVelocityReal, data._campaignsActives[2].engagementVelocityReal],
                    [data._campaignsActives[0].engagementVelocityExpected, data._campaignsActives[1].engagementVelocityExpected, data._campaignsActives[2].engagementVelocityExpected]
                ]
            }
            break;
    }

    return (
        <Card>
                <CardHeader color="rose" icon>
                <h6 className={classes.cardIconTitle}>
                {tags.lastThreeActiveCampaings}
                </h6>
                </CardHeader>
                <CardBody minWidth200={true}>
                <ChartistGraph
                    data={engagementVelocityStatus.data}
                    type="Bar"
                    options={multipleBarsChart.options}
                    listener={multipleBarsChart.animation}
                />
                {` `}
                <i className={"fas fa-circle " + classes.colorOnGoing}>{` `}</i>
                {` ${tags.realVelocity}`}
                {` `}
                <i className={"fas fa-circle " + classes.danger}>{` `}</i>
                {` ${tags.velocityExpented}`}
                {` `}
                </CardBody>
            </Card>
    )
}

export default EngagmentVelocityStatus;