import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchvalidateToken } from "../state/actions/login";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

import pagesRoutes from "routes/pages.jsx";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import bgImage from "assets/img/fondo1.jpg";

class Pages extends React.Component {

  componentWillMount() {
    if(this.props.login.user === null && localStorage.getItem('@token') !== null)
      this.props.fetchvalidateToken({app: "Web", token: localStorage.getItem('@token')});
  }
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            <Switch>
              {pagesRoutes.map((prop, key) => {
                let itemOK = false;
                if (prop.permission === "public") itemOK = true;
                else {
                  this.props.login.user.permissions.forEach(element => {
                    if (element === prop.permission) itemOK = true;
                  })
                }
                
                if(itemOK === false) return null;
                else {
                  if (prop.collapse) {
                    return null;
                  }
                  if (prop.redirect) {
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  }
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                }                
              })}
            </Switch>
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    login: state.login,
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchvalidateToken: bindActionCreators(fetchvalidateToken, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pagesStyle)(Pages));

//export default withStyles(pagesStyle)(Pages);
