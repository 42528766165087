import companies from "./companies";
import campaigns from "./campaigns";
import campaignCreation from "./campaignCreation";
import ages from "./ages";
import categories from "./categories";
import regions from "./regions";
import sexes from "./sexes";
import sponsorshipeffectivenesses from "./sponsorshipeffectivenesses";
import spoteffectivenesses from "./spoteffectivenesses";
import stars from "./stars";
import people from "./people";
import advertisingEffectivenesses from "./advertisingEffectivenesses";
import login from "./login";
import basics from "./basics";
import person from "./person";
import advertisements from "./advertisements";
import allCollectionsToCampaign from "./allCollectionsToCampaign";

export default {
  companies,
  campaigns,
  campaignCreation,
  ages,
  categories,
  regions,
  sexes,
  sponsorshipeffectivenesses,
  spoteffectivenesses,
  stars,
  people,
  advertisingEffectivenesses,
  login,
  basics,
  person,
  advertisements,
  allCollectionsToCampaign
};
