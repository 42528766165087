import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchStarsDataSuccess,
    fetchStarsDataFail
 } from "../actions/stars";
import {
    FETCH_STARS_DATA
} from '../actionTypes';
import api from '../api';

function* fetchStarsData(action) {
    try {
          let data = yield api.stars.getStarsData(action.payload);
          yield put(fetchStarsDataSuccess({data:data.stars}));
    } catch (e) {
          yield put(fetchStarsDataFail());
    }
}

export default function* starsSaga() {
    yield takeLatest(FETCH_STARS_DATA, fetchStarsData);
}