
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import { emphasize } from '@material-ui/core/styles/colorManipulator';
// @material-ui/core components
import { fade } from '@material-ui/core/styles/colorManipulator';

var styleIndex = theme => ({
    ...chartsStyle,
    ...extendedTablesStyle,
    ...regularFormsStyle,
    width70percentage: {
      'max-width': '200px !important',
      height: '200px'
    },
    ancho: {
        "min-width": "300px"
    },
    root: {
        width: '100%',
      },
    menu: {
      backgroundColor: "transparent",
      "box-shadow": "0 0px 0px 0 rgba(0, 0, 0, 0.14)",
    },
    grow: {
        flexGrow: 1,
      },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
      },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
      marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing.unit,
          width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 120,
          '&:focus': {
            width: 200,
          },
        },
    },
    colorOnGoing: {
      color: "#00acc1"
    },
    colorFinished: {
      color: "#f4c63d"
    },
    colorWaitingForPayment: {
      color: "#4267b2"
    },
    colorWaitingForApproval: {
      color: "#d17905"
    },
    colorIsDraft: {
      color: "#59922b"
    },
    //Estilos del buscador autocpmpletable
    rootSearcher: {
      flexGrow: 1,
      //height: 250,
    },
    input: {
      display: 'flex',
      padding: 0,
      'text-align': 'center'
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
    Without: {
      height: "300px",
      "text-align": "center",
      "padding-top": "150px",
      "font-size": "23px"
    }
  });
  

export default styleIndex;