/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "./tags";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {
  fetchLoginData,
  fetchLogOut,
  fetchApiReferences,
  fetchvalidateToken
} from "../../state/actions/login";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ReactLoading from "react-loading";
import styled from "tachyons-components";
import ImageVideoUpload from "./components/imageUpload";

const Section = styled("div")`
flex flex-wrap content-center justify-center w-100 h-100`;

const Article = styled("div")`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      loginEmail: "",
      loginEmailState: "",
      loginPassword: "",
      loginPasswordState: ""
    };
    this.loginClick = this.loginClick.bind(this);
    this.logOutClick = this.logOutClick.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
  }
  componentDidMount() {
    this.props.fetchApiReferences(null);
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      50
    );
    if(this.props.login.user === null && localStorage.getItem('@token') !== null)
      this.props.fetchvalidateToken({app: "Web", token: localStorage.getItem('@token')});
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

//   shouldComponentUpdate(nextProps, nextState) {
//     // eslint-disable-next-line react/prop-types
//     if (!this.props.login.loadingLogin && this.props.login.user !== null) {
//       const { history } = this.props;
//       history.push({pathname: `AdMe`});
//     }

//     return true;
//   }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 8)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      default:
        break;
    }
  }
  handlerRegister() {
    const { history } = this.props;
    history.push({ pathname: `register-page` });
  }
  logOutClick() {
    this.props.fetchLogOut();
    this.setState({
      loginEmail: "",
      loginEmailState: "",
      loginPassword: "",
      loginPasswordState: ""
    });
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
    if (
      this.state.loginEmailState === "success" &&
      this.state.loginPasswordState === "success"
    ) {
      this.props.fetchLoginData({
        email: this.state.loginEmail,
        password: this.state.loginPassword
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              {this.props.login.user !== null ? (
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>
                      {`${this.props.tags.logOut}: `}
                      {this.props.login.user.firstName}
                    </h4>
                    <div className={classes.socialLine} />
                  </CardHeader>
                  <CardBody className={`${classes.textCenter}`}>
                    <h4 className={classes.cardIconTitle}>
                      {`${this.props.tags.company} :`}{" "}
                      {this.props.login.user.company.name}
                    </h4>
                    <ImageVideoUpload
                      className={classes}
                      addButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      round
                      color="rose"
                      simple
                      size="lg"
                      block
                      onClick={this.logOutClick}
                    >
                      {this.props.tags.logOut}
                    </Button>
                  </CardFooter>
                </Card>
              ) : (
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>
                      {this.props.tags.createAccount}
                    </h4>
                    <div className={classes.socialLine} />
                  </CardHeader>
                  {this.props.login.loadingLogin ? (
                    <CardBody>
                      <Section>
                        <Article key={"spinningBubbles"}>
                          <ReactLoading
                            type={"spinningBubbles"}
                            color="#00acc1"
                          />
                        </Article>
                      </Section>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <CustomInput
                        success={this.state.loginEmailState === "success"}
                        error={this.state.loginEmailState === "error"}
                        defaultValue={this.state.loginEmail}
                        labelText={this.props.tags.email}
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, "loginEmail", "email"),
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <form onSubmit={this.loginClick}>
                        <CustomInput
                          success={this.state.loginPasswordState === "success"}
                          error={this.state.loginPasswordState === "error"}
                          textCenter={"hla"}
                          defaultValue={this.state.loginPassword}
                          labelText={this.props.tags.password}
                          id="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "loginPassword", "password"),
                            type: "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </form>

                      {this.props.login.loginStatus != null ? (
                        <div className={classes.formCategory}>
                          <small>*</small> {this.props.login.loginStatus}
                        </div>
                      ) : null}

                      <br />
                      <h4 className={classes.cardIconTitle}>
                        {this.props.tags.withoutAccount}
                        {/* <a
                          href="register-page"
                          onClick={() => this.props.handlerRegister}
                        >
                          {" "}
                          {this.props.tags.createAccount}
                        </a> */}
                        <Button
                            round
                            color="rose"
                            simple
                            size="lg"
                            block
                            onClick={this.handlerRegister}
                        >
                            {this.props.tags.createAccount}
                        </Button>
                      </h4>
                    </CardBody>
                  )}
                  {this.props.login.loadingLogin ? null : (
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button
                        round
                        color="rose"
                        simple
                        size="lg"
                        block
                        onClick={this.loginClick}
                      >
                        {this.props.tags.letsGo}
                      </Button>
                    </CardFooter>
                  )}
                </Card>
              )}
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    login: state.login
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLoginData: bindActionCreators(fetchLoginData, dispatch),
    fetchLogOut: bindActionCreators(fetchLogOut, dispatch),
    fetchApiReferences: bindActionCreators(fetchApiReferences, dispatch),
    fetchvalidateToken: bindActionCreators(fetchvalidateToken, dispatch)
  };
}

export default tags(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(loginPageStyle)(LoginPage))
);
