import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Timeline from "@material-ui/icons/Timeline";
import CardBody from "components/Card/CardBody.jsx";
import ChartistGraph from "react-chartist";
import Tooltip from "@material-ui/core/Tooltip";

import {
    pieChart
  } from "variables/charts";

function EngagmentInsights({ ...props }) {
    const {
        classes,
        data,
        tags
    } = props;
    
    let engagementTotal = data.tagCount +
                            data.likeCount +
                            data.sharedCount + 
                             data.linkCount +
                             data.printCount +
                             data.mentionCount +
                             data.hashtagCount +
                            data.commentCount +
                            data.engagementNotSponsored;
    
    let percentajes = {
        engagementTag: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.tagCount,
        engagementLike: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.likeCount,
        engagementShared: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.sharedCount,
        engagementLink: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.linkCount,
        engagementPrint: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.printCount,
        engagementMention: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.mentionCount,
        engagementHashtag: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.hashtagCount,
        engagementComment: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.commentCount,
        engagementNotSponsored: engagementTotal === 0 ? 0 : 100 / engagementTotal * data.engagementNotSponsored
    }

    const engagementInsights = {
        data: {
            labels: [ 
                    `${percentajes.engagementTag.toFixed(0)}%`,
                    `${percentajes.engagementLike.toFixed(0)}%`, 
                    `${percentajes.engagementShared.toFixed(0)}%`,
                    `${percentajes.engagementLink.toFixed(0)}%`,
                    `${percentajes.engagementPrint.toFixed(0)}%`,
                    `${percentajes.engagementMention.toFixed(0)}%`,
                    `${percentajes.engagementHashtag.toFixed(0)}%`,
                    `${percentajes.engagementComment.toFixed(0)}%`,
                    `${percentajes.engagementNotSponsored.toFixed(0)}%`
                    ],
            series: [
                    percentajes.engagementTag.toFixed(0),
                    percentajes.engagementLike.toFixed(0),
                    percentajes.engagementShared.toFixed(0),
                    percentajes.engagementLink.toFixed(0),
                    percentajes.engagementPrint.toFixed(0),
                    percentajes.engagementMention.toFixed(0),
                    percentajes.engagementHashtag.toFixed(0),
                    percentajes.engagementComment.toFixed(0),
                    percentajes.engagementNotSponsored.toFixed(0)
                    ]
        }
    }

    return (
        <GridItem xs={12} sm={10} md={2} className={classes.center}>
            <Card>
                <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                    <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                    {`${props.tags.insightsByReactions}: ${engagementTotal}`}
                    </h4>
                </CardHeader>
                <CardBody minWidth230={true}>
                    {engagementTotal}
                        <ChartistGraph
                            data={engagementInsights.data}
                            type="Pie"
                            options={pieChart.optionsMin}
                        />
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.tag}: ${data.tagCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorOnGoing}>{` `}</i>
                    </Tooltip>
                   {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.like}: ${data.likeCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.danger}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.shared}: ${data.sharedCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorFinished}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.link}: ${data.linkCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorWaitingForApproval}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.print}: ${data.printCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorWaitingForPayment}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.mentionValue}: ${data.mentionCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorIsDraft}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.hashtag}: ${data.hashtagCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorHashtag}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.comment}: ${data.commentCount}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.colorComment}>{` `}</i>
                    </Tooltip>
                    {` `}
                    <Tooltip
                        id="tooltip-top"
                        title={`${tags.notSponsored}: ${data.engagementNotSponsored}`}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <i className={"fas fa-circle " + classes.danger}>{` `}</i>
                    </Tooltip>
                </CardBody>
            </Card>
        </GridItem>
      )
}

export default EngagmentInsights;