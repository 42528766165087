import { FETCH_SPOTEFFECTIVENESSES_DATA,
    FETCH_SPOTEFFECTIVENESSES_DATA_SUCCESS,
    FETCH_SPOTEFFECTIVENESSES_DATA_FAIL
} from '../actionTypes';

export const fetchSpoteffectivenessesData = (data) => ({
    type: FETCH_SPOTEFFECTIVENESSES_DATA,
    payload: data
})

export const fetchSpoteffectivenessesDataSuccess = (data) => ({
    type: FETCH_SPOTEFFECTIVENESSES_DATA_SUCCESS,
    payload: data
})

export const fetchSpoteffectivenessesDataFail = () => ({
    type: FETCH_SPOTEFFECTIVENESSES_DATA_FAIL,
    success: false,
})