import { put, takeLatest } from "redux-saga/effects";
import { fetchCampaignsDataSuccess, 
         fetchCampaignsDataFail,
         fetchCampaignsDataAddSuccess, 
         fetchCampaignsDataAddFail,
         fetchCampaignsNameSuccess,
         fetchCampaignsNameFail,
         fetchCampaignDataSuccess, 
         fetchCampaignDataFail,
         fetchCampaignDataResourcesSuccess,
         fetchCampaignDataCardinalSuccess,
         fetchCompanyAndCampaignsDataSuccess,
         fetchCompanyAndCampaignsDataFail,
         fetchCampaignHeaderAuditDataSuccess,
         fetchCampaignHeaderAuditDataFail
        } from "../actions/campaigns";

import { FETCH_CAMPAIGNS_DATA, 
         FETCH_CAMPAIGNS_DATA_ADD, 
         FETCH_CAMPAIGNS_NAME,
         FETCH_CAMPAIGN_DATA,
         FETCH_COMPANY_AND_CAMPAIGNS_DATA,
         FETCH_CAMPAIGN_HEADER_AUDIT_DATA
        } from '../actionTypes';

import api from '../api';

function* fetchCampaignsData(action) {
    try {
      let data = yield api.campaigns.getCampaignsData(action.payload);
      let hasMore = true;
      let active = true;
      if (!action.payload.active) active = false;
      if (action.payload.limit > data.campaigns.length) hasMore = false;
      yield put(fetchCampaignsDataSuccess({data:data.campaigns, hasMore, active}));
    } catch (e) {
      yield put(fetchCampaignsDataFail());
    }
}

function* fetchCampaignsDataAdd(action) {
    try {
      const data = yield api.campaigns.getCampaignsData(action.payload);
      let hasMore = true;
      let active = true;
      if (!action.payload.active) active = false;
      if (action.payload.limit > data.campaigns.length) hasMore = false;
      yield put(fetchCampaignsDataAddSuccess({data:data.campaigns, hasMore, active}));
    } catch (e) {
      yield put(fetchCampaignsDataAddFail());
    }
}

function* fetchCampaignsName(action) {
    try {
      const data = yield api.campaigns.getCampaignsName(action.payload);
      yield put(fetchCampaignsNameSuccess(data.campaigns));
    } catch (e) {
      yield put(fetchCampaignsNameFail());
    }
  }

function* fetchCampaignData(action) {
  try {
    let data = yield api.campaigns.getCampaignData(action.payload);
    yield put(fetchCampaignDataSuccess({data:data.campaigns}));
    yield put(fetchCampaignDataResourcesSuccess({data:data.campaigns}));
    yield put(fetchCampaignDataCardinalSuccess({data:data.campaigns}));
  } catch (e) {
    yield put(fetchCampaignDataFail());
  }
}

function* fetchCompanyAndCampaignsData(action) {
  try {
    const data = yield api.companies.getCompaniesData(action.payload);
    yield put(fetchCompanyAndCampaignsDataSuccess({data: data.companies[0]}));
  } catch (e) {
    yield put(fetchCompanyAndCampaignsDataFail());
  }
}

function* fetchCampaignHeaderAuditData(action) {
  try {
    const data = yield api.campaigns.getCampaignHeaderAuditData(action.payload);
    yield put(fetchCampaignHeaderAuditDataSuccess({data: data.campaigns[0]}));
  } catch (e) {
    yield put(fetchCampaignHeaderAuditDataFail());
  }
}

export default function* companiesSaga() {
    yield takeLatest(FETCH_CAMPAIGNS_DATA, fetchCampaignsData);
    yield takeLatest(FETCH_CAMPAIGNS_DATA_ADD, fetchCampaignsDataAdd);
    yield takeLatest(FETCH_CAMPAIGNS_NAME, fetchCampaignsName);
    yield takeLatest(FETCH_CAMPAIGN_DATA, fetchCampaignData);
    yield takeLatest(FETCH_COMPANY_AND_CAMPAIGNS_DATA, fetchCompanyAndCampaignsData);
    yield takeLatest(FETCH_CAMPAIGN_HEADER_AUDIT_DATA, fetchCampaignHeaderAuditData);
}