var query = {
    advertisingEffectiveness: `
    query advertisingEffectiveness(
        $limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $from: Int
        $to: Int
    )
    {
        advertisingEffectiveness(
            limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            from: $from
            to: $to
        ){
            _id,
            from,
            to,
            AdPrice,
            framePrice,
            hashtagPrice,
            webSitePrice,
            tagMentionMePrice,
            sealPrice,
            tagMentionPeoplePrice,
            productUsagePrice,
            insightAveragePrice,
            freezedBudget,
            insightPriceFactor,
            totalPrice,
        }
    }
    `
}
export default {
        query
};