import { FETCH_SEXES_DATA,
    FETCH_SEXES_DATA_SUCCESS,
    FETCH_SEXES_DATA_FAIL,
} from '../actionTypes'; 

export const fetchSexesData = (data) => ({
    type: FETCH_SEXES_DATA,
    payload: data
})

export const fetchSexesDataSuccess = (data) => ({
    type: FETCH_SEXES_DATA_SUCCESS,
    payload: data
})

export const fetchSexesDataFail = () => ({
    type: FETCH_SEXES_DATA_FAIL,
    success: false,
})