export default {
    listOfCompanies : "Lista de empresas",
    campaigns:"Campañas",
    engagement:"Engagement",
    investment:"Inversion",
    investmentMin: "Inversion",
    engagementVelocity: "VELOCIDAD ENGAGEMENT",
    velocity:"VELOCIDAD",
    real:"Real",
    spent:"Gastada",
    expented: "Gastado",
    freezed:"congelada",
    availible:"disponible",
    realVelocity:"Velocidad Real",
    velocityExpented:"Velocidad Eesperada",
    campaignTotal:"TOTAL DE CAMPAÑAS",
    lastThreeActiveCampaings:"ULTIMAS TRES CAMPAÑAS ACTIVAS",
    totalInvestment:"INVERSION TOTAL",
    searchCompany:"buscar una compania",
    searchCampaign:"buscar una campaña",
    active:"activas",
    withoutCampaigns:"Sin campañas",
    withoutCampaignsCreated:"Sin campañas creadas",
    loading: "cargando...",
    youHaveSeenItAll: "¡Hurra! Lo has visto todo",
    waitingForApproval:"A LA ESPERA DE LA APROBACIÓN",
    draft:"BORRADOR",
    waitingForPayment:"A LA ESPERA DEL PAGO",
    finished:"TERMINADO",
    onGoing:"EN MARCHA",
    stoped: "DETENIDO",
    tag: "ETIQUETA",
    like: "ME GUSTA",
    shared: "COMPARTIDO",
    link: "ENLACE",
    print: "IMPRESIONES",
    comment:"COMENTARIOS",
    mentionValue:"MENCION",
    notSponsored: "NO PATROCINADO",
    saveAndMore:"Guardar y mas...",
    all:"Todos",
    amountToFreezed:" Monto a congelar",
    approve: "APROVADO",
    reason:"Razón",
    selectReason: "Seleccionar Razon",
    amountPayable: "Cantidad a Pagar",
    postedIn: "Posteado en:",
    noAdvertisementToAudit: "No hay más Publicidades que auditar, por favor vuelva a la lista de Campañas.",
    campaignName:"Nombre de la Campaña:",
    resources:"Recursos",
    type:"Tipo",
    returnToCampaignList: "Retornar a la lista de Campaña",
    hashtag:"HASHTAG",
    unknown: "Desconocidos",
    webSite:"Sitio Web",
    seal:"Sello",
    tagMentionPeopleEnable:"Etiquetar Personas: Habilitado",
    frameEnabled:"Marco: Habilitado",
    productUsageCampaign:"Uso del producto: Sí, el producto de la campaña debe estar en la imagen o el video.",
    user:"Usuario",
    starPlatform:"Estrellas en Plataforma",
    starEnterprise: "Estrellas de la Empresa",
    selectAll:"Sellecionar todo",
    selectDisapproval:"Seleccionar razon por desapruebo",
    item:"Item",
    price:"Precio",
    frame:"Marco",
    advertisement:"Publicidad",
    productUsage:"Uso de Producto",
    tagMentionCompany:"Etiqueta de la empresa",
    tagMentionPeople:"Personas etiquetadas",
    email:"correo electronico",
    password:"Contraseña",
    withoutAccount: "No tienes una cuenta?",
    letsGo:"Adelante!",
    createAccount:"Registrarase",
    logOut:"Cerrar Sesión",
    company:"Empresa",
    remove:"Eliminar",
    change:"Cambiar",
    getStarted:"Comenzar",
    iAgree:"Estoy de acuerdo",
    termsAndConditions:"terms and conditions",
    companyName:"Nombre de Empresa ",
    firstName:"Nombre...",
    lastName:"Apellido...",
    withoutLogo:"Sin logo",
    campaign:"Campaña",
    summaryOfYourCampaigns:"Resumen de sus Campañas",
    editMyCompany:"Editar mi Empresa",
    address:"Direccion",
    editCampaign:"Editar Campaña",
    viewDashboard:"Ver Panel de Control",
    pendingAuditByPlatform:"Pendiente de Auditoria por plataforma",
    pendingAuditByCompany:"Auditoria pendiente por Empresa",
    newButton:"NUEVO",
    influencer:"Influencer",
    influencers:"Influencers",
    influencedValue:"Influenciado",
    womens:"Mujeres",
    mens:"Hombres",
    insightsByReactions:"Insights por reacciones",
    insightsBySocialMedia:"Insights por RS",
    post:"Post",
    realVsPromise:"Real vs Expectativa",
    campaignInvestment:"Inversion de la Campaña",
    lineChart:"Gráfico de líneas",
    activityOfCampaign:"Actividad de su Campaña",
    selectImage: "Seleccionar Imagen",
    addPhoto:"Agregar Foto",
    sponsorshipCampaign:"Sponsorship",
    sponsorshipCampaignDescription1:"Campañas publicitarias listas para auspiciar los momentos espontaneos que las personas comparten en redes sociales a diario.",
    sponsorshipCampaignDescription2: "El objectivo inicial de las personas no sería ganar dinero con el contenido que comparten, por lo tanto la publicación en redes sociales es inmediata y si tu marca se encuentra en el contenido de los mismos, ellos podrian elegir este tipo de campañas para auspiciarlos.",
    sponsorshipCampaignCounter:"700 campañas de este tip ya han sido creadas",
    spotCampaign:"Spot",
    spotDescription1:"Campañas publicitarias con spots creados por tu marca.",
    spotDescription2:"Las cuales tienen por objectivo que las personas simplemente las compartan en sus redes sociales.",
    spotCounter:"1300 Campañas de este tipo ya han sido creadas",
    advertisingCampaign:"Advertising",
    advertisingDescription1:"Campañas publicitarias con instrucciones especificas sobre las pretenciones del contenido multimedia.",
    advertisingDescription2:"Solamente los Ads auditados y aprovados por las marcas serán posteados a las redes sociales.",
    advertisingCounter:"4000 Campañas de este tipo ya han sido creadas.",
    viewVideo:"Ver video",
    chooseCampaignType:"Seleccionar tipo de Campaña",
    campaignType:"Tipo de Campaña",
    sponsorship:"Sponsorship",
    spot:"Spot",
    advertising:"Advertising",
    adsIR:"Ads por I-R",
    dateFrom:"Fecha desde",
    dateTo:"Fecha hasta",
    defineShortDescription:"Define una descripción corta",
    whoAndHow:"Quién y Cómo",
    how:"Como",
    howDoYouGetThem:"Cómo consegeuir a ellos",
    addressed:"Dirijido a",
    byCriteria:"por criterio",
    toPeople:"a personas",
    toHisFollowers:"A sus seguidores",
    makingThemIR:"Haciéndolos I-R",
    makingThemID: "Haciéndolos I-D",
    youEntered:"Usted Ingresó:",
    loadYourSpot:"Ingrese su spot",
    defineSpot:"Escriba descripcion del spot.",
    basicInformation: "Vamos a comenzar con la información básica  (con validaciones)",
    required:"requerido",
    whatAreYouDoing:"Qué desea hacer? (checkboxes)",
    code:"Código",
    develop:"Desarrolar",
    chooseCity:"Seleccione una ciudad",
    satelliteMap:"Mapa satelital",
    regularMap:"Mapa normal",
    customAndSettings:"Configuración del mapa",
    regionForYourCampaign:"Region para su campaña",
    selectCity:"Seleccionar Ciudad",
    selectState:"seleccione Provincia",
    argentina:"Argentina",
    country:"Pais",
    state:"Provincia", 
    city:"Ciudad",
    action:"Acción",
    add:"Agregar",
    tagMentionMe:"Etiquetarme",
    tagsproductUsage:"Etiquetar Uso de Producto",
    productUsageOficial:" Uso Oficial de Producto",
    resourcesOfAdvertisement:"Recursos de Publicidad",
    ads:"Anuncios",
    enabled:"Habilitado",
    disabled:"Deshabilitado",
    description:"Descripción",
    completeInstructions:"Completar instrucciones",
    from:"Desde",
    to:"Hasta",
    ir:"I-R",
    adPrice:"Precio Pub.",
    freezedBudget:"Presupuesto frizado",
    totalPrice:"Precio frizado",
    epa:"EPA",
    total:"Total",
    age:"Edad",
    years:"años",
    engagementOfAdvertisement:"Engagement de la Publicidad",
    costPerAd:"Costo por Adv",
    costReference:"Costos de Referencia",
    target:"Target",
    nameOfNewCampaign:"nombre de campaña nueva para",
    productServiceDescrption:"Descripcion del producto/servicio ofrecido",
    categoryOfInfluencers:"Categoria de los Influencers",
    genre:"Género",
    male:"Masculino",
    female:"Femenino",
    assessment:"Puntuaciones",
    values:"Val.",
    influencersScope:"Alcance de Influencers",
    engagmentScope:"Alcance del Engagment",
    amountUsd:"Cantidad (USD)",
    properties:"Properties",
    searchPeople:"Buscar personas",
    searchPerson:"Buscar persona",
    save:"Guardar",
    next:"Próximo",
    previous:"Anterior",
    requiredName:"Nombre Requerido",
    requiredAdsIR:"Cantidad de Ads por I-R requerido",
    requiredInvestment:"Inversion requerida",
    requiredPaymentPerEach:"Pago por cada Uno requerido",
    equiredProductPaymentDescription:"Descripción de producto requerido",
    requiredRegion:"Requiere region",
    changeDate:"Cambiar Fecha",
    changeWeb:"Cambiar Web",
    changeMention: "Cambiar Mención",
    evenWithoutLoading: "Aun sin cargar",
    withoutActiveCampaigns: "Sin campañas activas",
    name: "Nombre",
    status: "Estado",
    knowUs: "Conocenos",
    dashboard: "Panel de control",
    english: "Ingles",
    spanish: "español",
    register: "Registrar nueva compañia",
    login: "Entrar",
    admeMobileDescription: "Adme mobile, es la app que le permite a cualquier persona crear posts en todas sus redes sociales desde un único lugar, mientras que pueden ganar dinero publicitando el contenido.",
    admeWebDescription: "Adme web, la herramienta que le permite a las marcas lanzar campañas publicitarias para llegar a potenciales consumidores, permitiendo auditar, visualizar alcance en tiempo real y hacer ajustes en base a resultados.",
    brands: "Empresas",
    targetPeople: "Publico objetivo",
    play: "Reproducir",
    stop: "Detener",
    admeSlogan: "La plataforma que genera influencia de consumo de la manera mas autentica. De persona a persona.",
    HowDoesItWork: "¿Como funciona?",
    adme: "ADME",
    fullDemo: "Full Demo",
    customAdPrice:"Personalizar precio del Ad."



    


};