import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchAdvertisementsDataSuccess,
    fetchAdvertisementsDataFail,
    fetchWaitingSaveAndMore
 } from "../actions/advertisements";
import {
    FETCH_ADVERTISEMENTS_DATA,
    FETCH_SAVE_AND_MORE
} from '../actionTypes';
import api from '../api';

function* fetchAdvertisementsData(action) {
    try {
          let data = yield api.advertisements.getAdvertisementsData(action.payload);
          yield put(fetchAdvertisementsDataSuccess({data:data.advertisements}));
          yield put(fetchWaitingSaveAndMore(false));
    } catch (e) {
          yield put(fetchAdvertisementsDataFail());
    }
}

function* fetchSaveAndMore(action) {
    try {
        let campaignId = action.payload.advertisements[0].campaignId;
        let status = action.payload.advertisements[0].status;
        yield put(fetchWaitingSaveAndMore(true));
        for (let index = 0; index < action.payload.advertisements.length; index++) {
            const element = action.payload.advertisements[index];
            if(element.status === "WaitingForPlatformAudit" && element.approve) element.status = "WaitingForCustomerAudit";
            else if (element.status === "WaitingForPlatformAudit" && !element.approve) element.status = "RejectedByPlatform"; 
            else if (element.status === "WaitingForCustomerAudit" && element.approve) element.status = "Approved"; 
            else if (element.status === "WaitingForCustomerAudit" && !element.approve) element.status = "RejectedByCustomer"; 
            
            yield api.advertisements.updateAdvertisementsData(element);
            if(action.payload.advertisements.length -1 === index){
                yield fetchAdvertisementsData({payload: {campaignId, status, limit: 3, page: 0}});
            } 
        }
    } catch (e) {
          //yield put(fetchAdvertisementsDataFail());
    }
}

export default function* advertisementsSaga() {
    yield takeLatest(FETCH_ADVERTISEMENTS_DATA, fetchAdvertisementsData);
    yield takeLatest(FETCH_SAVE_AND_MORE, fetchSaveAndMore);
}