import {
    SUBSCRIPTION_SCOPE,
    SUBSCRIPTION_SCOPE_SUCCESS,
    SUBSCRIPTION_ON,
    SUBSCRIPTION_OFF,
    SUBSCRIPTION_RECALCULATING
} from "../actionTypes";

const initialState = {
    influencersScopeCount: 0,
    influencedScopeCount: 0,
    engagementScopeCount: 0,
    investmentRequired: 0,
    investmentPercentage: 0,
    recalculating: false,
    subscription: false
}
const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_SCOPE: {
            return {
                influencersScopeCount: 0,
                engagementScopeCount: 0,
                recalculating: false,
                subscription: false
            }
        }
        case SUBSCRIPTION_RECALCULATING: {
            return {
                ...state,
                recalculating: true
            }
        }
        case SUBSCRIPTION_ON: {
            return {
                ...state,
                subscription: true
            }
        }
        case SUBSCRIPTION_OFF: {
            return {
                ...state,
                subscription: false
            }
        }
        case SUBSCRIPTION_SCOPE_SUCCESS: {
            let influencersScopeCount = state.influencersScopeCount;
            let influencedScopeCount = state.influencedScopeCount;
            let recalculating = false;
            let engagementScopeCount = state.engagementScopeCount;
            let investmentRequired = state.investmentRequired;

            if(influencersScopeCount !== action.payload.data.influencersScopeCount){
                influencersScopeCount = action.payload.data.influencersScopeCount;
            }

            if (influencedScopeCount != action.payload.data.influencedScopeCount) {
                influencedScopeCount = action.payload.data.influencedScopeCount;
            }

            engagementScopeCount = action.payload.data.influencedExtraScopeCount;

            if(investmentRequired !== action.payload.data.investmentRequired.toFixed(2)) {
                investmentRequired = action.payload.data.investmentRequired.toFixed(2);
            }

            
            return {
                ...state,
                influencersScopeCount,
                engagementScopeCount,
                investmentRequired,
                influencedScopeCount,
                recalculating,
            }
        }
        default:
            return state;
    }
}