import Start from "views/DashboardI/start/index.jsx";
import Companies from "views/DashboardI/companies/index.jsx";
import Campaigns from "views/DashboardI/campaigns/index.jsx";
import Dashboard from "views/DashboardI/dashboard/index.jsx";
import Wizard from "views/DashboardI/wizard";
import WizardAction from "views/DashboardI/wizard/indexWizard";
import Audit from "views/DashboardI/audit/index.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

var dashRoutes = [
  {
    view: true,
    permission: "panelAccess",
    path: "/panel/start",
    name: "Start",
    icon: DashboardIcon,
    component: Start
  },
  {
    view: true,
    permission: "viewAllCompanies",
    path: "/panel/Companies",
    name: "Companies",
    icon: DashboardIcon,
    component: Companies
  },
  {
    view: true,
    permission: "campaignReadonly",
    path: "/panel/Campaigns",
    name: "Campaigns",
    icon: DashboardIcon,
    component: Campaigns
  },
  {
    view: false,
    permission: "campaignReadonly",
    path: "/panel/Dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard
  },
  {
    view: true,
    permission: "campaignEdit",
    path: "/panel/Wizard",
    name: "New Campaign",
    icon: DashboardIcon,
    component: Wizard
  },
  {
    view: false,
    permission: "campaignEdit",
    path: "/panel/WizardAction",
    name: "New Campaign",
    icon: DashboardIcon,
    component: WizardAction
  },
  {
    view: false,
    permission: "viewWaitingForCustomerAudit",
    path: "/panel/Audit",
    name: "Audit",
    icon: DashboardIcon,
    component: Audit
  },
  { redirect: true, path: "/panel", pathTo: "/panel/start", name: "Dashboard" }
];
export default dashRoutes;
