import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import { MyLoaderAgeGenreAssessment } from '../../loaders';

function assessment({...props}) {
    const {
        classes,
        widgets,
        star,
        handleWidgets,
        handleStar
      } = props;
      return (
        props.stars.stars.length === 0 ?
        <Card className={widgets === true ? null: classes.disabled2}>
          <CardHeader color="rose" icon>
            <GridContainer justify="space-between">
                <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={10} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                              {props.tags.assessment}
                              </h4>
                            </GridItem>
                          </GridContainer>
                </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody height141={true}>
            {MyLoaderAgeGenreAssessment()}
          </CardBody>
        </Card>
        :
        <Card className={widgets === true ? null: classes.disabled2}>
                  <CardHeader color="rose" icon>
                    <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={2}>
                              <CardIcon color="info">
                                <Assignment />
                              </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={7} className={classes.alignCenter}>
                              <h4 className={classes.cardIconTitle}>
                              {props.tags.assessment}
                              </h4>
                            </GridItem>
                            <GridItem xs={12} sm={3} className={classes.align}>
                              <Checkbox
                                disabled={
                                  props.campaignCreation.enabledEdit ? false : true
                                }
                                tabIndex={8}
                                checked={widgets}
                                onClick={() => handleWidgets('assessment')}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody height130={true} className={classes.desabled}>
                  {widgets === true ? 
                    <div>
                      <GridItem xs={12} sm={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={2}>
                            <h6 className={classes.cardIconTitle}>{props.tags.values}</h6>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <CardIcon>
                                  {
                                      widgets === false ? 
                                      <i className={`far fa-star ${classes.starDisabled}`}></i>
                                      :
                                      star[0].enabled ? 
                                      <i onClick={widgets === true ? () => handleStar(0) : null} className={`fas fa-star ${classes.starEnabled}`}></i>
                                      :
                                      <i onClick={widgets === true ? () => handleStar(0) : null} className={`far fa-star ${classes.starDisabled}`}></i>
                                  }
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <CardIcon>
                                  {
                                      widgets === false ? 
                                      <i className={`far fa-star ${classes.starDisabled}`}></i>
                                      :
                                      star[1].enabled ? 
                                      <i onClick={widgets === true ? () => handleStar(1) : null} className={`fas fa-star ${classes.starEnabled}`}></i>
                                      :
                                      <i onClick={widgets === true ? () => handleStar(1) : null} className={`far fa-star ${classes.starDisabled}`}></i>
                                  }
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <CardIcon>
                                  {
                                      widgets === false ? 
                                      <i className={`far fa-star ${classes.starDisabled}`}></i>
                                      :
                                      star[2].enabled ? 
                                      <i onClick={widgets === true ? () => handleStar(2) : null} className={`fas fa-star ${classes.starEnabled}`}></i>
                                      :
                                      <i onClick={widgets === true ? () => handleStar(2) : null} className={`far fa-star ${classes.starDisabled}`}></i>
                                  }
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <CardIcon>
                                  {
                                      widgets === false ? 
                                      <i className={`far fa-star ${classes.starDisabled}`}></i>
                                      :
                                      star[3].enabled ? 
                                      <i onClick={widgets === true ? () => handleStar(3) : null} className={`fas fa-star ${classes.starEnabled}`}></i>
                                      :
                                      <i onClick={widgets === true ? () => handleStar(3) : null} className={`far fa-star ${classes.starDisabled}`}></i>
                                  }
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <CardIcon>
                                  {
                                      widgets === false ? 
                                      <i className={`far fa-star ${classes.starDisabled}`}></i>
                                      :
                                      star[4].enabled ? 
                                      <i onClick={widgets === true ? () => handleStar(4) : null} className={`fas fa-star ${classes.starEnabled}`}></i>
                                      :
                                      <i onClick={widgets === true ? () => handleStar(4) : null} className={`far fa-star ${classes.starDisabled}`}></i>
                                  }
                                </CardIcon>
                            </GridItem>
                          </GridContainer>
                      </GridItem>
                    </div>
                    : null
                  }
                  </CardBody>
        </Card>
      )
}

export default assessment;