export default {
    name: "Name",
    listOfCompanies : "List of companies",
    campaigns:"Campaings",
    engagement:"Engagement",
    investment:"Investment",
    investmentMin:"Investment",
    engagementVelocity: "Engagement Velocity",
    velocity:"Velocity",
    real:"Real",
    expented: "Expented",
    freezed:"Freezed",
    availible:"Availible",
    realVelocity:"Real Velocity",
    velocityExpented:"Velocity Expented",
    campaignTotal:"Campaign Total",
    lastThreeActiveCampaings:"Last three active campaigns",
    totalInvestment:"Total Investment",
    searchCompany:"Search a company",
    searchCampaign:"Search a campaign",
    active:"Active",
    withoutCampaigns:"Without campaigns",
    withoutCampaignsCreated:"Without campaigns created",
    loading: "Loading...",
    youHaveSeenItAll: "Yay! You have seen it all",
    waitingForApproval:"Waiting for approval",
    draft:"Draft",
    waitingForPayment:"Waiting for Payment",
    finished:"Finished",
    onGoing:"On Going",
    stoped: "Stoped",
    tag: "TAG",
    like: "Lke",
    shared: "Shared",
    link: "Link",
    print: "Print",
    comment:"Comment",
    mentionValue:"Mention",
    notSponsored: "Not Sponsored",
    saveAndMore: "Save and More...",
    unknown: "Unknown",
    all:"All",
    amountToFreezed:"Amount to freezed",
    approve: "Approve",
    reason:"Reason",
    selectReason: "Select Reason",
    amountPayable: "Amount Payable",
    postedIn: "Posted in:",
    noAdvertisementToAudit:"No advertisement to audit, please return to campaign list.",
    companyName:"Company Name ",
    campaignName:"Campaign Name:",
    resources:"Resources",
    type:"Type",
    returnToCampaignList:"Return to campaign list",
    hashtag:"Hashtag",
    webSite:"WebSite",
    seal:"Seal",
    productUsageOficialEnable:"Tag Mention People: Enabled",
    frameEnabled:"Frame: Enabled",
    productUsageCampaign:"Product Usage: Yes, the product of the campaign must be in the image or video.",
    user:"User",
    starPlatform:"Star Platform",
    starEnterprise: "Star enterprise",
    selectAll:"Select All",
    selectDisapproval:"Select reason for disapproval!",
    item:"Item",
    price:"Price",
    frame:"Frame",
    advertisement:"Advertisement",
    productUsage:"Product Usage",
    tagMentionCompany:"Tag Mention Company",
    email:"Email",
    password:"Password",
    withoutAccount: "Don't Have an Account?",
    letsGo:"Let's Go",
    createAccount:"Create Account",
    logOut:"Log Out",
    company:"Company",
    remove:"Remove",
    change:"Change",
    getStarted:"Get started",
    iAgree:"I agree to the",
    termsAndConditions:"terms and conditions",
    firstName:"First Name",
    lastName:"Last Name",
    register:"Register new Company",
    withoutLogo:"without logo",
    campaign:"Campaign",
    summaryOfYourCampaigns:"Summary of your campaigns",
    editMyCompany:"Edit My Company",
    address:"Address",
    editCampaign:"Edit Campaign",
    viewDashboard:"View Dashboard",
    pendingAuditByPlatform:"Pending audit by platarma",
    pendingAuditByCompany:"Pending audit by company",
    newButton:"New",  
    influencer:"Influencer",
    influencers:"Influencers",
    influencedValue:"Influenced",
    womens:"Womens",
    mens:"Mens",
    insightsByReactions:"Insights by reactions",
    insightsBySocialMedia:"Insights by SM",
    post:"Post",
    realVsPromise:"Real vs Promise",
    campaignInvestment:"Campaign investment",
    spent:"Spent",
    lineChart:"Line Chart",
    activityOfCampaign:"Activity of your campaign",
    selectImage: "Select Image",
    addPhoto:"Add Photo",
    sponsorshipCampaign:"Sponsorship",
    sponsorshipCampaignDescription1:"Campaigns ready to sponsor spontaneous moments that people is sharing in social medias.",
    sponsorshipCampaignDescription2: "The multimedia contents are not commercial purpose, so they will be posted in social medias immediately and if you brand is in there, they would chose to sponsor such posts with this kind of campaigns.",
    sponsorshipCampaignCounter:"799 campaigns of this type have already been created.",
    spotCampaign:"Spot",
    spotDescription1:"Campaigns with a spot created by your brand.",
    spotDescription2:"The people only have to share it in their social medias.",
    spotCounter:"1300 campaigns of this type have already been created",
    advertisingCampaign:"Advertising",
    advertisingDescription1:"Campaigns with specific instructions for creating the multimedia content.",
    advertisingDescription2:"Only Ads audited and approved by the campaign owner will be posted to the social medias.",
    advertisingCounter:"1300 campaigns of this type have already been created",
    viewVideo:"View video",
    chooseCampaignType:"Choose campaign type",
    sponsorship:"Sponsorship",
    spot:"Spot",
    advertising:"Advertising",
    adsIR:"Ads for each I-R",
    dateFrom:"Date from",
    dateTo:"Date to",
    defineShortDescription:"Define short Description",
    whoAndHow:"Who and How",
    how:"How",
    howDoYouGetThem:"How do you get them",
    addressed:"Addressed",
    byCriteria:"by criteria",
    toPeople:"to people",
    toHisFollowers:"To his followers",
    makingThemIR:"by making them I-R",
    makingThemID: "by making them I-D",
    youEntered:"You entered:",
    loadYourSpot:"Load your spot",
    defineSpot:"Define Spot description.",
    basicInformation: "Let's start with the basic information (with validation)",
    required:"Required",
    whatAreYouDoing:"What are you doing? (checkboxes)",
    code:"Code",
    develop:"Develop",
    chooseCity:"Choose City",
    satelliteMap:"Satellite Map",
    regularMap:"Regular Map",
    customAndSettings:"Custom Skin & Settings Map",
    campaignType:"Campaign Type",
    selectCity:"Select City",
    selectState:"Select State",
    argentina:"Argentina",
    regionForYourCampaign:"Region for your campaign",
    add:"Add",
    country:"Country",
    state:"State",
    city:"City",
    action:"Action",
    tagMentionMe:"Tag Mention Me",
    tagMentionPeople:"tag Mention People",
    tagsproductUsage:"tag Product Usage",
    productUsageOficial:"product Usage Oficial",
    resourcesOfAdvertisement:"Resources of advertisement", 
    ads:"Ads",
    enabled:"Enabled",
    disabled:"disabled",
    description:"Description",
    completeInstructions:"Complete instructions",
    from:"From",
    to:"To",
    ir:"I-R",
    adPrice:"adPrice",
    freezedBudget:"freezed Budget",
    totalPrice:"Freezed Price",
    epa:"EPA",
    total:"Total",
    age:"Age",
    years:"years",
    engagementOfAdvertisement:"Engagement of advertisement",
    costPerAd:"Cost per Ad",
    costReference:"Cost Reference",
    target:"Target",
    nameOfNewCampaign:"Name of new campaign to ",
    productServiceDescrption:"Product/Service description offered",
    categoryOfInfluencers:"Category of Influencers",
    genre:"Genre",
    male:"Male",
    female:"Female",
    assessment:"Assessment",
    values:"Val.",
    campaignStatus:"campaign Status",
    influencersScope:"Influencers Scope",
    engagmentScope:"Engagment Scope",
    amountUsd:"Amount (USD)",
    properties:"Properties",
    searchPeople:"Search people",
    searchPerson:"search person",
    save:"Save",
    next:"Next",
    previous:"Previous",
    requiredName:"Required Name",
    requiredAdsIR:"Required Cantidad de Ads por I-R",
    requiredInvestment:"Required investment",
    requiredPaymentPerEach:"Required paymentPerEach",
    equiredProductPaymentDescription:"Required Product Payment Description",
    requiredRegion:"Required region",
    changeDate:"Change Date",
    changeWeb:"Change Web",
    changeMention: "Change Mention",
    evenWithoutLoading: "Even without loading",
    withoutActiveCampaigns: "Without active campaigns",
    status: "Status",
    knowUs: "Know-Us",
    dashboard: "Dashboard",
    english: "English",
    spanish: "Spanish",
    login: "Login",
    admeMobileDescription: "Adme mobile, an app for social media users that allows to post in all of linked social medias at once, having the chance of making money by advertising the content.",
    admeWebDescription: "Ame web, a tool for Brands that allows to launch advertising campaigns for reaching potential customers. The tool allows to audit, visualize results in real time and make adjustements base on them.",
    brands: "Brands",
    targetPeople: "Target people",
    play: "Play",
    stop: "Stop",
    admeSlogan: "The platform that influence potential customers in a nature way. From people to people.",
    HowDoesItWork: "How does it work?",
    adme: "ADME",
    fullDemo: "Full Demo",
    customAdPrice:"Customize Ad Price"

















   
    










    

    

    





};
