import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import cssVideo from "assets/cssVideo/video-react.css"; 

export default theme => ({
  minHeightHeader: {
    minHeight: "350px"
  },
  minHeightBody: {
    minHeight: "200px"
  },
  fullWidth50: {
    width: "50%"
  },
  fontzise40: {
    'font-size': '20px !important',
    'text-align': 'center !important',
    'font-family': 'Roboto, Helvetica, Arial, sans-serif'
  },
  width70percentage: {
    'max-width': '70% !important'
  },
  video: {
    'max-height': '100%',
    'width': '100%',
    'height': '50%',
    'margin-right': 'auto',
    'margin-left': 'auto',
    'display': 'block',
    'max-width': '100%', 
  },
  starEnabled: {
    color: '#ec407a',
    'font-size': '30px'
  },
  starDisabled: {
    'font-size': '30px'
  },
  backgroundColor: {
    'background-color': 'green'
  },
  displayNone: {
    display: 'none'
  },
  alignCenter: {
    'text-align': 'center',
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  root: {
    flexGrow: 1,
    height: 50,
  },
  input: {
    display: 'flex',
    padding: 0,
    'text-align': 'center'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
    inputMoney: {
      'margin-left': '10px',
      'margin-right': '10px',
      width: '150px',
      input: {
        fontSize: "14px !important",
      }
  },
  align: {
      'text-align': 'right',
      'margin-right': '0px'
  },
    disabled2: {
      'background-color': 'rgba(200, 200, 200, 0.2)'
  },
  
  //Estilos del buscador autocpmpletable
menu: {
    'background-color': '#fff',
    width: '100%'
},
grow: {
  flexGrow: 1,
},
  rootSearcher: {
    flexGrow: 1,
    height: 40,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  Without: {
    height: "300px",
    "text-align": "center",
    "padding-top": "150px",
    "font-size": "23px"
  },
  ...buttonsStyle,
  ...extendedTablesStyle,
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...regularFormsStyle,
  ...dashboardStyle,
  ...cssVideo,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...styles,
});