import React from "react";

import GridItem from "components/Grid/GridItem.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Language from "@material-ui/icons/Language";
import GridContainer from "components/Grid/GridContainer.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import StarRatingComponent from 'react-star-rating-component';
import MuiVirtualizedTable from "./tableOfRow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const rowReason = (classes, data, key) => {
  return (
    <MenuItem
      key={key}
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
      value={data}
    >
      {data}
    </MenuItem>
  )
}

function Row({ ...props }) {
        const { classes } = props;

    var socialMediaString = '';
    props.advertisement.socialMediaTarget.forEach(element => {
        socialMediaString += element + ' ';
    })
    
   
      return (
        <GridItem xs={12} key={props.key}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Language />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {`${props.tags.postedIn} ${socialMediaString}`}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={3}>
                  <MuiVirtualizedTable 
                        item={props.advertisement} 
                        advertisementType={props.advertisementType} 
                        classes={classes}
                        tags={props.tags}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="fileinput text-center">
                    <CardHeader image className={classes.cardHeaderHover}>
                        <img src={props.advertisement.multimediaUri} alt="..." />
                    </CardHeader>
                    </div>
                    <Tooltip
                        id="tooltip-top"
                        title={props.advertisement.caption}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                        >
                        <h3 className={classes.cardTitle}>
                        {
                          props.advertisement.hashtagIncluded ?
                          `${props.advertisement.resources.hashtag.value} ${props.advertisement.caption}`
                          :
                          props.advertisement.caption
                        }
                        </h3>
                    </Tooltip>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    
                    <Table
                      tableData={[
                        [
                           `${props.tags.user}:` ,
                            `${props.advertisement._person.firstName} ${props.advertisement._person.lastName}` ,
                            ],
                        [
                          `${props.tags.starPlatform}:`,
                        props.advertisement._person.platformStars.toFixed(2),
                        ],
                        [
                          `${props.tags.starEnterprise}:`,
                            props.advertisement._person.customerStars.toFixed(2),
                        ]
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                  <label className={classes.alignCenterMio}>
                    <input type="checkbox" className={classes.checkedMio}
                      checked={props.advertisement.approve}
                      onChange={() => props.handlerApprove(props.advertisement._id)}
                    />
                    {props.tags.approve}
                  </label>
                  {
                    props.advertisement.approve ?
                    <StarRatingComponent 
                      name={props.advertisement._id}
                      starColor="#ffb400"
                      emptyStarColor="#ffb400"
                      starCount={5}
                      value={
                          props.history.location.state.status === "WaitingForPlatformAudit" ?
                              props.advertisement.platformScore
                              :
                              props.history.location.state.status === "WaitingForCustomerAudit" ?
                                  props.advertisement.custumerScore
                                  :
                                  0
                      }
                      onStarClick={props.handlerStar}
                      renderStarIcon={(index, value) => {
                        return (
                          <h2>
                          <span>
                            <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                          </span>
                          </h2>
                        );
                      }}
                      renderStarIconHalf={() => {
                        return (
                          <h2>
                          <span>
                            <span style={{position: 'absolute'}}><i className="far fa-star" /></span>
                            <span><i className="fas fa-star-half" /></span>
                          </span>
                          </h2>
                        );
                      }}
                    />
                    :
                      <FormControl fullWidth className={`${classes.selectFormControl} ${classes.height120}` }>
                        <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                        >
                          {props.tags.reason}
                        </InputLabel>
                        <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={props.advertisement.rejectionReason}
                                onChange={props.handlerRejection}
                                inputProps={{
                                  name: props.advertisement._id,
                                  id: props.advertisement._id
                                }}
                        >
                          <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                          >
                            {props.tags.selectReason}
                          </MenuItem>
                          {
                            props.advertisement.rejectionReasonList.map((item, index) => (
                              rowReason(classes, item, index)
                          ))
                          }
                        </Select>
                    </FormControl>
                  }
                    <h4>
                      {props.tags.amountToFreezed}
                    </h4>
                    <h4> {`$ `}
                      {
                        props.advertisementType === "Sponsorship" ? 
                          (props.advertisement._person.sponsorshipEffectivenessReference.freezedBudget).toFixed(2) 
                          :
                          props.advertisementType === "Advertising" ?
                              (props.advertisement._person.advertisingEffectivenessReference.freezedBudget).toFixed(2)
                              :
                              props.advertisementType === "Spot" ?
                                (props.advertisement._person.spotEffectivenessReference.freezedBudget).toFixed(2)
                                :
                                0
                        }
                    </h4>
                    <h3>
                      {props.tags.amountPayable}
                    </h3>
                  {
                      <h3> {`$ `}
                      {
                        props.advertisementType === "Sponsorship" ? 
                        props.advertisement.amountPayableSponsorship
                        :
                        props.advertisementType === "Advertising" ? 
                            props.advertisement.amountPayableAdvertising
                            :
                          props.advertisementType === "Spot" ? 
                            props.advertisement.amountPayableSpot
                            :
                            0
                        }
                        </h3>
                  }

                  
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
                  
      )
}

export default Row;