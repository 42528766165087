import {put, takeLatest } from "redux-saga/effects";
import { 
    fetchAgesDataSuccess,
    fetchAgesDataFail
 } from "../actions/ages";
import {
    FETCH_AGES_DATA
} from '../actionTypes';
import api from '../api';

function* fetchAgesData(action) {
    try {
          let data = yield api.ages.getAgesData(action.payload);
          yield put(fetchAgesDataSuccess({data:data.ages}));
    } catch (e) {
          yield put(fetchAgesDataFail());
    }
}

export default function* agesSaga() {
    yield takeLatest(FETCH_AGES_DATA, fetchAgesData);
}