import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCampaignHeaderAuditData } from "../../../state/actions/campaigns";
import { fetchAdvertisementsData,
  fetchChangeApprove,
  fetchChangeRejectionReason,
  fetchChangeCheckedAll,
  fetchSaveAndMore,
  fetchChangeStar } from "../../../state/actions/advertisements";
import Header from "./header";
import Row from "./row";
import tags from "./tags";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NavLink } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

const styles = {
  height120: {
    height: "120px"
  },
  alignCenterMio: {
    "font-size": "35px"
  },
  checkedMio: {
    width: "35px",
    height: "35px",
    "font-size": "25px"
  },
    ...dashboardStyle,
    ...regularFormsStyle,
    ...sweetAlertStyle
  };

class Audit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      show: false
    };

    this.onStarClick = this.onStarClick.bind(this);
    this.handlerApprove = this.handlerApprove.bind(this);
    this.handlerRejection = this.handlerRejection.bind(this);
    this.handlerCheckAll = this.handlerCheckAll.bind(this);
    this.handlerSaveAndMore = this.handlerSaveAndMore.bind(this);
    this.handlerStar = this.handlerStar.bind(this);
    this.basicAlert = this.basicAlert.bind(this);
}

  componentWillMount() {
    this.props.fetchCampaignHeaderAuditData({_id: this.props.history.location.state.campaignId});
    this.props.fetchAdvertisementsData({campaignId: this.props.history.location.state.campaignId, status: this.props.history.location.state.status, page: 0, limit: 3});
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({star: nextValue});
  }

  handlerApprove(itemId) {
    this.props.fetchChangeApprove({_id: itemId});
  }

  handlerRejection (event) {
    this.props.fetchChangeRejectionReason({_id: event.target.name, rejectionReason: event.target.value});
  }

  handlerCheckAll () {
      this.props.fetchChangeCheckedAll({checked: !this.props.checkedAll});
  }

  handlerStar(nextValue, prevValue, name) {
    this.props.fetchChangeStar({_id: name, score: nextValue});
  }

  handlerSaveAndMore() {
    let ok = true;
    this.props.advertisements.forEach(element => {
      if (!element.approve && element.rejectionReason === "None") {
        this.basicAlert();
        ok = false;
        return;
      }
    });

    if (ok) this.props.fetchSaveAndMore({advertisements: this.props.advertisements});
  }

  basicAlert() {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={this.props.tags.selectDisapproval}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer justify="space-between">

        </GridContainer>
        <GridContainer>
        <GridItem xs={12} sm={12}>
          <GridContainer justify="space-between">
            <Header {...this.props}/>
          <GridItem xs={12} sm={9} className={classes.alignCenter}>
          </GridItem>
          <GridItem xs={12} sm={2} className={classes.alignCenter}>
          {
            this.props.waitingSaveAndMore ?
              "wait please"
              :
              this.props.advertisements.length === 0 ?
                null
                :
                <Button color="info" onClick={this.handlerSaveAndMore}>
                  {this.props.tags.saveAndMore}
                </Button>
          }


          </GridItem>
          <GridItem xs={12} sm={1} className={classes.alignCenter}>
          {
            this.props.waitingSaveAndMore ?
            null
            :
            this.props.advertisements.length === 0 ?
              null
              :
              <Tooltip
                      id="tooltip-top"
                      title={this.props.tags.selectAll}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                      >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.checkedAll}
                            tabIndex={-1}
                            onClick={() => this.handlerCheckAll()}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={this.props.tags.all}
                      />
              </Tooltip>
          }
          </GridItem>
          </GridContainer>
        </GridItem>
        {
          this.props.waitingSaveAndMore ?
          "Wait please"
          :
          this.props.advertisements.length === 0 ?
            <NavLink to={{pathname:"/panel/Campaigns",
                state: { companyId: this.props.history.location.state !== undefined ? this.props.history.location.state.companyId : this.props.login.user.company.id,
                    companyName: this.props.history.location.state !== undefined ? this.props.history.location.state.companyName : this.props.login.user.company.name}}}>
             {this.props.tags.noAdvertisementToAudit}  
            </NavLink>
            :
            this.props.advertisements.map((item, index) => (
              <Row
              key={index}
              advertisementType={this.props.campaignHeaderAudit.type}
              handlerStar={this.handlerStar}
              handlerApprove={this.handlerApprove}
              handlerRejection={this.handlerRejection}
              advertisement={item}
              {...this.props}/>
            ))
          }
          <GridItem xs={12} sm={12}>
            <GridContainer justify="space-between">

            <GridItem xs={12} sm={9} className={classes.alignCenter}>
            </GridItem>
            <GridItem xs={12} sm={2} className={classes.alignCenter}>
            {
            this.props.waitingSaveAndMore ?
              null
              :
              this.props.advertisements.length === 0 ?
                null
                :
                <Button color="info" onClick={this.handlerSaveAndMore}>
               {this.props.tags.saveAndMore}   
                </Button>
            }

            </GridItem>
            <GridItem xs={12} sm={1} className={classes.alignCenter}>
            {
            this.props.waitingSaveAndMore ?
            null
            :
            this.props.advertisements.length === 0 ?
              null
              :
              <Tooltip
                      id="tooltip-top"
                      title={this.props.tags.selectAll}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                      >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.checkedAll}
                            tabIndex={-1}
                            onClick={() => this.handlerCheckAll()}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={this.props.tags.all}
                      />
              </Tooltip>
          }

            </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Audit.propTypes = {
  classes: PropTypes.object.isRequired
};

//export default withStyles(dashboardStyle)(Dashboard);


function mapStateToProps(state){
  return {
    campaignHeaderAudit: state.campaigns.campaignHeaderAudit,
    advertisements: state.advertisements.advertisements,
    checkedAll: state.advertisements.checkedAll,
    waitingSaveAndMore: state.advertisements.waitingSaveAndMore
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchCampaignHeaderAuditData: bindActionCreators(fetchCampaignHeaderAuditData, dispatch),
    fetchAdvertisementsData: bindActionCreators(fetchAdvertisementsData, dispatch),
    fetchChangeApprove: bindActionCreators(fetchChangeApprove, dispatch),
    fetchChangeRejectionReason: bindActionCreators(fetchChangeRejectionReason, dispatch),
    fetchChangeCheckedAll: bindActionCreators(fetchChangeCheckedAll, dispatch),
    fetchSaveAndMore: bindActionCreators(fetchSaveAndMore, dispatch),
    fetchChangeStar: bindActionCreators(fetchChangeStar, dispatch)
  }
}
export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Audit)));
