import { FETCH_AGES_DATA_SUCCESS,
} from "../actionTypes";

const initialState = {
    ages: []
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_AGES_DATA_SUCCESS:
       return {
        ages: action.payload.data,
   }
   default:
       return state;
    }
}