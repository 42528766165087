
import {
    FETCH_CREATE_PREDRAFT_SUCCESS,
    FETCH_CHANGE_SPECIFICTARGET,
    FETCH_UPDATE_CAMPAIGN_SUCCESS,
    FETCH_CHANGE_TYPE,
    FETCH_CHANGE_NAME,
    FETCH_CHANGE_STARTDT,
    FETCH_CHANGE_ENDDT,
    FETCH_CHANGE_RESOURCE_VALUE,
    FETCH_CHANGE_RESOURCE_ENABLED,
    FETCH_IMAGE_URL_SUCCESS,
    FETCH_VIDEO_URL_SUCCESS,
    DELETE_SPOT,
    FETCH_CHANGE_REGION,
    FETCH_ADD_REGION,
    FETCH_REMOVE_REGION,
    FETCH_CAMPAIGN_FOR_UPDATE_DATA_SUCCESS,
    FETCH_CHANGE_INVESTMENT,
    FETCH_CHANGE_STOCK,
    FETCH_CHANGE_CUSTOM_PRICES_ENABLED,
    FETCH_CHANGE_CUSTOM_AD_PRICE,
    FETCH_CHANGE_CUSTOM_INSIGHT_MULTIPLIER,
    FETCH_CHANGE_STATUS,
    FETCH_CHANGE_SEXES,
    FETCH_CHANGE_SEXES_ADD,
    FETCH_CHANGE_SEXES_REMOVE,
    FETCH_CHANGE_STARS,
    FETCH_CHANGE_AGES,
    FETCH_CLEAN_CAMPAIGN_CREATION,
    FETCH_CHANGE_CATEGORIES,
    FETCH_CHANGE_CATEGORIES_ADD,
    FETCH_CHANGE_CATEGORIES_REMOVE,
    LOADING_CAMPAIGN_FOR_UPDATE,
    LOADING_CAMPAIGN_PRE_DRAFT,
    FETCH_CHANGE_SPOTEFFECTIVENESSES,
    FETCH_CHANGE_SPORNSORSHIP_EFFECTIVENESSES,
    FETCH_CHANGE_ADVERTISING_EFFECTIVENESSES,
    FETCH_ADD_PEOPLE,
    FETCH_REMOVE_PEOPLE,
    FEtCH_UPDATE_PEOPLE,
    FETCH_CHANGE_BRIEF,
    FETCH_CHANGE_PAYMENT_TYPE,
    FETCH_CHANGE_INFLUENCEPEOPLEAS,
    FETCH_CHANGE_FOLLOWERS,
    FETCH_CHANGE_PAYMENT_PER_EACH,
    FETCH_CHANGE_PRODUCT_PAYMENT_DESCRIPTION,
    FETCH_CHANGE_ADS_PER_IR,
    FETCH_CHANGE_SPOT_DESCRIPTION,
    FETCH_CHANGE_MEDIA,
    FETCH_CHANGE_VIRTUAL,
    FETCH_CHANGE_CELLPHONE
    } from "../actionTypes";


const initialState = {
    existingCampaign: null,
    _id: null,
    nameReadonly: String,
    followers: false,
    enabledEdit: true,
    influencePeopleAs: 'IR',
    adsPerIR: 1,
    type: 'Spot',
    paymentType: "Product",
    productPaymentDescription: String,
    paymentPerEach: 1,
    paymentTypeList: ["Money", "Product"],
    brief: String,
    status: 'PreDraft',
    statusReadonly: 'PreDraft',
    nextStatuses: ["PreDraft", "Draft", "OnGoing", "WaitingForPayment", "WaitingForApproval", "Stopped", "Finished"],
    active: true,
    name: '',
    creationDt: new Date(),
    startDt: new Date(),
    investment: 0,
    stock: 0,
    customPricesEnabled: false,
    customAdPrice: 0,
    customInsightMultiplier: 1,
    endDt: new Date().setDate(new Date().getDate() + 10),
    media: {
        file: null,
        type: null,
        imagePreviewUrl: null,
        videoPreviewUrl: null,
    },
    resources: {
		frame: {enabled: true, mandatory: false},
        hashtag: { enabled: true, value: '', mandatory: false },
        webSite: { enabled: true, value: '', mandatory: false },
        tagMentionMe: { enabled: true, mandatory: false, facebook: { enabled: true, value: ''}, instagram: { enabled: true, value: ''}, twitter: { enabled: false, value: ''} },
        seal: { enabled: true, value: 'companyNameUI', mandatory: false },
        tagMentionPeople: { enabled: true, mandatory: false },
        productUsage: { enabled: true, mandatory: false },
        productUsageOficial: { enabled: false, value: '', mandatory: false  },
		spot: {
			imageEnabled: false,
			image: null,
			description: String,
			videoEnabled: false,
            video: null,
		}
    },
    categoryCriterias: [],
    regionCriterias: [],
    regionCriteriasReadonly: [],
    regionChecked: false,
    sexCriterias: [],
    customerStarCriterias: [],
    platformStarCriterias: [],
    platformStarCriteriasRead: [
        {enabled: true, value: 1},
        {enabled: true, value: 2},
        {enabled: true, value: 3},
        {enabled: true, value: 4},
        {enabled: true, value: 5}
    ],
    ageCriterias: [],
    ageCriteriasSlideFrom: 0,
    ageCriteriasSlideTo: 99,
    ageCriteriasLabelFrom: 0,
    ageCriteriasLabelTo: 99,
    specificTargetScope: [],
    sponsorshipEffectivenessCriterias: [],
    sponsorshipEffectivenessCriteriasChecked: false,
    sponsorshipEffectivenessCriteriasStarIdSlide: 0,
    sponsorshipEffectivenessCriteriasEndIdSlide: 0,
    sponsorshipEffectivenessCriteriasObjectFrom: null,
    sponsorshipEffectivenessCriteriasObjectTo: null,
    spotEffectivenessCriterias: [],
    spotEffectivenessCriteriasChecked: false,
    spotEffectivenessCriteriasStarIdSlide: 0,
    spotEffectivenessCriteriasEndIdSlide: 0,
    spotEffectivenessCriteriasObjectFrom: null,
    spotEffectivenessCriteriasObjectTo: null,
    advertisingEffectivenessCriterias: [],
    advertisingEffectivenessCriteriasChecked: false,
    advertisingEffectivenessCriteriasStarIdSlide: 0,
    advertisingEffectivenessCriteriasEndIdSlide: 0,
    advertisingEffectivenessCriteriasObjectFrom: null,
    advertisingEffectivenessCriteriasObjectTo: null,
    specificTarget: false,
    loadingCampaignPreDraft: true,
    loadingCampaignForUpdate: true,
    virtual: false,
    whatsappNumber: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHANGE_MEDIA: {
            return {
                ...state,
                media: {
                    file: action.payload.file,
                    type: action.payload.type,
                    imagePreviewUrl: action.payload.imagePreviewUrl,
                    videoPreviewUrl: action.payload.videoPreviewUrl,
                }
            }
        }
        case FETCH_CHANGE_SPOT_DESCRIPTION: {
            return {
                ...state,
                resources: {
                    ...state.resources, 
                    spot: {
                      ...state.resources.spot,
                      description: action.payload
                    }
                  }
            }
        }
        case FETCH_CHANGE_ADS_PER_IR: {
            return {
                ...state,
                adsPerIR: action.payload
            }
        }
        case FETCH_CHANGE_PRODUCT_PAYMENT_DESCRIPTION: {
            return {
                ...state,
                productPaymentDescription: action.payload
            }
        }
        case FETCH_CHANGE_PAYMENT_PER_EACH: {
            return {
                ...state,
                paymentPerEach: action.payload
            }
        }
        case FETCH_CHANGE_FOLLOWERS: {
            return {
                ...state,
                followers: action.payload
            }
        }
        case FETCH_CHANGE_INFLUENCEPEOPLEAS: {
            return {
                ...state,
                influencePeopleAs: action.payload
            }
        }
        case FETCH_CHANGE_PAYMENT_TYPE:{
            return {
                ...state,
                paymentType: action.payload
            }
        }
            
        case FETCH_CHANGE_BRIEF: {
            return {
                ...state,
                brief: action.payload
            }
        }
        case FEtCH_UPDATE_PEOPLE: {
            return {
                ...state,
                specificTargetScope: action.payload,
            }
        }
        case FETCH_ADD_PEOPLE: {
            return {
                ...state,
                specificTargetScope: [...state.specificTargetScope, action.payload],
            }
        }
        case FETCH_REMOVE_PEOPLE:
            return {
                ...state,
                specificTargetScope: state.specificTargetScope.reduce((collection, item) => {
                    if(item._id !== action.payload._id){
                        collection.push(item);
                    }
                    return collection;
                }, [])
        }
        case FETCH_CHANGE_ADVERTISING_EFFECTIVENESSES: {
            if (action.payload.from.toFixed(0) !== 0 || action.payload.to.toFixed(0) !== 0) {
                let advertisingEffectivenessCriteriasObjectFrom = state.advertisingEffectivenessCriteriasObjectFrom;
                let advertisingEffectivenessCriteriasObjectTo = state.advertisingEffectivenessCriteriasObjectTo;
                let advertisingEffectivenessCriteriasStarIdSlide = state.advertisingEffectivenessCriteriasStarIdSlide;
                let advertisingEffectivenessCriteriasEndIdSlide = state.advertisingEffectivenessCriteriasEndIdSlide;

                let advertisingEffectiveness = [];
                for (let index = 0; index < action.payload.items.length; index++) {
                    const item = action.payload.items[index];
                    if(item.idSlide >= action.payload.from && item.idSlide <= action.payload.to){
                        advertisingEffectiveness.push({
                            _id: item._id,
                            from: item.from,
                            to: item.to,
                            AdPrice: item.AdPrice,
                            framePrice: item.framePrice,
                            hashtagPrice: item.hashtagPrice,
                            webSitePrice: item.webSitePrice,
                            tagMentionMePrice: item.tagMentionMePrice,
                            sealPrice: item.sealPrice,
                            tagMentionPeoplePrice: item.tagMentionPeoplePrice,
                            productUsagePrice: item.productUsagePrice,
                            insightAveragePrice: item.insightAveragePrice,
                            freezedBudget: item.freezedBudget,
                            insightPriceFactor: item.insightPriceFactor,
                            totalPrice: item.totalPrice
                        });
                    }
                    if (item.idSlide.toString() === action.payload.from.toFixed(0)) {
                        advertisingEffectivenessCriteriasObjectFrom = item;
                    }
                    if (item.idSlide.toString() === action.payload.to.toFixed(0)) {
                        advertisingEffectivenessCriteriasObjectTo = item;
                    }
                }

                if (action.payload.changeSlide) {
                    advertisingEffectivenessCriteriasStarIdSlide = action.payload.from;
                    advertisingEffectivenessCriteriasEndIdSlide = action.payload.to;
                }
                return {
                    ...state,
                    advertisingEffectivenessCriterias: advertisingEffectiveness,
                    advertisingEffectivenessCriteriasChecked: action.payload.checked,
                    advertisingEffectivenessCriteriasStarIdSlide,
                    advertisingEffectivenessCriteriasEndIdSlide,
                    advertisingEffectivenessCriteriasObjectFrom,
                    advertisingEffectivenessCriteriasObjectTo,
                }
            } else {
                return {
                    ...state,
                    advertisingEffectivenessCriterias: [],
                    advertisingEffectivenessCriteriasChecked: false,
                    advertisingEffectivenessCriteriasStarIdSlide: 0,
                    advertisingEffectivenessCriteriasEndIdSlide: 0,
                    advertisingEffectivenessCriteriasObjectFrom: null,
                    advertisingEffectivenessCriteriasObjectTo: null,
                }
            }
        }
        case FETCH_CHANGE_SPORNSORSHIP_EFFECTIVENESSES: {
            if (action.payload.from.toFixed(0) !== 0 || action.payload.to.toFixed(0) !== 0) {
                let sponsorshipEffectivenessCriteriasObjectFrom = state.sponsorshipEffectivenessCriteriasObjectFrom;
                let sponsorshipEffectivenessCriteriasObjectTo = state.sponsorshipEffectivenessCriteriasObjectTo;
                let sponsorshipEffectivenessCriteriasStarIdSlide = state.sponsorshipEffectivenessCriteriasStarIdSlide;
                let sponsorshipEffectivenessCriteriasEndIdSlide = state.sponsorshipEffectivenessCriteriasEndIdSlide;

                let sponsorshipEffectiveness = [];
                for (let index = 0; index < action.payload.items.length; index++) {
                    const item = action.payload.items[index];
                    if(item.idSlide >= action.payload.from && item.idSlide <= action.payload.to){
                        sponsorshipEffectiveness.push({
                            _id: item._id,
                            from: item.from,
                            to: item.to,
                            AdPrice: item.AdPrice,
                            framePrice: item.framePrice,
                            hashtagPrice: item.hashtagPrice,
                            webSitePrice: item.webSitePrice,
                            tagMentionMePrice: item.tagMentionMePrice,
                            sealPrice: item.sealPrice,
                            tagMentionPeoplePrice: item.tagMentionPeoplePrice,
                            productUsagePrice: item.productUsagePrice,
                            insightAveragePrice: item.insightAveragePrice,
                            freezedBudget: item.freezedBudget,
                            insightPriceFactor: item.insightPriceFactor,
                            totalPrice: item.totalPrice
                        });
                    }
                    if (item.idSlide.toString() === action.payload.from.toFixed(0)) {
                        sponsorshipEffectivenessCriteriasObjectFrom = item;
                    }
                    if (item.idSlide.toString() === action.payload.to.toFixed(0)) {
                        sponsorshipEffectivenessCriteriasObjectTo = item;
                    }
                }

                if (action.payload.changeSlide) {
                    sponsorshipEffectivenessCriteriasStarIdSlide = action.payload.from;
                    sponsorshipEffectivenessCriteriasEndIdSlide = action.payload.to;
                }
                return {
                    ...state,
                    sponsorshipEffectivenessCriterias: sponsorshipEffectiveness,
                    sponsorshipEffectivenessCriteriasChecked: action.payload.checked,
                    sponsorshipEffectivenessCriteriasStarIdSlide,
                    sponsorshipEffectivenessCriteriasEndIdSlide,
                    sponsorshipEffectivenessCriteriasObjectFrom,
                    sponsorshipEffectivenessCriteriasObjectTo,
                }
            } else {
                return {
                    ...state,
                    sponsorshipEffectivenessCriterias: [],
                    sponsorshipEffectivenessCriteriasChecked: false,
                    sponsorshipEffectivenessCriteriasStarIdSlide: 0,
                    sponsorshipEffectivenessCriteriasEndIdSlide: 0,
                    sponsorshipEffectivenessCriteriasObjectFrom: null,
                    sponsorshipEffectivenessCriteriasObjectTo: null,
                }
            }
        }
        case FETCH_CHANGE_SPOTEFFECTIVENESSES: {
            if (action.payload.from.toFixed(0) !== 0 || action.payload.to.toFixed(0) !== 0) {
                let spotEffectivenessCriteriasObjectFrom = state.spotEffectivenessCriteriasObjectFrom;
                let spotEffectivenessCriteriasObjectTo = state.spotEffectivenessCriteriasObjectTo;
                let spotEffectivenessCriteriasStarIdSlide = state.spotEffectivenessCriteriasStarIdSlide;
                let spotEffectivenessCriteriasEndIdSlide = state.spotEffectivenessCriteriasEndIdSlide;

                let spotEffectiveness = [];
                for (let index = 0; index < action.payload.items.length; index++) {
                    const item = action.payload.items[index];
                    if(item.idSlide >= action.payload.from && item.idSlide <= action.payload.to){
                        spotEffectiveness.push({
                            _id: item._id,
                            from: item.from,
                            to: item.to,
                            AdPrice: item.AdPrice,
                            freezedBudget: item.freezedBudget,
                            insightPriceFactor: item.insightPriceFactor,
                            totalPrice: item.totalPrice
                        });
                    }
                    if (item.idSlide.toString() === action.payload.from.toFixed(0)) {
                        spotEffectivenessCriteriasObjectFrom = item;
                    }
                    if (item.idSlide.toString() === action.payload.to.toFixed(0)) {
                        spotEffectivenessCriteriasObjectTo = item;
                    }
                }

                if (action.payload.changeSlide) {
                    spotEffectivenessCriteriasStarIdSlide = action.payload.from;
                    spotEffectivenessCriteriasEndIdSlide = action.payload.to;
                }
                return {
                    ...state,
                    spotEffectivenessCriterias: spotEffectiveness,
                    spotEffectivenessCriteriasObjectFrom,
                    spotEffectivenessCriteriasObjectTo,
                    spotEffectivenessCriteriasChecked: action.payload.checked,
                    spotEffectivenessCriteriasStarIdSlide,
                    spotEffectivenessCriteriasEndIdSlide,
                }
            } else {
                return {
                    ...state,
                    spotEffectivenessCriterias: [],
                    spotEffectivenessCriteriasObjectFrom: null,
                    spotEffectivenessCriteriasObjectTo: null,
                    spotEffectivenessCriteriasChecked: false,
                    spotEffectivenessCriteriasStarIdSlide: 0,
                    spotEffectivenessCriteriasEndIdSlide: 0,
                }
            }
        }
        case LOADING_CAMPAIGN_FOR_UPDATE: {
            return {
                ...state,
                loadingCampaignForUpdate: action.payload,
            }
        }
        case LOADING_CAMPAIGN_PRE_DRAFT: {
            return {
                ...state,
                loadingCampaignPreDraft: action.payload,
            }
        }
        case FETCH_CHANGE_CATEGORIES_ADD: {
            return {
                ...state,
                categoryCriterias: [...state.categoryCriterias, action.payload],
            }
        }
        case FETCH_CHANGE_CATEGORIES_REMOVE:
            return {
                ...state,
                categoryCriterias: state.categoryCriterias.reduce((collection, item) => {
                    if(item._id !== action.payload._id){
                        collection.push(item);
                    }
                    return collection;
                }, [])
            }
        case FETCH_CHANGE_CATEGORIES: {
            return {
                ...state,
                categoryCriterias: action.payload,
            }
        }
        case FETCH_CLEAN_CAMPAIGN_CREATION: 
        return {
            existingCampaign: null,
            _id: null,
            name: '',
            nameReadonly: '',
            followers: false,
            enabledEdit: true,
            influencePeopleAs: 'IR',
            adsPerIR: 1,
            type: 'Spot',
            paymentType: "Product",
            productPaymentDescription: '',
            paymentPerEach: 1,
            paymentTypeList: ["Money", "Product"],
            brief: '',
            specificTarget: false, 
            status: 'PreDraft',
            statusReadonly: 'PreDraft',
            nextStatuses: ["PreDraft", "Draft", "OnGoing", "WaitingForPayment", "WaitingForApproval", "Stopped", "Finished"],
            active: true,
            creationDt: new Date(),
            startDt: new Date(),
            investment: 0,
            stock: 0, 
            customPricesEnabled: false,
            customAdPrice: 0,
            customInsightMultiplier: 1,
            endDt: new Date().setDate(new Date().getDate() + 10),
            media: {
                file: null,
                type: null,
                imagePreviewUrl: null,
                videoPreviewUrl: null,
            },
            resources: {
                frame: {enabled: true, mandatory: false},
                hashtag: { enabled: true, value: '', mandatory: false },
                webSite: { enabled: true, value: '', mandatory: false },
                tagMentionMe: { enabled: true, mandatory: false, facebook: { enabled: true, value: ''}, instagram: { enabled: true, value: ''}, twitter: { enabled: false, value: ''} },
                seal: { enabled: true, value: 'companyNameUI', mandatory: false },
                tagMentionPeople: { enabled: true, mandatory: false },
                productUsage: { enabled: true, mandatory: false },
                productUsageOficial: { enabled: false, value: '', mandatory: false  },
                spot: {
                    imageEnabled: false,
                    image: null,
                    description: String,
                    videoEnabled: false,
                    video: null,
                }
            },
            categoryCriterias: [],
            regionCriterias: [],
            regionCriteriasReadonly: [],
            regionChecked: false,
            sexCriterias: [],
            customerStarCriterias: [],
            platformStarCriterias: [],
            platformStarCriteriasRead: [
                {enabled: true, value: 1},
                {enabled: true, value: 2},
                {enabled: true, value: 3},
                {enabled: true, value: 4},
                {enabled: true, value: 5}
            ],
            ageCriterias: [],
            ageCriteriasSlideFrom: 0,
            ageCriteriasSlideTo: 99,
            ageCriteriasLabelFrom: 0,
            ageCriteriasLabelTo: 99,
            specificTargetScope: [],
            sponsorshipEffectivenessCriterias: [],
            sponsorshipEffectivenessCriteriasChecked: false,
            sponsorshipEffectivenessCriteriasStarIdSlide: 0,
            sponsorshipEffectivenessCriteriasEndIdSlide: 0,
            sponsorshipEffectivenessCriteriasObjectFrom: null,
            sponsorshipEffectivenessCriteriasObjectTo: null,
            spotEffectivenessCriterias: [],
            spotEffectivenessCriteriasChecked: false,
            spotEffectivenessCriteriasStarIdSlide: 0,
            spotEffectivenessCriteriasEndIdSlide: 0,
            spotEffectivenessCriteriasObjectFrom: null,
            spotEffectivenessCriteriasObjectTo: null,
            advertisingEffectivenessCriterias: [],
            advertisingEffectivenessCriteriasChecked: false,
            advertisingEffectivenessCriteriasStarIdSlide: 0,
            advertisingEffectivenessCriteriasEndIdSlide: 0,
            advertisingEffectivenessCriteriasObjectFrom: null,
            advertisingEffectivenessCriteriasObjectTo: null,
            loadingCampaignPreDraft: true,
            loadingCampaignForUpdate: true,
        }
        case FETCH_CHANGE_AGES: {
            if (action.payload.from.toFixed(0) !== 0 || action.payload.to.toFixed(0) !== 0) {
                let ageCriteriasLabelFrom = state.ageCriteriasLabelFrom;
                let ageCriteriasLabelTo = state.ageCriteriasLabelTo;
                let ageCriteriasSlidFrom = state.ageCriteriasSlidFrom;
                let ageCriteriasSlideTo = state.ageCriteriasSlideTo;

                ageCriteriasLabelFrom = action.payload.from.toFixed(0);
                ageCriteriasLabelTo = action.payload.to.toFixed(0);
                if (action.payload.changeSlide) {
                    ageCriteriasSlidFrom = action.payload.from;
                    ageCriteriasSlideTo = action.payload.to;
                }
                let ages = action.payload.ages.reduce((collection, item) => {
                    if(item.age >= action.payload.from && item.age <= action.payload.to){
                        collection.push(item);
                    }
                    return collection;
                }, [])
                
                return {
                    ...state,
                    ageCriterias: ages,
                    ageCriteriasLabelFrom,
                    ageCriteriasLabelTo,
                    ageCriteriasSlidFrom,
                    ageCriteriasSlideTo
                }
            } else {
                return {
                    ...state,
                    ageCriterias: [],
                    ageCriteriasLabelFrom: 0,
                    ageCriteriasLabelTo: 0,
                    ageCriteriasSlideFrom: 0,
                    ageCriteriasSlideTo: 0
                }
            }
        }
        
        case FETCH_CHANGE_STARS:
            return {
                ...state,
                platformStarCriterias: action.payload.forBD,
                platformStarCriteriasRead: action.payload.forUI
            }
        case FETCH_CHANGE_SEXES:
            return {
                ...state,
                sexCriterias: action.payload
            }
        case FETCH_CHANGE_SEXES_ADD:
            return {
                ...state,
                sexCriterias: [...state.sexCriterias, action.payload],
            }
        case FETCH_CHANGE_SEXES_REMOVE:
            return {
                ...state,
                sexCriterias: state.sexCriterias.reduce((collection, item) => {
                    if(item._id !== action.payload._id){
                        collection.push(item);
                    }
                    return collection;
                }, [])
            }
        case FETCH_CAMPAIGN_FOR_UPDATE_DATA_SUCCESS: {
            //ENABLEDEDIT
            let enabledEdit = state.enabledEdit;
            if(action.payload[0].status === "Draft" || action.payload[0].status === "PreDraft")
            enabledEdit = true;
            else enabledEdit = false;

            //AGE
            let ageCriteriasSlideFrom = state.ageCriteriasSlideFrom;
            let ageCriteriasSlideTo = state.ageCriteriasSlideTo;
            let ageCriteriasLabelFrom = state.ageCriteriasLabelFrom;
            let ageCriteriasLabelTo = state.ageCriteriasLabelTo;

            if (action.payload[0].ageCriterias.length !== 0){
                ageCriteriasSlideFrom = action.payload[0].ageCriterias[0].age;
                ageCriteriasSlideTo = action.payload[0].ageCriterias[action.payload[0].ageCriterias.length-1].age;
                ageCriteriasLabelFrom = action.payload[0].ageCriterias[0].age;
                ageCriteriasLabelTo = action.payload[0].ageCriterias[action.payload[0].ageCriterias.length-1].age;
            }
            //STARS
            let platformStarCriteriasRead =  state.platformStarCriteriasRead.reduce((collection, item) => {
                for (let index = 1; index < action.payload[0].platformStarCriterias.length; index++) {
                    const element = action.payload[0].platformStarCriterias[index];
                    if (element.stars === item.value) item.enabled = false;
                    
                }
                collection.push(item);
                return collection;
            }, []);
            return {
                ...state,
                existingCampaign: {...action.payload[0]},
                //_id: action.payload[0]._id,
                name: action.payload[0].name,
                nameReadonly: action.payload[0].name,
                followers: action.payload[0].followers,
                enabledEdit,
                type: action.payload[0].type,
                influencePeopleAs: action.payload[0].influencePeopleAs,
                adsPerIR: action.payload[0].adsPerIR,
                productPaymentDescription: action.payload[0].productPaymentDescription,
                paymentPerEach: action.payload[0].paymentPerEach,
                brief: action.payload[0].brief,
                paymentType: action.payload[0].paymentType,
                specificTarget: action.payload[0].specificTarget, 
                status: action.payload[0].status,
                statusReadonly: action.payload[0].status,
                nextStatuses: action.payload[0].nextStatuses,
                active: action.payload[0].active,
                creationDt: action.payload[0].creationDt,
                startDt: action.payload[0].startDt,
                endDt: action.payload[0].endDt,
                investment: action.payload[0].investment,
                stock: action.payload[0].stock,
                customPricesEnabled: action.payload[0].customPricesEnabled,
                customAdPrice: action.payload[0].customAdPrice,
                customInsightMultiplier: action.payload[0].customInsightMultiplier,
                resources: action.payload[0].resources,
                regionCriterias: action.payload[0].regionCriterias,
                regionCriteriasReadonly: action.payload[0].regionCriterias,
                regionChecked: action.payload[0].regionCriterias.length !== 0 ? true : false,
                sexCriterias: action.payload[0].sexCriterias,
                platformStarCriterias: action.payload[0].platformStarCriterias,
                platformStarCriteriasRead,
                customerStarCriterias: action.payload[0].customerStarCriterias,
                sponsorshipEffectivenessCriterias: action.payload[0].sponsorshipEffectivenessCriterias,
                sponsorshipEffectivenessCriteriasChecked: action.payload[0].sponsorshipEffectivenessCriterias.length !== 0 ? true :  false,
                advertisingEffectivenessCriterias: action.payload[0].advertisingEffectivenessCriterias,
                advertisingEffectivenessCriteriasChecked: action.payload[0].advertisingEffectivenessCriterias.length !== 0 ? true :  false,
                spotEffectivenessCriterias: action.payload[0].spotEffectivenessCriterias,
                spotEffectivenessCriteriasChecked: action.payload[0].spotEffectivenessCriterias.length !== 0 ? true : false, 
                categoryCriterias: action.payload[0].categoryCriterias,
                ageCriterias: action.payload[0].ageCriterias,
                ageCriteriasSlideFrom,
                ageCriteriasSlideTo,
                ageCriteriasLabelFrom,
                ageCriteriasLabelTo,
                specificTargetScope: action.payload[0].specificTargetScope,
                virtual: action.payload[0].virtual,
                whatsappNumber: action.payload[0].whatsappNumber,
            }
        }
        case FETCH_REMOVE_REGION:
            return {
            ...state,
            regionCriteriasReadonly: state.regionCriterias.reduce((collection, item) => {
                if(item._id !== action.payload){
                    collection.push(item);
                }
                return collection;
            }, []),
            regionCriterias: state.regionCriterias.reduce((collection, item) => {
                if(item._id !== action.payload){
                    collection.push(item);
                }
                return collection;
            }, [])
        }
        case FETCH_ADD_REGION:
            return {
            ...state,
            regionCriteriasReadonly: [...state.regionCriterias, action.payload],
            regionCriterias: [...state.regionCriterias, action.payload]
        }
        case FETCH_CHANGE_REGION: {
            return {
                ...state,
                regionCriterias: action.payload.items,
                regionChecked: action.payload.checked
            }
        }
        case FETCH_UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
        }
        case FETCH_CREATE_PREDRAFT_SUCCESS: {
            if (state.existingCampaign !== null) {
                return {
                ...state,
                _id: action.payload.data._id,
                }
            } else {
                 //ENABLEDEDIT
                let enabledEdit = state.enabledEdit;
                if(action.payload.data.status === "Draft" || action.payload.data.status === "PreDraft")
                enabledEdit = true;
                else enabledEdit = false;

                return {
                    ...state,
                    _id: action.payload.data._id,
                    name: action.payload.data.name,
                    nameReadonly: action.payload.data.name,
                    followers: action.payload.data.followers,
                    enabledEdit,
                    type: action.payload.data.type,
                    influencePeopleAs: action.payload.data.influencePeopleAs,
                    adsPerIR: action.payload.data.adsPerIR,
                    productPaymentDescription: action.payload.data.productPaymentDescription,
                    paymentPerEach: action.payload.data.paymentPerEach,
                    status: action.payload.data.status,
                    nextStatuses: action.payload.data.nextStatuses,
                    active: action.payload.data.actve,
                    creationDt: action.payload.data.creationDt,
                    startDt: action.payload.data.startDt,
                    endDt: action.payload.data.endDt,
                    investment: action.payload.data.investment,
                    stock: action.payload.data.stock,
                    customPricesEnabled:  action.payload.data.customPricesEnabled,
                    customAdPrice:  action.payload.data.customAdPrice,
                    customInsightMultiplier:  action.payload.data.customInsightMultiplier,
                    resources: action.payload.data.resources,
                    regionCriterias: action.payload.data.regionCriterias,
                    sexCriterias: action.payload.data.sexCriterias,
                    starCriterias: action.payload.data.starCriterias,
                    sponsorshipEffectivenessCriterias: action.payload.data.sponsorshipEffectivenessCriterias,
                    advertisingEffectivenessCriterias: action.payload.data.advertisingEffectivenessCriterias,
                    spotEffectivenessCriterias: action.payload.data.spotEffectivenessCriterias,
                    categoryCriterias: action.payload.data.categoryCriterias,
                    ageCriterias: action.payload.data.ageCriterias,
                    specificTargetScope: action.payload.data.specificTargetScope,
                    specificTarget: action.payload.data.specificTarget,
                    virtual: action.payload.data.virtual,
                    whatsappNumber: action.payload.data.whatsappNumber
                }
            }   
            
        }   
        case FETCH_CHANGE_SPECIFICTARGET:
            return {
            ...state,
            specificTarget: action.payload
        }
        case FETCH_CHANGE_TYPE:
            return {
            ...state,
            type: action.payload.type
        }
        case FETCH_CHANGE_STATUS:
            return {
                ...state,
                status: action.payload
        }
        case FETCH_CHANGE_NAME:
            return {
                ...state,
                name: action.payload
        }
        case FETCH_CHANGE_VIRTUAL:
          return {
                ...state,
                virtual: action.payload
        }
        case FETCH_CHANGE_CELLPHONE:
          return {
                ...state,
                whatsappNumber: action.payload
        }
        case FETCH_CHANGE_STARTDT:
            return {
            ...state,
            startDt: action.payload
        }
        case FETCH_CHANGE_ENDDT:
            return {
            ...state,
            endDt: action.payload
        }
        case FETCH_CHANGE_INVESTMENT:
            return {
                ...state,
                investment: action.payload
        }
        case FETCH_CHANGE_STOCK:
            return {
                ...state,
                stock: action.payload
        }
        case FETCH_CHANGE_CUSTOM_PRICES_ENABLED:
            return {
                ...state,
                customPricesEnabled: action.payload
        }
        case FETCH_CHANGE_CUSTOM_AD_PRICE:
            return {
                ...state,
                customAdPrice: action.payload
        }
        case FETCH_CHANGE_CUSTOM_INSIGHT_MULTIPLIER:
            return {
                ...state,
                customInsightMultiplier: action.payload
        }
        case FETCH_CHANGE_RESOURCE_VALUE:
            
            if( action.payload.key === 'tagMentionMe'){
                
                return {
                    ...state,
                    resources: {
                        ...state.resources, 
                        [action.payload.key]: {
                            ...state.resources[action.payload.key],
                            facebook: { 
                                ...state.resources[action.payload.key]['facebook'],
                                value: action.payload.value},
                            instagram: { 
                                ...state.resources[action.payload.key]['instagram'],
                                value: action.payload.value},
                            twitter: { 
                                ...state.resources[action.payload.key]['twitter'],
                                value: action.payload.value}
                        }
                      }
                    
                }
            } else {
                console.log('/// Yulian');
                return {
                    ...state,
                    resources: {
                        ...state.resources, 
                        [action.payload.key]: {
                          ...state.resources[action.payload.key],
                          value: action.payload.value
                        }
                      }
                    
                }
            }

            
        case FETCH_CHANGE_RESOURCE_ENABLED:
            return {
                ...state,
                resources: {
                    ...state.resources, 
                    [action.payload.key]: {
                      ...state.resources[action.payload.key],
                      enabled: action.payload.enabled
                    }
                  }
            }
        case FETCH_IMAGE_URL_SUCCESS: 
            return {
                ...state,
                resources: {
                    ...state.resources, 
                    spot: {
                        ...state.resources.spot,
                        imageEnabled: true,
                        image: action.payload,
                        videoEnabled: false,
                        video: null
                    }
                }
        }
        case FETCH_VIDEO_URL_SUCCESS: 
            return {
                ...state,
                resources: {
                    ...state.resources, 
                    spot: {
                        ...state.resources.spot,
                        imageEnabled: false,
                        image: null,
                        videoEnabled: true,
                        video: action.payload
                    }
                }
        }
        case DELETE_SPOT:
            return {
            ...state,
            resources: {
                ...state.resources, 
                spot: {
                    ...state.resources.spot,
                    imageEnabled: false,
                    image: null,
                    videoEnabled: false,
                    video: null
                }
            }
        }
        default:
            return state;
    }
}