import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import { 
    fetchChangeInvestment,
    fetchChangePaymentPerEach,

  } from "../../state/actions/campaignCreation";


class AutomaticAdjustmentInvestment extends React.Component {
    constructor(props) {
        super(props);
        this.automaticAdjustmentInvestment = this.automaticAdjustmentInvestment.bind(this);
    }
    automaticAdjustmentInvestment() {
        if (this.props.enabledEdit) {
          if (this.props.paymentType == "Money") {
            let investmentRequired = this.props.campaignCreationSubscription.influencersScopeCount == 0 ? 0 : this.props.campaignCreationSubscription.investmentRequired * this.props.campaignCreationSubscription.influencersScopeCount;
            this.props.fetchChangeInvestment(parseFloat(investmentRequired));
          } else if (this.props.paymentType == "Product") {
            let investmentRequired = this.props.campaignCreationSubscription.influencersScopeCount;
            this.props.fetchChangePaymentPerEach(parseFloat(investmentRequired));
          }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Button 
                color="info" 
                className={classes.alignCenter}
                onClick={() => this.automaticAdjustmentInvestment()}>
                Automatic Adjustment
            </Button>
              
        );
    }
}

AutomaticAdjustmentInvestment.propTypes = {
  classes: PropTypes.object.isRequired
};


function mapStateToProps(state){
  return {
    campaignCreationSubscription: state.campaignCreationSubscription,
    enabledEdit: state.campaignCreation.enabledEdit,
    paymentType: state.campaignCreation.paymentType,

  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchChangePaymentPerEach: bindActionCreators(fetchChangePaymentPerEach, dispatch),
    fetchChangeInvestment: bindActionCreators(fetchChangeInvestment, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AutomaticAdjustmentInvestment));
