import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "./tags";
import { fetchvalidateToken } from "../../state/actions/login";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Menu from "@material-ui/icons/Menu";
import Language from "@material-ui/icons/Language";
import Adme from "../../assets/img/general/adme1.ico";

// core components
import Button from "components/CustomButtons/Button";

import pagesRoutes from "routes/pages.jsx";

import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx";

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.changeLocale = this.props.changeLocale.bind(this);
  }

  handleEn = () => {
    debugger;
    this.changeLocale("en");
  };

  handleEs = () => {
    this.changeLocale("es");
  };

  componentWillMount() {
    if(this.props.login.user === null && localStorage.getItem('@token') !== null)
      this.props.fetchvalidateToken({app: "Web", token: localStorage.getItem('@token')});
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }
  render() {
    const { classes, color, changeLocale } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });

    let panelOK = false;
    if(this.props.login.user != null) {
      this.props.login.user.permissions.forEach(element => {
        if (element === "panelAccess") panelOK = true;
      })
    }
    
    var list = (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
        {
          panelOK ?
          <NavLink to={"/panel/start"} className={classes.navLink}>
            <ListItemIcon className={classes.listItemIcon}>
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={this.props.tags.dashboard} //"Dashboard"
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
          :
          null
        }
        </ListItem>
        {pagesRoutes.map((prop, key) => {
          if (!prop.view) return null;
          if (prop.redirect) {
            return null;
          }
          const navLink =
            classes.navLink +
            cx({
              [" " + classes.navLinkActive]: this.activeRoute(prop.path)
            });
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={this.props.tags[prop.short]}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
        <ListItem key={1} className={classes.listItem}>
            <NavLink to={"#"} onClick={() => this.handleEn()} className={classes.navLink}>
              <ListItemIcon className={classes.listItemIcon}>
                <Language />
              </ListItemIcon>
              <ListItemText
                primary={this.props.tags.english}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
            </ListItem>
          <ListItem key={2} className={classes.listItem}>
            <NavLink to={"#"} onClick={() => this.handleEs()}  className={classes.navLink}>
              <ListItemIcon className={classes.listItemIcon}>
                <Language />
              </ListItemIcon>
              <ListItemText
                primary={this.props.tags.spanish}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
          <div className={classes.flex}>
          </div>
          </Hidden>
          <Hidden smDown>{list}</Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}

              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

function mapStateToProps(state){
  return {
    login: state.login,

  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchvalidateToken: bindActionCreators(fetchvalidateToken, dispatch)
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(pagesHeaderStyle)(PagesHeader)));
