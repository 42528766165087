import { translatable } from "react-multilingual";

const mapTranslationsToProps = ({
    listOfCompanies,
    loading,
    youHaveSeenItAll,
    campaigns,
    engagement,
    investment,
    stock,
    velocity,
    active,
    engagementVelocity,
    draft,
    waitingForPayment,
    finished,
    onGoing,
    stoped,
    campaignTotal,
    lastThreeActiveCampaings,
    realVelocity,
    velocityExpented,
    real,
    expented,
    totalInvestment,
    spent,
    freezed,
    availible,
    withoutCampaigns,
    withoutCampaignsCreated,
    searchCompany,
    waitingForApproval
}) => ({tags:{listOfCompanies,
        loading,
        youHaveSeenItAll,
        campaigns,
        engagement,
        investment,
        stock,
        velocity,
        active,
        engagementVelocity,
        draft,
        waitingForPayment,
        finished,
        onGoing,
        stoped,
        campaignTotal,
        lastThreeActiveCampaings,
        realVelocity,
        velocityExpented,
        real,
        expented,
        totalInvestment,
        spent,
        freezed,
        availible,
        withoutCampaigns,
        withoutCampaignsCreated,
        searchCompany,
        waitingForApproval
    }});

export default translatable(mapTranslationsToProps);