import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Timeline from "@material-ui/icons/Timeline";
import CardBody from "components/Card/CardBody.jsx";
import Tooltip from "@material-ui/core/Tooltip";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

import {
    pieChart
  } from "variables/charts";

function InvestmentStatus({ ...props }) {
    const {
        classes,
        data,
        tags
    } = props;

    let percentajes = {
        budgetSpent: 100 / data.investment * data.budgetSpent,
        budgetFreezed: 100 / data.investment * data.budgetFreezed,
        budgetAvailable: 100 / data.investment * data.budgetAvailable
    }

    const investmentStatus = {
        data: {
            labels: [ 
                    `${percentajes.budgetSpent.toFixed(0)}%`,
                    `${percentajes.budgetFreezed.toFixed(0)}%`, 
                    `${percentajes.budgetAvailable.toFixed(0)}%`,
                    ],
            series: [
                    percentajes.budgetSpent.toFixed(0),
                    percentajes.budgetFreezed.toFixed(0),
                    percentajes.budgetAvailable.toFixed(0),
                    ]
        }
    }

    return (
        <GridItem xs={12} sm={10} md={2} className={classes.center}>
            <Card>
                <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                    <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{tags.investmentMin}</h4>
                </CardHeader>
                <CardBody minWidth230={true}>
                    {`${props.tags.investment} ${data.investment}`}
                    <ChartistGraph
                        data={investmentStatus.data}
                        type="Pie"
                        options={pieChart.optionsMin}
                        />
                        {` `}
                        <Tooltip
                            id="tooltip-top"
                            title={`${tags.spent}: ${data.budgetSpent.toFixed(2)} $`}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <i className={"fas fa-circle " + classes.colorOnGoing}>{` `}</i>
                        </Tooltip>
                        {` `}
                        <Tooltip
                            id="tooltip-top"
                            title={`${tags.freezed}: ${data.budgetFreezed.toFixed(2)} $`}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <i className={"fas fa-circle " + classes.danger}>{` `}</i>
                        </Tooltip>
                        {` `}
                        <Tooltip
                            id="tooltip-top"
                            title={`${tags.availible}: ${data.budgetAvailable.toFixed(2)} $`}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <i className={"fas fa-circle " + classes.colorFinished}>{` `}</i>
                        </Tooltip>
                </CardBody>
            </Card>
        </GridItem>
    )
}

export default InvestmentStatus;