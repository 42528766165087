/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex = null }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, columns, classes, sort } = this.props;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc',
    };

    const inner =
      !columns[columnIndex].disableSort && sort != null ? (
        <TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
          {label}
        </TableSortLabel>
      ) : (
        label
      );

    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
              let renderer;
              if (cellContentRenderer != null) {
                renderer = cellRendererProps =>
                  this.cellRenderer({
                    cellData: cellContentRenderer(cellRendererProps),
                    columnIndex: index,
                  });
              } else {
                renderer = this.cellRenderer;
              }

              return (
                <Column
                  key={dataKey}
                  headerRenderer={headerProps =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classNames(classes.flexContainer, className)}
                  cellRenderer={renderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 56,
  rowHeight: 56,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const createData = (data) => {
  return {
    itemIcon: data.itemIcon,
    itemDescription: data.itemDescription,
    price: `$ ${data.price}` 
    }
}

function tableOfRow(props) {
  const {
    item,
    advertisementType,
  }  = props;
    let rows = [];

    if (advertisementType === "Sponsorship")
      rows.push(createData({itemIcon: <i className="fas fa-share-square"></i>, itemDescription: `${props.tags.advertisement}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.AdPrice : 0}));
    else if (advertisementType === "Advertising")
      rows.push(createData({itemIcon: <i className="fas fa-share-square"></i>, itemDescription: `${props.tags.advertisement}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.AdPrice : 0}));
    else if (advertisementType === "Spot")
      rows.push(createData({itemIcon: <i className="fas fa-share-square"></i>, itemDescription: `${props.tags.advertisement}`, price: item._person.spotEffectivenessReference !== null ? item._person.spotEffectivenessReference.AdPrice : 0}));

    if (item.linkIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="fas fa-globe"></i>, itemDescription: `${props.tags.webSite}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.webSitePrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="fas fa-globe"></i>, itemDescription: `${props.tags.webSite}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.webSitePrice : 0}));
    }

    if (item.bannerIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="far fa-image"></i>, itemDescription: `${props.tags.frame}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.framePrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="far fa-image"></i>, itemDescription: `${props.tags.frame}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.framePrice : 0}));
    }

    if (item.hashtagIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="fas fa-hashtag"></i>, itemDescription: `${props.tags.hashtag}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.hashtagPrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="fas fa-hashtag"></i>, itemDescription: `${props.tags.hashtag}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.hashtagPrice : 0}));
    }

    if (item.watermarkIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="fas fa-paint-brush"></i>, itemDescription: `${props.tags.seal}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.sealPrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="fas fa-paint-brush"></i>, itemDescription: `${props.tags.seal}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.sealPrice : 0}));
    }

    if (item.productUsageIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="fab fa-product-hunt"></i>, itemDescription: `${props.tags.productUsage}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.productUsagePrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="fab fa-product-hunt"></i>, itemDescription: `${props.tags.productUsage}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.productUsagePrice : 0}));
    }

    if (item.mentionToCompanyIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="fas fa-at"></i>, itemDescription: `${props.tags.tagMentionCompany}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.tagMentionMePrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="fas fa-at"></i>, itemDescription: `${props.tags.tagMentionCompany}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.tagMentionMePrice : 0}));
    }

    if (item.mentionToOtherIncluded) {
        if (advertisementType === "Sponsorship")
            rows.push(createData({itemIcon: <i className="fas fa-users"></i>, itemDescription: `${props.tags.tagMentionPeople}`, price: item._person.sponsorshipEffectivenessReference !== null ? item._person.sponsorshipEffectivenessReference.tagMentionPeoplePrice : 0}));
        else if (advertisementType === "Advertising")
            rows.push(createData({itemIcon: <i className="fas fa-users"></i>, itemDescription: `${props.tags.tagMentionPeople}`, price: item._person.advertisingEffectivenessReference !== null ? item._person.advertisingEffectivenessReference.tagMentionPeoplePrice : 0}));
    }
    
  return (
    <Paper style={{ height: 370, width: '100%' }}>
    {
      <WrappedVirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        onRowClick={event => console.log(event)}
        columns={[
          {
            width: 70,
            flexGrow: 1.0,
            label: '',
            dataKey: 'itemIcon',
          },
          {
            width: 150,
            label:`${props.tags.item}` ,
            dataKey: 'itemDescription',
            numeric: true,
          },
          {
            width: 150,
            label: `${props.tags.price}`,
            dataKey: 'price',
            numeric: true,
          },
        ]}
      /> 
    }
      
    </Paper>
  );
}

export default tableOfRow;
