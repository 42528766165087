import { FETCH_SPOTEFFECTIVENESSES_DATA_SUCCESS,
} from "../actionTypes";

const initialState = {
    spotEffectiveness: [],
    spotEffectivenessRead: []
}

export default (state = initialState, action) => {
switch (action.type) {
   case FETCH_SPOTEFFECTIVENESSES_DATA_SUCCESS:{
    let spotEffectivenessRead = [];
    
    for (let index = 0; index < action.payload.data.length; index++) {
        const element = action.payload.data[index];
        let newItem = {...element, idSlide: index};
        spotEffectivenessRead.push(newItem);
    }
    
    return {
        ...state,
        spotEffectiveness: action.payload.data,
        spotEffectivenessRead
        }
   }
       
   default:
       return state;
    }
}