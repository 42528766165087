import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "../tags";
import styles from "../styleIndex";
import Genre from "../ComponentsSteps/step3/genre";
import Assessment from "../ComponentsSteps/step3/assessment";
import Age from "../ComponentsSteps/step3/age";
import CategoryOfInfluencers from "../ComponentsSteps/step3/categoryOfInfluencers";
import { 
  fetchChangeSexes, 
  fetchUpdateCampaign, 
  fetchChangeSexesAdd, 
  fetchChangeSexesRemove,
  fetchChangeStars,
  fetchChangeAges,
  fetchChangeCategories,
  fetchChangeCategoriesAdd,
  fetchChangeCategoriesRemove
} from "../../../../state/actions/campaignCreation";
import { fetchSubscriptionRecalculating } from "../../../../state/actions/campaignCreationSubscription";
// @material-ui/core components
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.handleWidgets = this.handleWidgets.bind(this);
    this.handleStar = this.handleStar.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handleChangeGenre = this.handleChangeGenre.bind(this);
    this.handleAge = this.handleAge.bind(this);
}

componentWillMount() {
    
}

shouldComponentUpdate(nextProps, nextState) {
      if(nextProps.campaignCreation.sexCriterias.length !== 0 && this.props.campaignCreation.sexCriterias.length !== 0 && this.props.campaignCreation.sexCriterias[0]._id !== nextProps.campaignCreation.sexCriterias[0]._id)
      this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id,
        sexCriterias: nextProps.campaignCreation.sexCriterias,
        specificTarget: nextProps.campaignCreation.specificTarget,
        type: nextProps.campaignCreation.type
      });
      else if(this.props.campaignCreation.sexCriterias.length !== nextProps.campaignCreation.sexCriterias.length) {
        this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
          sexCriterias: nextProps.campaignCreation.sexCriterias,
          specificTarget: nextProps.campaignCreation.specificTarget,
          type: nextProps.campaignCreation.type
        });
      }

      if(this.props.campaignCreation.platformStarCriterias.length !== nextProps.campaignCreation.platformStarCriterias.length) {
        this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
          platformStarCriterias: nextProps.campaignCreation.platformStarCriterias,
          specificTarget: nextProps.campaignCreation.specificTarget,
          type: nextProps.campaignCreation.type
        });
      }

      if(this.props.campaignCreation.ageCriterias.length !== nextProps.campaignCreation.ageCriterias.length) {
        this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
          ageCriterias: nextProps.campaignCreation.ageCriterias,
          specificTarget: nextProps.campaignCreation.specificTarget,
          type: nextProps.campaignCreation.type
        });
      }

      if(this.props.campaignCreation.categoryCriterias.length !== nextProps.campaignCreation.categoryCriterias.length) {
        this.props.fetchUpdateCampaign({_id: nextProps.campaignCreation._id, 
          categoryCriterias: nextProps.campaignCreation.categoryCriterias,
          specificTarget: nextProps.campaignCreation.specificTarget,
          type: nextProps.campaignCreation.type});
      }
    
  return true;
}

isValidated() {
  return true;
}

handleCategory(value) {
    if (this.props.campaignCreation.enabledEdit) {
      if(this.props.campaignCreation.categoryCriterias.find(item => item._id === value._id) !== undefined) {
        this.props.fetchChangeCategoriesRemove(value);
      } else { this.props.fetchChangeCategoriesAdd(value); }

      this.props.fetchSubscriptionRecalculating();
    }
  }

  sendState() {
    return this.state;
  }
  
  handleChangeGenre = value => event => {
    if (this.props.campaignCreation.enabledEdit){
      if (event.target.checked) this.props.fetchChangeSexesAdd(value)
      else if(this.props.campaignCreation.sexCriterias.length === 1) {
        this.props.fetchChangeSexesRemove(value);
        this.props.sexes.sexes.forEach(element => {
          if(element._id !== value._id) this.props.fetchChangeSexesAdd(element);
        });
      } else { this.props.fetchChangeSexesRemove(value); }

      this.props.fetchSubscriptionRecalculating();
    }
  };

  handleWidgets(value) {
    if (this.props.campaignCreation.enabledEdit){
      switch (value) {
        case "genre":
          var currentIndex = this.props.campaignCreation.sexCriterias.length !== 0 ? true : false;
          if (currentIndex === true) {
            this.props.fetchChangeSexes([]);
          } else {
            this.props.fetchChangeSexes(this.props.sexes.sexes);
          }
          this.props.fetchSubscriptionRecalculating();
          break;
        case "assessment":
          var currentIndex = this.props.campaignCreation.platformStarCriterias.length !== 0 ? true : false;
          if (currentIndex === true) {
            this.props.fetchChangeStars({forBD: [], forUI: [
              {enabled: false, value: 1},
              {enabled: false, value: 2},
              {enabled: false, value: 3},
              {enabled: false, value: 4},
              {enabled: false, value: 5}
            ]});
          } else {
            this.props.fetchChangeStars({forBD: this.props.stars.stars, forUI: [
              {enabled: false, value: 1},
              {enabled: false, value: 2},
              {enabled: false, value: 3},
              {enabled: false, value: 4},
              {enabled: false, value: 5}
            ]});
          }
          this.props.fetchSubscriptionRecalculating();
        break;
          break;
        case "age":
          var currentIndex = this.props.campaignCreation.ageCriterias.length !== 0 ? true : false;
          if (currentIndex === true) {
            this.props.fetchChangeAges({from: 0, to: 0, ages: [], changeSlide: true});
          } else {
            this.props.fetchChangeAges({from: 0, to: 99, ages: this.props.ages.ages, changeSlide: true});
          }
          this.props.fetchSubscriptionRecalculating();
          break;
        case "categoryOfInfluencers":
          var currentIndex = this.props.campaignCreation.categoryCriterias.length !== 0 ? true : false;
          if (currentIndex === true) {
            this.props.fetchChangeCategories([]);
          } else {
            this.props.fetchChangeCategories(this.props.categories.categories);
          }
          this.props.fetchSubscriptionRecalculating();
          break;
        default:
          break;
      }
    }
  }

  handleStar(value) {
    if (this.props.campaignCreation.enabledEdit) {
      if(this.props.stars.stars != [] && this.props.stars.stars.length !== 0) {

        var currentIndex = this.props.campaignCreation.platformStarCriteriasRead[value];
        var enabled = currentIndex.enabled;
        if (currentIndex.enabled === true && currentIndex.value === 1) {
          enabled = false;
        } else if (currentIndex.enabled === false && currentIndex.value === 1) {
          enabled = true;
        } else enabled = true;

        var starForDB = this.props.stars.stars.reduce((newArray, item) => {
          if (item.stars >= currentIndex.value) newArray.push(item);
          return newArray;
        }, []);


        let starForUI = this.props.campaignCreation.platformStarCriteriasRead.reduce((newArray, item) => {
          (item.value > currentIndex.value) ? item.enabled = false : item.enabled = true;
          if (item.value === currentIndex.value) { item.enabled = enabled };
          newArray.push(item);
          return newArray;
        }, [])

        let item0 = this.props.stars.stars.filter(it => it.stars === 0)[0];
        if(starForUI.filter(item => item.value === 1)[0].enabled === false) starForDB.push(item0)
        this.props.fetchChangeStars({forBD: starForDB, forUI: starForUI});

        this.props.fetchSubscriptionRecalculating();
      }
    }
  }

  handleAge = () => (render, handle, value, un, percent) => {
    if (this.props.campaignCreation.enabledEdit) {
      if(this.props.ages !== undefined && this.props.ages.ages.length !== 0) this.props.fetchChangeAges({from: value[0], to: value[1], ages: this.props.ages.ages, changeSlide: false});

      this.props.fetchSubscriptionRecalculating();
    }
  };

  render() {
    const { classes } = this.props;
    //const { edadDesde, edadHasta, star } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <Genre
                  classes={classes}
                  widgets={
                    this.props.campaignCreation.sexCriterias.length !== 0 ? true : false
                  }
                  handleWidgets={this.handleWidgets}
                  handleChange={this.handleChangeGenre}
                  checkedFemale={this.props.campaignCreation.sexCriterias.filter(item => item.name === "Female").length !== 0 ? true : false}
                  valueFemale={this.props.sexes.itemFemale !== null ? this.props.sexes.itemFemale : null}
                  checkedMale={this.props.campaignCreation.sexCriterias.filter(item => item.name === "Male").length !== 0 ? true : false}
                  valueMale={this.props.sexes.itemMale !== null ? this.props.sexes.itemMale : null }
                  {...this.props}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Assessment
                  classes={classes}
                  widgets={this.props.campaignCreation.platformStarCriterias.length !== 0 ? true : false}
                  handleWidgets={this.handleWidgets}
                  handleStar={this.handleStar}
                  star={this.props.campaignCreation.platformStarCriteriasRead}
                  {...this.props}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Age
                  classes={classes}
                  widgets={this.props.campaignCreation.ageCriterias.length !== 0 ? true : false}
                  handleWidgets={this.handleWidgets}
                  handleAge={this.handleAge}
                  ageLabelFrom={this.props.campaignCreation.ageCriteriasLabelFrom}
                  ageLabelTo={this.props.campaignCreation.ageCriteriasLabelTo}
                  ageSlideFrom={this.props.campaignCreation.ageCriteriasSlideFrom}
                  ageSlideTo={this.props.campaignCreation.ageCriteriasSlideTo}
                  {...this.props}
                />
              </GridItem>
              <GridItem xs={12}>
                <CategoryOfInfluencers
                  classes={classes}
                  widgets={
                    this.props.campaignCreation.categoryCriterias.length !== 0 ? true : false
                  }
                  checked={this.props.campaignCreation.categoryCriterias}
                  handleWidgets={this.handleWidgets}
                  handleCategory={this.handleCategory}
                  //categories={this.props.categories.categories}
                  {...this.props}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    campaignCreation: state.campaignCreation,
    sexes: state.sexes,
    stars: state.stars,
    ages: state.ages,
    categories: state.categories
  }
}

function mapDispatchToProps(dispatch){
  return {
    //-GENRE
    fetchChangeSexes: bindActionCreators(fetchChangeSexes, dispatch),
    fetchChangeSexesAdd: bindActionCreators(fetchChangeSexesAdd, dispatch),
    fetchChangeSexesRemove: bindActionCreators(fetchChangeSexesRemove, dispatch),
    //-STAR
    fetchChangeStars: bindActionCreators(fetchChangeStars, dispatch),
    //-AGE
    fetchChangeAges: bindActionCreators(fetchChangeAges, dispatch),
    //-CATEGORIES
    fetchChangeCategories: bindActionCreators(fetchChangeCategories, dispatch),
    fetchChangeCategoriesAdd: bindActionCreators(fetchChangeCategoriesAdd, dispatch),
    fetchChangeCategoriesRemove: bindActionCreators(fetchChangeCategoriesRemove, dispatch),
    //---------------------------
    fetchUpdateCampaign: bindActionCreators(fetchUpdateCampaign, dispatch),
    fetchSubscriptionRecalculating: bindActionCreators(fetchSubscriptionRecalculating, dispatch),
  }
}

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step3)));
