import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import tags from "../tags";
import withStyles from "@material-ui/core/styles/withStyles";
import { fetchChangeCompanyLogo, fetcheChangeCompanyData } from "../../../state/actions/login";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import LinearProgress from '@material-ui/core/LinearProgress';
import firebase from 'firebase';
import moment from 'moment';
 
const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

const styles = {
    width70percentage: {
      'max-width': '70% !important'
    },
    backgroundColor: {
      'background-color': 'green'
    },
  };

class imageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      urlFireBase: '',
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSnapshot = this.handleSnapshot.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.getDownload = this.getDownload.bind(this);
  }
  async handleSnapshot(snapshot){
        var progressSnap = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
            progress: progressSnap
        });
        if (progressSnap === 100) {
          await sleep(1000);
          this.getDownload(snapshot);
        }
  }
  async getDownload(snapshot){
    try {
      var result = await snapshot.ref.getDownloadURL();
      this.getUrl(result);
    } catch (error) {
    }
  }
  getUrl(url) {
    this.setState({
        urlFireBaserl: url
    });
    
    this.props.fetchChangeCompanyLogo({
      file: null,
      type: "image",
      imagePreviewUrl: url,
    });

    this.props.fetcheChangeCompanyData({_id: this.props.login.user.company.id, logo: url});

  }
  handleImageChange(e) {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
          if (file.type === "image/png" || file.type === "image/jpeg") {
             this.props.fetchChangeCompanyLogo(
              {
                file: file,
                type: "image",
                imagePreviewUrl: reader.result,
              });
          }
      };

      if (file.type === "image/png" || file.type === "image/jpeg"){
          let folder = null;
          if (file.type === "image/png" || file.type === "image/jpeg"){
              folder = `/company/images/${this.props.login.user.company.name}_${this.props.login.user.company.id}_logo`;
          }
          this.props.fetchChangeCompanyLogo({
            file: null,
            type: null,
            imagePreviewUrl: null,
            });
          const storageRef = firebase.storage().ref(`${folder}`);

          var uploadTask = storageRef.put(file);

          uploadTask.on('state_changed', this.handleSnapshot, function(error) {
                }, function(){})
      }

      reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.props.fetchChangeCompanyLogo(
      {
          file: null,
          type: null,
          imagePreviewUrl: null
      });
    this.refs.fileInput.value = null;
    this.props.fetcheChangeCompanyData({_id: this.props.login.user.company.id, logo: ''});
  }

  render() {
    var {
    classes,
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
    } = this.props;

    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        { 
              <div className={`thumbnail ${classes.width70percentage}`}>
                  {
                    this.state.progress !== 100 ?
                      <LinearProgress variant="determinate" value={this.state.progress} />
                      :
                      <LinearProgress className={classes.backgroundColor} variant="determinate" value={0} />
                  }
                  {
                    this.props.login.media.imagePreviewUrl !== null ? 
                      <img src={this.props.login.media.imagePreviewUrl} alt="..." />
                      :
                      <img src={defaultImage} alt="..." />
                  }   
              </div>
        }
        <div>
          {this.props.login.media.imagePreviewUrl === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar ? this.props.tags.addPhoto: this.props.tags.selectImage}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                {this.props.tags.change}
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> {this.props.tags.remove}
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

imageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

function mapStateToProps(state){
    return {
      login: state.login,
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
      fetchChangeCompanyLogo: bindActionCreators(fetchChangeCompanyLogo, dispatch),
      fetcheChangeCompanyData: bindActionCreators(fetcheChangeCompanyData, dispatch),
    }
  }

//export default tags(connect(mapStateToProps, mapDispatchToProps)(imageUpload));

export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(imageUpload)));
