import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./styleIndex";
import tags from "./tags";
import { bindActionCreators } from "redux";
import { fetchCompaniesData } from "../../../state/actions/companies";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import ArtTrack from "@material-ui/icons/ArtTrack";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { NavLink } from "react-router-dom";

import advertising from "assets/img/general/advertising.png";
import sponsorship from "assets/img/general/sponsorship.png";
import spot from "assets/img/general/spot.png";

class Start extends React.Component {
  state = {
    value: 0
  };

  componentWillMount() {
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.cardIconTitle}>
            {`Select campaign type `} { this.props.history.location.state !== undefined ? this.props.history.location.state.companyName :  this.props.login.user.company.name }
            </h4>
        <br />
        <GridContainer justify="center">
          <GridItem xs={9} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={`${classes.cardHeaderHover} ${classes.minHeightHeader}`}>
                <NavLink to={{pathname:"/panel/WizardAction",
                  state: { ...this.props.history.location.state, companyId: this.props.history.location.state !== undefined ? this.props.history.location.state.companyId : this.props.login.user.company.id,
                    type: "Spot",
                    campaignId: null}}}>
                  <img src={spot} alt="..." />
                </NavLink>
              </CardHeader>
              <CardBody className={classes.minHeightBody}>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title={this.props.tags.viewVideo}
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#Spot" onClick={e => e.preventDefault()}>
                    {this.props.tags.spotCampaign}
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  {this.props.tags.spotDescription1}
                </p>
                <p className={classes.cardProductDesciprion}>
                  {this.props.tags.spotDescription2}
                </p>
              </CardBody>
              {/* <CardFooter product>
                <div className={classes.price}>
                  <h4>{this.props.tags.spotCounter}</h4>
                </div>
              </CardFooter> */}
            </Card>
          </GridItem>
          <GridItem xs={9} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={`${classes.cardHeaderHover} ${classes.minHeightHeader}`}>
                <NavLink to={{pathname:"/panel/WizardAction",
                  state: { ...this.props.history.location.state, companyId: this.props.history.location.state !== undefined ? this.props.history.location.state.companyId : this.props.login.user.company.id,
                    type: "Sponsorship",
                    campaignId: null}}}>
                  <img src={sponsorship} alt="..." />
                </NavLink>
              </CardHeader>
              <CardBody className={classes.minHeightBody}>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View Video"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#sponsorship" onClick={e => e.preventDefault()}>
                    {this.props.tags.sponsorshipCampaign}
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  {this.props.tags.sponsorshipCampaignDescription1}
                </p>
                <p className={classes.cardProductDesciprion}>
                  {this.props.tags.sponsorshipCampaignDescription2}
                </p>
              </CardBody>
              {/* <CardFooter product>
                <div className={classes.price}>
                  <h4>{this.props.tags.sponsorshipCampaignCounter}</h4>
                </div>
              </CardFooter> */}
            </Card>
          </GridItem>
          <GridItem xs={9} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={`${classes.cardHeaderHover} ${classes.minHeightHeader}`}>
                <NavLink to={{pathname:"/panel/WizardAction",
                  state: { ...this.props.history.location.state, companyId: this.props.history.location.state !== undefined ? this.props.history.location.state.companyId : this.props.login.user.company.id,
                           type: "Advertising",
                           campaignId: null}}}>
                  <img src={advertising} alt="..." />
                </NavLink>
              </CardHeader>
              <CardBody className={classes.minHeightBody}>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View video"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#Advertising" onClick={e => e.preventDefault()}>
                    {this.props.tags.advertisingCampaign}
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  {this.props.tags.advertisingDescription1}
                </p>
                <p className={classes.cardProductDesciprion}>
                  {this.props.tags.advertisingDescription2}
                </p>
              </CardBody>
              {/* <CardFooter product>
                <div className={classes.price}>
                  <h4>{this.props.tags.advertisingCounter}</h4>
                </div>
              </CardFooter> */}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Start.propTypes = {
  classes: PropTypes.object.isRequired
};


function mapStateToProps(state){
  return {
    campaigns: state.campaigns,
    login: state.login
  }
}

function mapDispatchToProps(dispatch){
  return {
    fetchCompaniesData: bindActionCreators(fetchCompaniesData, dispatch)
  }
}
export default tags(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Start)));
