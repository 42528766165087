var query = {
    spoteffectivenesses: `
    query spotEffectiveness(
        $limit: Float
        $page: Float
        $orderBy: String
        $asc: Boolean
        $_id: ID
        $from: Int
        $to: Int
    )
    {
        spotEffectiveness(
            limit: $limit
            page: $page
            orderBy: $orderBy
            asc: $asc
            _id: $_id
            from: $from
            to: $to
        ){
            _id,
            from,
            to,
            AdPrice,
            freezedBudget,
            insightPriceFactor,
            totalPrice,
        }
    }
    `
}
export default {
        query
};