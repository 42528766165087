import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import Language from "@material-ui/icons/Language";

function Insights({ ...props }) {
    const {
         classes,
         data,
      } = props;
  
    return (
        <GridItem xs={12} sm={12} md={4}>
                <Card>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                    <Language />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                    {`${props.tags.insightsByReactions} Total: ${data.totalCount}`}  
                    </h4>
                </CardHeader>
                <CardBody>
                        <Table
                        tableData={[
                            [
                            <i className="far fa-thumbs-up"></i>,
                            `${props.tags.like}`,
                            data.likeCount,
                            `${data.likePercentage} %`
                            ],
                            [
                            <i className="fas fa-link"></i>,
                            `${props.tags.link}`,
                            data.linkCount,
                            `${data.linkPercentage} %`
                            ],
                            [
                            <i className="fas fa-tags"></i>,
                            `${props.tags.tag}`,
                            data.tagCount,
                            `${data.tagPercentage} %`
                            ],
                            [
                            <i className="fas fa-share-alt"></i>,    
                            `${props.tags.shared}`,
                            data.sharedCount,
                            `${data.sharedPercentage} %`
                            ],
                            [
                            <i className="fas fa-at"></i>,
                            `${props.tags.mentionValue}`,
                            data.mentionCount,
                            `${data.mentionPercentage} %`
                            ],
                            [
                            <i className="fas fa-hashtag"></i>,    
                            `${props.tags.hashtag}`,
                            data.hashtagCount,
                            `${data.hashtagPercentage} %`
                            ],
                            [
                            <i className="fas fa-print"></i>,
                            `${props.tags.print}`,
                            data.printCount,
                            `${data.printPercentage} %`
                            ],
                            [
                            <i className="fas fa-comments"></i>,
                            `${props.tags.comment}`,
                            data.commentCount,
                            `${data.commentPercentage} %`
                            ]
                        ]}
                        />
                </CardBody>
                </Card>
        </GridItem >
    )
}


export default Insights;