import { put, takeLatest } from "redux-saga/effects";
import { 
    fetchCitiesDataSuccess,
    fetchCitiesDataFail,
    fetchStatesDataSuccess,
    fetchStatesDataFail,
    fetchCountriesDataSuccess,
    fetchCountriesDataFail
 } from "../actions/regions";
import {
    FETCH_COUNTRIES_DATA,
    FETCH_STATES_DATA,
    FETCH_CITIES_DATA,
} from '../actionTypes';
import api from '../api';

function* fetchCountriesData(action) {
    try {
          let data = yield api.regions.getRegionsData(action.payload);
          yield put(fetchCountriesDataSuccess({data:data.regions}));
    } catch (e) {
          yield put(fetchCountriesDataFail());
    }
}

function* fetchStatesData(action) {
    try {
        let data = yield api.regions.getRegionsData(action.payload);
        yield put(fetchStatesDataSuccess({data:data.regions}));
    } catch (e) {
        yield put(fetchStatesDataFail());
    }
}

function* fetchCitiesData(action) {
    try {
        let data = yield api.regions.getRegionsData(action.payload);
        yield put(fetchCitiesDataSuccess({data:data.regions}));
    } catch (e) {
        yield put(fetchCitiesDataFail());
    }
  }

export default function* regionsSaga() {
    yield takeLatest(FETCH_COUNTRIES_DATA, fetchCountriesData);
    yield takeLatest(FETCH_STATES_DATA, fetchStatesData);
    yield takeLatest(FETCH_CITIES_DATA, fetchCitiesData);
}