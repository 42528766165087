import { Login } from "../mutations/login";
import { GrahpQL } from "../mutations/parametersMutations";

export default {
  login: function* login(payload) {
    try {
      const mutation = Login.login;
      const response = yield GrahpQL.mutation(mutation, payload);
      return response.body;
    } catch (e) {}
  },
  validateToken: function* validateToken(payload) {
    try {
      const mutation = Login.validateToken;
      const response = yield GrahpQL.mutation(mutation, payload);
      return response.body;
    } catch (e) {}
  },
  ChangeCompanyData: function* ChangeCompanyData(payload) {
    try {
      const mutation = Login.changeCompanyData;
      const response = yield GrahpQL.mutation(mutation, payload);
      return response.body;
    } catch (e) {}
  }
};
